import { useEffect, useRef } from 'react';

import { agentAuth, useAgentAuth } from '@ecp/utils/auth';
import * as botcontrol from '@ecp/utils/botcontrol';
import { useEvent } from '@ecp/utils/react';
import { Switch } from '@ecp/utils/routing';
import { sessionStorage } from '@ecp/utils/storage';

import { useRealignLogoSpinner } from '@ecp/components';
import { env } from '@ecp/env';
import { TeardownController, useEnsureSessionCompleted } from '@ecp/features/sales/shared/misc';
import { useNavRules } from '@ecp/features/sales/shared/routing';
import {
  getBootstrapSessionInProgress,
  getGlobalError,
  getInitializing,
  getStartedInitializing,
  startOverFlow,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { useGoogleAnalytics } from '@ecp/features/sales/shared/utils/analytics';
import { useFlags } from '@ecp/features/sales/shared/utils/flags';
import { useHotjar } from '@ecp/features/sales/shared/utils/hotjar';
import { useLogger } from '@ecp/features/sales/shared/utils/logger';
import { bootstrapSession, SessionErrorStopPage } from '@ecp/features/sales/shell';
// TODO - Figure out why this eslint ignore is needed, otherwise app crashes :(
// eslint-disable-next-line import/order
import { ErrorBoundary, SessionExpiryPromptDialog } from '@ecp/features/sales/shared/components';

import { PageLayout } from '../common/components';
import { dynamicRoutes, staticPaths, staticRoutes } from '../routing';

interface EnsureSessionProps {
  setEnsureSessionCompleted: (val: boolean) => void;
  ensureDuplicateTab: React.MutableRefObject<boolean>;
}

const EnsureSession: React.FC<EnsureSessionProps> = (props) => {
  const dispatch = useDispatch();
  const { setEnsureSessionCompleted, ensureDuplicateTab } = props;
  const startedInitializing = useSelector(getStartedInitializing);
  const initializing = useSelector(getInitializing);
  const bootstrapInProgress = useSelector(getBootstrapSessionInProgress);

  // HACK to handle a special case when user clicks on duplicate tab,
  // where browser copies sessionStorage over to new tab. There is no clean way to determine this event.
  // Runs once on app start.
  useEffect(() => {
    // Only run if duplicate tab was not yet ensured, as this component might get removed and rendered anew apparently,
    // so ensureDuplicateTab global var is our escape hatch
    if (!ensureDuplicateTab.current) {
      /**
       * New browsers / browser versions are caching not only static resources,
       * but also window object, which includes document and everything related.
       * When such browser navigates FROM our page/app through history traversal,
       * window object still resides in the browser's cache, therefore our app and its state are also cached.
       * So, such browser does not call unload event, but rather pagehide.
       * Unload is only called when the page is gone for good (close the tab, click refresh or enter app URL into the address bar and hit Enter).
       * Now, when browser navigates TO our page/app through history traversal, it is loaded from the cache.
       * So, such browser does not call load event, but rather pageshow.
       * Therefore not only static assets are loaded, but also our app and its state are also loaded from the cache.
       * And our app root is not re-rendered.
       * ! On Safari (Mac and iOS) pageshow / pagehide events are only fired once, then they are gone:
       * ! https://bugs.webkit.org/show_bug.cgi?id=156356 - Apple claims to have it fixed on 2020-05-27 (latest Safari versions)
       * Therefore on Safari when navigating from the app then back to the app, it won't reload.
       * It happens, because we already lost pagehide event listener, after we called window.location.reload at the app start.
       */

      const leaveHandler = (event: BeforeUnloadEvent): void => {
        sessionStorage.setItem('tabId', '123', true);
        // eslint-disable-next-line no-param-reassign
        if ('returnValue' in event) delete event.returnValue;
      };

      // When user within same tab back/forward navigates FROM the app or clicks refresh,
      // or enters our app URL into the address bar and hits Enter, set tabId flag, which we check on every start.
      // Case for the browsers, that employ page caching mechanism - `pagehide` and for browsers, that do not - `beforeunload`
      const leaveEventName = 'onpagehide' in window ? 'pagehide' : 'beforeunload';
      window.addEventListener(leaveEventName, leaveHandler);

      const enterHandler = async (): Promise<void> => {
        if (sessionStorage.getItem('tabId', true)) {
          // Only clear out tabId, so it won't be copied to a new tab when user clicks on duplicate tab,
          // otherwise tabId will be of no help to us.
          // Reuse same session.
          sessionStorage.removeItem('tabId', true);

          // Default case when user opens new tab or window
        } else {
          // Clear session to prevent duplicate tab reusing same session.
          // Teardown.
          await dispatch(startOverFlow());
        }
        ensureDuplicateTab.current = true;
      };
      // When user within same tab back/forward navigates TO the app (including starting the app for a first time).
      enterHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Fire only after we ensured the tab wasn't duplicated
    if (ensureDuplicateTab.current) {
      const setSession = async (): Promise<void> => {
        // we have finished boostrapping previously
        if (!initializing) {
          setEnsureSessionCompleted(true);

          return;
        }

        // we've either never tried, or we tried and failed, and then the user successfully has logged in.
        if (!bootstrapInProgress && (!startedInitializing || agentAuth.isAuth)) {
          await dispatch(bootstrapSession({}));
          setEnsureSessionCompleted(true);
        }
      };
      setSession();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agentAuth?.isAuth,
    bootstrapInProgress,
    dispatch,
    ensureDuplicateTab,
    initializing,
    setEnsureSessionCompleted,
    startedInitializing,
  ]);

  return null;
};

export const App: React.FC = () => {
  const { hasError } = useSelector(getGlobalError);
  const [ensureSessionCompleted, setEnsureSessionCompleted] =
    useEnsureSessionCompleted(staticPaths);
  // Before fetching any data, we want to ensure the tab wasn't duplicated
  const ensureDuplicateTab = useRef(false);

  useAgentAuth();

  // Re-initialize the app on teardown
  const handleTeardown = useEvent(() => {
    setEnsureSessionCompleted(false);
  });

  // It is safe to conditionally call this as the condition result doesn't change for the entire session
  /* eslint-disable react-hooks/rules-of-hooks */
  if (env.optimizely.enabled) {
    useFlags();
  }
  if (env.hotjarEnabled) useHotjar();
  if (env.botControlEnabled) botcontrol.initialize({ src: env.botControlScriptSrc });
  // TODO Call the below hook conditionally just like the above.
  // Must be something like `if (env.logger.infrastructure !== 'none')` where infrastructure is 'none' | 'console' | 'datadog'
  useLogger();
  if (env.gtmEnabled) useGoogleAnalytics();
  /* eslint-enable react-hooks/rules-of-hooks */

  useNavRules();

  useRealignLogoSpinner(ensureSessionCompleted);

  const sessionCompleted = !hasError && ensureSessionCompleted;

  return (
    <PageLayout hideFooter={!sessionCompleted} hideHeader={!sessionCompleted}>
      <Switch>
        {staticRoutes}
        <TeardownController onTeardown={handleTeardown}>
          <ErrorBoundary FallbackComponent={SessionErrorStopPage}>
            <EnsureSession
              setEnsureSessionCompleted={setEnsureSessionCompleted}
              ensureDuplicateTab={ensureDuplicateTab}
            />
            {sessionCompleted && (
              <>
                <Switch>{dynamicRoutes}</Switch>
                <SessionExpiryPromptDialog />
              </>
            )}
          </ErrorBoundary>
        </TeardownController>
      </Switch>
    </PageLayout>
  );
};

import { useCallback } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { updatePageStatus } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import {
  AddEditTPIQuestions,
  AddTPISearch,
  ThirdPartyInterestPageDetails,
  ThirdPartyInterestPageProviderComponent,
} from '../../components';
import { useStyles } from './ThirdPartyInterestPage.styles';

export const ThirdPartyInterestPage: React.FC = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const navigateToNextPage = useNavigateToNextPage();

  const handleNext = useCallback(async () => {
    await navigateToNextPage();
    dispatch(updatePageStatus(NavStatus.INVALID, PagePath.QUOTES, false));

    return;
  }, [dispatch, navigateToNextPage]);

  return (
    <Page
      title='Third-Party Interest (TPI)'
      sidebarProps={{
        drawer: <NavbarDrawer pagePath={PagePath.THIRD_PARTY_INTEREST} />,
      }}
    >
      <ThirdPartyInterestPageProviderComponent onNext={handleNext}>
        <div className={classes.root}>
          <ThirdPartyInterestPageDetails />
          <AddTPISearch />
          <AddEditTPIQuestions />
        </div>
      </ThirdPartyInterestPageProviderComponent>
    </Page>
  );
};

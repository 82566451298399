import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { agentAuth } from '@ecp/utils/auth';
import { uuid } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import { PARTNER_EXPERIENCE_ID } from '@ecp/features/sales/shared/constants';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import type { ExperienceId } from '@ecp/partners';

import type {
  AFIEmailData,
  AFIEmailQuoteResponse,
  AnalyticsElement,
  EmailData,
  EmailQuotesResponse,
  EmailStatus,
} from '../types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getRequestParams = async () => ({
  agentToken: await agentAuth.token,
  requestId: uuid(),
  expId: (sessionStorage.getItem(PARTNER_EXPERIENCE_ID) || env.static.expId) as ExperienceId,
  interactionId: interactionId.get(),
});

/** Returns response body upon success and `undefined` otherwise. */
export const postEmailQuotesToRecipient = async (
  emailData: EmailData,
  analyticsElement: AnalyticsElement,
): Promise<EmailQuotesResponse | undefined> => {
  const { agentToken, requestId, expId, interactionId } = await getRequestParams();
  const requestUrl = `${env.emailQuotesApiUrl}enterprise-email`;
  const { agentFrom, agentTo } = emailData;
  agentTo.ContactAttributes.SubscriberAttributes.GUID = requestId;

  let response: Awaited<ReturnType<typeof fetch>> | undefined;
  let emailResponse: EmailQuotesResponse | undefined;

  trackSapiAnalyticsEvent({
    element: analyticsElement,
    event: 'click',
    eventDetail: JSON.stringify(emailData),
  });

  try {
    response = await fetch(requestUrl, {
      body: JSON.stringify([
        {
          From: agentFrom,
          To: agentTo,
        },
      ]),
      headers: {
        'Content-Type': 'application/json',
        'X-Request-Id': requestId,
        'x-interaction-id': interactionId,
        'X-Experience-Id': expId,
        Authorization: `Bearer ${agentToken}`,
      },
      mode: 'cors',
      method: 'POST',
    });

    emailResponse = await response.json();
  } catch (error) {
    const e = error as Error;

    datadogLog({
      logType: 'error',
      message: `Could not post email quotes (catch) - ${e?.message}`,
      context: {
        logOrigin: 'libs/features/sales/shared/email-quotes/src/util/api.ts',
        functionOrigin: 'postEmailQuotesToRecipient',
        requestUrl,
        requestId,
        interactionId,
        responseStatus: response?.status,

        // EDSP-868 Currently the shape of this error is unknown, will verify and update if necessary.
        statusText: JSON.stringify(error),
      },
      error: e,
    });
  } finally {
    if (response?.status !== 200) {
      // EDSP-868 This may be redundant after adding the catch block above, will monitor DD to confirm and remove after deploy.
      datadogLog({
        logType: 'error',
        message: 'Could not post email quotes',
        context: {
          logOrigin: 'libs/features/sales/shared/email-quotes/src/util/api.ts',
          functionOrigin: 'postEmailQuotesToRecipient',
          requestUrl,
          requestId,
          interactionId,
          responseStatus: response?.status,
          statusText: response?.statusText,
        },
      });
    } else {
      // EDSP-868 Add logging to troubleshoot api error
      datadogLog({
        logType: 'info',
        message: 'Success on post email quotes',
        context: {
          logOrigin: 'libs/features/sales/shared/email-quotes/src/util/api.ts',
          functionOrigin: 'postEmailQuotesToRecipient',
          requestUrl,
          requestId,
          interactionId,
          responseStatus: response?.status,
          statusText: response?.statusText,
        },
      });
    }
  }

  return emailResponse;
};

export const fetchEmailStatus = async (messageId: string): Promise<EmailStatus> => {
  const { agentToken, requestId, expId, interactionId } = await getRequestParams();
  const requestUrl = `${env.emailQuotesApiUrl}status/${messageId}`;
  const response = await fetch(requestUrl, {
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': requestId,
      'x-interaction-id': interactionId,
      'X-Experience-Id': expId,
      Authorization: `Bearer ${agentToken}`,
    },
    mode: 'cors',
  });
  const statusResponse = await response.json();

  if (response.status !== 200) {
    datadogLog({
      logType: 'error',
      message: 'Could not fetch email status',
      context: {
        logOrigin: 'libs/features/sales/shared/email-quotes/src/util/api.ts',
        functionOrigin: 'fetchEmailStatus',
        requestUrl,
        requestId,
        interactionId,
        responseStatus: response.status,
        statusText: response.statusText,
      },
    });
  }

  return statusResponse;
};

export const postAFIEmailQuotesToRecipient = async (
  emailData: AFIEmailData,
  analyticsElement?: AnalyticsElement,
): Promise<AFIEmailQuoteResponse | undefined> => {
  const { agentToken, requestId, expId, interactionId } = await getRequestParams();
  const requestUrl = `${env.agentToolingApiRoot}/email/send`;

  let response: Awaited<ReturnType<typeof fetch>> | undefined;
  let emailResponse: AFIEmailQuoteResponse | undefined;

  trackSapiAnalyticsEvent({
    element: analyticsElement || '',
    event: 'click',
    eventDetail: JSON.stringify(emailData),
  });

  try {
    response = await fetch(requestUrl, {
      body: JSON.stringify({ ...emailData }),
      headers: {
        'Content-Type': 'application/json',
        'afe-trace-id': requestId,
        'afe-session-id': interactionId,
        'afe-experience-id': expId,
        'afe-source-id': `${env.static.applicationName}`,
        'afe-user-token': `Bearer ${agentToken}`,
        'afe-auth-key': env.static.authKey ?? '',
      },
      mode: 'cors',
      method: 'POST',
    });

    emailResponse = await response.json();
  } catch (error) {
    const e = error as Error;

    datadogLog({
      logType: 'error',
      message: `Could not post email quotes (catch) - ${e?.message}`,
      context: {
        logOrigin: 'libs/features/sales/shared/email-quotes/src/util/api.ts',
        functionOrigin: 'postEmailQuotesToRecipient',
        requestUrl,
        requestId,
        interactionId,
        responseStatus: response?.status,

        // TODO: EDSP-868 Currently the shape of this error is unknown, will verify and update if necessary.
        statusText: JSON.stringify(error),
      },
      error: e,
    });
  } finally {
    if (response?.status !== 200) {
      // TODO: EDSP-868 This may be redundant after adding the catch block above, will monitor DD to confirm and remove after deploy.
      datadogLog({
        logType: 'error',
        message: 'Could not post email quotes',
        context: {
          logOrigin: 'libs/features/sales/shared/email-quotes/src/util/api.ts',
          functionOrigin: 'postEmailQuotesToRecipient',
          requestUrl,
          requestId,
          interactionId,
          responseStatus: response?.status,
          statusText: response?.statusText,
        },
      });
    } else {
      // TODO: EDSP-868 Add logging to troubleshoot api error
      datadogLog({
        logType: 'info',
        message: 'Success on post email quotes',
        context: {
          logOrigin: 'libs/features/sales/shared/email-quotes/src/util/api.ts',
          functionOrigin: 'postEmailQuotesToRecipient',
          requestUrl,
          requestId,
          interactionId,
          responseStatus: response?.status,
          statusText: response?.statusText,
        },
      });
    }
  }

  return emailResponse;
};

import { merge } from '@ecp/utils/common';

import type { PaletteOverrides } from '@ecp/themes/base';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { palette as basePalette } from '../../../../base/src';

const paletteOverrides: PaletteOverrides = {
  actions: {
    disabledBg: '#0000001f',
    disabledText: '#00000061',
  },
  common: {
    black: '#000000',
    white: '#ffffff',
    white15: '#ffffff26',
  },
  error: {
    contrastText: '#ffffff',
    dark: '#8a0200',
    darkText: '#000000de',
    light: '#fae9e9',
    main: '#d12421',
  },
  grey: {
    '50': '#ffffff',
    '100': '#f5f5f5',
    '200': '#eeeeee',
    '300': '#e0e0e0',
    '400': '#bdbdbd',
    '500': '#9e9e9e',
    '600': '#757575',
    '700': '#616161',
    '800': '#424242',
    '900': '#212121',
  },
  icon: {
    dark: '#46536b',
    default: '#ffffff',
    hover: '#1f66e0',
    stroke: '#8995ab',
  },
  info: {
    contrastText: '#ffffff',
    dark: '#005a78',
    darkText: '#000000de',
    light: '#e5f5fb',
    main: '#009fd4',
  },
  other: {
    backdrop: '#00000080',
    background: '#f7f8fa',
    backgroundContrastText: '#000000de',
    body: '#ffffff',
    border: '#0000003b',
    caution: '#ebc849',
    cookieBanner: '#ffffff',
    divider: '#0000001f',
    snackbar: '#323232',
    tooltip: '#616161',
  },
  partner: {
    footerBackground: '#f7f8fa',
    footerTextPrimary: '#000000de',
    footerTextSecondary: '#00000099',
    headerBackground: '#ffffff',
  },
  primary: {
    background: '#f5f9ff',
    border: '#abcaff',
    contrastText: '#ffffff',
    dark: '#00235e',
    darkText: '#003da5',
    light: '#e1e8f5',
    main: '#003da5',
  },
  secondary: {
    background: '#f5f9ff',
    border: '#abcaff',
    contrastText: '#ffffff',
    dark: '#00235e',
    darkText: '#003da5',
    light: '#e1e8f5',
    main: '#003da5',
  },
  shadow: {
    primary: '#00000033',
  },
  showcase: {
    color: '#ffffff',
  },
  success: {
    complete: '#ffffff',
    contrastText: '#ffffff',
    dark: '#004f07',
    darkText: '#000000de',
    light: '#e8f2e8',
    main: '#157d1e',
  },
  text: {
    disabled: '#00000061',
    hover: '#1f66e0',
    link: '#003da5',
    primary: '#000000de',
    secondary: '#00000099',
    tertiary: '#00000099',
  },
  warning: {
    contrastText: '#000000de',
    dark: '#963000',
    darkText: '#000000de',
    light: '#fef0e9',
    main: '#f56725',
  },
};

export const palette = merge({}, basePalette, paletteOverrides);

import type { PaginationItemProps, PaginationProps } from '@mui/material';
import { Pagination, PaginationItem } from '@mui/material';

import { useEvent } from '@ecp/utils/react';

import {
  IconUIArrowLeft,
  IconUIArrowRight,
  IconUISkipPrevious,
  IonUISkipNext,
  useIsMobile,
} from '@ecp/themes/base';

import { useStyles } from './Pagination.styles';

export interface CustomPaginationProps
  extends Pick<PaginationProps, 'count' | 'onChange' | 'page'> {}

export const CustomPagination: React.FC<CustomPaginationProps> = (props) => {
  const { classes } = useStyles(undefined, { props });

  const renderPaginationitem = useEvent((item: PaginationItemProps) => (
    <PaginationItem
      components={{
        first: IconUISkipPrevious,
        last: IonUISkipNext,
        previous: IconUIArrowLeft,
        next: IconUIArrowRight,
      }}
      {...item}
      color='primary'
    />
  ));

  return (
    <Pagination
      {...props}
      shape='rounded'
      showFirstButton
      showLastButton
      className={classes.root}
      renderItem={renderPaginationitem}
      size={useIsMobile() ? 'small' : 'large'}
      siblingCount={0}
    />
  );
};

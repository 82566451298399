import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'HomeEligibilityPageForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.formAll.root,
    marginTop: 0,
  },
  horizontalDivider: {
    width: '100%',
    padding: '32px 32px 32px 0px',
  },
}));

import { Fragment, useCallback, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { partition } from '@ecp/utils/common';

import { GridItem, Snackbar } from '@ecp/components';
import { Button } from '@ecp/features/sales/shared/components';
import { getDeltaField, getField, useField } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector, useStore } from '@ecp/features/sales/shared/store/utils';
import type { Driver } from '@ecp/features/sales/shared/types';
import type { AutoProduct } from '@ecp/features/shared/product';
import { IconUIDelete } from '@ecp/themes/base';
import type { AnswerValue, Field } from '@ecp/types';

import { DeltaDiscountsEmailQuestion } from '../DeltaDiscountsEmailQuestion/DeltaDiscountsEmailQuestion';
import { DeltaDriveMyWayPhoneQuestion } from '../DeltaDriveMyWayPhoneQuestion';
import { DeltaMilesMyWayPhoneQuestion } from '../DeltaMilesMyWayPhoneQuestion';
import { AddPersonOnDriveMyWayDialog } from './AddPersonOnDriveMyWayDialog';
import { useStyles } from './DeltaProgramsCard.styles';

export interface DeltaProgramsCardProps {
  drivers: Driver[];
  autoProduct: AutoProduct;
}

export const DeltaProgramsCard: React.FC<DeltaProgramsCardProps> = (props) => {
  const { drivers, autoProduct } = props;
  const store = useStore();
  const [addPersonDialogOpen, setAddPersonDialogOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const { classes } = useStyles();
  const programsDiscount = useField('discount.auto.ubiProgram.type');
  const subHeaderText = programsDiscount.value === 'MILES_MY_WAY' ? 'MILES_MY_WAY' : 'DRIVE_MY_WAY';

  const driversAndUbiProgramOptInFields = useSelector((state: RootStore) => {
    return drivers.reduce((acc, driver) => {
      const key = getDeltaField(state, driver.ref, 'ubiProgramOptIn');
      const field = getField(state, { key, dispatch: store.dispatch });
      // 'delta.driver.123.ubiProgramOptIn': [{DRIVER: driver.123}, {FIELD: delta.driver.123.ubiProgramOptIn}]
      acc[key] = { driver, field };

      return acc;
    }, {} as Record<string, { driver: Driver; field: Field<AnswerValue> }>);
  });

  // NOTE: lodash/partition can take a record, but only returns the values
  const [currentOptInDriversAndFields, potentialOptInDriversAndFields] = partition(
    driversAndUbiProgramOptInFields,
    ({ field }) => field.value,
  );
  const currentOptInDrivers = currentOptInDriversAndFields.map(({ driver }) => driver);
  const potentialOptInDrivers = potentialOptInDriversAndFields.map(({ driver }) => driver);

  const updateProgramOptIn = useCallback(
    (driverRef: string, optInFlag: string): Promise<void> => {
      const fieldName = getDeltaField(store.getState(), driverRef, 'ubiProgramOptIn');

      // log warning?
      if (!(fieldName in driversAndUbiProgramOptInFields)) return Promise.resolve();

      return driversAndUbiProgramOptInFields[fieldName].field.validateUpdateAndPatch(optInFlag);
    },
    [store, driversAndUbiProgramOptInFields],
  );

  const handleRemoveDriver = (removeDriver: Driver): Promise<void> => {
    const msg = `${removeDriver.firstName} ${removeDriver.lastName} has been removed from the DriveMyWay program.`;
    setOpenSnackBar(true);
    setMessage(msg);

    return updateProgramOptIn(removeDriver.ref, 'false');
  };

  const closeSnackBar = useCallback(() => {
    setOpenSnackBar(false);
  }, []);

  // Filter to get the removerd drivers and sending those drivers value to the driveMyWay Add Person dialog.
  const handleAddPerson = useCallback(() => {
    setAddPersonDialogOpen(true);
    trackClick({ action: 'AddPersonTypeButton', label: 'AddPerson' });
  }, []);

  const addPersonDialogClose = useCallback(() => {
    setAddPersonDialogOpen(false);
    // delta.thirdPartyInterests.123.foo
  }, []);

  // Update drive my way drivers when drivers are added.
  const addPeronSubmitHandle = useCallback(
    async (optedInDrivers: Driver[]): Promise<void> =>
      void (await Promise.all(
        optedInDrivers.map((driver) => updateProgramOptIn(driver.ref, 'true')),
      )),
    [updateProgramOptIn],
  );

  if (programsDiscount.value === undefined || programsDiscount.value === 'NONE') return null;

  return (
    <>
      <GridItem topSpacing='lg' xs={12}>
        <h2>Programs</h2>
        <Divider className={classes.divider} />
      </GridItem>
      <Grid container className={classes.body} columnSpacing={1.5}>
        <Grid item xs={12}>
          <GridItem xs={12} topSpacing='xs' className={classes.header}>
            <h2>{subHeaderText}</h2>
          </GridItem>
          <GridItem topSpacing='xs' xs={12} md={12}>
            <DeltaDiscountsEmailQuestion product={autoProduct} discountType='ubiProgramEmail' />
          </GridItem>

          <GridItem topSpacing='xs' xs={12} md={12}>
            {programsDiscount.value === 'MILES_MY_WAY' && (
              <DeltaMilesMyWayPhoneQuestion autoProduct={autoProduct} />
            )}
          </GridItem>

          {programsDiscount.value === 'DRIVE_MY_WAY' && (
            <>
              <Divider className={classes.horizontalDivider} />
              {currentOptInDrivers.map((driver, index) => (
                <Fragment key={driver.ref}>
                  <Grid item xs={12}>
                    <Grid container className={classes.dataGrids}>
                      <GridItem topSpacing='xs' xs={12} md={12}>
                        <div className={classes.root}>
                          <div className={classes.driverDiv}>
                            <div
                              className={classes.headerRight}
                              style={{ display: 'flex', gap: '15px' }}
                            >
                              {`${driver.firstName} ${driver.lastName}`}
                              {currentOptInDrivers.length > 1 && (
                                <Button
                                  className={classes.deleteButton}
                                  // eslint-disable-next-line react/jsx-no-bind
                                  onClick={() => handleRemoveDriver(driver)}
                                  variant='iconTextMedium'
                                  data-testid='driverFormRemove'
                                  trackingName='remove_driver'
                                  analyticsElement='choice.reviewVehicleAndDriverPage.DeleteDriverButton'
                                >
                                  <IconUIDelete />
                                  Remove
                                </Button>
                              )}
                            </div>

                            <DeltaDriveMyWayPhoneQuestion driverRef={driver.ref} index={index} />
                          </div>
                        </div>
                      </GridItem>
                    </Grid>
                  </Grid>
                </Fragment>
              ))}
              {drivers.length > currentOptInDrivers.length && (
                <GridItem topSpacing='md' bottomSpacing='lg' xs={12}>
                  <Button
                    type='button'
                    onClick={handleAddPerson}
                    color='primary'
                    variant='outlinePrimary'
                    trackingName='add_person_button'
                    trackingLabel='add_person'
                  >
                    Add Person
                  </Button>
                </GridItem>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {addPersonDialogOpen && (
        <AddPersonOnDriveMyWayDialog
          open={addPersonDialogOpen}
          onClose={addPersonDialogClose}
          optInDriver={potentialOptInDrivers}
          onSubmitDialog={addPeronSubmitHandle}
          {...props}
        />
      )}

      {openSnackBar && (
        <Snackbar
          classes={{ root: classes.snackBarWidth }}
          message={message}
          vertical='bottom'
          horizontal='center'
          open={openSnackBar}
          onClose={closeSnackBar}
        />
      )}
    </>
  );
};

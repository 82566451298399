import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldReadOnlyComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './RenovationCoverage.styles';

export const RenovationCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();

  // Get the coverage selected fields and all the related subcoverage fields
  const [renovationCoverageField, effectiveDateField, endDateField] = useGroupedCoverageFields(
    props.carrierName,
    props.product,
    'renovation',
    ['selected', 'effectiveDate', 'endDate'],
  );

  // Define the logic around subcoverage fields which are
  // impacted by opt-in/ opt-out feature for main coverage field
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(renovationCoverageField, [
    effectiveDateField,
    endDateField,
  ]);

  const handleRenovationCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );

  if (!renovationCoverageField.exists) return null;

  const isRenovationCoverageSelected = castToBoolean(renovationCoverageField.value);

  // TODO Probably with a new story - premium amount
  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='renovationCoverage'
          field={renovationCoverageField}
          isCoverageSelected={isRenovationCoverageSelected}
          handleCoverageChange={handleRenovationCoverageChange}
        />
        {isRenovationCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            <CoverageFieldReadOnlyComponent field={effectiveDateField} />
            <CoverageFieldReadOnlyComponent field={endDateField} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

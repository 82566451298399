import { useCallback, useState } from 'react';

import { useEffectOnce } from '@ecp/utils/react';

import { GridItem, RadioGroupWithOptions, SnackbarAlert } from '@ecp/components';
import { Snackbar } from '@ecp/components';
import type { ResourceFormProps } from '@ecp/features/sales/shared/components';
import { Dialog, Resource } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  deleteInquiryRef,
  getAnswer,
  updateAnswers,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, PageErrors } from '@ecp/features/sales/shared/types';
import type { Garage } from '@ecp/features/sales/shared/types';

import { usePropertyField } from '../../utils';
import { GaragesFormQuestion } from './GaragesFormQuestion';
import { useStyles } from './GaragesMultipleQuestion.styles';
import {
  useCreateGarages,
  useGarageRefValues,
  useGarages,
  useRemoveAllGarages,
} from './garageUtil';

export const GaragesMultipleQuestion: React.FC<QuestionProps> = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const trackingName = 'garages_selection';

  const garagesRefsKey = 'property.garage.ref';
  const hasGarageCarportField = usePropertyField('garageCarport');

  // This is important to denote that this component supports multiple garages.
  // The static field information is helpful for translation (in DAL) for
  // back navigation scenarios. Please review comments in DAL as well.
  useEffectOnce(() => {
    const saveStaticMultipleGarage = async (): Promise<void> => {
      await dispatch(
        updateAnswers({
          answers: {
            'static.dal.supportMultipleGarages': true,
          },
        }),
      );
    };
    saveStaticMultipleGarage();
  });

  const garagesRefsValue = useGarageRefValues(garagesRefsKey);

  const [garageRef, setGarageRef] = useState('');
  const [pageErrors] = useState<PageErrors[]>([]);
  const [actionType, setActionType] = useState<'NONE' | 'ADD' | 'REMOVE'>('NONE');
  const [removeAllMsg, setRemoveAllMsg] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);
  const [actionInfoMessage, setActionInfoMessage] = useState('');
  const [editItemRef, setEditItemRef] = useState('');
  const [garageItemToBeRemoved, setGarageItemToBeRemoved] = useState<Garage>();
  const [itemDataBeforeEdit, setItemDataBeforeEdit] = useState<Garage | undefined>();
  const removeAllGarages = useRemoveAllGarages(garagesRefsKey);
  const [addingStat, setAddingStat] = useState(false);

  const garageList = useGarages();
  const garagesItemField = useFieldWithPrefix(editItemRef, 'garage.<id>');
  const garageType = garagesItemField('type');
  const garageSize = garagesItemField('size');

  const addGarage = useCreateGarages();

  const GaragesByRef: { [key: string]: (typeof garageList)[number] } = garageList?.reduce(
    (acc: Record<string, (typeof garageList)[number]>, garage) => {
      acc[garage.ref] = garage;

      return acc;
    },
    {},
  );

  const handleAddGarages = useCallback(async () => {
    setAddingStat(true);
    const newGarage = addGarage();
    const { garageRef } = newGarage;
    await newGarage.done;
    setGarageRef(garageRef);
  }, [addGarage]);

  const handleHasGarageChange = useCallback(
    async (value: AnswerValue) => {
      hasGarageCarportField?.props.actionOnComplete(value);
      if (!value && garagesRefsValue?.length > 0) {
        if (garageList.length === 1 && addingStat) {
          const garageItem = garageList[0];

          if (!garageItem.type && !garageItem.size) {
            await dispatch(deleteInquiryRef(garageItem.ref));
          } else if (garageItem.type || garageItem.size) {
            setOpenDiscardDialog(true);
            setGarageItemToBeRemoved(garageItem);
          }
        } else {
          setRemoveAllMsg(true);
          setOpenRemoveDialog(true);
        }
      } else if (value && garagesRefsValue.length < 1) {
        if (garagesRefsValue.length === 0) {
          handleAddGarages();
        }
      }
    },
    [
      garagesRefsValue.length,
      garageList,
      addingStat,
      hasGarageCarportField?.props,
      dispatch,
      handleAddGarages,
    ],
  );
  const updateGarageDetails = useCallback(
    (itemDataBeforeEdit: Garage): void => {
      if (itemDataBeforeEdit.type) {
        const groupValue = garageType.question.options?.find(
          (option) => option.label === itemDataBeforeEdit.type,
        )?.value;
        if (groupValue) {
          garageType.validateUpdateAndPatch(groupValue);
        }
      }
      if (itemDataBeforeEdit.size) {
        const groupValue = garageSize.question.options?.find(
          (option) => option.label === itemDataBeforeEdit.size,
        )?.value;
        if (groupValue) {
          garageSize.validateUpdateAndPatch(groupValue);
        }
      }
      setItemDataBeforeEdit(undefined);
    },
    [garageSize, garageType],
  );
  const onCloseDialogAction = useCallback(() => {
    setOpenRemoveDialog(false);
    if (removeAllMsg) {
      hasGarageCarportField?.props.actionOnComplete(true);
    }
  }, [hasGarageCarportField?.props, removeAllMsg]);

  const garagesItems = useSelector((state: RootStore) =>
    getAnswer(state, garagesRefsKey),
  ) as Array<string>;

  const onRemoveDialogAction = useCallback(async () => {
    setOpenRemoveDialog(false);
    if (!removeAllMsg && itemDataBeforeEdit) {
      updateGarageDetails(itemDataBeforeEdit);
    } else {
      if (removeAllMsg) {
        await removeAllGarages();
        setActionInfoMessage('All garages or carports removed.');
      } else if (garageItemToBeRemoved) {
        setActionInfoMessage('Garage or carport removed.');
        await dispatch(deleteInquiryRef(garageItemToBeRemoved.ref));
        if (garagesItems.includes(garageItemToBeRemoved.ref) && garagesItems.length === 1) {
          handleAddGarages();
        }
      }
      setAddingStat(false);
      setActionType('REMOVE');
    }
    setGarageRef('');
    setEditItemRef('');
    setItemDataBeforeEdit(undefined);
    setGarageItemToBeRemoved(undefined);
  }, [
    removeAllMsg,
    itemDataBeforeEdit,
    updateGarageDetails,
    garageItemToBeRemoved,
    removeAllGarages,
    dispatch,
    garagesItems,
    handleAddGarages,
  ]);

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setActionInfoMessage('');
        setActionType('NONE');
      }
    },
    [],
  );

  const deleteItem = useCallback(
    (item: string) => {
      setGarageItemToBeRemoved(GaragesByRef[item]);
      setOpenRemoveDialog(true);
      setRemoveAllMsg(false);
    },
    [GaragesByRef],
  );

  const cancelItems = useCallback(
    async (item: string) => {
      if (item) {
        const garageItem = GaragesByRef[item];
        const itemExists = garageList.some(
          (item1) => item1.ref === item && !item1.type && !item1.size,
        );
        if (addingStat && itemExists) {
          await dispatch(deleteInquiryRef(garageItem.ref));
        } else {
          if (!itemDataBeforeEdit) {
            setGarageItemToBeRemoved(garageItem);
            setOpenDiscardDialog(true);
          }
          if (itemDataBeforeEdit) {
            const typeLabel = garageType.question.options?.find(
              (option) => option.value === garageType.value,
            )?.label;
            const sizeLabel = garageSize.question.options?.find(
              (option) => option.value === garageSize.value,
            )?.label;
            if (!(typeLabel === itemDataBeforeEdit.type && sizeLabel === itemDataBeforeEdit.size)) {
              setOpenDiscardDialog(true);
            } else {
              setGarageRef('');
              setEditItemRef('');
              setItemDataBeforeEdit(undefined);
              setGarageItemToBeRemoved(undefined);
            }
          }
        }

        setAddingStat(false);
      }
    },
    [
      GaragesByRef,
      addingStat,
      dispatch,
      garageList,
      garageSize.question.options,
      garageSize.value,
      garageType.question.options,
      garageType.value,
      itemDataBeforeEdit,
    ],
  );

  const saveItems = useCallback(() => {
    setGarageRef('');
    setEditItemRef('');
    setAddingStat(false);
  }, []);

  const handleEditItemRef = useCallback(
    (itemRef: string) => {
      setEditItemRef(itemRef);
      setItemDataBeforeEdit({ ...GaragesByRef[itemRef] });
    },
    [GaragesByRef],
  );

  const snackbarSuccess = actionType === 'ADD' && (
    <SnackbarAlert
      open
      autoHideDuration={3000}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
      severity='success'
      message={actionInfoMessage}
      hideActionButton
    />
  );
  const snackbarDefault = actionType === 'REMOVE' && (
    <Snackbar
      open
      autoHideDuration={3000}
      message={actionInfoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const removeDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText={removeAllMsg ? 'Remove all garages or carports?' : 'Remove garage or carport?'}
      textButtonLabel='Cancel'
      open={openRemoveDialog}
      onClose={onCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onRemoveDialogAction}
    >
      Any associated content will be removed.
    </Dialog>
  );

  const onDiscardCloseDialogAction = useCallback(() => {
    setOpenDiscardDialog(false);
  }, []);
  const onDiscardDialogAction = useCallback(async () => {
    setActionType('REMOVE');
    setActionInfoMessage('Changes discarded');
    setOpenDiscardDialog(false);
    if (garageItemToBeRemoved) {
      await dispatch(deleteInquiryRef(garageItemToBeRemoved.ref));
    }
    if (itemDataBeforeEdit) {
      updateGarageDetails(itemDataBeforeEdit);
    }
    setGarageRef('');
    setEditItemRef('');

    setGarageItemToBeRemoved(undefined);
  }, [dispatch, garageItemToBeRemoved, itemDataBeforeEdit, updateGarageDetails]);

  const discardDialog = (
    <Dialog
      actionButtonLabel='Discard'
      titleText='Discard changes?'
      textButtonLabel='Cancel'
      open={openDiscardDialog}
      onClose={onDiscardCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onDiscardDialogAction}
    >
      Any unsaved changes will be discarded.
    </Dialog>
  );

  const renderItems = useCallback(
    (item: string) => (
      <>
        <Resource.Item xs={4} title='Type' value={GaragesByRef[item]?.type} />
        <Resource.Item xs={7} title='Parking Spots' value={GaragesByRef[item]?.size} />
      </>
    ),
    [GaragesByRef],
  );

  const renderGarageForm = useCallback(
    (formProps: ResourceFormProps) => (
      <GaragesFormQuestion
        {...formProps}
        garageRef={garageRef}
        editItemRef={editItemRef}
        setOpenRemoveDialog={setOpenRemoveDialog}
        setActionInfoMessage={setActionInfoMessage}
        setActionType={setActionType}
        setAddingStat={setAddingStat}
        garagesList={garageList}
      />
    ),
    [editItemRef, garageList, garageRef],
  );

  if (!hasGarageCarportField) return null;

  return (
    <>
      {removeDialog}
      <GridItem topSpacing='sm' bottomSpacing='sm' xs={12}>
        <RadioGroupWithOptions
          {...hasGarageCarportField.props}
          id='GaragesCarports'
          label={hasGarageCarportField.question.title}
          variant='yesNoButton'
          trackingName={trackingName}
          actionOnComplete={handleHasGarageChange}
          data-testid='garage-type-options'
        />
      </GridItem>
      {hasGarageCarportField.value && (
        <>
          <Resource.List
            items={garagesRefsValue}
            editItemRef={editItemRef || garageRef}
            onEdit={handleEditItemRef}
            onDelete={deleteItem}
            pageErrors={pageErrors}
            renderListItem={renderItems}
            onCancel={cancelItems}
            onNext={saveItems}
            form={renderGarageForm}
          />

          <Resource.AddButton
            onClick={handleAddGarages}
            data-testid='add_garage_button'
            disabled={garageType.props.options?.length === garageList.length || addingStat}
            className={classes.actionButtons}
          >
            ADD ANOTHER GARAGE OR CARPORT
          </Resource.AddButton>
        </>
      )}

      {snackbarDefault}
      {snackbarSuccess}
      {discardDialog}
    </>
  );
};

import { useCallback } from 'react';

import { Typography } from '@mui/material';
import type { Control } from 'react-hook-form';
import { useController } from 'react-hook-form';

import { RadioGroupWithOptions } from '@ecp/components';
import type { PaginationOption } from '@ecp/components';

import type { SearchResult, TPI, TPISearchSelectionInputFields } from '../../types';
import { useStyles } from './TPIResultOptions.styles';

export const TPIResultOptions: React.FC<{
  control: Control<TPISearchSelectionInputFields>;
  handleOnChange: (params: { tpi: TPI }) => void;
  options: PaginationOption[][];
  tpiDataPerPage: SearchResult[][];
  pageNumber?: number;
}> = ({ control, options, pageNumber = 1, tpiDataPerPage, handleOnChange }) => {
  const { classes } = useStyles();
  const { field } = useController({
    name: 'tpi',
    control,
    rules: { required: true },
  });

  const handleOnChangeOfResultSelection = useCallback(
    (value: string) => {
      field.onChange(value);
      const pageIndex = pageNumber - 1; // Adjust for zero-based index if necessary
      // Access the selected record using the page index and record index
      const selectedRecordIndex = tpiDataPerPage[pageIndex].findIndex(
        (result) => result.tpiId === value,
      );

      const selectedRecord = tpiDataPerPage[pageIndex][selectedRecordIndex] as SearchResult;

      const updatedTPI: TPI = {
        addressLine1: selectedRecord?.address?.address1,
        addressLine2: selectedRecord?.address?.address2,
        city: selectedRecord?.address?.city,
        state: selectedRecord?.address?.state,
        zipCode: selectedRecord?.address?.zip5,
        companyName: selectedRecord?.bank?.name1,
        escrow: selectedRecord?.escrow,
        tpiId: selectedRecord?.tpiId,
        cdhId: selectedRecord?.addendum?.cdh ? selectedRecord.addendum?.cdh[0].id : undefined,
        cim: selectedRecord?.addendum?.cim ? selectedRecord.addendum?.cim[0].id : undefined,
      };

      handleOnChange({ tpi: updatedTPI });
    },
    [field, handleOnChange, pageNumber, tpiDataPerPage],
  );

  return (
    <RadioGroupWithOptions
      {...field}
      actionOnChange={handleOnChangeOfResultSelection}
      options={options[pageNumber - 1]}
      label={<Typography variant='body3Bold'>Search Results ({options.flat().length})</Typography>}
      variant='radioCard'
      caption
      className={classes.tpiResultOption}
    />
  );
};

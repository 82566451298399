import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PropertyTPICard' })((theme) => ({
  card: {
    padding: '12px 16px 12px 16px',
    width: '100%',
    border: `1px solid ${theme.palette.other.divider}`,
    borderRadius: '4px',
    marginTop: 12,
  },
  productTitleSection: {
    display: 'flex',
    alignItems: 'center',
  },
  productIcon: {
    '& svg': {
      width: 40,
      height: 40,
      ...theme.mixins.setColorSvg(theme.palette.icon.hover),
      marginRight: 16,
    },
  },
  productTitle: {
    ...theme.typography.body4Bold,
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '32px',
  },
  contentTitle: {
    ...theme.typography.body4,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  contentSubTitle: { ...theme.typography.body2, color: theme.palette.text.secondary },
}));

import { useEffect } from 'react';

import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import {
  DRIVER_INTL,
  DRIVER_UNLICENSED,
  DRIVER_VALID,
  DriverLicenseAgeFirstLicensedQuestion,
} from '@ecp/features/sales/quotes/auto';
import {
  DateOfBirthQuestion,
  FirstNameQuestion,
  GenderQuestion,
  LastNameQuestion,
  MiddleNameQuestion,
  SuffixQuestion,
} from '@ecp/features/sales/shared/questions';
import {
  getOfferProductsSelected,
  getPrimaryInsuredStateCode,
  useFieldWithPrefix,
  usePersonValues,
  usePniRef,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import {
  DriverFinancialResponsibilityQuestion,
  DriverLicenseQuestion,
  DriverLicenseRelationToInsuredQuestion,
  DriverLicenseStateQuestion,
  DriverLicenseStatusQuestion,
  DriverRoleQuestion,
} from '@ecp/sales/lob/auto';

import { MaritalStatusQuestion, RelationshipQuestion } from '../../../../../common';
import { useStyles } from './DriverFormQuestions.styles';

export interface DriverFormQuestionsProps {
  isPni: boolean;
  driverRef: string;
  personRef: string;
}

export const DriverFormQuestions: React.FC<DriverFormQuestionsProps> = (props) => {
  const { isPni, driverRef, personRef } = props;
  const { classes } = useStyles();
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const firstName = usePersonField('firstName');
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const dlStatus = useDriverField('license.status');
  const dlState = useDriverField('license.state');
  const middleName = usePersonField('middleName');
  const suffix = usePersonField('suffix');
  const dlNumberEntered = useDriverField('license.number').value;
  const pniRef = usePniRef();
  const { firstName: pniFirstName, lastName: pniLastName } = usePersonValues(pniRef);
  const {
    props: { value: name = '' },
  } = firstName;

  const nameOrThey = name ? `Does ${name}` : 'Do they';
  const wasNameOrWereThey = name ? `was ${name}` : 'were they';
  const isNameOrAreThey = name ? `is ${name}` : 'are they';
  const possessiveName = name ? `${name}'s` : 'their';

  useEffect(() => {
    // handles issue where driver's license state persists when driver's license number is cleared
    if (!dlNumberEntered && dlState.value) {
      dlState.props.actionOnComplete(null);
    }
    // Only execute when one of the values changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dlState.value, dlNumberEntered]);

  return (
    <Grid container>
      <GridItem xs={12} md={6} className={classes.columnLeft}>
        <FirstNameQuestion personRef={personRef} disabled={isPni} />
      </GridItem>
      {middleName.exists && (
        <GridItem xs={12} md={6} className={classes.columnRight}>
          <MiddleNameQuestion personRef={personRef} disabled={isPni} />
        </GridItem>
      )}
      <GridItem
        topSpacing='sm'
        xs={12}
        md={6}
        className={middleName.exists ? classes.columnLeft : classes.columnRight}
      >
        <LastNameQuestion personRef={personRef} disabled={isPni} />
      </GridItem>
      {suffix.exists && (
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
          <SuffixQuestion personRef={personRef} disabled={isPni} />
        </GridItem>
      )}
      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
        <DateOfBirthQuestion id='DOB' personRef={personRef} disabled={isPni} staticValueWithRef />
      </GridItem>
      {dlStatus.value !== DRIVER_UNLICENSED && (
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
          <DriverLicenseQuestion driverRef={driverRef} />
        </GridItem>
      )}
      <GridItem topSpacing='lg' xs={12}>
        {/* TODO add help text when clicking on the help icon */}
        <GenderQuestion
          personRef={personRef}
          readOnly={isPni} // Advance only allows gender for PNI to be controlled via the contact from CRM (Relate)
          label={`What's ${possessiveName} gender?`}
        />
      </GridItem>
      {!isPni && (
        <GridItem topSpacing='lg' xs={12}>
          <RelationshipQuestion
            personRef={personRef}
            groupLabel={`What's ${possessiveName} relationship to ${pniFirstName ?? ''} ${
              pniLastName ?? ''
            }?`}
          />
        </GridItem>
      )}
      <GridItem topSpacing='lg' xs={12}>
        <MaritalStatusQuestion
          personRef={personRef}
          groupLabel={isPni ? undefined : `What's ${possessiveName} marital status?`} // used for editable field to be same as other selects
          label={isPni ? `What's ${possessiveName} marital status?` : undefined} // used for readOnly field
          readOnly={isPni} // Advance only allows marital status for PNI to be controlled via the contact from CRM (Relate)
        />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <DriverLicenseStatusQuestion driverRef={driverRef} possessiveName={possessiveName} />
      </GridItem>
      {dlNumberEntered &&
        dlStatus.value !== DRIVER_UNLICENSED &&
        dlStatus.value !== DRIVER_INTL && (
          <GridItem topSpacing='lg' xs={12}>
            <DriverLicenseStateQuestion
              driverRef={driverRef}
              groupLabel={`What's ${possessiveName} driver license state?`}
            />
          </GridItem>
        )}
      {(dlStatus.value === DRIVER_VALID || dlStatus.value === DRIVER_INTL) && (
        <GridItem topSpacing='lg' xs={12}>
          <DriverLicenseAgeFirstLicensedQuestion
            personRef={personRef}
            driverRef={driverRef}
            groupLabel={`At what age ${wasNameOrWereThey} first licensed${
              stateCode === 'CA' && offerProductsSelected.includes('connect.auto')
                ? ''
                : ' in the United States'
            }?`}
          />
        </GridItem>
      )}
      <GridItem topSpacing='lg' xs={12}>
        <DriverLicenseRelationToInsuredQuestion driverRef={driverRef} personRef={personRef} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <DriverRoleQuestion
          driverRef={driverRef}
          isPni={isPni}
          groupLabel={`What type of driver ${isNameOrAreThey}?`}
        />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <DriverFinancialResponsibilityQuestion driverRef={driverRef} nameOrYou={nameOrThey} />
      </GridItem>
    </Grid>
  );
};

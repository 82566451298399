import type { RootStore } from '@ecp/features/sales/shared/store/types';
import type { AnswerValue } from '@ecp/features/sales/shared/types';

import { getAnswer } from '../inquiry';
import type { ProducerResponse } from './types';

export const getProducerDetails = (state: RootStore): ProducerResponse => state.producer.producer;

export const getProducerDetail = (state: RootStore): ProducerResponse => getProducerDetails(state);

export const getProducerID = (state: RootStore): AnswerValue => {
  const answerValue = getAnswer(state, 'producer.producerId');

  return answerValue;
};

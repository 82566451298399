import { useCallback } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { Button, GridItem } from '@ecp/components';
import { getVehicleDescription } from '@ecp/features/sales/quotes/auto';
import { getThirdPartyInterestRefsOnVeh, getVehicles } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconCardAuto, IconUIPlus } from '@ecp/themes/partners/amfam-adv';

import { useThirdPartyInterestPageContext } from '../../../../../common/components';
import { AutoTPICardDetails } from '../AutoTPICardDetails';
import { useStyles } from './AutoTPICard.styles';

export const AutoTPICard: React.FC = () => {
  const { classes } = useStyles();

  const { handleAutoAddTpiButton } = useThirdPartyInterestPageContext();

  const vehicles = useSelector(getVehicles);
  const tpiRefsOnVehicleSelector = useSelector(getThirdPartyInterestRefsOnVeh);

  const onAddTpiButton = useCallback(
    (vehicleRef: string) => (event: React.MouseEvent<HTMLButtonElement> | undefined) =>
      handleAutoAddTpiButton(vehicleRef),
    [handleAutoAddTpiButton],
  );

  return (
    <GridItem>
      <GridItem topSpacing='lg' xs={12} className={classes.productTitleSection}>
        <Box>
          <div className={classes.productIcon}>
            <IconCardAuto />
          </div>
        </Box>
        <Typography className={classes.productTitle}>Auto Policy</Typography>
      </GridItem>
      <GridItem>
        {vehicles.map((vehicle, index) => (
          <GridItem key={index} className={classes.card}>
            <Grid
              container
              className={
                tpiRefsOnVehicleSelector(vehicle.ref).length === 0
                  ? classes.containerDetailsRow
                  : classes.containerDetailsColumn
              }
              key={vehicle.ref}
            >
              <GridItem xs={12} md={6}>
                <Typography className={classes.contentTitle}>
                  {getVehicleDescription(vehicle)}
                </Typography>
                {vehicle.vin && (
                  <Typography
                    className={classes.contentSubTitle}
                  >{`VIN: ${vehicle.vin}`}</Typography>
                )}
              </GridItem>
              {tpiRefsOnVehicleSelector(vehicle.ref).length > 0 ? (
                <AutoTPICardDetails vehicleRef={vehicle.ref} />
              ) : (
                <GridItem xs={12} md={6} justifyContent='right' className={classes.columnRight}>
                  <Button
                    className={classes.button}
                    variant='outlinePrimary'
                    onClick={onAddTpiButton(vehicle.ref)}
                    trackingName='add_tpi_button_continue'
                    trackingLabel={GoogleAnalyticsLabels.CONTINUE}
                  >
                    <IconUIPlus className={classes.plusIcon} />
                    ADD TPI
                  </Button>
                </GridItem>
              )}
            </Grid>
          </GridItem>
        ))}
      </GridItem>
    </GridItem>
  );
};

import { useCallback } from 'react';

import {
  deleteInquiryRef,
  getAnswers,
  getPersonInfo,
  useField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Person } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

export interface SNIFormFields {
  firstName: Field;
  lastName: Field;
  middleName: Field;
  suffix: Field;
  dateOfBirth: Field;
  gender: Field;
  married: Field;
  relationshipToApplicant: Field;
  occupantType: Field;
}

export const sniFormKeys = {
  firstName: 'firstName',
  lastName: 'lastName',
  middleName: 'middleName',
  suffix: 'suffix',
  dateOfBirth: 'dob',
  gender: 'gender',
  married: 'married',
  relationshipToApplicant: 'relationshipToApplicant',
  occupantType: 'additionalInformation.occupantType',
};

export const useGetSniFormFields = (personRef: string): SNIFormFields => {
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');

  return {
    firstName: usePersonField(sniFormKeys.firstName),
    lastName: usePersonField(sniFormKeys.lastName),
    middleName: usePersonField(sniFormKeys.middleName),
    suffix: usePersonField(sniFormKeys.suffix),
    dateOfBirth: usePersonField(sniFormKeys.dateOfBirth),
    gender: usePersonField(sniFormKeys.gender),
    married: usePersonField(sniFormKeys.married),
    relationshipToApplicant: usePersonField(sniFormKeys.relationshipToApplicant),
    occupantType: usePersonField(sniFormKeys.occupantType),
  };
};

export const useRemoveSniRef = (personRef: string): (() => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    if (personRef) {
      await dispatch(deleteInquiryRef(personRef));
    }
  }, [dispatch, personRef]);
};

// It filter's persons from the all available persons except the PNI.
export const useGetNonPniPersons = (pniRef: string): Person[] => {
  const allAnswers = useSelector(getAnswers);
  const staticSelectedSniNewlyAddedPersonRef = useField(`static.selected.sniNewlyAddedPerson.ref`);
  const personKeys = Object.keys(allAnswers).filter((key) => key.startsWith('person.'));
  const uniquePersonKeys = [
    ...new Set(personKeys.map((key) => key.split('.')[0] + '.' + key.split('.')[1])),
  ];

  const nonPniPersonRefs = uniquePersonKeys.filter((personKey) => personKey !== pniRef);

  return useSelector((state: RootStore) => {
    return nonPniPersonRefs
      .filter((personRef) => {
        const { firstName, lastName } = getPersonInfo(state, personRef);

        return (
          personRef !== staticSelectedSniNewlyAddedPersonRef.props.value && firstName && lastName
        );
      })
      .map((personRef) => {
        const { firstName, lastName } = getPersonInfo(state, personRef);

        return { firstName, lastName, ref: personRef } as Person;
      });
  });
};

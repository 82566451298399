import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const DaycareOnPremisesQuestion: React.FC<QuestionProps> = (props) => {
  const daycareOnPremisesField = usePropertyField('dayCareOnPremisesInd');

  if (!daycareOnPremisesField) return null;

  const {
    label = daycareOnPremisesField.question?.title,
    trackingName = 'DaycareOnPremisesButton',
  } = props;

  return (
    <RadioGroupWithOptions
      {...daycareOnPremisesField.props}
      id='DaycareOnPremises'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};

import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldReadOnlyComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './DwellingUndamagedVinylCoverage.styles';

export const DwellingUndamagedVinylCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();
  const [dwellingUndamagedVinylCoverageField, limitField] = useGroupedCoverageFields(
    props.carrierName,
    props.product,
    'undamagedSidingMatch',
    ['selected', 'limit'],
  );

  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(
    dwellingUndamagedVinylCoverageField,
    [limitField],
  );

  const handleDwellingUndamagedVinylChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );

  if (!dwellingUndamagedVinylCoverageField.exists) return null;

  const isUndamagedVinylCoverageSelected = castToBoolean(dwellingUndamagedVinylCoverageField.value);

  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='dwellingUndamagedVinylCoverage'
          field={dwellingUndamagedVinylCoverageField}
          isCoverageSelected={isUndamagedVinylCoverageSelected}
          handleCoverageChange={handleDwellingUndamagedVinylChange}
        />
        {isUndamagedVinylCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            <CoverageFieldReadOnlyComponent field={limitField} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

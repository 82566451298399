import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import { useAddFields } from '@ecp/features/sales/form';
import { getAnswers, updateAnswers, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, Field } from '@ecp/types';

export interface TravelPackageManagerReturnType {
  handlePackageChange: (newValue: CoveragePackageOptions) => Promise<void>;
  selectedPackage: CoveragePackageOptions;
}

export enum CoveragePackageOptions {
  NONE = 'NONE',
  TRAVELASSISTANCE = 'TRAVELASSISTANCE',
  TRAVELPEACEOFMIND = 'TRAVELPEACEOFMIND',
}

enum CoverageLabels {
  TRAVELASSISTANCE = 'Travel Assistance',
  TRAVELPEACEOFMIND = 'Travel Peace of Mind',
  ROADTRIP = 'Road Trip Accident Accommodations',
  EMERGENCYROADSIDE = 'Emergency Roadside Service',
  RENTALREIMBURSEMENT = 'Rental Reimbursement',
  ACCIDENTALDEATH = 'Accidental Death & Dismemberment',
}

export const getPackageSelectionMessage = (packageName: CoverageLabels): string =>
  `By selecting the ${packageName} coverage package, we’ve selected the included coverages for you.`;
export const getPackageAutoApplyMessage = (packageName: CoverageLabels): string =>
  `You selected all of the coverages in the ${packageName} coverage package, so we’ve automatically selected the package for you.`;
export const getPackageRemovedMessage = (packageName: CoverageLabels): string =>
  `${packageName} removed.`;
const getCoverageAndPackageRemovedMessage = (
  packageName: CoverageLabels,
  coverageName: string,
): string => `${packageName}  and ${coverageName} removed.`;

export const useTravelPackageManager = (
  travelPeaceOfMindField: Field<AnswerValue> | undefined,
  setSnackbarMessages?: Dispatch<SetStateAction<string[]>>,
): TravelPackageManagerReturnType => {
  /**
   * travelPeaceOfMindField has to be a required prop because we don't have it when it's defined in the metadata, so
   *   the type is possibly undefined, but we know the value will not be.
   */
  if (!travelPeaceOfMindField || !travelPeaceOfMindField.key.includes('.travelPeaceOfMind'))
    throw Error(`travelPeaceOfMindField must be defined -  ${travelPeaceOfMindField}`);
  const vehicleCoveragePrefix = travelPeaceOfMindField.key.split('.travelPeaceOfMind')[0];
  const policyCoveragePrefix = `${travelPeaceOfMindField.key.split('.vehicle')[0]}.policy`;

  const useVehicleCoverageField = useFieldWithPrefix(vehicleCoveragePrefix);
  const usePolicyCoverageField = useFieldWithPrefix(policyCoveragePrefix);
  const travelAssistancePackageField = useVehicleCoverageField('travelAssistance.selected');
  const travelPeaceOfMindPackageField = travelPeaceOfMindField;
  const roadsideServiceCoverageField = useVehicleCoverageField('roadsideService.selected');
  const roadTripAccidentCoverageField = useVehicleCoverageField('roadTripAccident.selected');
  const rentalReimbursementCoverageField = useVehicleCoverageField('rental.dailyLimitMaximum');
  const accidentalDeathCoverageField = usePolicyCoverageField('accidentalDeath.limit');
  const dispatch = useDispatch();
  const hasTravelPeaceOfMind = castToBoolean(travelPeaceOfMindPackageField.value);
  const hasTravelAssistance = castToBoolean(travelAssistancePackageField.value);
  const hasRoadTripAccident = castToBoolean(roadTripAccidentCoverageField.value);
  const hasRoadsideService = castToBoolean(roadsideServiceCoverageField.value);
  const noValueSelectOptions = [undefined, null, 'NoCoverage'];
  const hasRentalReimbursement = !noValueSelectOptions.includes(
    rentalReimbursementCoverageField.value,
  );
  const hasAccidentalDeath = !noValueSelectOptions.includes(accidentalDeathCoverageField.value);

  let initialSelectedPackage = CoveragePackageOptions.NONE;
  if (hasTravelPeaceOfMind) {
    initialSelectedPackage = CoveragePackageOptions.TRAVELPEACEOFMIND;
  } else if (hasTravelAssistance) {
    initialSelectedPackage = CoveragePackageOptions.TRAVELASSISTANCE;
  }
  const [selectedPackage, setSelectedPackage] = useState(initialSelectedPackage);

  useAddFields({
    [travelPeaceOfMindPackageField.key]: travelPeaceOfMindPackageField,
    [travelAssistancePackageField.key]: travelAssistancePackageField,
  });

  // If more than 1 vehicles have TPOM, then we can't deselect the policy level coverage (AD&D)
  const allAnswers = useSelector(getAnswers);
  const canUpdatePolicyLevelCoverage =
    Object.keys(allAnswers).filter(
      (key) => key.includes('travelPeaceOfMind') && castToBoolean(allAnswers[key]),
    ).length < 2;

  const handlePackageChange = useEvent(async (newValue: CoveragePackageOptions) => {
    setSelectedPackage(newValue);
    switch (newValue) {
      case CoveragePackageOptions.TRAVELASSISTANCE:
        await dispatch(
          updateAnswers({
            answers: {
              [travelPeaceOfMindPackageField.key]: 'false',
              [travelAssistancePackageField.key]: 'true',
              [roadsideServiceCoverageField.key]: 'true',
              [roadTripAccidentCoverageField.key]: 'true',
              [rentalReimbursementCoverageField.key]: null,
              ...(canUpdatePolicyLevelCoverage && {
                [accidentalDeathCoverageField.key]: null,
              }),
            },
          }),
        );
        if (setSnackbarMessages) {
          setSnackbarMessages((prevState) => [
            ...prevState,
            getPackageSelectionMessage(CoverageLabels.TRAVELASSISTANCE),
          ]);
        }
        break;
      case CoveragePackageOptions.TRAVELPEACEOFMIND:
        /**
         * AD&D and Rental Reimbursement are dropdown. SAPI is ordering from lowest limit to highest.
         * Default to lowest limit on package apply, unless user has already selected a limit.
         */
        await dispatch(
          updateAnswers({
            answers: {
              [travelAssistancePackageField.key]: 'false',
              [travelPeaceOfMindPackageField.key]: 'true',
              [roadsideServiceCoverageField.key]: 'true',
              [roadTripAccidentCoverageField.key]: 'true',
              ...(!hasAccidentalDeath && {
                [accidentalDeathCoverageField.key]: getFirstValueFromOptions(
                  accidentalDeathCoverageField,
                ),
              }),
              ...(!hasRentalReimbursement && {
                [rentalReimbursementCoverageField.key]: getFirstValueFromOptions(
                  rentalReimbursementCoverageField,
                ),
              }),
            },
          }),
        );
        if (setSnackbarMessages) {
          setSnackbarMessages((prevState) => [
            ...prevState,
            getPackageSelectionMessage(CoverageLabels.TRAVELPEACEOFMIND),
          ]);
        }
        break;
      default: {
        const message = getPackageRemovedMessage(
          castToBoolean(travelAssistancePackageField.value)
            ? CoverageLabels.TRAVELASSISTANCE
            : CoverageLabels.TRAVELPEACEOFMIND,
        );
        await dispatch(
          updateAnswers({
            answers: {
              [travelAssistancePackageField.key]: 'false',
              [travelPeaceOfMindPackageField.key]: 'false',
              [roadsideServiceCoverageField.key]: 'false',
              [roadTripAccidentCoverageField.key]: 'false',
              [rentalReimbursementCoverageField.key]: null,
              ...(canUpdatePolicyLevelCoverage && {
                [accidentalDeathCoverageField.key]: null,
              }),
            },
          }),
        );
        if (setSnackbarMessages) {
          setSnackbarMessages((prevState) => [...prevState, message]);
        }
        break;
      }
    }
  });

  useEffect(() => {
    const travelAssistanceIncludedCoverageSelections = [
      { label: CoverageLabels.ROADTRIP, selectedCoverage: hasRoadTripAccident },
      { label: CoverageLabels.EMERGENCYROADSIDE, selectedCoverage: hasRoadsideService },
    ];
    const qualifiesForTravelAssistance = travelAssistanceIncludedCoverageSelections.every(
      (includedCoverage) => !!includedCoverage.selectedCoverage,
    );

    const travelPeaceOfMindIncludedCoverageSelections = [
      ...travelAssistanceIncludedCoverageSelections,
      { label: CoverageLabels.RENTALREIMBURSEMENT, selectedCoverage: hasRentalReimbursement },
      { label: CoverageLabels.ACCIDENTALDEATH, selectedCoverage: hasAccidentalDeath },
    ];
    const qualifiesForTravelPeaceOfMind = travelPeaceOfMindIncludedCoverageSelections.every(
      (includedCoverage) => !!includedCoverage.selectedCoverage,
    );

    const updatePackage = async (
      field: Field<AnswerValue>,
      value: string,
      message?: string | undefined,
    ): Promise<void> => {
      await field.props.actionOnComplete(value);
      if (setSnackbarMessages && message) {
        setSnackbarMessages((prevState) => [...prevState, message]);
      }
    };

    // Only one of these can happen when an included coverage changes; they are in the precise order they need to be
    // User has TPOM, unselects one of the included coverage
    if (!qualifiesForTravelPeaceOfMind && hasTravelPeaceOfMind) {
      const removedCoverageLabel =
        travelPeaceOfMindIncludedCoverageSelections.find((coverage) => !coverage.selectedCoverage)
          ?.label || '';
      updatePackage(
        travelPeaceOfMindPackageField,
        'false',
        getCoverageAndPackageRemovedMessage(CoverageLabels.TRAVELPEACEOFMIND, removedCoverageLabel),
      );
      // When User still qualifies for TA select it
      if (qualifiesForTravelAssistance) {
        updatePackage(travelAssistancePackageField, 'true');
        setSelectedPackage(CoveragePackageOptions.TRAVELASSISTANCE);
      } else {
        setSelectedPackage(CoveragePackageOptions.NONE);
      }
    }
    // User adds a coverage that now qulaifies them for TPOM
    else if (qualifiesForTravelPeaceOfMind && !hasTravelPeaceOfMind) {
      updatePackage(
        travelPeaceOfMindPackageField,
        'true',
        getPackageAutoApplyMessage(CoverageLabels.TRAVELPEACEOFMIND),
      );
      // If upgrading from TA, remove TA
      if (hasAccidentalDeath) {
        updatePackage(travelAssistancePackageField, 'false');
      }
      setSelectedPackage(CoveragePackageOptions.TRAVELPEACEOFMIND);
    }
    // User has TA, unselects one of the included coverage
    else if (!qualifiesForTravelAssistance && hasTravelAssistance) {
      const removedCoverageLabel =
        travelAssistanceIncludedCoverageSelections.find((coverage) => !coverage.selectedCoverage)
          ?.label || '';
      updatePackage(
        travelAssistancePackageField,
        'false',
        getCoverageAndPackageRemovedMessage(CoverageLabels.TRAVELASSISTANCE, removedCoverageLabel),
      );
      setSelectedPackage(CoveragePackageOptions.NONE);
    }
    // User adds a coverage that now qulaifies them for TA
    else if (qualifiesForTravelAssistance && !hasTravelAssistance && !hasTravelPeaceOfMind) {
      updatePackage(
        travelAssistancePackageField,
        'true',
        getPackageAutoApplyMessage(CoverageLabels.TRAVELASSISTANCE),
      );
      setSelectedPackage(CoveragePackageOptions.TRAVELASSISTANCE);
    }
    // Only execute when an included coverage value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accidentalDeathCoverageField.value,
    rentalReimbursementCoverageField.value,
    roadTripAccidentCoverageField.value,
    roadsideServiceCoverageField.value,
  ]);

  return {
    handlePackageChange,
    selectedPackage,
  };
};

export const getFirstValueFromOptions = (aField: Field<AnswerValue>): string => {
  if (!aField.question.options || aField.question.options.length < 1) {
    throw Error(`Invalid field - ${aField.key}. Field must have options.`);
  }

  return aField.question.options[0].value;
};

import { getReadOnlyDisplayValue, ReadOnlyField } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';

interface Props extends QuestionProps {
  personRef: string;
  readOnly?: boolean;
}

export const GenderQuestion: React.FC<Props> = (props) => {
  const { personRef, trackingName = 'gender', trackingLabel, readOnly, label } = props;
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const gender = usePersonField('gender');
  useAddFields({ gender });

  if (!gender.exists) return null;

  if (readOnly) {
    return (
      <ReadOnlyField
        label={label || ''}
        value={getReadOnlyDisplayValue(gender)}
        id='readOnlyGender'
      />
    );
  }

  return (
    <RadioGroupWithOptions
      {...(gender.props as OptionProps)}
      label={label}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
    />
  );
};

import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';

import type { TextFieldProps } from '@ecp/components';
import { GridItem, TextField } from '@ecp/components';
import { env } from '@ecp/env';
import {
  checkAndClearFields,
  useValidateVinNumber,
  useVehicleFields,
  validateVinFormat,
  VIN_REGEX_NEGATED_SYMBOL,
} from '@ecp/features/sales/quotes/auto';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import type { AnswerValue } from '@ecp/types';

import { useGroupPatchedVehicleInfo } from '../../../utils/vehicleOptions';

interface Props extends QuestionProps {
  setMaskRequiredFieldError: (value: boolean) => void;
  vehicleRef: string;
  maskRequiredFieldError: boolean;
}

export const VehicleVinQuestion: React.FC<Props> = (props) => {
  const { maskRequiredFieldError, vehicleRef, setMaskRequiredFieldError } = props;

  const { make, model, series, year, vin, stubVin, vehicleDetailId, vehicleInfoOrVin } =
    useVehicleFields(vehicleRef);

  const { setInfoError, fetchVehicleInfo } = useGroupPatchedVehicleInfo(
    make,
    model,
    series,
    vin,
    stubVin,
    vehicleDetailId,
    vehicleRef,
  );
  const { error: vinFieldError, ...vinProps } = vin.props;

  const smartError = (error: string | undefined, value: AnswerValue): string | undefined => {
    if (!error || !maskRequiredFieldError) return error;

    return maskRequiredFieldError && !value ? undefined : error;
  };
  const validateVinNumber = useValidateVinNumber(vin);
  const handleVinOnChange: NonNullable<TextFieldProps['actionOnChange']> = useCallback(
    (value) => {
      if (!value && vehicleInfoOrVin.value === undefined) {
        vehicleInfoOrVin.props.actionOnComplete('vin');
      }
      const vinString = value.toUpperCase().replace(VIN_REGEX_NEGATED_SYMBOL, '');
      setInfoError('');
      // if vin is masked clear VIN and patch it
      if (isMasked(vin.value)) {
        if (vin.value !== null) vin.props.actionOnComplete(null);
        vin.props.actionOnChange(null);
        vehicleInfoOrVin.props.actionOnComplete('vin');
      } else {
        vin.props.actionOnChange(vinString);
      }

      // reset fields on change
      if (!vinString) {
        checkAndClearFields([make, model, series, stubVin, vehicleDetailId]);
        setMaskRequiredFieldError(true);
      }
    },
    [
      make,
      model,
      series,
      stubVin,
      setInfoError,
      setMaskRequiredFieldError,
      vehicleDetailId,
      vehicleInfoOrVin.props,
      vehicleInfoOrVin.value,
      vin.props,
      vin.value,
    ],
  );

  const handleVinOnComplete: NonNullable<TextFieldProps['actionOnComplete']> = useCallback(
    async (value) => {
      if (isMasked(value)) return;
      if (!value) {
        setMaskRequiredFieldError(true);
        checkAndClearFields([make, model, series, stubVin, vehicleDetailId]);

        return;
      }
      if (env.static.isAgent) vin.props.actionOnComplete(value);
      const vinFormatValidationError = validateVinFormat(value);
      if (year.value && typeof year.value === 'string' && !vinFormatValidationError) {
        const vehicleInfoError = await fetchVehicleInfo(year.value, value);
        // check to see if it's a valid vin before patching it.
        if (!vehicleInfoError) validateVinNumber();
      }
    },
    [
      year.value,
      validateVinNumber,
      setMaskRequiredFieldError,
      series,
      model,
      make,
      stubVin,
      fetchVehicleInfo,
      vehicleDetailId,
      vin,
    ],
  );

  if (!vin.exists) return null;

  return (
    <GridItem topSpacing='sm' xs={12}>
      <TextField
        error={smartError(vinFieldError, vin.value) || validateVinFormat(vin.value as string)}
        {...vinProps}
        id='VIN'
        label='VIN'
        data-testid='vinNumber'
        ariaLabel='VIN'
        actionOnChange={handleVinOnChange}
        actionOnComplete={handleVinOnComplete}
        trackingName='VIN'
        trackingLabel={GoogleAnalyticsLabels.REDACTED}
      />
    </GridItem>
  );
};

import { Typography } from '@mui/material';
import type { Control } from 'react-hook-form';
import { useController } from 'react-hook-form';

import { TextField } from '@ecp/components';
import { theme } from '@ecp/themes/base';

import type { TPIInputFields } from '../../types';

export const CompanyName: React.FC<{
  control: Control<TPIInputFields>;
}> = ({ control }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name: 'companyName', control, rules: { required: 'Required' } });

  return (
    <>
      <TextField
        {...field}
        error={error?.message}
        id='searchCompanyName'
        label='Company name'
        placeholder='Enter company name'
        autoComplete='off'
        actionOnChange={field.onChange}
      />

      {!error?.message && (
        <Typography variant='body2' color={theme.palette.text.secondary} mt='3px'>
          This can be found on your property's financial document.
        </Typography>
      )}
    </>
  );
};

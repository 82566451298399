import { Divider, Grid } from '@mui/material';

import { formatDate } from '@ecp/utils/date';

import { GridItem } from '@ecp/components';
import { Button } from '@ecp/features/sales/shared/components';
import { usePersonValues, usePniRef } from '@ecp/features/sales/shared/store';

import { useStyles } from './QuoteResumeErrorPageForm.styles';

interface Props {
  productName: string;
  policyStartDate: string;
  isBundle: boolean;
  handleContinue: () => void;
  sniFirstName?: string;
  sniLastName?: string;
  areOffersBad?: boolean;
  plpcQuoteNumber?: string;
}

export const QuoteResumeContent: React.FC<Props> = (props) => {
  const {
    productName,
    policyStartDate,
    isBundle,
    handleContinue,
    sniFirstName,
    sniLastName,
    areOffersBad,
    plpcQuoteNumber,
  } = props;
  const { classes } = useStyles();

  // Section related to PNI
  const pniRef = usePniRef();
  const { firstName: pniFirstName, lastName: pniLastName } = usePersonValues(pniRef);

  return (
    <Grid container>
      <GridItem topSpacing='lg' xs={12}>
        <h3>{`${productName} policy`}</h3>
      </GridItem>
      <GridItem xs={12}>
        <p>Primary Named Insured: {`${pniFirstName} ${pniLastName}`}</p>
      </GridItem>
      {sniFirstName && sniLastName && (
        <GridItem xs={12}>
          <p>Secondary Named Insured: {`${sniFirstName} ${sniLastName}`}</p>
        </GridItem>
      )}
      <GridItem xs={12}>
        <p>Policy start date: {formatDate(policyStartDate)}</p>
      </GridItem>
      <GridItem xs={12}>
        <p>Policy Center JobNumber: {plpcQuoteNumber}</p>
      </GridItem>
      {areOffersBad && (
        <GridItem topSpacing='lg' xs={12}>
          <p>{`Unable to resume ${productName} quote in ASP, Please continue to policy center to resume.`}</p>
        </GridItem>
      )}
      <GridItem xs={12}>
        <Button className={classes.next} variant='primary' onClick={handleContinue}>
          {areOffersBad
            ? `Continue to Resume ${productName} in Policy Center`
            : `Continue to Resume ${productName} in ASP`}
        </Button>
      </GridItem>
      {!isBundle && productName === 'Auto' && (
        <GridItem topSpacing='lg' xs={12}>
          <Divider />
        </GridItem>
      )}
    </Grid>
  );
};

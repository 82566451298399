import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { IconUIArrowCircleRight } from '@ecp/themes/base';

import { useStyles } from './SearchTPIErrorMessage.styles';

export const SearchTPIErrorMessage: React.FC<{ message?: string }> = ({ message }) => {
  const { classes } = useStyles();

  if (!message) return null;

  return (
    <Grid container className={classes.errorContainer}>
      <GridItem className={classes.svgContainer} xs={1}>
        <IconUIArrowCircleRight className={classes.arrowRight} />
      </GridItem>
      <GridItem xs={11}>
        <p>{message}</p>
      </GridItem>
    </Grid>
  );
};

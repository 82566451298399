import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'TravelPeaceOfMindPackageCoverage' })((theme) => ({
  coverageName: {
    ...theme.typography.body4,
    color: theme.palette.text.primary,
  },
  toolTip: {
    position: 'relative',
    padding: 0,
    marginTop: -1,
  },
}));

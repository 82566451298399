import { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { GridItem, NumberFormat } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import {
  getDeltaField,
  getDriverRefs,
  getFieldValue,
  setFormErrorsChangedByField,
  useDeltaField,
  useField,
  usePniRef,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector, useStore } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, Field } from '@ecp/types';

import { getDefaultOptInPhone } from '../../utils/discountsUtil';

export interface DeltaDriveMyWayPhoneQuestionProps {
  driverRef: string;
  index: number;
}
export interface ProgramPhoneOptions {
  label: string;
  value: string;
}
// TODO: Need to merge this component with DeltaMilesMyWayPhoneQuestion
export const DeltaDriveMyWayPhoneQuestion: React.FC<DeltaDriveMyWayPhoneQuestionProps> = (
  props,
) => {
  const { driverRef } = props;
  const store = useStore();
  const dispatch = store.dispatch;
  const driversRef = useSelector(getDriverRefs);
  const driveMyWayPhoneStaticField: Field = useField(`static.delta.${driverRef}.ubiProgramPhone`);
  const driveMyWayPhoneField: Field = useDeltaField(driverRef, 'ubiProgramPhone');
  const ubiProgramOptIn: Field = useDeltaField(driverRef, 'ubiProgramOptIn');

  const pniRef = usePniRef();

  const pniPhone = useSelector((state: RootStore) => state.inquiry.answers[`${pniRef}.phone`]) as
    | string
    | undefined;

  const [initialDMWPhone, setInitialDMWPhone] = useState(pniPhone);

  const driveMyWayPhoneValue = getDefaultOptInPhone(initialDMWPhone);

  useMemo(() => {
    setInitialDMWPhone(driveMyWayPhoneValue);
  }, [driveMyWayPhoneValue]);

  useInitValues(
    initialDMWPhone
      ? {
          [driveMyWayPhoneStaticField.key]: driveMyWayPhoneValue,
          [driveMyWayPhoneField.key]: driveMyWayPhoneValue,
        }
      : { [driveMyWayPhoneStaticField.key]: 'Add New Phone', [driveMyWayPhoneField.key]: null },
  );
  useAddFields({
    driveMyWayPhoneStaticField,
    driveMyWayPhoneField,
    ubiProgramOptIn,
  });

  const getPhoneOptions = (options: string[]): ProgramPhoneOptions[] =>
    options.map((value) => ({
      label: value,
      value,
    }));

  const onPhoneOptionChange = useCallback(
    (value: AnswerValue): void => {
      driveMyWayPhoneStaticField.props.actionOnChange(value);
      if (value === initialDMWPhone) {
        driveMyWayPhoneField.props.actionOnComplete(initialDMWPhone);
      } else {
        driveMyWayPhoneField.validateUpdateAndPatch(null);
      }
    },
    [driveMyWayPhoneStaticField, driveMyWayPhoneField, initialDMWPhone],
  );

  // Filter to get the duplicate phone field and setting up the error
  const handlePhoneNumberOnComplete = useCallback(
    (value: AnswerValue): boolean => {
      driveMyWayPhoneField.validateUpdateAndPatch(value);
      // if there is a drive my way phone value, then we want to extract
      // all of the phone numbers that are set on all of the delta drivers.
      const phoneFieldNamesAndValues: [string, AnswerValue][] = value
        ? driversRef.map((driverRef: string) => {
            const driverPhoneNumberFieldName = getDeltaField(
              store.getState(),
              driverRef,
              'ubiProgramPhone',
            );

            return [
              driverPhoneNumberFieldName,
              getFieldValue(store.getState(), driverPhoneNumberFieldName),
            ];
          })
        : [];

      // finally return if all the phone numbers are unique,
      // (while also raising a field error for the first found duplicate).
      return phoneFieldNamesAndValues.some(([key, value], index) => {
        const haveDuplicate = phoneFieldNamesAndValues.some(
          // for any value beyond this phone number in the array,
          // do they have the same value as this phone number?
          ([otherKey, otherValue], otherIndex) => otherIndex > index && otherValue === value,
        );
        if (haveDuplicate) {
          dispatch(
            setFormErrorsChangedByField({
              key,
              errors: ['Enter a different Phone Number.'],
            }),
          );

          return true;
        }

        return false;
      });
    },
    [store, dispatch, driveMyWayPhoneField, driversRef],
  );

  if (!driveMyWayPhoneField.exists) return null;

  return (
    <Grid container columnSpacing={1}>
      {initialDMWPhone && (
        <GridItem topSpacing='xs' md={5} xs={12}>
          <Select
            {...driveMyWayPhoneStaticField.props}
            label='Phone Number'
            id='driveMyWayPhoneNumber'
            options={getPhoneOptions(
              initialDMWPhone ? [initialDMWPhone, 'Add New Phone'] : ['Add New Phone'],
            )}
            trackingName='drive_my_way_phone'
            actionOnChange={onPhoneOptionChange}
          />
        </GridItem>
      )}
      {driveMyWayPhoneStaticField.value === 'Add New Phone' && (
        <GridItem topSpacing='xs' md={5} xs={12}>
          <NumberFormat
            {...driveMyWayPhoneField.props}
            label={initialDMWPhone ? 'New Phone' : 'Phone Number'}
            trackingName='drive_my_way_new_phone'
            actionOnComplete={handlePhoneNumberOnComplete}
            formatType='phone'
          />
        </GridItem>
      )}
    </Grid>
  );
};

import { Button, GridItem } from '@ecp/components';

import { useStyles } from './PrimarySecondaryButtons.styles';

export const PrimarySecondaryButtons: React.FC<{
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
  secondaryOnClick: () => Promise<void>;
  primaryOnClick: () => Promise<void>;
  isProcessing?: boolean;
  disabled?: boolean;
  showSecondaryButton?: boolean;
}> = ({
  primaryButtonLabel,
  secondaryButtonLabel,
  primaryOnClick,
  secondaryOnClick,
  isProcessing = false,
  disabled = false,
  showSecondaryButton = true,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <GridItem xs={12} md={8}>
        <Button
          variant='primary'
          fullWidth
          className={classes.continueButton}
          onClick={primaryOnClick}
          isProcessing={isProcessing}
          disabled={disabled}
        >
          {primaryButtonLabel}
        </Button>
      </GridItem>
      <GridItem xs={12} md={4} className={classes.clearButtonContainer}>
        {showSecondaryButton && (
          <Button
            variant='text'
            onClick={secondaryOnClick}
            type='button'
            fullWidth
            className={classes.clearButton}
          >
            {secondaryButtonLabel}
          </Button>
        )}
      </GridItem>
    </>
  );
};

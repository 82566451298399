import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoLossesAndViolationsPage' })((theme) => ({
  link: {
    ...theme.typography.body1,
    textDecoration: 'none',
  },
  actionBarContent: {
    width: '20%',
    textAlign: 'right',
  },
}));

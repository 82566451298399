import { useCallback } from 'react';

import { GridItem, RadioGroupWithOptions, useShowMoreOrLess } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { useSingleFoundation } from '@ecp/features/sales/quotes/property/home';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/types';

import { HomeBasementFinishedPercentageQuestion } from '../HomeBasementFinishedPercentageQuestion';
import type { FoundationTypeQuestionProps } from './FoundationTypeQuestion';

const basementValues = [
  'FOUNDATION.FOUNDATION_TYPE.BASEMENT_UNDERGROUND_BELOW_GRADE',
  'FOUNDATION.FOUNDATION_TYPE.BASEMENT_DAYLIGHT_WALKOUT',
];

export const FoundationTypeSingleSelectionQuestion: React.FC<FoundationTypeQuestionProps> = (
  props,
) => {
  const { singleFoundationRef: foundationRef, addUpdateFoundationDetails } = useSingleFoundation();
  const foundationTypeField = useFieldWithPrefix(
    foundationRef,
    'foundation.<id>',
  )('foundationType');

  const basementFinishedPercentageField = useFieldWithPrefix(
    'property',
    'property',
  )('foundation.percentageFinished');

  const otherFoundationTypeDescriptionField = useFieldWithPrefix(
    foundationRef,
    'exteriorWall.<id>',
  )('otherFoundationTypeDescription');

  const finishedBasementLivingAreaField = useFieldWithPrefix(
    foundationRef,
    'foundation.<id>',
  )('finishedBasementLivingAreaSQF');

  useAddFields({ [foundationTypeField.key]: foundationTypeField });

  const {
    label = foundationTypeField?.question?.title,
    groupLabel = basementFinishedPercentageField?.question?.title,
    trackingName = 'home_foundation_type_selection',
    displayTypeForHomeBasementPercentageFinished,
  } = props;

  // sorting of options should happen here because this hook returns
  // the displayOptions to be used by RadioGroupWithOptions for display.
  const {
    showMore: showMoreFoundationType,
    displayOptions: displayFoundationType,
    handleClick: handleFoundationTypeShowMoreOrLess,
  } = useShowMoreOrLess(
    foundationTypeField.props.options,
    4,
    'home_foundation_type_more_choices',
    undefined,
    true,
  );

  const handleFoundationTypeComplete = useCallback(
    async (value: AnswerValue) => {
      foundationTypeField.validateAndUpdate(value); // No patching happens here
      await addUpdateFoundationDetails(value);
      if (!basementValues.includes(value)) {
        basementFinishedPercentageField.validateUpdateAndPatch(null);
      }
      // No need to validate the description field, we are resetting it
      if (otherFoundationTypeDescriptionField?.value) {
        otherFoundationTypeDescriptionField.updateAndPatch(null);
      }

      // If the foundation type is changed, user need to answer finishedBasementLivingAreaField again
      if (finishedBasementLivingAreaField?.value) {
        finishedBasementLivingAreaField.updateAndPatch(null);
      }
    },
    [
      basementFinishedPercentageField,
      foundationTypeField,
      finishedBasementLivingAreaField,
      otherFoundationTypeDescriptionField,
      addUpdateFoundationDetails,
    ],
  );

  if (!foundationTypeField.exists) return null;

  return (
    <>
      <GridItem xs={12}>
        <RadioGroupWithOptions
          {...foundationTypeField.props}
          options={displayFoundationType}
          label={label}
          showMoreOrLessProps={{
            showMore: showMoreFoundationType,
            onClick: handleFoundationTypeShowMoreOrLess,
            groupTestId: 'FoundationTypeMoreChoices',
          }}
          trackingName={trackingName}
          cardSize='small'
          id='FoundationType'
          actionOnComplete={handleFoundationTypeComplete}
          sortByKey
        />
      </GridItem>
      {basementValues.includes(foundationTypeField.value) && (
        <GridItem topSpacing='lg' xs={12}>
          <HomeBasementFinishedPercentageQuestion
            label={groupLabel}
            displayType={displayTypeForHomeBasementPercentageFinished}
            trackingName='home_basement_finished_percentage_slider'
          />
        </GridItem>
      )}
    </>
  );
};

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { getReadOnlyDisplayValue, ReadOnlyField } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getDrivers, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';

interface Props extends QuestionProps {
  driverRef: string;
  helperText?: string;
  isPni: boolean;
  groupLabel?: string;
}

export const DriverRoleQuestion: React.FC<Props> = (props) => {
  const {
    driverRef,
    id = 'DriverRole',
    label,
    groupLabel,
    trackingName = 'DriverRole',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    placeholder = 'Select Operator Role',
    helperText,
    isPni,
  } = props;
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const driverStatus = useDriverField('driverStatus');
  const drivers = useSelector(getDrivers);
  // If PNI is the only driver, they have to be an operator
  const isReadOnlyField = isPni && drivers.length === 1;

  useInitValues({ [driverStatus.key]: 'OPERATOR' });
  useAddFields({ driverStatus });

  // EDSP-13203 accomodate need for sentence casing of options
  let driverStatusOptions = driverStatus.props.options?.map((option) => {
    if (option.label === 'Excluded Operator') {
      return { label: 'Excluded operator', value: option.value };
    }
    if (option.label === 'Non-Operator') {
      return { label: 'Non-operator', value: option.value };
    }

    return option;
  });

  // PNI can't be Non-Operator for Advance
  if (isPni) {
    driverStatusOptions = driverStatusOptions?.filter((status) => status.label !== 'Non-operator');
  }

  if (!driverStatus.exists) return null;

  const statusDisplayName = getReadOnlyDisplayValue(driverStatus);

  const driverRole = isReadOnlyField ? (
    <ReadOnlyField
      label={groupLabel || label || ''}
      value={statusDisplayName}
      helperText='A single operator cannot be excluded.'
      id='driverStatus'
    />
  ) : (
    <Select
      {...(driverStatus.props as OptionProps)}
      fullWidth={false}
      placeholder={placeholder}
      id={id}
      inputButtonAriaLabel='Drivers Role'
      label={label}
      groupLabel={groupLabel}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
      helperText={helperText}
      options={driverStatusOptions}
    />
  );

  return driverRole;
};

import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { GridItem } from '@ecp/components';
import { Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import { Button } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import {
  getDriverInfo,
  updatePageStatus,
  usePniRef,
  useSniRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { PageErrors } from '@ecp/features/sales/shared/types';

import { InsuranceQuestions } from '../../components/InsuranceQuestions/InsuranceQuestions';
import type { PriorInsurance } from '../../utils';
import { useGetPriorInsuranceInfo } from '../../utils';
import { useStyles } from './AutoPriorCarrierPageForm.styles';

interface Props {
  onNext?: () => Promise<void>;
  nextPageInstructions?: string;
}

const validateInsuranceInfo = (insuranceInfo: PriorInsurance[], errors: PageErrors[]): void => {
  insuranceInfo.forEach((priorInsurance) => {
    if (
      !priorInsurance.carrierName ||
      !priorInsurance.policyInceptionDate ||
      !priorInsurance.policyEndDate ||
      !priorInsurance['coverages.policy.bodilyInjury']
    ) {
      errors.push({ id: priorInsurance.ref, message: '' });
    }
    if (
      dayjs(priorInsurance.policyEndDate).isBefore(priorInsurance.policyInceptionDate) ||
      dayjs(priorInsurance.policyEndDate).isSame(priorInsurance.policyInceptionDate)
    ) {
      errors.push({ id: priorInsurance.ref, message: '' });
    }
  });
};

export const AutoPriorCarrierPageForm: React.FC<Props> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageErrors, setPageErrors] = useState<PageErrors[]>([]);
  const [isPNIFormOpen, setIsPNIFormOpen] = useState(false);
  const [isSNIFormOpen, setIsSNIFormOpen] = useState(false);
  const primaryInsuredPersonRef = usePniRef(); // primaryInsured.driver.ref does not exist for us so we have to get it this way
  const secondaryNamedInsuredPersonRef = useSniRef();
  const drivers = useSelector(getDriverInfo);
  const pniDriver = drivers.find((driver) => {
    return driver.personRef === primaryInsuredPersonRef;
  });
  const sniDriver = drivers.find((driver) => {
    return driver.personRef === secondaryNamedInsuredPersonRef;
  });
  const pniPriorInsuranceInfo = useGetPriorInsuranceInfo(pniDriver?.ref);
  const sniPriorInsuranceInfo = useGetPriorInsuranceInfo(sniDriver?.ref);

  let pniDisplayName = pniDriver?.firstName || '';
  const sniDisplayName = sniDriver?.firstName || '';
  // IF: the driver (assumed to be SNI here) has the same first name as the PNI...
  const hasSameFirstName = drivers.some((driver) => {
    return driver.firstName === pniDriver?.firstName;
  });

  if (hasSameFirstName) {
    /* THEN, set the displayed name of the PNI to be the first name and their middle or suffix
    to distinguish them from the secondary driver, and use that name on the insuranceQuestions component */
    pniDisplayName = pniDriver?.middleName
      ? `${pniDriver?.firstName} ${pniDriver?.middleName}`
      : `${pniDriver?.firstName} ${pniDriver?.suffix}`;
  }

  const handleMouseDown = useEvent((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  });

  const handleSubmit = useCallback(async () => {
    setPageErrors([]);
    setLoading(true);
    const errors: PageErrors[] = [];

    validateInsuranceInfo(pniPriorInsuranceInfo, errors);
    validateInsuranceInfo(sniPriorInsuranceInfo, errors);

    setPageErrors(errors);
    if (errors.length < 1) {
      dispatch(updatePageStatus(NavStatus.VALID));
      if (onNext) await onNext();
    }
    setLoading(false);
  }, [dispatch, onNext, pniPriorInsuranceInfo, sniPriorInsuranceInfo]);

  return (
    <div className={classes.root}>
      <Form>
        <Grid container>
          <Grid container xs={12}>
            <InsuranceQuestions
              driverRef={pniDriver?.ref || ''}
              personRef={primaryInsuredPersonRef}
              isPni
              allowAddSNI={false}
              unambiguousName={pniDisplayName}
              pageErrors={pageErrors}
              setIsFormOpen={setIsPNIFormOpen}
            />
            {secondaryNamedInsuredPersonRef && (
              <InsuranceQuestions
                driverRef={sniDriver?.ref || ''}
                personRef={secondaryNamedInsuredPersonRef}
                isPni={false}
                allowAddSNI
                unambiguousName={sniDisplayName}
                pageErrors={pageErrors}
                setIsFormOpen={setIsSNIFormOpen}
              />
            )}
          </Grid>
          <GridItem topSpacing='lg' xs={12}>
            <NextPageInstructions divider />
          </GridItem>
          <div>
            <Button
              className={classes.next}
              variant='primary'
              onClick={handleSubmit}
              data-testid='autoPriorCarrierContinue'
              // This stops the onBlur from firing only when this button is clicked
              // and the button retains its coordinates for the mouseup to eventually
              // register it as a click event. If you want the onBlur as well, you can
              // fire it yourself from the onClick handler. Touch should keep working.
              onMouseDown={handleMouseDown}
              isProcessing={loading}
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='auto_prior_carrier_page_continue'
              analyticsElement='choice.autoPriorCarrierPage.continueButton'
              disabled={isPNIFormOpen || isSNIFormOpen}
            >
              Continue
            </Button>
          </div>
        </Grid>
      </Form>
    </div>
  );
};

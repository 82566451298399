import { memo, useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useAddConditionValues, useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { AddressQuestions } from '@ecp/features/sales/shared/components';
import {
  getLineOfBusiness,
  useFieldWithPrefix,
  usePniRef,
  usePriorAddressRef,
} from '@ecp/features/sales/shared/store';
import {
  useAddAddress,
  useAddressFields,
  useRemoveAllAddressRef,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';

export const PriorAddressQuestions: React.FC = memo(() => {
  const pniRef = usePniRef();
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const hasPriorAddress = usePersonField('additionalInformation.hasResidedAtDifferentAddress');
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const [isValidAddress, setIsValidAddress] = useState<boolean>(true);
  const [showAddressSuggestion, setShowAddressSuggestion] = useState<boolean>(false);

  const priorAddressFieldLabel =
    'Has the primary named insured had any other addresses in the last 5 years?';

  const priorAddressRef = usePriorAddressRef();
  const addPriorAddress = useAddAddress();
  const removePriorAddress = useRemoveAllAddressRef(
    `${pniRef}.additionalInformation.priorAddressRefIds`,
  );
  const {
    address: { line1, line2, city, state, zipcode, isLocked },
  } = useAddressFields(priorAddressRef);

  const isAddressFieldLocked = isLocked && Boolean(isLocked.value);

  useInitValues({ [hasPriorAddress.key]: false });
  useAddFields({
    [line1.key]: line1,
    [line2.key]: line2,
    [city.key]: city,
    [state.key]: state,
    [zipcode.key]: zipcode,
  });
  useAddConditionValues({
    conditionalFields: [line1, line2, city, state, zipcode],
    isExcluded: () => !hasPriorAddress.value,
  });

  const handleChangePriorAddress = useCallback(
    async (value: boolean) => {
      hasPriorAddress.validateUpdateAndPatch(value);
      if (value) {
        addPriorAddress(`${pniRef}.additionalInformation.priorAddressRefIds`);
      } else {
        removePriorAddress();
      }
    },
    [addPriorAddress, removePriorAddress, hasPriorAddress, pniRef],
  );

  if (
    !hasPriorAddress.exists ||
    !(
      lineOfBusiness === LineOfBusiness.RENTERS ||
      lineOfBusiness === LineOfBusiness.HOME ||
      lineOfBusiness === LineOfBusiness.BUNDLE ||
      lineOfBusiness === LineOfBusiness.BUNDLE_AUTO_RENTERS
    )
  )
    return null;

  return (
    <Grid container>
      <GridItem topSpacing='sm' xs={12}>
        <RadioGroupWithOptions
          {...hasPriorAddress.props}
          label={priorAddressFieldLabel}
          actionOnComplete={handleChangePriorAddress}
          variant='yesNoButton'
          trackingName='prior_address_same_as_property_selection'
          disabled={!!isAddressFieldLocked}
        />
      </GridItem>
      {hasPriorAddress.value && (
        <AddressQuestions
          selectedAddressRef={priorAddressRef}
          addressLinelabel='Prior Street Address'
          trackingName='prior_address'
          addressType='Other'
          isValidAddress={isValidAddress}
          setIsValidAddress={setIsValidAddress}
          showAddressSuggestion={showAddressSuggestion}
          setShowAddressSuggestion={setShowAddressSuggestion}
        />
      )}
    </Grid>
  );
});

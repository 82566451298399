import { Divider, Grid, Link, Typography } from '@mui/material';

import { isEmpty } from '@ecp/utils/common';

import { Alert, GridItem } from '@ecp/components';
import { Button } from '@ecp/features/sales/shared/components';
import { SearchTPI, SearchTPIResults } from '@ecp/features/sales/tpi';
import { PolicyCenterDialog } from '@ecp/sales/lob/property';

import { AutoAddTPIVehicleHeader } from '../../../../lob/autoLine';
import { PropertyAddTPIHeader } from '../../../../lob/propertyLine';
import { useThirdPartyInterestPageContext } from '../ThirdPartyInterestContextProvider';
import { useStyles } from './AddTPISearch.styles';

export const AddTPISearch: React.FC = () => {
  const { classes } = useStyles();

  const {
    showSearch,
    isSubmitting,
    canOpenRouteToPLPCDialog,
    selectTpiErrorMessage,
    currentSearchResults,
    showNoResultsFromSearch,
    selectedProductFromAddEditButton,
    handleCancelSearch,
    handleRouteToPLPCDialogAction,
    handleCancelButtonOnClick,
    handleContinueFromSearchResult,
    onClickPolicyCenter,
    handleOnSelectedFromSearch,
    handleSearchReturn,
  } = useThirdPartyInterestPageContext();

  const tpiSelectionErrorMessage = (
    <>
      {selectTpiErrorMessage !== '' && (
        <GridItem topSpacing='sm' xs={12}>
          <Alert withIcon type='error'>
            {selectTpiErrorMessage}
          </Alert>
        </GridItem>
      )}
    </>
  );

  const additionalTpiMessage = (
    <Typography className={classes.contentSubTitle}>
      For additional TPIs or to add a new organization, continue this quote in{' '}
      <Link component='button' onClick={onClickPolicyCenter}>
        PolicyCenter
      </Link>
      .
    </Typography>
  );

  if (!showSearch) return null;

  return (
    <Grid container>
      <AutoAddTPIVehicleHeader />
      <PropertyAddTPIHeader />
      <Grid container className={classes.searchAddContainer}>
        <Grid container>
          <SearchTPI
            product={selectedProductFromAddEditButton!}
            handleSearch={handleSearchReturn}
          />
          {!isEmpty(currentSearchResults) && (
            <>
              <SearchTPIResults
                tpiResults={currentSearchResults}
                onSelectionChange={handleOnSelectedFromSearch}
              />
              {additionalTpiMessage}
            </>
          )}
          {isEmpty(currentSearchResults) && showNoResultsFromSearch && (
            <Grid container>
              <Divider className={classes.divider} />
              <Grid item xs={12}>
                <Typography className={classes.contentTitle}>Search Results (0)</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.contentSubTitle}>
                  No results found. Adjust your search criteria and search again.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {additionalTpiMessage}
              </Grid>
            </Grid>
          )}
          {tpiSelectionErrorMessage}
        </Grid>
      </Grid>
      <Grid container className={classes.buttonContainer}>
        <Divider className={classes.divider} />
        <GridItem className={classes.cancelButton}>
          <Button
            className={classes.button}
            variant='iconTextMedium'
            onClick={handleCancelSearch}
            trackingName='tpi_search_cancel'
          >
            CANCEL
          </Button>
        </GridItem>
        <GridItem>
          <Button
            className={classes.button}
            variant='primary'
            onClick={handleContinueFromSearchResult}
            trackingName='tpi_search_continue'
          >
            CONTINUE
          </Button>
        </GridItem>
      </Grid>
      {canOpenRouteToPLPCDialog && (
        <PolicyCenterDialog
          dialogStat={canOpenRouteToPLPCDialog}
          dialogSubmit={handleRouteToPLPCDialogAction}
          isProcessing={isSubmitting}
          toggleDialog={handleCancelButtonOnClick}
        />
      )}
    </Grid>
  );
};

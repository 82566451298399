import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { PolicyCenterDialog } from '../../components';
import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import { CoverageFieldMainCoverageCheckboxComponent } from '../CoverageFieldComponents';
import { CoverageFieldRadioGroupComponent } from '../CoverageFieldComponents/CoverageFieldComponents';
import { useStyles } from './PersonalInjuryCoverage.styles';

export const PersonalInjuryCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();

  // Get the coverage selected fields and all the related subcoverage fields
  const [personalInjuryCoverageField, positionField, suedField] = useGroupedCoverageFields(
    props.carrierName,
    props.product,
    'personalInjury',
    ['selected', 'position', 'sued'],
  );

  // Define the logic around subcoverage fields which are
  // impacted by opt-in/ opt-out feature for main coverage field
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(personalInjuryCoverageField, [
    positionField,
    suedField,
  ]);

  const [dialogOpenForPosition, setDialogOpenForPosition] = useState(false);
  const [dialogOpenForSued, setDialogOpenForSued] = useState(false);

  const handlePersonalInjuryCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );

  const handleSubCoverageSelection = useCallback(
    (value: AnswerValue, field: Field<AnswerValue>) => {
      field.validateUpdateAndPatch(value);

      // If either sued or position is true, open the dialog
      if (field.key === positionField.key) {
        setDialogOpenForPosition(castToBoolean(value));
      } else {
        setDialogOpenForSued(castToBoolean(value));
      }
    },
    [positionField.key, setDialogOpenForPosition, setDialogOpenForSued],
  );

  const handlePositionSelection = useEvent((value: AnswerValue) => {
    handleSubCoverageSelection(value, positionField);
  });

  const handleSuedSelection = useEvent((value: AnswerValue) => {
    handleSubCoverageSelection(value, suedField);
  });

  // This is triggered when user click on Confirm
  const handleDialogSubmit = useCallback(() => {
    // Close the dialog
    setDialogOpenForPosition(false);
    setDialogOpenForSued(false);
    // TODO: handle redirection to Policy Center Page
  }, [setDialogOpenForPosition, setDialogOpenForSued]);

  // This is triggered when user clicks on Cancel
  const handleToggleDialog = useCallback(() => {
    // If the user has tried to select yes for one field and then cancel it,
    // cancel the selection and set that field to null.
    if (dialogOpenForPosition) {
      positionField.updateAndPatch(null);
      setDialogOpenForPosition(false);
    } else {
      suedField.updateAndPatch(null);
      setDialogOpenForSued(false);
    }
  }, [
    dialogOpenForPosition,
    positionField,
    setDialogOpenForPosition,
    suedField,
    setDialogOpenForSued,
  ]);

  if (!personalInjuryCoverageField.exists) return null;

  const isPersonalInjuryCoverageSelected = castToBoolean(personalInjuryCoverageField.value);

  return (
    <>
      <div className={classes.root}>
        <Grid container gap={1} justifyItems='space-between'>
          <CoverageFieldMainCoverageCheckboxComponent
            coverageName='personalInjuryCoverage'
            field={personalInjuryCoverageField}
            isCoverageSelected={isPersonalInjuryCoverageSelected}
            handleCoverageChange={handlePersonalInjuryCoverageChange}
          />
        </Grid>
      </div>
      {isPersonalInjuryCoverageSelected && (
        <Grid container item className={classes.subCoverageRoot}>
          <CoverageFieldRadioGroupComponent
            coverageName='PersonalInjuryPosition'
            field={positionField}
            sortByKey
            handleSubCoverageChange={handlePositionSelection}
          />
          <CoverageFieldRadioGroupComponent
            coverageName='PersonalInjurySued'
            field={suedField}
            sortByKey
            handleSubCoverageChange={handleSuedSelection}
          />
        </Grid>
      )}
      {(dialogOpenForPosition || dialogOpenForSued) && (
        <PolicyCenterDialog
          dialogStat={dialogOpenForPosition || dialogOpenForSued}
          dialogSubmit={handleDialogSubmit}
          toggleDialog={handleToggleDialog}
        />
      )}
    </>
  );
};

import { useCallback, useRef, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { scrollToTop } from '@ecp/utils/web';

import { GridItem } from '@ecp/components';
import {
  useAddConditionValues,
  useGetConditionValues,
  useGetFields,
  useGetInitValues,
} from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import {
  PaperlessBillingQuestion,
  RecurringPaymentQuestion,
} from '@ecp/features/sales/shared/questions';
import {
  getAnswer,
  getDrivers,
  setFormErrorsChangedByField,
  submitProofDraft,
  useField,
  useForm,
  usePniRef,
} from '@ecp/features/sales/shared/store';
import type {
  RootStore,
  ThunkAction,
  ValidateFormResult,
} from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { ProductName } from '@ecp/features/shared/product';
import { ProgramDiscountsQuestion } from '@ecp/sales/lob/auto';

import {
  AffiliationForm,
  MultiProductDiscountQuestion,
  ResidencyTypeQuestion,
} from '../../../../common';
import { DriverDiscountsForm } from '../DriverDiscountsForm';
import { useStyles } from './AutoDiscountsPageForm.styles';

interface SubmitParams {
  onNext: () => Promise<void>;
  patchFormValues: () => Promise<string>;
  setIsSubmitting: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
  policyType: ProductName;
}

interface Props {
  onNext: () => Promise<void>;
  policyType: ProductName;
}

const doSubmit =
  ({
    onNext,
    patchFormValues,
    setIsSubmitting,
    validateForm,
    policyType,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    setIsSubmitting(true);
    if (validateForm().isValid) {
      await patchFormValues();
      await dispatch(
        submitProofDraft({
          policyTypes: [policyType],
        }),
      );
      await onNext();
    }

    setIsSubmitting(false);
  };

export const AutoDiscountsPageForm: React.FC<Props> = (props) => {
  const { onNext, policyType } = props;
  const { classes } = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const getFields = useGetFields();
  const dispatch = useDispatch();
  const getConditions = useGetConditionValues();
  const getInitValues = useGetInitValues();
  const pniRef = usePniRef();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: getConditions(),
  });

  const parentPolicyNumber = useField(`${pniRef}.parentsPolicyNumber`);
  const commercialPolicyNumber = useField(`${pniRef}.commercialPolicyNumber`);
  const otherInsuredObjectsAndPolicies = useField(`${pniRef}.otherInsuredObjectsAndPolicies`);

  const multiProductDiscountValues = otherInsuredObjectsAndPolicies.value as string[];
  const isParentPolicySelected = multiProductDiscountValues?.includes('PARENT_POLICY');
  const isCommercialPolicySelected = multiProductDiscountValues?.includes('COMMERCIAL_AUTO');

  const drivers = useSelector(getDrivers);

  const driversWithoutCourseCompleted = useSelector((state: RootStore) => {
    return drivers.filter((driver) => {
      const selectedDefensiveDriver = getAnswer(state, `${driver.ref}.discount.defensiveDriver`);
      const hasNotCompletedDefensiveDriverCourse = !getAnswer(
        state,
        `${driver.ref}.discount.defensiveDriverCourseCompletionDueToViolationInd`,
      );

      return selectedDefensiveDriver && hasNotCompletedDefensiveDriverCourse;
    });
  });

  const handleSubmit = useCallback(async () => {
    if (driversWithoutCourseCompleted.length > 0) {
      driversWithoutCourseCompleted.forEach((driver) => {
        dispatch(
          setFormErrorsChangedByField({
            key: `${driver.ref}.discount.defensiveDriverCourseCompletionDueToViolationInd`,
            errors: ['Completing a defensive driver course is required to receive the discount.'],
          }),
        );
      });
      scrollToTop();

      return;
    }
    await dispatch(
      doSubmit({
        onNext,
        patchFormValues,
        setIsSubmitting,
        validateForm,
        policyType,
      }),
    );
  }, [dispatch, driversWithoutCourseCompleted, onNext, patchFormValues, validateForm, policyType]);

  useAddConditionValues({
    conditionalFields: [parentPolicyNumber, commercialPolicyNumber],
    isExcluded: () => !isParentPolicySelected || !isCommercialPolicySelected,
    isRequiredOverride: () => isParentPolicySelected || isCommercialPolicySelected,
  });

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container rowSpacing='20px'>
          <GridItem topSpacing='md' xs={12}>
            <DriverDiscountsForm />
          </GridItem>
          <GridItem topSpacing='sm' xs={12}>
            <h3>Policy-Level Discounts</h3>
          </GridItem>
          <GridItem xs={12}>
            <PaperlessBillingQuestion defaultValue lob={policyType} />
          </GridItem>
          <GridItem topSpacing='sm' xs={12}>
            <RecurringPaymentQuestion defaultValue lob={policyType} />
          </GridItem>
          <ProgramDiscountsQuestion />
          <GridItem topSpacing='sm' xs={12}>
            <ResidencyTypeQuestion />
          </GridItem>
          <AffiliationForm policyType={policyType} />
          <Divider orientation='horizontal' className={classes.horizontalDivider} />
          <MultiProductDiscountQuestion lob={policyType} />
          <GridItem topSpacing='sm' xs={12}>
            <Button
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress || isSubmitting}
              className={classes.next}
              data-testid='continue'
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='discounts'
              analyticsElement='choice.discounts.continueButton'
              type='submit'
            >
              Continue
            </Button>
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};

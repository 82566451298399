import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CoverageFieldComponents' })((theme) => ({
  coveragesDropdown: {
    '& > *:first-child': {
      marginBottom: 0,
    },
    paddingTop: 8,
    paddingBottom: 8,
    overflow: 'hidden',
  },
  label: {
    color: theme.palette.text.secondary,
  },
  coverageName: {
    ...theme.typography.body4,
    color: theme.palette.text.primary,
  },
  formControlCheckBox: {
    marginRight: 30,
  },
  formControlLabel: {
    padding: 20,
  },
  formControl: {
    textAlign: 'left',
  },
  toolTip: {
    position: 'relative',
    padding: 0,
    marginTop: -2,
  },
  mainCoverage: {
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
  },
  selectedMainCoverage: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
  },
  subCoverage: {
    padding: '20px 6px 20px 0px',
  },
  subTitle: {
    ...theme.typography.body2,
    color: theme.palette.grey[800],
  },
}));

import { useCallback, useEffect, useRef, useState } from 'react';

import { CircularProgress, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import type { GeoAddress } from '@ecp/utils/geo';
import { useEvent } from '@ecp/utils/react';

import { GridItem, SnackbarAlert } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import {
  useAddressRefForVehicle,
  useCheckDuplicateVin,
  useGarageField,
  useRestrictedGaragedState,
  useValidateVehicleInfo,
  useValidateVinNumber,
  useVehicleEditValidation,
  useVehicleInfoOrVinValue,
  VIN_REGEX_FULL,
} from '@ecp/features/sales/quotes/auto';
import { Form, Resource } from '@ecp/features/sales/shared/components';
import {
  useAddressFieldValue,
  useField,
  useForm,
  usePrimaryAddressRef,
  useValidateAddress,
} from '@ecp/features/sales/shared/store';
import { goToFirstError } from '@ecp/features/sales/shared/utils/web';
import type { AnswerValue } from '@ecp/types';

import { useAutoLineFormStyles } from '..';
import { useVehicleInfo } from '../../utils/vehicleOptions';
import type { AddEditVehicleFormProps } from './types';
import { VehicleFormQuestions } from './VehicleFormQuestions';

export const AddEditVehicleForm: React.FC<AddEditVehicleFormProps> = (props) => {
  const {
    itemRef: vehicleRef,
    setVehicleDataBeforeEdit,
    setMaskRequiredFieldError,
    onNext,
    isEdit,
    onCancel,
    onRemove,
  } = props;
  const { classes } = useAutoLineFormStyles();
  const disableVehicleServiceCallFlag = flagValues[FeatureFlags.DISABLE_VEHICLE_SERVICE_CALL];
  const [showOutOfStateError, setShowOutOfStateError] = useState(false);
  const vehicleInfoOrVin = useField(`static.${vehicleRef}.vehicleInfoOrVin`);
  const make = useField(`${vehicleRef}.make`);
  const model = useField(`${vehicleRef}.model`);
  const series = useField(`${vehicleRef}.series`);
  const vin = useField(`${vehicleRef}.vin`);
  const year = useField(`${vehicleRef}.year`);
  const stubVin = useField(`${vehicleRef}.stubVin`);
  const vehicleDetailId = useField(`${vehicleRef}.vehicleDetailId`);
  const msrpPrice = useField(`${vehicleRef}.msrpPrice`);
  const riskAddress = useField('static.vehicleKeptAddress');
  const garageState = useGarageField(vehicleRef, 'state');
  const primaryAddress = usePrimaryAddressRef();
  const primaryAddressState = useAddressFieldValue(primaryAddress, 'state');
  const selectedAddressRef = useAddressRefForVehicle(vehicleRef);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isContinueClicked, setContinueClicked] = useState(false);
  const [isValidAddress, setIsValidAddress] = useState<boolean>(true);
  const [geoAddressSuggestions, setGeoAddressSuggestions] = useState<GeoAddress[]>([]);
  const [showAddressSuggestion, setShowAddressSuggestion] = useState<boolean>(false);
  const validateAddress = useValidateAddress(
    selectedAddressRef ? selectedAddressRef : primaryAddress,
  );

  const vehicleInfoOrVinValue = useVehicleInfoOrVinValue(vehicleInfoOrVin, vin);
  const validateGarageAddress = useRestrictedGaragedState(garageState, primaryAddressState);

  const getFields = useGetFields();
  const fields = getFields();
  const fieldsLength = Object.keys(fields).length;
  const getInitValues = useGetInitValues();
  const getConditions = useGetConditionValues();
  const conditions = getConditions();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions,
  });
  const validateVehicleInfo = useValidateVehicleInfo(vehicleInfoOrVinValue, make, model, series);
  const validateVinNumber = useValidateVinNumber(vin);

  const { isVehicleApiInProgress: isFetchVehicleInProgress, fetchVehicleInfo } = useVehicleInfo(
    make,
    model,
    series,
    vin,
    stubVin,
    vehicleDetailId,
    msrpPrice,
  );

  useVehicleEditValidation(
    validateForm,
    vehicleInfoOrVinValue,
    fetchVehicleInfo,
    year.value,
    vin.value,
    goToFirstError,
    isEdit,
    fieldsLength,
    vehicleRef,
  );
  useEffect(() => {
    const vehicleFormFields = getFields();
    // Storing vehicle values before user edits.
    setVehicleDataBeforeEdit({
      vin: vehicleFormFields.vin?.value as AnswerValue,
      year: vehicleFormFields.year?.value as AnswerValue,
      make: vehicleFormFields.make?.value as AnswerValue,
      model: vehicleFormFields.model?.value as AnswerValue,
      series: vehicleFormFields.series?.value as AnswerValue,
      primaryUse: vehicleFormFields.primaryUse?.value as AnswerValue,
      vehicleComprehensive: vehicleFormFields.vehicleComprehensive?.value as AnswerValue,
      coOwnsVehicle: vehicleFormFields.coOwnsVehicle?.value as AnswerValue,
      kitCarReplica: vehicleFormFields.kitCarReplica?.value as AnswerValue,
      keptAtRiskAddress: vehicleFormFields?.keptAtRiskAddress?.value as AnswerValue,
      antiTheftActive: vehicleFormFields?.antiTheftActive?.value as AnswerValue,
      antiTheftPassive: vehicleFormFields.antiTheftPassive?.value as AnswerValue,
      annualMiles: vehicleFormFields.annualMiles?.value as AnswerValue,
      safetyFeatures: vehicleFormFields.safety?.value as AnswerValue,
      airbagDriver: vehicleFormFields[`${vehicleRef}.features.safety.airbag.driver`]
        ?.value as AnswerValue,
      airbagPassenger: vehicleFormFields[`${vehicleRef}.features.safety.airbag.passenger`]
        ?.value as AnswerValue,
      airbagSide: vehicleFormFields[`${vehicleRef}.features.safety.airbag.side`]
        ?.value as AnswerValue,
      antiLockBrakes: vehicleFormFields[`${vehicleRef}.features.safety.antiLockBrakes`]
        ?.value as AnswerValue,
      dayTimeRunningLights: vehicleFormFields[`${vehicleRef}.features.safety.dayTimeRunningLights`]
        ?.value as AnswerValue,
      electronicStabilityControl: vehicleFormFields[
        `${vehicleRef}.features.safety.electronicStabilityControl`
      ]?.value as AnswerValue,
      selectedGarageRef: selectedAddressRef,
    });
    // this executes when the form first opens
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: duplicate but slightly different function, see vehicleModelUtil.ts and VehicleInfoOrVinQuestion.tsx
  const validateVinFormat = (value: string | undefined): string | undefined => {
    if (value) {
      if (value.length > 17) {
        return 'Please enter 17 digits';
      }

      return VIN_REGEX_FULL.test(value) ? undefined : 'Please enter a valid VIN';
    }

    return undefined;
  };

  const checkDuplicateVin = useCheckDuplicateVin(vin, vehicleRef);
  const updateAnswers = useEvent(async () => {
    let shouldPatch = true;
    // set mask-required-field-error to false for form-level validation
    setMaskRequiredFieldError(false);

    const isFormValid = validateForm().isValid;
    if (!isFormValid) shouldPatch = false;

    if (isFormValid && selectedAddressRef !== primaryAddress) {
      const { parsedAddress, isValidAddress, geoAddressSuggestions } = await validateAddress(
        selectedAddressRef ? selectedAddressRef : primaryAddress,
      );

      if (!parsedAddress || !isValidAddress) {
        setIsSubmitting(false);

        if (
          geoAddressSuggestions &&
          Array.isArray(geoAddressSuggestions) &&
          geoAddressSuggestions.length > 0
        ) {
          setGeoAddressSuggestions(geoAddressSuggestions);
          setShowAddressSuggestion(true);
        } else {
          setIsValidAddress(false);
          setShowAddressSuggestion(false);
        }

        shouldPatch = false;
      }
    }

    const isGarageValid = validateGarageAddress();
    if (!isGarageValid) {
      if (garageState.value) setShowOutOfStateError(true);
      garageState.props.actionOnComplete(null);

      shouldPatch = false;
    }
    const isVehicleInfoValid = validateVehicleInfo();
    if (!isVehicleInfoValid) {
      goToFirstError();

      shouldPatch = false;
    }
    // returning after all valiodations are done so that any alert error shows in addition to required field errors
    if (!shouldPatch) return false;

    // send form values to answers endpoint
    await patchFormValues();

    // This is for a special scenario when user types in a VIN
    // and directly click on submit and avoids onBlur event of VIN
    let vehicleInfoError = false;
    // we need to make sure the length of the masked VIN is equal to 17 characters
    if (isMasked(vin.value) && vin.props.value?.length !== 17) {
      goToFirstError();

      return false;
    }
    if (
      year.value &&
      !validateVinFormat(vin.value as string) &&
      !isMasked(vin.value) &&
      !disableVehicleServiceCallFlag
    ) {
      vehicleInfoError = await fetchVehicleInfo(year.value as string, vin.value as string);
      if (!vehicleInfoError) {
        checkDuplicateVin();
      }
    }
    if (vehicleInfoError) {
      goToFirstError();

      return false;
    }
    if (vin.value && !validateVinNumber() && !isMasked(vin.value)) {
      // if there is a 'vin'-related issue, but the user is on the 'info' tab,
      // we need to manually switch tabs in order for the user to see the error message
      if (vehicleInfoOrVinValue === 'vehicleInfo') {
        vehicleInfoOrVin.props.actionOnChange('vin');
      }
      goToFirstError();

      return false;
    }

    return true;
  });

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    setContinueClicked(true);
    if (await updateAnswers()) await onNext?.();
    setIsSubmitting(false);
  }, [onNext, updateAnswers]);

  const handleDeleteVehicle = useCallback(async (): Promise<void> => {
    onRemove(vehicleRef, getFields());
  }, [onRemove, vehicleRef, getFields]);

  const handleCancelVehicle = useCallback(async (): Promise<void> => {
    onCancel(vehicleRef, getFields());
  }, [onCancel, vehicleRef, getFields]);

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setShowOutOfStateError(false);
      }
    },
    [],
  );

  return (
    <>
      {isFetchVehicleInProgress && <CircularProgress />}
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          {showOutOfStateError && (
            <SnackbarAlert
              message='We are unable to provide coverage for a vehicle kept outside of your residence state on
          this quote. To proceed with your in-state vehicles, please delete this vehicle.'
              vertical='bottom'
              horizontal='center'
              open
              onClose={handleInfoSnackbarClose}
              severity='error'
            />
          )}
          <VehicleFormQuestions
            isValidAddress={isValidAddress}
            setIsValidAddress={setIsValidAddress}
            showAddressSuggestion={showAddressSuggestion}
            setShowAddressSuggestion={setShowAddressSuggestion}
            geoAddressSuggestions={geoAddressSuggestions}
            isContinueClicked={isContinueClicked}
            vehicleRef={vehicleRef}
            {...props}
          />
          <Grid container justifyContent='space-between' className={classes.formFooter}>
            <Resource.DeleteButton
              onClick={handleDeleteVehicle}
              trackingName='remove_vehicle_link'
              trackingLabel={vehicleRef}
              analyticsElement='choice.reviewVehicleAndDriverPage.DeleteVehicleButton'
              analyticsEventDetail={vehicleRef}
            >
              Delete vehicle
            </Resource.DeleteButton>
            <GridItem>
              <Resource.CancelButton
                onClick={handleCancelVehicle}
                data-testid='autoVehicleCancel'
                trackingName='remove_vehicle_link'
                trackingLabel={vehicleRef}
                analyticsElement='choice.reviewVehicleAndDriverPage.CancelVehicleButton'
                analyticsEventDetail={vehicleRef}
              >
                Cancel
              </Resource.CancelButton>
              <Resource.SaveButton
                onClick={handleSubmit}
                data-testid='autoVehicleContinue'
                isProcessing={isPatchFormInProgress || isSubmitting}
                trackingName={GoogleAnalyticsLabels.CONTINUE}
                trackingLabel='vehicle_details_page_continue'
                analyticsElement='choice.vehicleDetailsPage.continueButton'
                disabled={riskAddress.value === 'differentState'}
              >
                Save vehicle
              </Resource.SaveButton>
            </GridItem>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

import type { Control } from 'react-hook-form';
import { useController } from 'react-hook-form';

import { Select } from '@ecp/components';

import { stateOptions } from '../../constants';
import { useStyles } from './State.styles';

export const State: React.FC<{ control: Control; optional?: boolean }> = ({
  control,
  optional = false,
}) => {
  const { classes } = useStyles();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'state',
    control,
    rules: { required: { value: !optional, message: !optional ? 'Required' : '' } },
  });

  return (
    <Select
      {...field}
      error={error?.message}
      options={stateOptions}
      fullWidth
      id='State'
      label={`State ${optional ? '(optional)' : ''}`}
      actionOnChange={field.onChange}
      classes={{ root: classes.stateSelectFieldLabel }}
    />
  );
};

import { Grid } from '@mui/material';

import { useEvent } from '@ecp/utils/react';

import { downloadAndSaveDocuments } from '@ecp/features/sales/checkout';
import { Button } from '@ecp/features/sales/shared/components';
import { getOfferProductsSelected } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './DownloadAdditionalDocuments.styles';
import metadata from './metadata';

export const DownloadAdditionalDocuments: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const selectedProducts = useSelector(getOfferProductsSelected);
  const handleDownloadAdditionalDocument = useEvent((documentName: string) => async () => {
    for (const product of selectedProducts) {
      await dispatch(
        downloadAndSaveDocuments({
          product: product,
          documentTypeName: documentName,
          documentDisplayName: documentName,
        }),
      );
    }
  });

  if (!Object.entries(metadata).filter(([key]) => selectedProducts.includes(key)).length)
    return null;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <h3>Additional Documents</h3>
      </Grid>
      <ul className={classes.listItemsContent}>
        {Object.entries(metadata)
          .filter(([key]) => selectedProducts.includes(key))
          .map(([product, documents]) => (
            <div key={product}>
              {Object.entries(documents).map(([key, item]) => (
                <li key={product} className={classes.paddingLeft}>
                  <Grid item xs={12}>
                    <Button
                      classes={{
                        root: classes.documentButton,
                      }}
                      onClick={handleDownloadAdditionalDocument(item.documentName)}
                      trackingName={`download_${item.documentName}`}
                      trackingLabel={item.documentName}
                      analyticsElement={`choice.policySummaryPage.${item.documentName}`}
                      data-testid='downloadAdditionalDocument'
                      variant='iconTextMedium'
                    >
                      {item.displayText}
                    </Button>
                  </Grid>
                </li>
              ))}
            </div>
          ))}
      </ul>
    </Grid>
  );
};

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { getPrimaryInsuredPersonInfo } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { getProductNameFromUnbundledLob, LineOfBusiness } from '@ecp/features/shared/product';

import { AutoDiscountsPageForm } from '../../forms';

export const AutoDiscountsPage: React.FC = () => {
  const { firstName } = useSelector(getPrimaryInsuredPersonInfo);
  const policyType = getProductNameFromUnbundledLob(LineOfBusiness.AUTO);

  const subtitle = `Help us determine what discounts ${firstName} may be eligible for.`;

  const navigateToNextPage = useNavigateToNextPage();

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.AUTO_DISCOUNTS} />,
        }}
        title='Auto Discounts'
        subTitle={subtitle}
        analyticsElement='choice.discountPage.page'
      >
        <AutoDiscountsPageForm onNext={navigateToNextPage} policyType={policyType} />
      </Page>
    </div>
  );
};

import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useRef } from 'react';

import { FormHelperText, FormLabel, Grid } from '@mui/material';
import dayjs from 'dayjs';
import type { NumberFormatProps } from 'react-number-format';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { parseDollar } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import { DatePicker, GridItem, NumberFormat, ReadOnlyField } from '@ecp/components';
import {
  INCIDENT_TYPE_CLAIM_OR_ACCIDENT,
  INCIDENT_TYPE_VIOLATION,
  useAddIncident,
  useAndEnsureCurrentIncidentRef,
  useAttachIncidentToDriver,
  useGetIncidentFields,
} from '@ecp/features/sales/quotes/auto';
import { Button, Select } from '@ecp/features/sales/shared/components';
import {
  getPrimaryInsuredStateCode,
  setFormErrorsChangedByField,
  useFieldWithPrefix,
  useForm,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Incident, OptionProps } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { useStyles } from './IncidentQuestions.styles';
import metadata from './metadata';
import { getIncidentTypeDetails, isIncidentFromPrefill } from './util';

export interface IncidentQuestionsProps {
  driverRef: string;
  personRef: string;
  setAddingLossViolation: Dispatch<SetStateAction<boolean>>;
  setLossActionInfoMessage: Dispatch<SetStateAction<string>>;
  incidentTypeValue: string;
  editIncidentRef: string;
  setOpenLossViolationDialog: Dispatch<SetStateAction<string>>;
  setEditIncidentRef: Dispatch<SetStateAction<string>>;
  setLossAndViolationActionInfoType: Dispatch<SetStateAction<'NONE' | 'ADD' | 'REMOVE'>>;
  setIncidentTypeValue: Dispatch<SetStateAction<string>>;
  setIncidentDataBeforeEdit: Dispatch<SetStateAction<Incident | undefined>>;
  onNext: () => void;
  onCancel: (incidentRef: string) => void;
}

export const IncidentQuestions: React.FC<IncidentQuestionsProps> = (props) => {
  const {
    driverRef,
    personRef,
    setAddingLossViolation,
    incidentTypeValue,
    setLossActionInfoMessage,
    editIncidentRef,
    setOpenLossViolationDialog,
    setEditIncidentRef,
    setLossAndViolationActionInfoType,
    setIncidentTypeValue,
    setIncidentDataBeforeEdit,
    onNext,
    onCancel,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const initValues = useRef({});

  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const {
    props: { value: firstName },
  } = usePersonField('firstName');

  const currentIncidentRef = useAndEnsureCurrentIncidentRef(driverRef) || '';
  const incidentFields = useGetIncidentFields(editIncidentRef || currentIncidentRef || '');
  const addIncident = useAddIncident(driverRef);
  const addIncidentToDriver = useAttachIncidentToDriver(driverRef || '');

  const {
    incident: {
      type: incidentType,
      combinedDate: incidentDate,
      violationDescription,
      claimDescription,
      lossAmountUserEntered,
    },
  } = incidentFields;
  const descriptionField =
    incidentType.value === INCIDENT_TYPE_VIOLATION ? violationDescription : claimDescription;

  const {
    incidentTypeDate,
    typeOfIncident,
    incidentTypeDescription,
    incidentTypeSaveButtonText,
    incidentTypeInfoMessage,
  } = getIncidentTypeDetails(incidentType.value);

  const fieldsToValidate = {
    type: incidentType,
    combinedDate: incidentDate,
    violationDescription,
    claimDescription,
    lossAmountUserEntered,
  };

  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const accidentHelperText = metadata.accidentHelpText[stateCode];
  const incidentHelperText = metadata.helpText(stateCode);

  const { validateForm: validateIncidentForm } = useForm({
    fields: fieldsToValidate, // field validation on day field not working, so removed it from for validation
    initValues,
    conditions: [],
  });

  const { patchFormValues: patchIncidentFormValues } = useForm({
    fields: incidentFields.incident,
    initValues,
    conditions: [],
  });

  useEffect(() => {
    if (!incidentType.props.value) {
      incidentType.props.actionOnChange(incidentTypeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIncidentRef]);

  const onValidateIncidentFields = useCallback(
    (type: Field, incidentDesc: Field, amount: Field) => {
      let isValid = true;
      if (!incidentDesc.value) {
        dispatch(
          setFormErrorsChangedByField({ key: incidentDesc.key, errors: ['Required field'] }),
        );
        isValid = false;
      }
      if (type.value === INCIDENT_TYPE_CLAIM_OR_ACCIDENT && !amount.value && amount.exists) {
        dispatch(setFormErrorsChangedByField({ key: amount.key, errors: ['Required field'] }));
        isValid = false;
      }

      return isValid;
    },
    [dispatch],
  );

  const handleAddIncident = useEvent(async () => {
    setIncidentTypeValue('');
    const incidentFormValid = validateIncidentForm().isValid;
    // add any errors in incidentMonth and incidentYearErrors to incidentDate.errors for display
    const incidentFieldValid = onValidateIncidentFields(
      incidentType,
      descriptionField,
      lossAmountUserEntered,
    );
    if (incidentFormValid && incidentFieldValid) {
      // patch incident only.
      await patchIncidentFormValues();
      // do not add new incident if editing
      if (!editIncidentRef) {
        addIncidentToDriver(currentIncidentRef);
        addIncident();
        incidentDate.reset();
        incidentDate.patch('');
      }
      onNext();
      setLossActionInfoMessage(incidentTypeInfoMessage);
      setLossAndViolationActionInfoType('ADD');
      setIncidentDataBeforeEdit(undefined);
    }
  });

  const handleCancel = useCallback(() => {
    if (
      incidentDate.props.value ||
      claimDescription.props.value ||
      violationDescription.props.value
    ) {
      setEditIncidentRef(editIncidentRef || currentIncidentRef);
      if (incidentType.props.value) {
        const typeLabel =
          incidentType.question.options?.find(
            (option) => option.value === (incidentType.props.value as string),
          )?.label || '';
        setOpenLossViolationDialog(typeLabel.toString());
        onCancel(editIncidentRef || currentIncidentRef);
      }
    } else {
      setIncidentTypeValue('');
      setAddingLossViolation(false);
      incidentType.validateUpdateAndPatch('');
      onCancel('');
    }
  }, [
    incidentDate.props.value,
    claimDescription.props.value,
    violationDescription.props.value,
    setEditIncidentRef,
    editIncidentRef,
    currentIncidentRef,
    incidentType,
    setOpenLossViolationDialog,
    onCancel,
    setIncidentTypeValue,
    setAddingLossViolation,
  ]);

  const normalizeValue = ({
    value,
    prefix,
    thousandSeparator,
  }: {
    value: string;
    prefix: string;
    thousandSeparator: boolean;
  }): string => {
    let result = value;
    if (prefix) {
      if (value.startsWith(`-${prefix}`)) {
        // if value is -ve it will come as "-$123" we will normalize it to "-123"
        result = '-' + result.substring(prefix.length + 1);
      } else {
        // if value is +ve it will come as "$123" we will normalize it to "123"
        result = result.substring(prefix.length);
      }
    }
    if (thousandSeparator) result = result.replace(/,/g, '');

    return result;
  };

  /**
   * handleOnblur in NumberFormat has issues in normalizeValue function
   * where it is not able to normalize value when we have -ve value with prefix
   */
  const handlerOnBlur: NonNullable<NumberFormatProps['onBlur']> = useEvent((event) => {
    const value = normalizeValue({
      value: event.target.value,
      prefix: '$',
      thousandSeparator: true,
    });
    lossAmountUserEntered.props.actionOnComplete(value);
  });

  const incidentSource = incidentFields.incident.incidentSource.value;
  const isPrefillIncident = isIncidentFromPrefill(incidentSource);
  const isLoss = incidentType.value === INCIDENT_TYPE_CLAIM_OR_ACCIDENT;

  // Checks if the description field value is neither 'NOT_AT_FAULT_ACCIDENT' nor 'AT_FAULT' then disable all other description options.
  const canChangePrefilledDescription = ![
    'INCIDENT_CLAIM_DESCRIPTION.NOT_AT_FAULT_ACCIDENT',
    'INCIDENT_CLAIM_DESCRIPTION.AT_FAULT',
  ].includes(descriptionField.value);

  /**
   * Determines whether the prefill incident description should be disabled.
   *
   * This flag checks various conditions related to the incident type and description field
   * to decide if the incident description prefill should be disabled.
   *
   * if the incident is prefilled and Violation Type then it will be disabled.
   * if the incident is prefilled and Loss Type and if description is not AtFault and NotAtFault then it will be disabled.
   */
  const shouldDisablePrefillIncidentDescription =
    isPrefillIncident && (!isLoss || canChangePrefilledDescription);

  /**
   * Filters the description options based on the incident type and description field value.
   *
   * This function checks if the incident is an auto prefill incident and if the incident type is a claim or accident.
   * If these conditions are met and the description field value is either "NOT_AT_FAULT_ACCIDENT" or "AT_FAULT",
   * it filters the options to include only those with labels 'At Fault' or 'Not At Fault'.
   * Otherwise, it returns all available options.
   *
   * @returns {Array} - Returns the filtered description options.
   */
  const shouldFilterPrefillIncidentDescriptionOptions =
    isPrefillIncident &&
    incidentType.value === INCIDENT_TYPE_CLAIM_OR_ACCIDENT &&
    !canChangePrefilledDescription;

  const filteredDescriptionOptions = shouldFilterPrefillIncidentDescriptionOptions
    ? descriptionField.props.options?.filter(
        // Include only options with labels 'At Fault' or 'Not At Fault' for Loss type prefill incidents.
        (option) => option.label === 'At Fault' || option.label === 'Not At Fault',
      )
    : // Use all available options if it's not an auto prefill incident
      descriptionField.props.options;

  // Render incident date field
  const incidentDateContent = !incidentDate.exists ? null : isPrefillIncident ? (
    // Loss date for prefill incidents is readOnly.
    <ReadOnlyField label={incidentTypeDate} value={incidentDate.props.value} id='IncidentDate' />
  ) : (
    <DatePicker {...incidentDate.props} label={incidentTypeDate} maxDate={dayjs().startOf('d')} />
  );

  // Render incident description field
  const incidentDescriptionContent =
    !descriptionField.exists ? null : shouldDisablePrefillIncidentDescription ? (
      // The prefill incident description can be edited only if changing from 'At Fault' to 'Not At Fault' or vice versa.
      <ReadOnlyField
        label={incidentTypeDescription}
        value={descriptionField.props.options
          ?.find((option) => option.value === descriptionField.props.value)
          ?.label.toString()}
        id='IncidentDescription'
      />
    ) : (
      <Select
        {...(descriptionField.props as OptionProps)}
        options={filteredDescriptionOptions}
        id='IncidentDescription'
        inputButtonAriaLabel='Incident Description'
        placeholder='Select one'
        label={incidentTypeDescription}
        trackingName='incident_description_selection'
        trackingLabel={GoogleAnalyticsLabels.REDACTED}
      />
    );

  // Render incident loss amount field
  const incidentLossAmountContent = !lossAmountUserEntered.exists
    ? null
    : incidentType.value === INCIDENT_TYPE_CLAIM_OR_ACCIDENT &&
      (isPrefillIncident ? (
        // Loss amount for prefill incidents is readOnly.
        <ReadOnlyField
          label='Amount'
          value={
            lossAmountUserEntered.props.value
              ? parseDollar(lossAmountUserEntered.props.value)
              : '$0.00'
          }
        />
      ) : (
        <NumberFormat
          {...lossAmountUserEntered.props}
          ariaLabel='Amount'
          placeholder='$0.00'
          prefix='$'
          thousandSeparator
          label='Amount'
          trackingName='amount'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          onBlur={handlerOnBlur}
        />
      ));

  // Render save and cancel buttons
  const incidentSaveAndCancelButtonContent = (
    <div className={classes.actionButtons}>
      <Button
        type='button'
        color='primary'
        variant='iconTextMedium'
        onClick={handleCancel}
        trackingName='add_incident_button'
        trackingLabel='add_incident'
      >
        Cancel
      </Button>
      <Button
        type='button'
        color='primary'
        variant='primary'
        onClick={handleAddIncident}
        trackingName='add_incident_button'
        trackingLabel='add_incident'
      >
        {incidentTypeSaveButtonText}
      </Button>
    </div>
  );

  const incidentContent = (
    <Grid container item xs={12}>
      {incidentDateContent && (
        <Grid container item xs={12}>
          <GridItem topSpacing='sm' xs={12} md={6}>
            {incidentDateContent}
          </GridItem>
        </Grid>
      )}
      {incidentDescriptionContent && (
        <Grid container item xs={12}>
          <GridItem topSpacing='sm' xs={12} md={6}>
            {incidentDescriptionContent}
          </GridItem>
        </Grid>
      )}
      {incidentLossAmountContent && (
        <Grid container item xs={12}>
          <GridItem topSpacing='sm' xs={12} md={6}>
            {incidentLossAmountContent}
          </GridItem>
        </Grid>
      )}
      {incidentSaveAndCancelButtonContent && (
        <GridItem xs={12}>{incidentSaveAndCancelButtonContent}</GridItem>
      )}
    </Grid>
  );

  return (
    <GridItem topSpacing='lg'>
      <FormLabel component='legend' focused={false}>
        {`Tell us about ${firstName}'s ${typeOfIncident}`}
        {incidentHelperText && <FormHelperText error={false}>{incidentHelperText}</FormHelperText>}
        {accidentHelperText && <FormHelperText error={false}>{accidentHelperText}</FormHelperText>}
      </FormLabel>
      {incidentContent}
    </GridItem>
  );
};

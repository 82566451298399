import { useCallback } from 'react';

import { castAnswerType } from '@ecp/utils/common';

import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix, usePniRef } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/types';

import { usePniFirstName } from '../../utils';

export const OccupantTypeQuestion: React.FC<QuestionProps> = (props) => {
  const pniRef = usePniRef();
  const field = useFieldWithPrefix(pniRef, 'person.<id>')('additionalInformation.occupantType');
  const isOwnedField = useFieldWithPrefix(
    pniRef,
    'person.<id>',
  )('additionalInformation.isOwnedByImmediateFamily');

  const { trackingName = '' } = props;

  const label = usePniFirstName(field?.question?.title || '');
  useAddFields({ occupantType: field });

  // useInitValues set the default value for the field.
  const initValue = castAnswerType(field.question?.defaultValue, field.question.answerType);
  useInitValues({ [field.key]: initValue });

  const handleOnChange = useCallback(
    (value: AnswerValue) => {
      field.validateUpdateAndPatch(value);

      if (value !== 'OCCUPANT') {
        isOwnedField.validateUpdateAndPatch(false);
      }
    },

    [field, isOwnedField],
  );

  if (!field.exists) return null;

  return (
    <Select
      {...field.props}
      id='occupantType'
      groupLabel={label}
      trackingName={trackingName}
      inputButtonAriaLabel='occupantType'
      fullWidth={false}
      actionOnComplete={handleOnChange}
    />
  );
};

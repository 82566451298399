import {
  IconAfiProductAuto,
  IconAfiProductCamper,
  IconAfiProductCondo,
  IconAfiProductFarmRanch,
  IconAfiProductGolfCart,
  IconAfiProductHome,
  IconAfiProductManufacturedHome,
  IconAfiProductMotorcycle,
  IconAfiProductMotorhome,
  IconAfiProductParentPolicy,
  IconAfiProductPersonalMobilityDevice,
  IconAfiProductRenter,
  IconAfiProductSimpTermLife,
  IconAfiProductSnowmobile,
  IconAfiProductTermLife,
  IconAfiProductTractor,
  IconAfiProductUmbrella,
  IconAfiProductUmbrellaFarmRanch,
  IconAfiProductUniversalLife,
  IconAfiProductWatercraft,
  IconAfiProductWholeLife,
} from '@ecp/themes/partners/amfam-adv';
import type { CardOption } from '@ecp/types';

interface MetadataItem {
  label: string;
  cardOptions: CardOption[];
}

export type Metadata = {
  [key: string]: MetadataItem;
};

const multiProductDiscounts: Metadata = {
  auto: {
    label: 'Personal Vehicle',
    cardOptions: [
      {
        label: 'Family Car',
        value: 'FAMILYCAR',
        icon: <IconAfiProductAuto />,
        helpText: 'FamilyCar helptext',
        testId: 'familyCar',
        exclude: ['auto'],
      },
      {
        label: 'Motorcycle',
        value: 'MOTOR_CYCLE',
        icon: <IconAfiProductMotorcycle />,
        helpText: 'Motorcycle helptext',
        testId: 'motorcycle',
      },
      {
        label: 'Watercraft',
        value: 'BOAT',
        icon: <IconAfiProductWatercraft />,
        helpText: 'Watercraft helptext',
        testId: 'boat',
      },
    ],
  },
  commercialAuto: {
    label: 'Commercial',
    cardOptions: [
      {
        label: 'Commercial Auto',
        value: 'COMMERCIAL_AUTO',
        icon: <IconAfiProductAuto />,
        helpText: 'CommercialAuto helptext',
        testId: 'commercialAuto',
        exclude: ['auto'],
      },
    ],
  },
  home: {
    label: 'Homeowners',
    cardOptions: [
      {
        label: 'Homeowners',
        value: 'HOME_OWNERS',
        icon: <IconAfiProductHome />,
        helpText: 'Homeowners helptext',
        testId: 'homeowners',
        exclude: ['home', 'renters'],
      },
      {
        label: 'Renters',
        value: 'RENTERS',
        icon: <IconAfiProductRenter />,
        helpText: 'Renters helptext',
        testId: 'renters',
        exclude: ['home', 'renters'],
      },
      {
        label: 'Condominium',
        value: 'CONDOMINIUM',
        icon: <IconAfiProductCondo />,
        helpText: 'Condominium helptext',
        testId: 'condominium',
        exclude: ['home', 'renters'],
      },
      {
        label: 'Manufactured Home',
        value: 'MANUFACTURED_HOME',
        icon: <IconAfiProductManufacturedHome />,
        helpText: 'manufacturedHome helptext',
        testId: 'manufacturedHome',
        exclude: ['home', 'renters'],
      },
    ],
  },
  life: {
    label: 'Life',
    cardOptions: [
      {
        label: 'WholeLife',
        value: 'WHOLE_LIFE',
        icon: <IconAfiProductWholeLife />,
        helpText: 'WholeLife helptext',
        testId: 'wholeLife',
      },
      {
        label: 'EasyProtect',
        subLabel: 'Term Life',
        value: 'TERM',
        icon: <IconAfiProductTermLife />,
        helpText: 'Term/EasyProtect helptext',
        testId: 'term',
      },
      {
        label: 'Simply Protected Life',
        subLabel: 'Simplified Term Life',
        value: 'SIMPLY_PROTECTED',
        icon: <IconAfiProductSimpTermLife />,
        helpText: 'FamilyCar helptext',
        testId: 'simplyProtected',
      },
      {
        label: 'MyLife',
        value: 'MY_LIFE_UNIVERSAL_LIFE',
        subLabel: 'Universal Life',
        icon: <IconAfiProductUniversalLife />,
        helpText: 'MyLife/Universal Life helptext',
        testId: 'myLifeUniversalLife',
      },
    ],
  },
  umbrella: {
    label: 'Umbrella',
    cardOptions: [
      {
        label: 'Umbrella',
        value: 'UMBRELLA',
        icon: <IconAfiProductUmbrella />,
        helpText: 'Umbrella helptext',
        testId: 'umbrella',
      },
      {
        label: 'Umbrella Farm Ranch',
        value: 'UMBRELLA_FARM_RANCH',
        icon: <IconAfiProductUmbrellaFarmRanch />,
        helpText: 'Umbrella Farm/Ranch helptext',
        testId: 'umbrellaFarmRanch',
      },
    ],
  },
  misc: {
    label: 'Miscellaneous Vehicles',
    cardOptions: [
      {
        label: 'Snowmobile',
        value: 'SNOW_MOBILE',
        icon: <IconAfiProductSnowmobile />,
        helpText: 'Snowmobile helptext',
        testId: 'snowMobile',
      },
      {
        label: 'Tractor',
        value: 'TRACTOR',
        icon: <IconAfiProductTractor />,
        helpText: 'Tractor helptext',
        testId: 'tractor',
      },
      {
        label: 'Golf Cart',
        value: 'GOLF_CART',
        icon: <IconAfiProductGolfCart />,
        helpText: 'golfCart helptext',
        testId: 'golfCart',
      },
      {
        label: 'Personal Mobility Device',
        value: 'PERSONAL_MOBILITY_DEVICE',
        icon: <IconAfiProductPersonalMobilityDevice />,
        helpText: 'Personal Mobility helptext',
        testId: 'personalMobilityDevice',
      },
    ],
  },
  motorHomeCamper: {
    label: 'MotorHome or Camper',
    cardOptions: [
      {
        label: 'Motorhome',
        value: 'MOTOR_HOME',
        icon: <IconAfiProductMotorhome />,
        helpText: 'Motorhome helptext',
        testId: 'motorhome',
      },
      {
        label: 'Camper',
        value: 'CAMPER',
        icon: <IconAfiProductCamper />,
        helpText: 'Camper helptext',
        testId: 'camper',
      },
    ],
  },
  farmRanch: {
    label: 'Farm Ranch',
    cardOptions: [
      {
        label: 'Farm Ranch',
        value: 'FARM_RANCH',
        icon: <IconAfiProductFarmRanch />,
        helpText: 'Farm Ranch helptext',
        testId: 'farmRanch',
      },
    ],
  },
  legacy: {
    label: 'LEGACY',
    cardOptions: [
      {
        label: 'Parent Policy',
        value: 'PARENT_POLICY',
        icon: <IconAfiProductParentPolicy />,
        helpText: 'Parent Policy helptext',
        testId: 'parentPolicy',
        state: ['WA'],
      },
    ],
  },
};

export default multiProductDiscounts;

import type { SearchTPIInputFields } from './form.types';
import type { SearchResult } from './tpiSearch.types';

export enum Product {
  HOME = 'home',
  AUTO = 'auto',
}

export type AddTPIProps = {
  addCompanyName: string;
  addAddress: string;
  unit: string;
  addCityName: string;
  addStateName: string;
  zipCode: string;
  userId: string | undefined;
  product: Product;
};

export type UseThirdPartyInterestRetrunType = {
  searchTPI: (params: SearchTPIInputFields) => Promise<Array<SearchResult>>;
  addUncontrolledTPI: (params: AddTPIProps) => Promise<void>;
  isSearching: boolean;
  isAdding: boolean;
};

export type TPI = {
  isUnControlledTPI?: boolean;
  companyName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string;
  tpiId: string;
  escrow: boolean;
  cdhId?: string;
  cim?: string;
};

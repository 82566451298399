import type { Dispatch, SetStateAction } from 'react';

import { QuoteCoverages } from '../../../../../common/components/QuoteCoverages';
import { type VehicleCoverages } from '../../../../../common/types';
import { getAutoParentGroupNames, getAutoVehicleCoverageGroupNames } from '../util';

interface Props {
  vehicles: VehicleCoverages[];
  setSnackbarMessages?: Dispatch<SetStateAction<string[]>>;
}

export const VehicleCoveragesForm: React.FC<Props> = (props) => {
  const { vehicles } = props;

  const vehiclesElements = vehicles.map((vehicle, index) => {
    const { coverages, description } = vehicle;
    const autoParentGroupNames = getAutoParentGroupNames(description);
    const autoSubGroupNames = getAutoVehicleCoverageGroupNames();

    return (
      <>
        {coverages && coverages?.length ? (
          <QuoteCoverages
            coverageItems={coverages}
            parentCoverageGroupingList={autoParentGroupNames}
            subsectionCoverageGroupingList={autoSubGroupNames}
            key={`VehicleCoverageForm-${vehicle.ref}`}
            setSnackbarMessages={props.setSnackbarMessages}
          />
        ) : undefined}
      </>
    );
  });

  return <> {vehiclesElements} </>;
};

import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';
import { getPLPCQuoteNumber, useRouteToPLPC } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { getUnbundledLobFromProductName } from '@ecp/features/shared/product';

import { PolicyCenterDialog } from '../../../components';
import { useCoverageSelectedField, useGroupedCoverageFields } from '../../../utils';
import { CoverageFieldMainCoverageCheckboxComponent } from '../../CoverageFieldComponents';

interface Props extends CoverageQuestionProps {
  coverageKey: string;
}

export const CoverageWithPolicyCenterDialog: React.FC<Props> = (props) => {
  const { carrierName, coverageKey, product } = props;

  // Get the coverage selected fields and all the related subcoverage fields
  const [coverageSelectField] = useGroupedCoverageFields(carrierName, product, coverageKey, [
    'selected',
  ]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dialogStat, setDialogStat] = useState(false);

  const handleChange = useEvent((_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
    selectedCoverageFieldChangeHandler(newChecked);
    setDialogStat(newChecked);
  });

  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(coverageSelectField, []);

  const toggleDialog = useCallback(() => {
    setDialogStat((prev: boolean) => {
      if (prev) {
        coverageSelectField?.updateAndPatch('false');
      }

      return !prev;
    });
  }, [coverageSelectField]);

  // Start - Route to PLPC section
  const routeToPLPC = useRouteToPLPC();
  const plpcQuoteNumber = useSelector((state: RootStore) => getPLPCQuoteNumber(state, product));
  const lineOfBusiness = getUnbundledLobFromProductName(product);

  const handleDialogSubmit = useCallback(async () => {
    setDialogStat(!dialogStat);
    if (plpcQuoteNumber) await routeToPLPC(setIsSubmitting, lineOfBusiness, plpcQuoteNumber);
  }, [dialogStat, lineOfBusiness, plpcQuoteNumber, routeToPLPC]);
  // End - Route to PLPC section

  if (!coverageSelectField.exists) return null;

  const isCoverageSelected = castToBoolean(coverageSelectField.value);

  return (
    <>
      <Grid container gap={1} justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName={coverageSelectField.props.name}
          field={coverageSelectField}
          isCoverageSelected={isCoverageSelected}
          handleCoverageChange={handleChange}
        />
      </Grid>
      {dialogStat && (
        <PolicyCenterDialog
          dialogStat={dialogStat}
          toggleDialog={toggleDialog}
          dialogSubmit={handleDialogSubmit}
          isProcessing={isSubmitting}
        />
      )}
    </>
  );
};

import type { Control } from 'react-hook-form';
import { useController } from 'react-hook-form';

import { TextField } from '@ecp/components';

export const City: React.FC<{ control: Control; optional?: boolean }> = ({ control, optional }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'city',
    control,
    rules: { required: { value: !optional, message: !optional ? 'Required' : '' } },
  });

  return (
    <TextField
      {...field}
      error={error?.message}
      id='City'
      label={`City ${optional ? '(optional)' : ''}`}
      autoComplete='off'
      placeholder='City'
      actionOnChange={field.onChange}
    />
  );
};

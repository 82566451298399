import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePniPropertyField } from '../../utils';

export const OwnedByImmediateFamilyMemberQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePniPropertyField('additionalInformation.isOwnedByImmediateFamily', true);

  if (!field) return null;

  const {
    label = field.question?.title,
    trackingName = 'residence_owned_by_immediate_family_member',
  } = props;

  return (
    <RadioGroupWithOptions
      {...field.props}
      id='IsOwnedByImmediateFamily'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};

import { GridItem } from '@ecp/components';

import {
  CommuterVanQuestion,
  KitCarReplicaQuestion,
  VehicleAnnualMileageQuestion,
  VehicleAntiTheftQuestion,
  // VehicleComprehensiveQuestion,
  VehicleCoOwnsQuestion,
  VehicleInfoQuestions,
  VehicleKeptAtQuestion,
  VehicleSafetyQuestion,
  VehicleUseQuestion,
} from '../../../components';
import type { VehicleFormQuestionsProps } from './types';

export const VehicleFormQuestions: React.FC<VehicleFormQuestionsProps> = (props) => {
  return (
    <>
      <GridItem xs={12}>
        <VehicleInfoQuestions {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <VehicleKeptAtQuestion {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <VehicleUseQuestion {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <CommuterVanQuestion {...props} />
      </GridItem>
      {/**
       * EDSP-13204 Commenting following temporarily, we'll be using it again
       */}
      {/* <GridItem topSpacing='lg' xs={12}>
        <VehicleComprehensiveQuestion {...props} />
      </GridItem> */}
      <GridItem topSpacing='lg' xs={12}>
        <VehicleCoOwnsQuestion {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <KitCarReplicaQuestion {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <VehicleAnnualMileageQuestion {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <VehicleSafetyQuestion {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <VehicleAntiTheftQuestion {...props} />
      </GridItem>
    </>
  );
};

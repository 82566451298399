import { Dialog } from '@ecp/features/sales/shared/components';

interface Props {
  dialogStat: boolean;
  toggleDialog?: () => void;
  dialogSubmit(): void;
  isProcessing?: boolean;
  shouldHideCancelButton?: boolean;
  hideDivider?: boolean;
}

export const PolicyCenterDialog: React.FC<Props> = (props) => {
  const {
    dialogStat,
    toggleDialog,
    dialogSubmit,
    isProcessing,
    shouldHideCancelButton = false,
  } = props;

  return (
    <Dialog
      buttonPlacement='right'
      open={dialogStat}
      actionButtonLabel='CONTINUE'
      textButtonLabel={shouldHideCancelButton ? undefined : 'Cancel'}
      titleText='Continue quote in PolicyCenter?'
      textButtonOnClick={toggleDialog}
      actionButtonOnClick={dialogSubmit}
      isProcessing={isProcessing}
      hideTitleCloseButton
      hideDivider
    >
      <p>All provided information will be available.</p>
    </Dialog>
  );
};

import {
  useHomeSniRef,
  usePniRef,
  useRentersSniRef,
  useSniRef,
} from '@ecp/features/sales/shared/store';
import type { ProductName } from '@ecp/features/shared/product';

import { AffiliationFormQuestion } from './AffiliationFormQuestion';

interface AffiliationFormProps {
  policyType: ProductName;
}

export const AffiliationForm: React.FC<AffiliationFormProps> = ({ policyType }) => {
  const primaryNamedInsuredRef = usePniRef();
  const secondaryNamedInsuredRef = useSniRef();
  const homeSecondaryNamedInsuredRef = useHomeSniRef();
  const rentersSecondaryNamedInsuredRef = useRentersSniRef();

  const getSNIRef = (): string => {
    switch (policyType) {
      case 'auto':
        return secondaryNamedInsuredRef;
      case 'home':
        return homeSecondaryNamedInsuredRef;
      case 'renters':
        return rentersSecondaryNamedInsuredRef;
      default:
        return '';
    }
  };

  if (!primaryNamedInsuredRef) return null;

  return (
    <>
      <AffiliationFormQuestion namedInsuredRef={primaryNamedInsuredRef} />
      {getSNIRef() && <AffiliationFormQuestion namedInsuredRef={getSNIRef()} />}
    </>
  );
};

import { useCallback, useState } from 'react';

import { NavStatus, OfferType } from '@ecp/features/sales/shared/constants';
import type { ThirdPartyInterest } from '@ecp/features/sales/shared/store';
import {
  createRef,
  deleteInquiryRef,
  getIsBundleForOfferProductsSelected,
  getLineOfBusiness,
  getPLPCQuoteNumber,
  submitDelta,
  updateAnswers,
  updateOffers,
  updateOfferType,
  updatePageStatus,
  useAllVehicleTpiDetails,
  useRemoveTpiFromVehicle,
  useRouteToPLPC,
} from '@ecp/features/sales/shared/store';
import type { RootStore, ThunkErrorHandler } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { PageErrors } from '@ecp/features/sales/shared/types';
import type { SearchResult, TPI } from '@ecp/features/sales/tpi';
import { Product } from '@ecp/features/sales/tpi';
import { LineOfBusiness } from '@ecp/features/shared/product';
import type { AnswerValue, Field } from '@ecp/types';

import { ThirdPartyInterestPageProvider } from './ThirdPartyInterestPageContext';

interface ThirdPartyInterestPageProviderProps {
  children: React.ReactNode;
  onNext: () => Promise<void>;
}

export const ThirdPartyInterestPageProviderComponent: React.FC<
  ThirdPartyInterestPageProviderProps
> = ({ children, onNext }) => {
  const dispatch = useDispatch();

  /* Page Level State */
  const [openRemoveAllTpisDialog, setOpenRemoveAllTpisDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [triggerRerender, setTriggerRerender] = useState(0);
  const [pendingRemoveValue, setPendingRemoveValue] = useState(null);
  const allVehiclesTpiDetails = useAllVehicleTpiDetails();
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const selectedProductOnOffer = useSelector(getLineOfBusiness);
  const [pageErrors, setPageErrors] = useState<PageErrors[]>([]);
  /* Card State */
  const [selectedProductFromAddEditButton, setSelectedProductFromAddEditButton] =
    useState<Product | null>(null);
  /* Card Details State */
  const [isEdit, setIsEdit] = useState(false);
  const [currentSelectedVehicleRef, setCurrentSelectedVehicleRef] = useState<string | null>(null);
  const [openSingleTpiDialog, setOpenSingleTpiDialog] = useState(false);

  /* Search TPI State */
  const [showSearch, setShowSearch] = useState(false);
  const [currentSelectedTpi, setCurrentSelectedTpi] = useState<ThirdPartyInterest | null>(null);
  const [currentSearchResults, setCurrentSearchResults] = useState<SearchResult[]>([]);
  const [showNoResultsFromSearch, setShowNoResultsFromSearch] = useState(false);
  const [selectTpiErrorMessage, setSelectTpiErrorMessage] = useState('');
  const [canOpenRouteToPLPCDialog, setCanOpenRouteToPLPCDialog] = useState(false);

  /** ADD/EDIT TPI state */
  const [showAddEditTpi, setShowAddEditTpi] = useState(false);
  const [tpiSnackbarActionInfoMessage, setSnackBarTpiActionInfoMessage] = useState('');
  const [tpiSnackbarActionInfoType, setTpiSnackBarActionInfoType] = useState<
    'NONE' | 'ADD' | 'REMOVE'
  >('NONE');
  const [tpiToBeRemoved, setTpiToBeRemoved] = useState<ThirdPartyInterest | null>(null);

  /* Page Level Functions */
  const onRemoveRemoveAllTpiDialogAction = useCallback(() => {
    allVehiclesTpiDetails.forEach(async (tpiOnVeh) => {
      setIsSubmitting(true);
      await dispatch(deleteInquiryRef(tpiOnVeh.thirdPartyInterest.ref));
      await dispatch(deleteInquiryRef(tpiOnVeh.thirdPartyInterest.addressRef));
    });
    setIsSubmitting(false);
    setOpenRemoveAllTpisDialog(false);
    setSnackBarTpiActionInfoMessage('All Third Party Interest removed.');
    setTpiSnackBarActionInfoType('REMOVE');
    setTriggerRerender((prev) => prev + 1);
  }, [allVehiclesTpiDetails, dispatch]);

  const onCloseRemoveAllTpiDialogAction = useCallback(
    (confirmed: boolean, hasThirdPartyInterestInd: Field) => {
      if (confirmed) {
        onRemoveRemoveAllTpiDialogAction();
        hasThirdPartyInterestInd.validateUpdateAndPatch(false); // Perform the removal if confirmed
      }
      setOpenRemoveAllTpisDialog(false);
      if (pendingRemoveValue !== null) {
        hasThirdPartyInterestInd.validateUpdateAndPatch(pendingRemoveValue);
        setPendingRemoveValue(null); // Reset the pending value
      }
    },
    [onRemoveRemoveAllTpiDialogAction, pendingRemoveValue],
  );

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setSnackBarTpiActionInfoMessage('');
        setTpiSnackBarActionInfoType('NONE');
      }
    },
    [],
  );

  const onHasThirdPartyInterestRadioChange = useCallback(
    (value: AnswerValue, hasThirdPartyInterestInd: Field) => {
      if (value === false && allVehiclesTpiDetails.length > 0) {
        setOpenRemoveAllTpisDialog(true);
      } else {
        hasThirdPartyInterestInd.validateUpdateAndPatch(value);
      }
      setPageErrors([]);
    },
    [allVehiclesTpiDetails.length],
  );

  const onSubmitOfTPIPage = useCallback(
    async (hasThirdPartyInterestIndValue: AnswerValue) => {
      const isTpiCompleteForAuto =
        allVehiclesTpiDetails.length > 0 &&
        allVehiclesTpiDetails.every((detail) => !!detail.thirdPartyInterest.serviceTpiId);

      // TODO: add property check for property tpi validation
      if (
        hasThirdPartyInterestIndValue === true
          ? selectedProductOnOffer === LineOfBusiness.BUNDLE ||
            (selectedProductOnOffer === LineOfBusiness.AUTO && !isTpiCompleteForAuto)
          : false
      ) {
        const errors: PageErrors[] = [];
        errors.push({
          message: 'Add at least one TPI to continue.',
          id: 'thirdPartyInterest_on_all_policies',
        });
        setPageErrors(errors);

        return;
      }
      // TODO: remove this dispatch from delta page and remove the hard coding of 0 to the length of the tpis added

      if (
        selectedProductOnOffer === LineOfBusiness.HOME ||
        selectedProductOnOffer === LineOfBusiness.BUNDLE
      ) {
        await dispatch(updateAnswers({ answers: { 'delta.property.noOfMortgages': '0' } }));
      }

      setIsSubmitting(true);
      let response: null | ThunkErrorHandler<unknown> = null;
      if (isBundle) {
        response = await dispatch(submitDelta({}));
      } else if (selectedProductOnOffer === LineOfBusiness.AUTO) {
        response = await dispatch(submitDelta({ policyTypes: ['auto'] }));
      } else if (selectedProductOnOffer === LineOfBusiness.HOME) {
        response = await dispatch(submitDelta({ policyTypes: ['home'] }));
      }
      if (response && !response.error) {
        response = await dispatch(updateOfferType({ offerType: OfferType.OFFER_WITH_PREMIUM }));
        if (response && !response.error) {
          response = await dispatch(updateOffers({ force: true }));
        }
      }

      if (response && !response.error) {
        dispatch(updatePageStatus(NavStatus.VALID));
        await onNext();
      }
      setIsSubmitting(false);
    },
    [allVehiclesTpiDetails, selectedProductOnOffer, dispatch, isBundle, onNext],
  );

  const handleAutoAddTpiButton = useCallback(
    (vehicleRef: string) => {
      setCurrentSelectedVehicleRef(vehicleRef);
      setSelectedProductFromAddEditButton(Product.AUTO);
      setShowSearch(true);
      setPageErrors([]);
    },
    [
      setCurrentSelectedVehicleRef,
      setPageErrors,
      setSelectedProductFromAddEditButton,
      setShowSearch,
    ],
  );

  /* Tpi Search Functions */
  const handleCancelSearch = useCallback(() => {
    setCurrentSelectedVehicleRef(null);
    setCurrentSearchResults([]);
    setShowSearch(false);
    setShowNoResultsFromSearch(false);
    setSelectTpiErrorMessage('');
    setSelectedProductFromAddEditButton(null);
    setCurrentSelectedTpi(null);
  }, [setCurrentSelectedVehicleRef, setSelectedProductFromAddEditButton, setShowSearch]);

  const routeToPLPC = useRouteToPLPC();
  const plpcQuoteNumber = useSelector((state: RootStore) =>
    getPLPCQuoteNumber(state, selectedProductFromAddEditButton!),
  );

  const handleRouteToPLPCDialogAction = useCallback(async () => {
    if (!plpcQuoteNumber) {
      setCanOpenRouteToPLPCDialog(false);

      return;
    }

    if (selectedProductFromAddEditButton === 'auto') {
      await routeToPLPC(setIsSubmitting, LineOfBusiness.AUTO, plpcQuoteNumber);
    } else if (selectedProductFromAddEditButton === 'home')
      await routeToPLPC(setIsSubmitting, LineOfBusiness.HOME, plpcQuoteNumber);
  }, [plpcQuoteNumber, routeToPLPC, selectedProductFromAddEditButton]);

  const onClickPolicyCenter = useCallback(() => {
    setCanOpenRouteToPLPCDialog(true);
  }, []);

  const handleCancelButtonOnClick = useCallback(async () => {
    setCanOpenRouteToPLPCDialog(false);

    return;
  }, []);

  const handleContinueFromSearchResult = useCallback(() => {
    if (currentSelectedTpi === null) {
      if (currentSearchResults.length > 0) {
        setSelectTpiErrorMessage('Select a third party to continue.');
      } else if (currentSearchResults.length === 0) {
        setSelectTpiErrorMessage('Search and select a third party to continue.');
      }

      return;
    }
    setSelectTpiErrorMessage('');
    setShowSearch(false);
    setCurrentSearchResults([]);
    setCurrentSelectedTpi({
      ...currentSelectedTpi,
      ref: dispatch(createRef('thirdPartyInterest')),
      addressRef: dispatch(createRef('address')),
    });
    setShowAddEditTpi(true);
  }, [
    currentSearchResults.length,
    currentSelectedTpi,
    dispatch,
    setCurrentSelectedTpi,
    setShowAddEditTpi,
    setShowSearch,
  ]);

  const handleOnSelectedFromSearch = useCallback(
    async ({ tpi }: { tpi: TPI }) => {
      setCurrentSelectedTpi({
        ref: '',
        addressRef: '',
        name: tpi.companyName,
        addressLine1: tpi.addressLine1,
        addressLine2: tpi.addressLine2,
        city: tpi.city,
        state: tpi.state,
        zipCode: tpi.zipCode,
        serviceTpiId: tpi.tpiId,
        escrow: tpi.escrow,
        cdhId: tpi.cdhId,
      });
      setSelectTpiErrorMessage('');
    },
    [setCurrentSelectedTpi],
  );

  const handleSearchReturn = useCallback(
    async ({ tpiSearchResult = [] }: { tpiSearchResult?: Array<SearchResult> }) => {
      let currentResults = [];
      if (selectedProductFromAddEditButton === Product.AUTO) {
        const filteredTpiResultsByCDHId = tpiSearchResult.filter(
          (result) => result.addendum && result.addendum?.cdh && result.addendum?.cdh?.length > 0,
        );
        currentResults = filteredTpiResultsByCDHId;
      } else {
        currentResults = tpiSearchResult;
      }
      setCurrentSearchResults(currentResults);
      setCurrentSelectedTpi(null);
      setSelectTpiErrorMessage('');

      if (currentResults.length === 0) {
        setShowNoResultsFromSearch(true);
      } else {
        setShowNoResultsFromSearch(false);
      }
    },
    [selectedProductFromAddEditButton],
  );
  /* Add Edit TPI Functions  */
  const removeTpiFromVehicle = useRemoveTpiFromVehicle();

  const onRemoveTpi = useCallback((thirdPartyInterest: ThirdPartyInterest, vehicleRef: string) => {
    setTpiToBeRemoved(thirdPartyInterest);
    setCurrentSelectedVehicleRef(vehicleRef);
    setOpenSingleTpiDialog(true);
  }, []);

  const onRemoveSingleTpiDialogAction = useCallback(async () => {
    if (tpiToBeRemoved) {
      setIsSubmitting(true);
      await removeTpiFromVehicle(
        currentSelectedVehicleRef,
        tpiToBeRemoved.ref,
        tpiToBeRemoved.addressRef,
      );
    }
    setIsSubmitting(false);
    setOpenSingleTpiDialog(false);
    setTpiSnackBarActionInfoType('REMOVE');
    setSnackBarTpiActionInfoMessage('Third Party Interest removed.');
    setTpiToBeRemoved(null);
    setCurrentSelectedVehicleRef(null);
  }, [tpiToBeRemoved, removeTpiFromVehicle, currentSelectedVehicleRef]);

  const handleSaveTpi = useCallback(() => {
    setSnackBarTpiActionInfoMessage('Third Party Interest Saved.');
    setTpiSnackBarActionInfoType('ADD');
  }, []);

  const handleBackOnAddEditTpi = useCallback(() => {
    if (isEdit) {
      setCurrentSelectedVehicleRef(null);
      setCurrentSelectedTpi(null);
      setIsEdit(false);
      setShowAddEditTpi(false);
      setSelectedProductFromAddEditButton(null);
    } else {
      setShowAddEditTpi(false);
      setShowSearch(true);
      setCurrentSelectedTpi(null);
    }
  }, [
    isEdit,
    setCurrentSelectedTpi,
    setCurrentSelectedVehicleRef,
    setIsEdit,
    setShowAddEditTpi,
    setShowSearch,
  ]);

  const onEditVehicleTpi = useCallback(
    (thirdPartyInterest: ThirdPartyInterest, vehicleRef: string) => {
      setSelectedProductFromAddEditButton(Product.AUTO);
      setCurrentSelectedVehicleRef(vehicleRef);
      setCurrentSelectedTpi(thirdPartyInterest);
      setIsEdit(true);
      setShowAddEditTpi(true);
    },
    [],
  );

  const onCloseSingleTpiDialogAction = useCallback(() => {
    setOpenSingleTpiDialog(false);
  }, []);

  return (
    <ThirdPartyInterestPageProvider
      value={{
        openRemoveAllTpisDialog,
        triggerRerender,
        isSubmitting,
        selectedProductOnOffer,
        selectedProductFromAddEditButton,
        currentSelectedVehicleRef,
        showSearch,
        currentSelectedTpi,
        tpiSnackbarActionInfoMessage,
        tpiSnackbarActionInfoType,
        openSingleTpiDialog,
        showAddEditTpi,
        isEdit,
        pageErrors,
        canOpenRouteToPLPCDialog,
        selectTpiErrorMessage,
        showNoResultsFromSearch,
        currentSearchResults,
        setShowSearch,
        setShowAddEditTpi,
        onCloseRemoveAllTpiDialogAction,
        handleInfoSnackbarClose,
        onHasThirdPartyInterestRadioChange,
        onSubmitOfTPIPage,
        setCurrentSelectedVehicleRef,
        setCurrentSelectedTpi,
        handleSaveTpi,
        handleBackOnAddEditTpi,
        onRemoveTpi,
        onRemoveSingleTpiDialogAction,
        onEditVehicleTpi,
        setIsEdit,
        onCloseSingleTpiDialogAction,
        setPageErrors,
        setSelectedProductFromAddEditButton,
        handleCancelSearch,
        handleRouteToPLPCDialogAction,
        onClickPolicyCenter,
        handleCancelButtonOnClick,
        handleContinueFromSearchResult,
        handleOnSelectedFromSearch,
        handleSearchReturn,
        handleAutoAddTpiButton,
      }}
    >
      {children}
    </ThirdPartyInterestPageProvider>
  );
};

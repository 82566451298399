import { useCallback, useEffect } from 'react';

import { ensureStringArray } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import {
  createRef,
  deleteInquiryRef,
  getAllValues,
  getInquiryLoaded,
  getMilitaryDeploymentsRef,
  getMiltiaryDeploymentsForDriver,
  getValueForPrefix,
  setNavMilitaryDeploymentRefChanged,
  updateAddedRef,
  useField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, FieldsDef } from '@ecp/features/sales/shared/types';
import type { Fields } from '@ecp/types';

import { MILITARY_DEPLOYMENTS_REF_SUFFIX } from '../../constants';

export interface AddMilitaryDeploymentsResult {
  // the api has received the update
  done: Promise<string>;
  // the ref used
  militaryDeploymentsRef: string;
}

export interface MilitaryDeployments {
  activeMilitaryDeployDate: string;
  militaryReturnDate: string;
  ref: string;
}

export interface MilitaryDeploymentsFields extends Fields {
  militaryDeployments: FieldsDef<MilitaryDeployments>;
}

export const useAddMilitaryDeployments = (
  driverRef?: string,
): (() => AddMilitaryDeploymentsResult) => {
  const dispatch = useDispatch();
  const inquiryLoaded = useSelector(getInquiryLoaded);
  const driverMilitaryDeploymentsRefs = useSelector((state: RootStore) =>
    getMiltiaryDeploymentsForDriver(state, { driverRef }),
  );

  if (!inquiryLoaded) {
    datadogLog({
      logType: 'error',
      message: 'inquiry not loaded',
      context: {
        logOrigin:
          'libs/features/sales/quotes/auto/src/state/modelUtil/militaryDeploymentsModelUtil.ts',
        functionOrigin: 'useAddTPI/useCallback',
      },
    });
    throw new Error('inquiry not loaded');
  }

  return useCallback(() => {
    let militaryDeploymentsRef = driverRef ? dispatch(createRef('militaryDeployment')) : '';
    while (driverMilitaryDeploymentsRefs.includes(militaryDeploymentsRef)) {
      militaryDeploymentsRef = dispatch(createRef('militaryDeployment'));
    }
    const updateRefToNav = dispatch(
      setNavMilitaryDeploymentRefChanged(militaryDeploymentsRef || ''),
    );

    const done = Promise.all([updateRefToNav]).then((): string => militaryDeploymentsRef);

    return { done, militaryDeploymentsRef };
  }, [dispatch, driverRef, driverMilitaryDeploymentsRefs]);
};

export const useAndEnsureCurrentMilitaryDeploymentsRef = (
  driverRef?: string,
): string | undefined => {
  const inquiryLoaded = useSelector(getInquiryLoaded);
  const currentMilitaryDeploymentsRef = useSelector(getMilitaryDeploymentsRef);
  const addMilitaryDeployments = useAddMilitaryDeployments(driverRef);

  useEffect(() => {
    // FIXME: assume inquiry is loaded?
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin:
            'libs/features/sales/quotes/auto/src/state/modelUtil/militaryDeploymentsModelUtil.ts',
          functionOrigin: 'useAndEnsureCurrentMilitaryDeploymentsRef/useEffect',
        },
      });
      throw new Error('inquiry not loaded');
    }

    const ref = currentMilitaryDeploymentsRef;
    if (!ref && driverRef) {
      addMilitaryDeployments();
    }
  }, [inquiryLoaded, currentMilitaryDeploymentsRef, addMilitaryDeployments, driverRef]);

  return currentMilitaryDeploymentsRef;
};

export const useRemoveUnUsedRefForMilitaryDeployments = (
  driverRef: string,
): ((militaryDeployments: MilitaryDeployments) => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(
    async (militaryDeployments: MilitaryDeployments) => {
      if (driverRef) {
        await dispatch(deleteInquiryRef(militaryDeployments.ref));
      }
    },
    [dispatch, driverRef],
  );
};

export const useAttachMilitaryDeploymentsToDriver = (
  driverRef: string,
): ((militaryDeploymentsRef: string) => AddMilitaryDeploymentsResult) => {
  const dispatch = useDispatch();
  const allValues = useSelector(getAllValues);
  const inquiryLoaded = useSelector(getInquiryLoaded);

  return useCallback(
    (militaryDeploymentsRef: string): AddMilitaryDeploymentsResult => {
      // FIXME: assume inquiry is loaded?
      if (!inquiryLoaded) {
        datadogLog({
          logType: 'error',
          message: 'inquiry not loaded',
          context: {
            logOrigin:
              'libs/features/sales/quotes/auto/src/state/modelUtil/militaryDeploymentsModelUtil.ts',
            functionOrigin: 'useAttachMilitaryDeploymentsToDriver',
          },
        });
        throw new Error('inquiry not loaded');
      }
      const updateRefToNav = dispatch(
        setNavMilitaryDeploymentRefChanged(militaryDeploymentsRef || ''),
      );
      const updateMilitaryDeploymentsRef = dispatch(
        updateAddedRef({
          allValues,
          type: `${driverRef}.${MILITARY_DEPLOYMENTS_REF_SUFFIX}`,
          newRef: militaryDeploymentsRef,
        }),
      );
      const done = Promise.all([updateRefToNav, updateMilitaryDeploymentsRef]).then(
        (): string => militaryDeploymentsRef,
      );

      return { done, militaryDeploymentsRef };
    },
    [allValues, dispatch, driverRef, inquiryLoaded],
  );
};

export const useGetmilitaryDeploymentsFields = (
  militaryDeploymentsRef: string,
): MilitaryDeploymentsFields => {
  const userMilitaryDeploymentsField = useFieldWithPrefix(
    militaryDeploymentsRef,
    'militaryDeployment.<id>',
  );

  return {
    militaryDeployments: {
      ref: useField(militaryDeploymentsRef),
      activeMilitaryDeployDate: userMilitaryDeploymentsField('activeMilitaryDeployDate'),
      militaryReturnDate: userMilitaryDeploymentsField('militaryReturnDate'),
    },
  };
};

const getMilitaryDeployments = (ref: string, allValues: Answers): MilitaryDeployments => {
  const getString = getValueForPrefix<string>(ref, allValues);

  return {
    ref,
    activeMilitaryDeployDate: getString('activeMilitaryDeployDate'),
    militaryReturnDate: getString('militaryReturnDate'),
  };
};

export const useMilitaryDeployment = (militaryDeploymentsRef: string): MilitaryDeployments => {
  const allValues = useSelector(getAllValues);

  return getMilitaryDeployments(militaryDeploymentsRef, allValues);
};

export const useMilitaryDeployments = (driverRef: string): MilitaryDeployments[] => {
  const allValues = useSelector(getAllValues);

  const refs: string[] = ensureStringArray(
    allValues[`${driverRef}.${MILITARY_DEPLOYMENTS_REF_SUFFIX}`],
  );

  return refs.map((ref) => getMilitaryDeployments(ref, allValues));
};

import { updateAnswers } from '@ecp/features/sales/shared/store';
import type { AppDispatch } from '@ecp/features/sales/shared/store/types';
/**
 * Clears only the fields that would be populated by fetchVehicleByVinNumber
 */
export const clearVehicleInfo = (vehicleRef: string, dispatch: AppDispatch): void => {
  dispatch(
    updateAnswers({
      answers: {
        [`${vehicleRef}.make`]: '',
        [`${vehicleRef}.model`]: '',
        [`${vehicleRef}.vehicleDetailId`]: '',
        [`${vehicleRef}.stubVin`]: '',
        [`${vehicleRef}.series`]: '',
        [`${vehicleRef}.msprPrice`]: '',
      },
    }),
  );
};

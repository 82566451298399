interface Metadata {
  defaultValue: boolean | string;
  routeToPLPCValue: boolean | string;
}

const routeToPCQuestions: {
  auto: {
    driver: Record<string, Metadata>;
    vehicle: Record<string, Metadata>;
    delta: Record<string, Metadata>;
  };
} = {
  auto: {
    driver: {
      sr22FinancialRespFiling: {
        defaultValue: false,
        routeToPLPCValue: true,
      },
    },
    vehicle: {
      kitCarReplicaInd: {
        defaultValue: false,
        routeToPLPCValue: true,
      },
      otherThanNICoOwnedVehicleInd: {
        defaultValue: false,
        routeToPLPCValue: true,
      },
      primaryUse: {
        defaultValue: 'VEHICLE_USE.PRIMARY.COMMUTE',
        routeToPLPCValue: 'VEHICLE_USE.PRIMARY.ANTIQUE',
      },
    },
    delta: {
      'policy.auto.vehiclesBusinessUse': {
        defaultValue: false,
        routeToPLPCValue: true,
      },
    },
  },
};

export default routeToPCQuestions;

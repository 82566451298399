import { Typography } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, TooltipWithIcon } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { useAclReportForPerson, useMvrReportForPerson } from '@ecp/features/sales/quotes/auto';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField, usePniRef } from '@ecp/features/sales/shared/store';

import { useStyles } from './HouseholdPriorLossQuestion.styles';
interface Props extends QuestionProps {
  priorLossKey: string;
  priorLossHelpLabel: string;
  secondaryText: string;
}
export const HouseholdPriorLossQuestion: React.FC<Props> = (props) => {
  const {
    priorLossKey,
    trackingName = 'householdPriorLoss',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    priorLossHelpLabel,
    secondaryText,
  } = props;
  const { classes } = useStyles();
  const householdPriorLoss = useField(priorLossKey);
  const pniRef = usePniRef();
  const aclReport = useAclReportForPerson(pniRef);
  const mvrReport = useMvrReportForPerson(pniRef);
  const readOnly = !!aclReport || !!mvrReport;
  useInitValues({ [householdPriorLoss.key]: false });
  useAddFields({ householdPriorLoss });
  if (!householdPriorLoss.exists) return null;
  const priorLossQuestion = (
    <GridItem>
      {priorLossHelpLabel}
      <TooltipWithIcon title={priorLossHelpLabel} />
    </GridItem>
  );
  const readOnlyPriorLossQuestion = (
    <>
      <GridItem className={classes.primaryText}>
        {priorLossHelpLabel}
        <TooltipWithIcon title={priorLossHelpLabel} />
        <p className={classes.secondaryText}>{secondaryText}</p>
      </GridItem>
      <GridItem>
        <Typography variant='body1'>Yes</Typography>
      </GridItem>
    </>
  );

  return (
    <>
      {readOnly ? (
        readOnlyPriorLossQuestion
      ) : (
        <RadioGroupWithOptions
          id='householdPriorLoss'
          {...householdPriorLoss.props}
          label={priorLossQuestion}
          helperText={secondaryText}
          variant='yesNoButton'
          trackingName={trackingName}
          trackingLabel={trackingLabel}
        />
      )}
    </>
  );
};

import { useCallback, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import {
  getPLPCQuoteNumber,
  submitProofDraft,
  updateAnswers,
  useForm,
  useRouteToPLPC,
} from '@ecp/features/sales/shared/store';
import type {
  RootStore,
  ThunkAction,
  ValidateFormResult,
} from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import {
  getProductNameFromUnbundledLob,
  LineOfBusiness,
  type ProductName,
} from '@ecp/features/shared/product';
import {
  AddOtherCoveragesQuestion,
  OtherStructuresBlanketCoverageQuestion,
} from '@ecp/sales/lob/property';
import { PolicyCenterDialog } from '@ecp/sales/lob/property';

import { useShouldRouteToPLPC, useUpdatedAnswers } from '../RentersPropertyDetailsForm/utils';
import { useStyles } from './HomeOtherStructuresPageForm.styles';

export interface HomeOtherStructuresProps {
  onNext: () => Promise<void>;
}

interface SubmitParams {
  policyType: ProductName;
  onNext: () => Promise<void>;
  patchFormValues: () => Promise<string>;
  setIsSubmitting: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
  setCanOpenDialog?: (value: boolean) => void;
  shouldRouteToPLPC?: (lob: ProductName) => boolean;
}

const doSubmit =
  ({
    policyType,
    onNext,
    patchFormValues,
    setIsSubmitting,
    validateForm,
    setCanOpenDialog,
    shouldRouteToPLPC,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    setIsSubmitting(true);
    if (validateForm().isValid) {
      await patchFormValues();

      // check if quote should be routed to PLPC once the form is valid
      if (shouldRouteToPLPC?.(policyType)) {
        setIsSubmitting(false);
        setCanOpenDialog?.(true);

        return;
      }

      await dispatch(
        submitProofDraft({
          policyTypes: [policyType],
        }),
      );
      await onNext();
    }

    setIsSubmitting(false);
  };

export const HomeOtherStructuresPageForm: React.FC<HomeOtherStructuresProps> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();

  const dispatch = useDispatch();
  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const conditions = useGetConditionValues();

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: conditions(),
  });

  // Start - Route to PLPC section
  const [canOpenDialog, setCanOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const policyType = getProductNameFromUnbundledLob(LineOfBusiness.HOME);
  const plpcQuoteNumber = useSelector((state: RootStore) => getPLPCQuoteNumber(state, policyType));
  // operations to perform routing to PLPC
  const routeToPLPC = useRouteToPLPC();
  // check if quote should be routed to PLPC
  const shouldRouteToPLPC = useShouldRouteToPLPC();
  // flip answers back to defaults before route to PLPC
  const updatedAnswers = useUpdatedAnswers(policyType);

  const handleRouteToPLPCDialogAction = useCallback(async () => {
    if (!plpcQuoteNumber) {
      setCanOpenDialog(false);

      return;
    }
    await dispatch(updateAnswers({ answers: updatedAnswers }));
    await routeToPLPC(setIsSubmitting, LineOfBusiness.HOME, plpcQuoteNumber);
  }, [dispatch, plpcQuoteNumber, routeToPLPC, updatedAnswers]);

  const handleCancelButtonOnClick = useCallback(async () => {
    setCanOpenDialog(false);

    return;
  }, []);
  // End - Route to PLPC section

  const handleSubmit = useCallback(async () => {
    await dispatch(
      doSubmit({
        policyType,
        onNext,
        patchFormValues,
        setIsSubmitting,
        validateForm,
        setCanOpenDialog,
        shouldRouteToPLPC,
      }),
    );
  }, [dispatch, policyType, onNext, patchFormValues, validateForm, shouldRouteToPLPC]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <GridItem topSpacing='lg' xs={12}>
            <OtherStructuresBlanketCoverageQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <AddOtherCoveragesQuestion />
          </GridItem>
          <Grid container item xs={12}>
            <Button
              id='submit'
              variant='primary'
              onClick={handleSubmit}
              data-testid='showMyDiscounts'
              isProcessing={isPatchFormInProgress || isSubmitting}
              classes={{ root: classes.nextButton }}
              className={classes.next}
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='home_other_structures_continue'
            >
              Save & continue
            </Button>
          </Grid>
        </Grid>
      </Form>
      {canOpenDialog && (
        <PolicyCenterDialog
          dialogStat={canOpenDialog}
          dialogSubmit={handleRouteToPLPCDialogAction}
          isProcessing={isSubmitting}
          toggleDialog={handleCancelButtonOnClick}
        />
      )}
    </div>
  );
};

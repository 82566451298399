import { useCallback } from 'react';

import { emptyArray, parseDollar } from '@ecp/utils/common';

import { STATE_CODE_PREFIX } from '@ecp/features/sales/shared/constants';
import {
  AutoCoverageMetadata,
  HomeCoverageMetadata,
  RentersCoverageMetadata,
} from '@ecp/features/sales/shared/metadata';
import {
  buildAddressLabel,
  buildAutoPolicyCoverage,
  buildPropertyCoverages,
  getAllValues,
  getIsBundleForOfferProductsSelected,
  getOfferDetailsForAdvanceProduct,
  getOptionLabelByType,
  getProducerDetail,
  getProducerID,
} from '@ecp/features/sales/shared/store';
import {
  getLineOfBusiness,
  getOfferProductsSelected,
  getPrimaryInsuredPersonInfo,
  useField,
} from '@ecp/features/sales/shared/store';
import type { Producer, RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Address, CoverageItem } from '@ecp/features/sales/shared/types';
import {
  useGetPolicyCoverageFields,
  useGetPropertyCoverageFields,
} from '@ecp/features/sales/shell';
import {
  type AutoProduct,
  getProductDisplayNameFromProduct,
  type HomeProduct,
  isProductAuto,
  isProductHome,
  isProductRenters,
  LineOfBusiness,
  type Product,
  type RentersProduct,
} from '@ecp/features/shared/product';
import type { AnswerValue } from '@ecp/types';

import {
  EMAIL_QUOTES_AGENCY_ADDRESS,
  EMAIL_QUOTES_AGENCY_NAME,
  EMAIL_QUOTES_AGENCY_PHONE,
  EMAIL_QUOTES_CC,
  EMAIL_QUOTES_FROM,
  EMAIL_QUOTES_MESSAGE,
  EMAIL_QUOTES_TO,
} from '../constants';
import type {
  ProducerAddress,
  ProducerAndQuoteDetail,
  ProducerDetails,
  SendEmailFields,
} from '../types';

export const useGetSendEmailFields = (): SendEmailFields => {
  return {
    emailFrom: useField(EMAIL_QUOTES_FROM),
    emailTo: useField(EMAIL_QUOTES_TO),
    emailCC: useField(EMAIL_QUOTES_CC),
    emailMessage: useField(EMAIL_QUOTES_MESSAGE),
    agencyName: useField(EMAIL_QUOTES_AGENCY_NAME),
    agencyAddress: useField(EMAIL_QUOTES_AGENCY_ADDRESS),
    agencyPhone: useField(EMAIL_QUOTES_AGENCY_PHONE),
  };
};

export const useBuildProducerDetails = (): (() => Pick<Producer, 'name' | 'phones' | 'emails'> &
  Record<'addresses', ProducerAddress[] & { displayValue: string }[]>) => {
  const producerDetails = useSelector((state: RootStore) => getProducerDetail(state));

  return useCallback(() => {
    const addDisplayValue = producerDetails?.addresses?.map((address) => {
      return {
        ...address,
        displayValue: `${address.addressLine1} ${
          address.addressLine2 ? ` ${address.addressLine2},` : ','
        }${' '}${address.city} ${address.state} ${address.zip}`,
      };
    });

    return {
      name: producerDetails?.name,
      phones: producerDetails?.phones,
      emails: producerDetails?.emails,
      addresses: addDisplayValue,
    };
  }, [producerDetails]);
};

const useGetProducerAgencyAddress = (): ((
  value: string,
) => Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode'>) => {
  const producerDetails = useBuildProducerDetails();

  return useCallback(
    (value: string) => {
      const { addresses } = producerDetails();

      const matchedAddress = addresses && addresses.filter((e) => e.displayValue === value)[0];
      if (!matchedAddress) {
        // Handle the case where no address matches the provided value
        return { city: '', line1: '', line2: '', state: '', zipcode: '' };
      }

      return {
        city: matchedAddress.city,
        line1: matchedAddress.addressLine1,
        line2: matchedAddress.addressLine2 || '',
        state: `${STATE_CODE_PREFIX}${matchedAddress.state}`,
        zipcode: matchedAddress.zip,
      };
    },
    [producerDetails],
  );
};

const useGetProducerDetails = (): (() => ProducerDetails) => {
  const { agencyName, agencyPhone } = useGetSendEmailFields();
  const producerID = useSelector(getProducerID);
  const selectedAgencyAddress = useSelector(getAllValues)[EMAIL_QUOTES_AGENCY_ADDRESS] as string;
  const agencyAddressValue = useGetProducerAgencyAddress();

  return useCallback(() => {
    const agencyAddress = agencyAddressValue(selectedAgencyAddress);

    return {
      ProdID: producerID?.toString(),
      ProdIDSender: producerID?.toString(),
      AgentName: agencyName.value?.toString(),
      AddrA: agencyAddress?.line1,
      AgentsCity: agencyAddress?.city,
      AgentsState: agencyAddress?.state,
      AgentsZip: agencyAddress?.zipcode,
      AgentsPhone: agencyPhone.value?.toString(),
    };
  }, [selectedAgencyAddress, agencyAddressValue, agencyName.value, agencyPhone.value, producerID]);
};

const useGetRecipientDetails = (): { [key: string]: string } => {
  const { firstName, lastName } = useSelector(getPrimaryInsuredPersonInfo);

  return {
    FirstName: firstName,
    LastName: lastName,
  };
};

const useGetPremiumDetails = (
  offerProduct: Product,
): { [key: string]: string | undefined } | null => {
  const offerDetails = useSelector((state: RootStore) =>
    getOfferDetailsForAdvanceProduct(state, offerProduct),
  );
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);

  const productDisplayName = getProductDisplayNameFromProduct(offerProduct);
  const productName = isBundle ? productDisplayName : '';

  if (!offerDetails) return null;

  const { monthlyPremium, fullPremium, policyDuration } = offerDetails;

  return {
    [`PolicyTerm${productName}`]: policyDuration === 'ANNUAL' ? '12 months' : '6 months',
    [`PmtAmtMonth${productName}`]: parseDollar(
      monthlyPremium?.installmentPayment?.toString(),
      true,
    ),
    [`PmtAmt${productName}`]: parseDollar('00.00', true),
    [`FullPayAmt${productDisplayName}`]: parseDollar(fullPremium?.totalPremium?.toString(), true),
    [`DiscountsAmount${productName}`]: parseDollar(
      monthlyPremium?.discountTotal?.toString().substring(1),
      true,
    ),
    DiscountsAmtCombined: parseDollar('00.00', true),
    PmtAmtCombined: parseDollar('00.00', true),
    [`FullPaySavingsAmt${productDisplayName}`]: parseDollar('00.00', true),
  };
};

const useBuildPremiumDetails = (
  products: Product[],
): (() => { [key: string]: string | undefined } | null | undefined) => {
  const premiumInfo = products.map(useGetPremiumDetails);

  return useCallback(() => {
    return premiumInfo?.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});
  }, [premiumInfo]);
};

// Convert 100,000/300,000 to $100,000/$300,000
const getLabelByCurrency = (label: string | undefined): string | undefined => {
  return (
    label &&
    label
      .split('/')
      .map((e) => {
        const numericString = parseFloat(e.replace(/,/g, '')).toString();

        return /^-?\d+$/.test(numericString) ? getOptionLabelByType(e, 'CURRENCY') : e;
      })
      .join('/')
  );
};

const useGetRentersCoverageDetails = (
  rentersProduct?: RentersProduct,
): (() => { [key: string]: string | undefined }) => {
  const propertyCoverageFields = useGetPropertyCoverageFields(rentersProduct);

  const rentersCoverages =
    rentersProduct && buildPropertyCoverages(rentersProduct, propertyCoverageFields);

  return useCallback(() => {
    const { personalProperty, personalLiability, propertyDeductible } = RentersCoverageMetadata;

    const RentersCovC = getLabelByCurrency(
      rentersCoverages?.coverages.find((e) => e.title === personalProperty.coverageTitle)
        ?.displayValue,
    );
    const RentersCovE = getLabelByCurrency(
      rentersCoverages?.coverages.find((e) => e.title === personalLiability.coverageTitle)
        ?.displayValue,
    );
    const RentersDeductible = getLabelByCurrency(
      rentersCoverages?.coverages.find((e) => e.title === propertyDeductible.coverageTitle)
        ?.displayValue,
    );

    return {
      RentersCovC,
      RentersCovE,
      RentersDeductible,
    };
  }, [rentersCoverages]);
};

const useGetHomeCoverageDetails = (
  homeProduct?: HomeProduct,
): (() => { [key: string]: string | undefined }) => {
  const propertyCoverageFields = useGetPropertyCoverageFields(homeProduct);

  const homeCoverages = homeProduct && buildPropertyCoverages(homeProduct, propertyCoverageFields);

  return useCallback(() => {
    const { dwelling, personalProperty, personalLiability, propertyDeductible } =
      HomeCoverageMetadata;

    const homeCovA = homeCoverages?.coverages.find(
      (e) => e.title === dwelling.coverageTitle,
    )?.displayValue;
    const homeCovC = homeCoverages?.coverages.find(
      (e) => e.title === personalProperty.coverageTitle,
    )?.displayValue;
    const homeCovE = homeCoverages?.coverages.find(
      (e) => e.title === personalLiability.coverageTitle,
    )?.displayValue;
    const homeDeductible = homeCoverages?.coverages.find(
      (e) => e.title === propertyDeductible.coverageTitle,
    )?.displayValue;

    return {
      HomeCovA: homeCovA,
      HomeCovC: homeCovC,
      HomeCovE: homeCovE,
      HomeDeductible: homeDeductible,
    };
  }, [homeCoverages]);
};

const useGetAutoCoverageDetails = (
  autoProduct?: AutoProduct,
): (() => { [key: string]: string | undefined }) => {
  const autoCoverageFields = {
    policyCoverageFields: useGetPolicyCoverageFields(autoProduct),
  };

  return useCallback(() => {
    const {
      bodilyInjury,
      propertyDamage,
      medicalExpense,
      underInsuredMotoristBi,
      uninsuredMotoristBi,
      uninsuredUnderInsuredCombined,
      pip,
    } = AutoCoverageMetadata;
    const autoPolicyCoverages =
      autoProduct && autoCoverageFields?.policyCoverageFields
        ? buildAutoPolicyCoverage(autoProduct, autoCoverageFields.policyCoverageFields)
        : (emptyArray as unknown as CoverageItem[]);

    const autoBI = getLabelByCurrency(
      autoPolicyCoverages.find((e) => e.title === bodilyInjury.coverageTitle)?.displayValue,
    );
    const autoPD = getLabelByCurrency(
      autoPolicyCoverages.find((e) => e.title === propertyDamage.coverageTitle)?.displayValue,
    );
    const autoMedExpense = getLabelByCurrency(
      autoPolicyCoverages.find((e) => e.title === medicalExpense.coverageTitle)?.displayValue,
    );
    const autoUMBI = getLabelByCurrency(
      autoPolicyCoverages.find((e) => e.title === underInsuredMotoristBi.coverageTitle)
        ?.displayValue,
    );
    const autoUIM = getLabelByCurrency(
      autoPolicyCoverages.find((e) => e.title === uninsuredMotoristBi.coverageTitle)?.displayValue,
    );
    const autoUMUIM = getLabelByCurrency(
      autoPolicyCoverages.find((e) => e.title === uninsuredUnderInsuredCombined.coverageTitle)
        ?.displayValue,
    );

    const autoPIP = getLabelByCurrency(
      autoPolicyCoverages.find((e) => e.title === pip.coverageTitle)?.displayValue,
    );

    return {
      AutoBI: autoBI,
      AutoPD: autoPD,
      AutoMedExpense: autoMedExpense,
      AutoUMBI: autoUMBI,
      AutoUIM: autoUIM,
      AutoUMUIM: autoUMUIM,
      AutoPIP: autoPIP,
    };
  }, [autoCoverageFields.policyCoverageFields, autoProduct]);
};

const useBuildCoverages = (products: Product[]): (() => { [key: string]: string | undefined }) => {
  const autoProduct = products.find(isProductAuto);
  const homeProduct = products.find(isProductHome);
  const rentersProduct = products.find(isProductRenters);
  const autoCoverages = useGetAutoCoverageDetails(autoProduct);
  const homeCoverages = useGetHomeCoverageDetails(homeProduct);
  const rentersCoverages = useGetRentersCoverageDetails(rentersProduct);

  return useCallback(() => {
    const info = {};

    if (autoProduct) {
      Object.assign(info, autoCoverages());
    }
    if (homeProduct) {
      Object.assign(info, homeCoverages());
    }
    if (rentersProduct) {
      Object.assign(info, rentersCoverages());
    }

    return info;
  }, [autoCoverages, autoProduct, homeCoverages, homeProduct, rentersCoverages, rentersProduct]);
};

const useDetermineTemplateCode = (): (() => { TemplateName: string; BundleType?: string }) => {
  const lob = useSelector(getLineOfBusiness);

  return useCallback(() => {
    switch (lob) {
      case LineOfBusiness.AUTO:
        return { TemplateName: 'SalesPortalQuote' };
      case LineOfBusiness.HOME:
        return { TemplateName: 'SalesPortalQuoteHome' };
      case LineOfBusiness.RENTERS:
        return { TemplateName: 'SalesPortalQuoteRenters' };
      case LineOfBusiness.BUNDLE:
      case LineOfBusiness.BUNDLE_AUTO_RENTERS:
        return { TemplateName: 'SalesPortalQuoteBundle', BundleType: 'AR' };
      default:
        return { TemplateName: '' };
    }
  }, [lob]);
};

const useGetEmailFieldValues = (): (() => {
  emailMessage: AnswerValue;
  ccEmail: AnswerValue;
  emailTo: AnswerValue;
  agencyName: AnswerValue;
  agencyPhone: AnswerValue;
}) => {
  const { emailMessage, emailCC, emailTo, agencyName, agencyPhone } = useGetSendEmailFields();

  return useCallback(() => {
    return {
      emailMessage: emailMessage.props.value,
      ccEmail: emailCC.props.value,
      emailTo: emailTo.props.value,
      agencyName: agencyName.props.value,
      agencyPhone: agencyPhone.props.value,
    };
  }, [
    agencyName.props.value,
    agencyPhone.props.value,
    emailCC.props.value,
    emailMessage.props.value,
    emailTo.props.value,
  ]);
};

export const useGetProducerAndQuoteDetails = (): (() => ProducerAndQuoteDetail) => {
  const offerProductsSelected = useSelector((state: RootStore) => getOfferProductsSelected(state));
  const producerDetails = useGetProducerDetails();
  const recipientDetails = useGetRecipientDetails();
  const premiumDetails = useBuildPremiumDetails(offerProductsSelected);
  const coverageDetails = useBuildCoverages(offerProductsSelected);
  const templateName = useDetermineTemplateCode();
  const emailFieldValues = useGetEmailFieldValues();

  return useCallback(() => {
    const { ...producer } = producerDetails();
    const { ...premium } = premiumDetails();
    const { ...coverages } = coverageDetails();
    const { ...templateCode } = templateName();
    const { emailMessage, ccEmail, emailTo, agencyName } = emailFieldValues();

    return {
      Message: emailMessage?.toString(),
      CustomerEmailAddress: emailTo?.toString(),
      CC: ccEmail?.toString(),
      SenderName: agencyName?.toString(),
      ...templateCode,
      ...recipientDetails,
      ...producer,
      ...premium,
      ...coverages,
    };
  }, [
    coverageDetails,
    emailFieldValues,
    premiumDetails,
    producerDetails,
    recipientDetails,
    templateName,
  ]);
};

export const getOptions = (
  options: string[],
): {
  label: string;
  value: string;
}[] =>
  options.map((value) => ({
    label: value,
    value,
  }));

export const useGetAgentAddressOptions = (): ((
  addresses: ProducerAddress[],
) => { label: string; value: string }[]) => {
  return useCallback((addresses: ProducerAddress[]) => {
    return addresses.map(
      ({ addressLine1: line1, addressLine2: line2 = '', city, state, zip: zipcode }) => {
        const address = buildAddressLabel({ line1, line2, city, state, zipcode });

        return {
          label: address,
          value: address,
        };
      },
    );
  }, []);
};

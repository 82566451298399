import { sanitize } from 'dompurify';

import { useGetReplacementCostDetails, usePropertyField } from '../../utils';

interface IFrameFormInputParams {
  valuationId: string;
  email: string;
  ruleset: string;
  prefillXml: string;
}

export const HomeReplacementCostCalculatorForm = (): React.ReactElement => {
  const replacementCostValuationId = usePropertyField('replacementCostValuationId');
  const { createXML, url } = useGetReplacementCostDetails();

  const params: IFrameFormInputParams = {
    valuationId: '',
    email: '',
    ruleset: '',
    prefillXml: '',
  };
  // Creating the form and set attributes as per cost calculator service need
  const ccLoginForm = document.createElement('form');
  ccLoginForm.name = 'frmLogin';
  ccLoginForm.method = 'POST';
  ccLoginForm.action = url;
  ccLoginForm.target = 'output_frame';

  // Below parameters will map validation id, transform property data to XML by getting the values answers,
  params.valuationId = Math.floor(replacementCostValuationId?.props.value).toString();
  params.email = '';
  params.ruleset = 'AM_FAM_ADVANCED';
  params.prefillXml = createXML();

  // Append and submits the form data to the service specified in the form’s action attribute, using method attribute
  for (const [key, value] of Object.entries(params)) {
    const anInput = document.createElement('input');
    anInput.type = 'hidden';
    anInput.name = key;
    anInput.value = value;
    ccLoginForm.appendChild(anInput);
  }
  document.body.appendChild(ccLoginForm);
  ccLoginForm.submit();

  return <div dangerouslySetInnerHTML={{ __html: sanitize(ccLoginForm) }} />;
};

import { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { GridItem, NumberFormat } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import { useDeltaField, useField, usePniRef } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AutoProduct } from '@ecp/features/shared/product';
import type { AnswerValue, Field } from '@ecp/types';

import { getDefaultOptInPhone } from '../../utils/discountsUtil';

export interface DeltaMilesMyWayPhoneQuestionProps {
  autoProduct: AutoProduct;
}
export interface ProgramPhoneOptions {
  label: string;
  value: string;
}

// TODO: Need to merge this component with DeltaDriveMyWayPhoneQuestion
export const DeltaMilesMyWayPhoneQuestion: React.FC<DeltaMilesMyWayPhoneQuestionProps> = (
  props,
) => {
  const { autoProduct } = props;
  const milesMyWayPhoneStaticField: Field = useField(
    `static.delta.coverageOption.${autoProduct}.ubiProgramPhone`,
  );
  const milesMyWayPhoneField: Field = useDeltaField(
    `coverageOption.${autoProduct}`,
    'ubiProgramPhone',
  );

  const pniRef = usePniRef();

  const pniPhone = useSelector((state: RootStore) => state.inquiry.answers[`${pniRef}.phone`]) as
    | string
    | undefined;

  const [initialMMWPhone, setInitialMMWPhone] = useState(pniPhone);

  const milesMyWayPhoneValue = getDefaultOptInPhone(initialMMWPhone);

  useMemo(() => {
    setInitialMMWPhone(milesMyWayPhoneValue);
  }, [milesMyWayPhoneValue]);

  useInitValues(
    initialMMWPhone
      ? {
          [milesMyWayPhoneStaticField.key]: milesMyWayPhoneValue,
          [milesMyWayPhoneField.key]: milesMyWayPhoneValue,
        }
      : { [milesMyWayPhoneStaticField.key]: 'Add New Phone', [milesMyWayPhoneField.key]: null },
  );
  useAddFields({ milesMyWayPhoneStaticField, milesMyWayPhoneField });

  const getPhoneOptions = (options: string[]): ProgramPhoneOptions[] =>
    options.map((value) => ({
      label: value,
      value,
    }));

  const onPhoneOptionChange = useCallback(
    (value: AnswerValue): void => {
      milesMyWayPhoneStaticField.props.actionOnChange(value);
      if (value === initialMMWPhone) {
        milesMyWayPhoneField.props.actionOnComplete(initialMMWPhone);
      } else {
        milesMyWayPhoneField.validateUpdateAndPatch(null);
      }
    },
    [milesMyWayPhoneStaticField, milesMyWayPhoneField, initialMMWPhone],
  );

  if (!milesMyWayPhoneStaticField.exists) return null;

  return (
    <Grid container columnSpacing={1}>
      {initialMMWPhone && (
        <GridItem topSpacing='xs' md={5} xs={12}>
          <Select
            {...milesMyWayPhoneStaticField.props}
            label='Phone Number'
            id='milesMyWayPhoneStaticField'
            options={getPhoneOptions(
              initialMMWPhone ? [initialMMWPhone, 'Add New Phone'] : ['Add New Phone'],
            )}
            trackingName='miles_my_way_phone'
            actionOnChange={onPhoneOptionChange}
          />
        </GridItem>
      )}
      {milesMyWayPhoneStaticField.value === 'Add New Phone' && (
        <GridItem topSpacing='xs' md={5} xs={12}>
          <NumberFormat
            {...milesMyWayPhoneField.props}
            label={initialMMWPhone ? 'New Phone' : 'Phone Number'}
            trackingName='miles_my_way_new_phone'
            formatType='phone'
          />
        </GridItem>
      )}
    </Grid>
  );
};

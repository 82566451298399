import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldRadioGroupComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './DwellingCosmeticHailCoverage.styles';

export const DwellingCosmeticHailCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();
  const [dwellingCosmeticHailCoverageField, hasMetalRoofField, hasMetalSlideField] =
    useGroupedCoverageFields(props.carrierName, props.product, 'dwellingCosmeticHail', [
      'selected',
      'hasMetalRoof',
      'hasMetalSliding',
    ]);

  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(
    dwellingCosmeticHailCoverageField,
    [hasMetalRoofField, hasMetalSlideField],
  );

  const handleDwellingCosmeticChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );

  if (!dwellingCosmeticHailCoverageField.exists) return null;

  const isCosmeticHailCoverageSelected = castToBoolean(dwellingCosmeticHailCoverageField.value);

  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='dwellingCosmeticHailCoverage'
          field={dwellingCosmeticHailCoverageField}
          isCoverageSelected={isCosmeticHailCoverageSelected}
          handleCoverageChange={handleDwellingCosmeticChange}
        />
        {isCosmeticHailCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            <CoverageFieldRadioGroupComponent
              coverageName={hasMetalRoofField.props.name}
              field={hasMetalRoofField}
              handleSubCoverageChange={hasMetalRoofField.props.actionOnComplete}
              sortByKey
            />
            <CoverageFieldRadioGroupComponent
              coverageName={hasMetalSlideField.props.name}
              field={hasMetalSlideField}
              handleSubCoverageChange={hasMetalSlideField.props.actionOnComplete}
              sortByKey
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

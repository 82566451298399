import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isDlNumberMasked } from '@ecp/utils/common';

import type { TextFieldProps } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import {
  getInvalidDlRefs,
  INVALID_DL_DRIVER_REFS,
  useValidateDriverLicenseNumberForOneDriver,
} from '@ecp/features/sales/quotes/auto';
import { TextField } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { updateAnswers, useDeltaField } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

interface Props extends QuestionProps {
  driverRef: string;
}

export const DeltaDriverLicenseNumberQuestion: React.FC<Props> = (props) => {
  const {
    driverRef,
    trackingName = 'delta_driver_license_number_question',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
  } = props;
  const dispatch = useDispatch();
  const licenseNumber = useDeltaField(driverRef, 'license.number');
  const licenseState = useDeltaField(driverRef, 'license.state');
  useAddFields({ [`${licenseNumber.key}`]: licenseNumber });
  const validateDriverLicenseNumber = useValidateDriverLicenseNumberForOneDriver(
    licenseNumber,
    licenseState,
  );
  const invalidDlRefs = useSelector(getInvalidDlRefs);

  const driverLicenseActionOnChange: NonNullable<TextFieldProps['actionOnChange']> = useCallback(
    (value) => {
      // if DL number is masked, clear out DL state and patch it
      if (isDlNumberMasked(licenseNumber.value)) {
        // if state value is not empty, clear out the state and patch it
        if (licenseState.value !== null) licenseState.props.actionOnComplete(null);
        licenseNumber.props.actionOnChange(null);
        // else change DL number with new value
      } else {
        // no need to patch; actionOnComplete will patch every keyboard input
        licenseNumber.props.actionOnChange(value);
      }
    },
    [licenseNumber.props, licenseNumber.value, licenseState.props, licenseState.value],
  );

  const driverLicenseActionOnComplete: NonNullable<TextFieldProps['actionOnComplete']> =
    useCallback(
      async (value) => {
        // If user tabs over this field with a masked value, on complete executes.
        // If the value is masked do nothing to it.
        if (isDlNumberMasked(value)) return;
        // If tabbed over with no value, we need to see Required field -- regardless of if state has value yet
        if (value === '') {
          // No need to validate license for this. Call on complete and the errors defined by DAL will show.
          licenseNumber.props.actionOnComplete(value);

          return;
        }
        const isDlNumberValid = await validateDriverLicenseNumber();
        if (isDlNumberValid) {
          licenseNumber.props.actionOnComplete(value);
          // since valid, will remove the current ref from the invalidDlRefs array if current ref is already in the array
          // will do nothing if the current ref is not in the invalidDlRefs array
          if (invalidDlRefs.includes(driverRef)) {
            invalidDlRefs.splice(invalidDlRefs.indexOf(driverRef), 1);
            await dispatch(updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: invalidDlRefs } }));
          }
        } else if (!isDlNumberValid) {
          // if validation failed, add current ref into the existing invalidDlRefs array,
          // or create a new array with the current ref in if no invalidDlRefs array created yet
          if (!invalidDlRefs.length) {
            await dispatch(updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: [driverRef] } }));
          } else if (!invalidDlRefs.includes(driverRef)) {
            invalidDlRefs.push(driverRef);
            await dispatch(updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: invalidDlRefs } }));
          }
        }
      },
      [dispatch, driverRef, invalidDlRefs, licenseNumber.props, validateDriverLicenseNumber],
    );

  return (
    <TextField
      {...licenseNumber.props}
      actionOnChange={driverLicenseActionOnChange}
      actionOnComplete={driverLicenseActionOnComplete}
      label='Drivers License Number'
      trackingName={trackingName}
      trackingLabel={trackingLabel}
    />
  );
};

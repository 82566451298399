import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AddressSuggestionsDialog' })((theme) => ({
  ...theme.mixins.formAll,
  useAddress: theme.typography.body2Bold,
  radio: {
    padding: 0,
  },
  label: {
    ...theme.typography.body2Bold,
    marginLeft: 16,
  },
  labelAddress: {
    ...theme.typography.body1,
    marginTop: 5,
  },
  labelAddressHighlight: {
    ...theme.typography.body1Bold,
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: 2,
  },
}));

import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const RoofShapeQuestion: React.FC<QuestionProps> = (props) => {
  const roofShape = usePropertyField('roof.shape');
  const { label = roofShape?.question?.title, trackingName = 'roof_shape_selection' } = props;

  if (!roofShape) return null;

  return (
    <RadioGroupWithOptions
      {...roofShape.props}
      options={roofShape.props.options}
      label={label}
      trackingName={trackingName}
      id='RoofShape'
      sortByKey
      cardSize='small'
    />
  );
};

import { castAnswerType } from '@ecp/utils/common';

import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { getFieldsByKeys } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, AnswerValue } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

type CoverageFieldNames =
  | 'applianceLimit'
  | 'contentLimit'
  | 'contentLimits'
  | 'selected'
  | 'limit'
  | 'deductible'
  | 'dwellingLimit'
  | 'position'
  | 'sued'
  | 'jewelryDeductible'
  | 'otherThanJewelryDeductible'
  | 'coverageB'
  | 'coverageC'
  | 'additionalLiving'
  | 'basement'
  | 'fungiValue'
  | 'packageLimit'
  | 'hasMetalRoof'
  | 'hasMetalSliding'
  | 'scheduledStructuresAway'
  | 'permanentDock'
  | 'portableDock'
  | 'fairRentalValue'
  | 'effectiveDate'
  | 'endDate';

// This hook creates the main coverage fields and the sub coverage field based on the inputs.
// Also, adds the fields to the form context and set up initial values (Default values)
export const useGroupedCoverageFields = (
  carrierName: string,
  product: string,
  coverageName: string,
  coverageFieldNames: CoverageFieldNames[],
): Field<AnswerValue>[] => {
  const coveragePrefix = `${carrierName}.${product}.coverages`;
  const dispatch = useDispatch();

  // Create all the fields here based on the coverage field names
  const groupedCoverageKeysAndFields = useSelector((state: RootStore) =>
    getFieldsByKeys(state, `${coveragePrefix}.${coverageName}`, coverageFieldNames, dispatch),
  );
  const fields = Object.values(groupedCoverageKeysAndFields);

  // Determine the default values for each of the fields
  const initFieldsInput = fields.reduce((initValuesAccumulator, individualField) => {
    const initValue = castAnswerType(
      individualField.question?.defaultValue,
      individualField.question.answerType,
    );

    initValuesAccumulator[individualField.key] = initValue;

    return initValuesAccumulator;
  }, {} as Answers);

  useInitValues(initFieldsInput);
  useAddFields(groupedCoverageKeysAndFields);

  return fields;
};

// This hook returns a method around performing opt-in/ opt-out for the main coverage
// and defines the behavior of any associated subcoverages based on certain rules
// 1. If main coverage is selected (opt-in) ->
//    a) Set the subcoverage to its default value if defined, or
//		b) Set the subcoverage to the only available option, if there is only one option.
//			This is needed if the sub coverage is a read only field with only one option.
// 2. If main coverage is not selected (opt-out), set all the associated subcoverages to null.
export const useCoverageSelectedField = (
  coverageSelectedField: Field<AnswerValue>,
  subCoverageFields: Field<AnswerValue>[],
): { (isCoverageSelected: boolean): Promise<void> } => {
  const selectedCoverageFieldChangeHandler = async (isCoverageSelected: boolean): Promise<void> => {
    const work: Promise<unknown>[] = [];

    // First step is to set the value for the main coverage
    /**
     * actionOnComplete was defined in the coverage field build function; It needs to be called to validate all forms.
     *   Otherwise cross coverage rules do not work correctly.
     */
    work.push(coverageSelectedField.props.actionOnComplete(isCoverageSelected.toString()));

    if (isCoverageSelected) {
      // For each subcoverage field, identify the work.
      subCoverageFields.forEach((subCoverageField) => {
        if (!subCoverageField.exists) {
          return;
        }

        // Determine the value of the subcoverage field if the main coverage is selected (opt-in)
        const initialSubCoverageValue =
          subCoverageField.question.defaultValue ??
          (subCoverageField.question.options?.length === 1 &&
            subCoverageField.question.options[0].value);

        if (initialSubCoverageValue) {
          work.push(subCoverageField.props.actionOnComplete(initialSubCoverageValue));
        }
      });
    } else {
      subCoverageFields.forEach((subCoverageField) => {
        if (!subCoverageField.exists) {
          return;
        }
        // Nullify all the subcoverage fields if the main coverage is opt-out.
        work.push(subCoverageField.props.actionOnComplete(null));
      });
    }
    await Promise.all(work);
  };

  return selectedCoverageFieldChangeHandler;
};

export const getDisplayForReadOnlyOptionField = (field: Field<AnswerValue>): string => {
  return field?.props?.options?.length === 1 ? field.props.options[0].label.toString() : '';
};

import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldRadioGroupComponent,
  CoverageFieldSelectComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './EarthquakeCoverage.styles';

export const EarthquakeCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();

  // Get the coverage selected fields and all the related subcoverage fields
  const [
    earthquakeCoverageField,
    coverageBField,
    coverageCField,
    deductibleField,
    scheduledStructuresAwayField,
  ] = useGroupedCoverageFields(props.carrierName, props.product, 'earthquake', [
    'selected',
    'coverageB',
    'coverageC',
    'deductible',
    'scheduledStructuresAway',
  ]);

  // Define the logic around subcoverage fields which are
  // impacted by opt-in/ opt-out feature for main coverage field
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(earthquakeCoverageField, [
    coverageBField,
    coverageCField,
    deductibleField,
    scheduledStructuresAwayField,
  ]);

  const handleEarthquakeCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );

  if (!earthquakeCoverageField.exists) return null;

  const isEarthquakeCoverageSelected = castToBoolean(earthquakeCoverageField.value);

  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='earthquakeCoverage'
          field={earthquakeCoverageField}
          isCoverageSelected={isEarthquakeCoverageSelected}
          handleCoverageChange={handleEarthquakeCoverageChange}
        />
        {isEarthquakeCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            <CoverageFieldRadioGroupComponent
              coverageName={scheduledStructuresAwayField.props.name}
              field={scheduledStructuresAwayField}
              sortByKey
              handleSubCoverageChange={scheduledStructuresAwayField.props.actionOnComplete}
            />
            <CoverageFieldRadioGroupComponent
              coverageName={coverageBField.props.name}
              field={coverageBField}
              sortByKey
              handleSubCoverageChange={coverageBField.props.actionOnComplete}
            />
            <CoverageFieldRadioGroupComponent
              coverageName={coverageCField.props.name}
              field={coverageCField}
              sortByKey
              handleSubCoverageChange={coverageCField.props.actionOnComplete}
            />
            <CoverageFieldSelectComponent field={deductibleField} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

import { Box, Divider, Grid, Typography } from '@mui/material';

import { GridItem } from '@ecp/components';
import { getPrimaryInsuredAddressInfo } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconCardHome } from '@ecp/themes/base';

import { useThirdPartyInterestPageContext } from '../../../../../common/components';
import { useStyles } from './PropertyAddTPIHeader.styles';

export const PropertyAddTPIHeader: React.FC = () => {
  const { classes } = useStyles();
  const { selectedProductFromAddEditButton: selectedProductFromAddButton } =
    useThirdPartyInterestPageContext();
  const addressInformation = useSelector(getPrimaryInsuredAddressInfo);

  if (!addressInformation || selectedProductFromAddButton !== 'home') return null;

  return (
    <>
      <GridItem topSpacing='lg' xs={12} className={classes.productTitleSection}>
        <Box>
          <div className={classes.productIcon}>
            <IconCardHome />
          </div>
        </Box>
        <Typography className={classes.productTitle}>Home Policy</Typography>
      </GridItem>
      <Divider className={classes.divider} />
      <Grid container className={classes.containerDetails}>
        <GridItem xs={12} md={6}>
          <Typography className={classes.contentTitle}>
            {addressInformation.line1 && (
              <>
                <div>
                  {addressInformation.line1}
                  {addressInformation.line2 ? ` ${addressInformation.line2},` : ','}{' '}
                </div>
                <div>
                  {`${addressInformation.city}, `}
                  {addressInformation.state}
                  {` ${addressInformation.zipcode}`}
                </div>
              </>
            )}
          </Typography>
        </GridItem>
      </Grid>
    </>
  );
};

import { useCallback, useEffect } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';

import { GridItem, ReadOnlyField, Select } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import {
  DatePicker,
  RadioGroupWithOptions,
  TextField,
} from '@ecp/features/sales/shared/components';
import {
  getFullPniPersonInfo,
  getPrimaryInsuredPersonInfo,
  useField,
  useUpdateSniInsuredType,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, OptionProps } from '@ecp/features/sales/shared/types';
import { getProductDisplayName, type ProductName } from '@ecp/features/shared/product';
import type { Option } from '@ecp/types';

import { useGetSniFormFields } from '../../utils/secondaryNamedInsuredUtil';
import { useStyles } from './SecondaryNamedInsuredQuestions.styles';

export interface AddEditSecondaryNamedInsuredProps {
  secondaryInsuredQuestionKey: string;
  lob: ProductName;
  isAnotherPerson: boolean;
  sniPersonRef: string;
}

export const AddEditSecondaryNamedInsured: React.FC<AddEditSecondaryNamedInsuredProps> = (
  props,
) => {
  const { secondaryInsuredQuestionKey, lob, isAnotherPerson, sniPersonRef } = props;

  const { classes } = useStyles();
  const hasSecondaryNamedInsured = useField('static.hasSecondaryNamedInsured');
  const staticDateOfBirth = useField(`static.personPropertySNI.dob`);
  const secondaryInsuredPerson = useField(secondaryInsuredQuestionKey);
  const pniInfo = useSelector(getPrimaryInsuredPersonInfo);
  const updateSniInsuredType = useUpdateSniInsuredType(secondaryInsuredPerson.props.value);
  const productDisplayName = getProductDisplayName(lob);
  const trackingPrefix = isAnotherPerson
    ? `${productDisplayName}_add_new`
    : `${productDisplayName}_house_hold`;
  const staticSelectedSniNewlyAddedPersonRef = useField(`static.selected.sniNewlyAddedPerson.ref`);
  const sniFormFields = useGetSniFormFields(secondaryInsuredPerson.props.value);

  const {
    firstName,
    middleName,
    lastName,
    suffix,
    dateOfBirth,
    gender,
    married,
    occupantType,
    relationshipToApplicant,
  } = sniFormFields;

  const fieldsToValidate = {
    [`${firstName.key}`]: firstName,
    [`${middleName.key}`]: middleName,
    [`${lastName.key}`]: lastName,
    [`${suffix.key}`]: suffix,
    [`${dateOfBirth.key}`]: dateOfBirth,
    [`${gender.key}`]: gender,
    [`${married.key}`]: married,
    [`${occupantType.key}`]: occupantType,
    [`${relationshipToApplicant.key}`]: relationshipToApplicant,
  };

  const {
    props: { value: name = '' },
  } = firstName;

  const nameOrYour = name || 'this person';
  const validSpousalMaritalOptions = ['Married', 'Separated'];
  let relationshipStatusOptions = relationshipToApplicant.props.options;
  let maritalStatusOptions = married.props.options;

  const { maritalStatus: pniMaritalStatus } = useSelector(getFullPniPersonInfo); // pni marital status

  const getRelationshipStatusOptions = (): Option<string>[] | undefined => {
    if (!validSpousalMaritalOptions.includes(pniMaritalStatus)) {
      // If PNI is changed from married to unmarried, the relationship option for spouse disappears,
      // but we also need to clear that selection from the store or else it won't trigger the required flag
      if (relationshipToApplicant.value === 'RELATIONSHIP_TO_APPLICANT.SPOUSE') {
        relationshipToApplicant.props.actionOnComplete(null);
      }

      return (relationshipStatusOptions = relationshipToApplicant.props.options?.filter(
        (status) => status.value !== 'RELATIONSHIP_TO_APPLICANT.SPOUSE',
      ));
    }

    return relationshipStatusOptions;
  };

  const getMaritalStatusOptions = (): Option<string>[] | undefined => {
    if (relationshipToApplicant.value === 'RELATIONSHIP_TO_APPLICANT.SPOUSE') {
      return (maritalStatusOptions = married.props.options?.filter(
        (status) =>
          status.value === 'MARITAL_STATUS.MARRIED' || status.value === 'MARITAL_STATUS.SEPARATED',
      ));
    }

    return maritalStatusOptions;
  };

  useInitValues(
    lob === 'renters' ? { [occupantType.key]: 'OCCUPANT' } : { [occupantType.key]: null },
  );

  useAddFields(fieldsToValidate);

  const handleDOBOnComplete = useCallback(
    async (value: AnswerValue) => {
      if (value) {
        staticDateOfBirth.props.actionOnComplete(value);
        dateOfBirth.props.actionOnComplete(value);
      }
    },
    [dateOfBirth.props, staticDateOfBirth.props],
  );

  useEffect(() => {
    if (isAnotherPerson && sniPersonRef !== '') {
      updateSniInsuredType();
      secondaryInsuredPerson.props.actionOnComplete(sniPersonRef);
      staticSelectedSniNewlyAddedPersonRef.props.actionOnComplete(sniPersonRef);
    } else {
      updateSniInsuredType();
    }
  }, [
    isAnotherPerson,
    secondaryInsuredPerson.props,
    sniPersonRef,
    staticSelectedSniNewlyAddedPersonRef.props,
    updateSniInsuredType,
  ]);

  if (!hasSecondaryNamedInsured.value || !secondaryInsuredPerson.props.value) return null;

  return (
    <Grid className={classes.sniQuestionBox}>
      <Grid container>
        {isAnotherPerson && (
          <>
            <GridItem xs={12} md={6} className={classes.columnLeft}>
              <TextField
                {...firstName.props}
                id='SecondaryInsuredFirstName'
                label='First name'
                ariaLabel='Secondary insured first name'
                trackingName={`${trackingPrefix}_sni_first_name`}
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
            <GridItem xs={12} md={6} className={classes.columnRight}>
              <TextField
                {...middleName.props}
                id='SecondaryInsuredMiddleName'
                label='Middle name (optional)'
                ariaLabel='Secondary insured middle name'
                trackingName={`${trackingPrefix}_sni_middle_name`}
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
            <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
              <TextField
                {...lastName.props}
                id='SecondaryInsuredLastName'
                label='Last name'
                ariaLabel='Secondary insured last name'
                trackingName={`${trackingPrefix}_sni_last_name`}
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
            <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
              <Select
                {...suffix.props}
                id='SecondaryInsuredSuffix'
                data-testid='suffix'
                inputButtonAriaLabel='Suffix'
                label='Suffix (optional)'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
            <GridItem topSpacing='sm' xs={12}>
              <DatePicker
                {...dateOfBirth.props}
                value={
                  isMasked(dateOfBirth.props.value)
                    ? staticDateOfBirth.props.value
                    : dateOfBirth.props.value
                }
                hidePicker
                id='SecondaryInsuredDob'
                label='Date of birth'
                trackingName={`${trackingPrefix}_sni_dob`}
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                fullWidth={false}
                actionOnComplete={handleDOBOnComplete}
              />
            </GridItem>
            <GridItem topSpacing='sm' xs={12}>
              <RadioGroupWithOptions
                {...gender.props}
                id='SecondaryInsuredGender'
                label={`What is ${nameOrYour}'s gender?`}
                trackingName={`${trackingPrefix}_sni_gender`}
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
          </>
        )}
        <GridItem topSpacing='sm' xs={12}>
          <RadioGroupWithOptions
            {...(relationshipToApplicant.props as OptionProps)}
            id='SecondaryInsuredRelationship'
            className={classes.radioGroupWidth}
            data-testid='relationshipToApplicant'
            label={`What is ${nameOrYour}'s relationship to ${pniInfo.firstName} ${pniInfo.lastName}?`}
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
            cardSize='small'
            row={false}
            variant='radioCard'
            options={getRelationshipStatusOptions()}
          />
        </GridItem>
        <GridItem topSpacing='sm' xs={12}>
          <RadioGroupWithOptions
            {...(married.props as OptionProps)}
            id='SecondaryInsuredMaritalStatus'
            className={classes.radioGroupWidth}
            data-testid='married'
            label={`What is ${nameOrYour}'s marital status?`}
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
            cardSize='small'
            row={false}
            variant='radioCard'
            options={getMaritalStatusOptions()}
          />
        </GridItem>
        {lob !== 'renters' ? (
          <GridItem topSpacing='sm' xs={12}>
            <RadioGroupWithOptions
              {...occupantType.props}
              className={classes.radioGroupWidth}
              id='SecondaryInsuredoccupantType'
              data-testid='occupantType'
              label={`What type of occupant is ${nameOrYour}?`}
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              cardSize='small'
              row={false}
              variant='radioCard'
            />
          </GridItem>
        ) : (
          <GridItem topSpacing='sm' xs={12}>
            <ReadOnlyField
              label={`What type of occupant is ${nameOrYour}?`}
              value='Occupant'
              id='SecondaryInsuredoccupantType'
              data-testid='occupantType'
            />
          </GridItem>
        )}
      </Grid>
    </Grid>
  );
};

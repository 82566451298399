/* eslint-disable react/jsx-no-bind */
import type { ReactElement, ReactPropTypes } from 'react';
import { useCallback } from 'react';

import type { GridProps } from '@mui/material';
import { Grid } from '@mui/material';

import type { ButtonProps } from '@ecp/components';
import { GridItem } from '@ecp/components';
import { Button } from '@ecp/features/sales/shared/components';
import type { PageErrors } from '@ecp/features/sales/shared/types';
import { IconUIDelete, IconUIEdit, IconUIPlus } from '@ecp/themes/base';
import type { AnswerValue } from '@ecp/types';

import { useStyles } from './Resource.styles';

type ResourceButtonProps = ButtonProps & {
  analyticsElement?: string;
  analyticsEventDetail?: string;
};

export const ResourceDeleteButton: React.FC<ResourceButtonProps & { showButton?: boolean }> = (
  props,
) => {
  const { showButton = true, ...rest } = props;
  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  return showButton ? (
    <GridItem>
      <Button onMouseDown={handleMouseDown} {...rest} icon={<IconUIDelete />} />
    </GridItem>
  ) : (
    <GridItem> </GridItem>
  );
};

export const ResourceAddButton: React.FC<ResourceButtonProps> = (props) => {
  const { classes } = useStyles();
  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  return (
    <Button
      onMouseDown={handleMouseDown}
      {...props}
      className={props.className ? props.className : classes.outlineButton}
      icon={<IconUIPlus />}
    />
  );
};

export const ResourceCancelButton: React.FC<ResourceButtonProps> = (props) => {
  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  return <Button onMouseDown={handleMouseDown} {...props} />;
};

export const ResourceSaveButton: React.FC<ResourceButtonProps> = (props) => {
  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  return <Button {...props} onMouseDown={handleMouseDown} variant='primary' />;
};

type ItemProps = {
  title: string;
  value: AnswerValue;
};

export const ResourceSummaryItem: React.FC<ItemProps & GridProps> = (props) => {
  const { title, value, ...rest } = props;
  const { classes } = useStyles();

  return (
    <GridItem xs={2} {...rest}>
      <GridItem className={classes.headerText}>{title}</GridItem>
      <GridItem className={classes.itemText}>{value || ' '}</GridItem>
    </GridItem>
  );
};

export interface ResourceFormProps {
  itemRef: string;
  onNext: () => void;
  onCancel: (id: string) => void;
  onRemove: (id: string) => void;
}

type ResourceSummaryListProps = {
  items: string[];
  pageErrors: PageErrors[];
  renderListItem: (item: string) => React.ReactElement | null;
  editItemRef?: string;
  hideEdit?: (id: string) => boolean;
  hideDelete?: (id: string) => boolean;
  onAdd?: (id: string) => void;
  onEdit?: (id: string) => void;
  onDelete: (id: string) => void;
  onCancel: (id: string) => void;
  onNext: () => void;
  form: (props: ResourceFormProps) => ReactElement;
  additionalFormProps?: Record<string, keyof ReactPropTypes>;
  editIconText?: string;
  deleteIconText?: string;
  showEditForm?: boolean;
  listTitle?: JSX.Element;
};

export const ResourceSummaryList: React.FC<ResourceSummaryListProps> = (props) => {
  const {
    form,
    editItemRef,
    hideEdit,
    hideDelete,
    items,
    onEdit,
    onCancel,
    onNext,
    onDelete,
    renderListItem,
    pageErrors,
    editIconText,
    deleteIconText,
    showEditForm,
    listTitle,
  } = props;
  const { classes, cx } = useStyles();

  /*
  For some resource display (Itemized Personal Property for Advance Home), the new item
  added is not part of the same resource list that gets displayed. Use showEditForm to
  show the edit form irrespective of whether the item is on the list or not.
  */

  return (
    <>
      {showEditForm && (
        <Grid
          container
          key={`resourcelist-summaryitem-${editItemRef}`}
          className={cx([
            classes.container,
            { [classes.summaryError]: !!pageErrors.find((err) => err.id === editItemRef) },
          ])}
        >
          {form({ itemRef: editItemRef || '', onNext, onCancel, onRemove: onDelete })}
        </Grid>
      )}
      {listTitle && items.length > 0 && listTitle}
      {items.map((item) => {
        const hasError = pageErrors.find((err) => err.id === item);

        return editItemRef === item ? (
          <Grid
            container
            key={`resourcelist-summaryitem-${item}`}
            className={cx([classes.container, { [classes.summaryError]: !!hasError }])}
          >
            {form({ itemRef: item, onNext, onCancel, onRemove: onDelete })}
          </Grid>
        ) : (
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            key={`resourcelist-summaryitem-${item}`}
            className={cx([classes.container, { [classes.summaryError]: !!hasError }])}
          >
            {/* Warning: Failed prop type: The prop `xs` of `Grid` can only be used together with the `item` prop. */}
            <Grid container item justifyContent='space-between' xs={10.5}>
              {renderListItem(item)}
            </Grid>
            <Grid container item justifyContent='space-between' xs={1.5}>
              {!!onEdit && !hideEdit?.(item) && (
                <GridItem width='50%'>
                  <Button
                    variant='iconTextExtraLarge'
                    icon={<IconUIEdit />}
                    title={editIconText ?? `Edit-${item}`}
                    className={classes.actionButton}
                    onClick={() => onEdit(item)}
                  />
                </GridItem>
              )}
              {!!onDelete && !hideDelete?.(item) && (
                <GridItem width='50%'>
                  <Button
                    variant='iconTextExtraLarge'
                    icon={<IconUIDelete />}
                    title={deleteIconText ?? `Remove-${item}`}
                    className={classes.actionButton}
                    onClick={() => onDelete(item)}
                  />
                </GridItem>
              )}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

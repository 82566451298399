import { useCallback, useEffect, useState } from 'react';

import { useField, useFieldWithPrefix, usePniRef } from '@ecp/features/sales/shared/store';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AllCoveragesFields } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { EMAIL_QUOTES_RECEPIENT_EMAIL } from '../constants';
import type { AFIEmailData, AFIEmailQuoteResponse, AnalyticsElement } from '../types';
import { postAFIEmailQuotesToRecipient, postEmailQuotesToRecipient } from './api';
import { getDataForCoveragesPage, getDataForQuotesPage } from './selectors';

type PostEmailQuotesToRecipientReturnType = ReturnType<typeof postEmailQuotesToRecipient>;

interface Args {
  /**
   * TODO Currently this argument tells us on which page email quotes event was triggered.
   * It has two applications - we send this string value to sapi analytics and determine how to aggregate data.
   * Data aggregation logic must be unaware of the page we're on.
   * Remove this argument after rewriting data aggregation logic.
   */
  analyticsElement: AnalyticsElement;
  fields?: AllCoveragesFields;
}

const sendEmailQuotesToRecipient =
  (args: Args): ThunkAction<PostEmailQuotesToRecipientReturnType> =>
  (...[, getState]) => {
    const { analyticsElement, fields } = args;

    const selector =
      analyticsElement === 'choice.quoteSummaryPage.emailQuotesSendButton'
        ? getDataForQuotesPage
        : getDataForCoveragesPage(fields);
    const emailData = selector(getState());

    return postEmailQuotesToRecipient(emailData, analyticsElement);
  };

export const useEmailQuotes = (): {
  handleSendEmail: (args: Args) => PostEmailQuotesToRecipientReturnType | undefined;
  isProcessing: boolean;
  recipientEmail: Field;
} => {
  const recipientEmail = useField(EMAIL_QUOTES_RECEPIENT_EMAIL);
  const pniRef = usePniRef();
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const pniEmail = usePersonField('email');

  // Use PNI email address if recipient email address has not been set
  useEffect(() => {
    if (!recipientEmail.props.value) {
      recipientEmail.props.actionOnComplete(pniEmail.props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState(false);

  const handleSendEmail = useCallback(
    async (args: Args) => {
      if (!isProcessing && !recipientEmail.props.error) {
        setIsProcessing(true);
        const response = await dispatch(sendEmailQuotesToRecipient(args));
        setIsProcessing(false);

        return response;
      }

      return undefined;
    },
    [dispatch, isProcessing, recipientEmail.props.error],
  );

  return { handleSendEmail, isProcessing, recipientEmail };
};

export const useAFIEmailQuotes = (): {
  handleSendEmail: (emailData: AFIEmailData) => Promise<AFIEmailQuoteResponse | undefined>;
} => {
  const recipientEmail = useField(EMAIL_QUOTES_RECEPIENT_EMAIL);
  const pniRef = usePniRef();
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const pniEmail = usePersonField('email');

  // Use PNI email address if recipient email address has not been set
  useEffect(() => {
    if (!recipientEmail.props.value) {
      recipientEmail.props.actionOnComplete(pniEmail.props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isProcessing, setIsProcessing] = useState(false);

  const handleSendEmail = useCallback(
    async (emailData: AFIEmailData) => {
      // TODO: Add analyticsElement
      if (!isProcessing && !recipientEmail.props.error) {
        setIsProcessing(true);
        const response = await postAFIEmailQuotesToRecipient(emailData);
        setIsProcessing(false);

        return response;
      }

      return undefined;
    },
    [isProcessing, recipientEmail.props.error],
  );

  return { handleSendEmail };
};

import { useCallback, useEffect, useMemo, useState } from 'react';

import { CircularProgress, Grid } from '@mui/material';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { isEmpty, isMasked } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';
import { useBackAndForwardNav } from '@ecp/utils/routing';
import { scrollToTop } from '@ecp/utils/web';

import { Alert, Snackbar } from '@ecp/components';
import { useRemoveByFieldsCallback } from '@ecp/features/sales/form';
import {
  checkAndUpdatePrefillVehicles,
  checkDuplicateVIN,
  DriverType,
  GARAGE_ADDRESS_REF_SUFFIX,
  useAddVehicle,
  useDefaultVehicleAddressRef,
  useRemoveUnusedRefsForVehicle,
  useUpdateVehiclesAndDrivers,
  useVehicleRef,
  useVehiclesFields,
  useVehiclesForSidebar,
} from '@ecp/features/sales/quotes/auto';
import {
  Button,
  Dialog,
  Form,
  NextPageInstructions,
  Resource,
} from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import {
  fetchInquiry,
  getCustomerId,
  getDriverRefs,
  getDrivers,
  getVehicleRefs,
  getVehiclesInfoByRef,
  isAnyApiInProgress,
  updatePageStatus,
  useField,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { PageErrors } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { goToFirstError, useLoading } from '@ecp/features/sales/shared/utils/web';
import type { AnswerValue, Field, Fields } from '@ecp/types';

import { defaultAnnualMileage } from '../../constants';
import { AddEditVehicleForm } from '../AddEditVehicleForm';
import type { VehicleFormSnapshot, VehiclesPageProps } from './types';
import { useGetDriverAssignmentFields, useGetLineItems, useResetAllItems } from './utils';
import { useStyles } from './VehiclesPageForm.styles';

export const VehiclesPageForm: React.FC<VehiclesPageProps> = ({
  onNext,
  nextPageInstructions,
  hideNavButtons = false,
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [fetchingInquiry, setFetchingInquiry] = useState(true);
  const addVehicle = useAddVehicle();
  const [maskRequiredFieldError, setMaskRequiredFieldError] = useState(true);
  const [vehicleId, setVehicleId] = useState<string | undefined>(undefined);
  const removeByFields = useRemoveByFieldsCallback();
  const currentVehicleRef = useVehicleRef(vehicleId) || ''; // returns last vehicle added and calls addVehicle if no vehicles exist
  const keptAtRiskAddress = useField(`${currentVehicleRef}.keptAtRiskAddress`);
  const garageAddressRef = useField(`${currentVehicleRef}.${GARAGE_ADDRESS_REF_SUFFIX}`);
  const vehicleItems = useSelector(getVehiclesInfoByRef);
  const vehicleRefs = useSelector(getVehicleRefs);
  const allVehicleFields = useVehiclesFields(vehicleRefs);
  const drivers = useSelector(getDrivers);
  const driverRefs = useSelector(getDriverRefs);
  const { updateVehiclesAndDrivers } = useUpdateVehiclesAndDrivers();
  const removeUnusedVehicleRef = useRemoveUnusedRefsForVehicle();
  const [pageErrors, setPageErrors] = useState<PageErrors[]>([]);
  const [removeVehicleRef, setRemoveVehicleRef] = useState('');
  const [snackbarDefaultMessage, setSnackbarDefaultMessage] = useState('');
  const [vehicleDataBeforeEdit, setVehicleDataBeforeEdit] = useState<
    VehicleFormSnapshot | undefined
  >();

  // Make sure not to run any action while previous one is still in flight, like multiple clicks on delete
  const shouldWait = useSelector(isAnyApiInProgress);
  const { isLoading, withLoading } = useLoading(shouldWait);

  const vehiclesValues = useMemo((): string[] => {
    return vehicleRefs.reduce<string[]>((preValues, ref) => {
      return [...preValues, ref];
    }, []);
  }, [vehicleRefs]);

  const vehicles = useVehiclesForSidebar();
  const driverAssignmentFields = useGetDriverAssignmentFields(vehicles, DriverType.PRIMARY);
  const occasionalDriverAssignmentFields = useGetDriverAssignmentFields(
    vehicles,
    DriverType.OCCASIONAL,
  );
  const lineItems = useGetLineItems(
    driverAssignmentFields,
    occasionalDriverAssignmentFields,
    drivers,
    vehicles,
  );
  const resetAssignments = useResetAllItems(lineItems);
  const customerId = useSelector(getCustomerId);
  const addressRef = useDefaultVehicleAddressRef();

  const checkIncompleteVehicles = useCallback(async () => {
    setPageErrors([]);
    const errors: PageErrors[] = [];
    const vehicles = allVehicleFields;

    Object.values(vehicleItems).forEach((vehicle) => {
      const { ref = '', description } = vehicle;

      const hasDuplicateVin = checkDuplicateVIN(vehicles, vehicle.vin, ref);
      if (hasDuplicateVin) {
        errors.push({
          message: `${description} has a duplicate VIN.`,
          id: ref,
        });
      }

      // We need to stop a user from continuing forward if the vin is masked but isnt equal to 17 chars
      if (isMasked(vehicle.vin) && vehicle.vin?.length !== 17) {
        errors.push({
          message: `${description}: VIN is incomplete.`,
          id: ref,
        });
      }
    });
    // Need to verify vehicles from prefill are updated with vehicleDetailId and garaged address values set
    const prefillErrors = await checkAndUpdatePrefillVehicles(
      vehicles,
      vehicleRefs,
      dispatch,
      customerId,
      addressRef,
    );
    const concatenatedErrors = errors.concat(prefillErrors);
    setPageErrors(concatenatedErrors);

    return concatenatedErrors.length > 0;
    // we don't want to re-render for vehicleQuestions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleItems, classes, dispatch]);

  // TODO: I moved the logic from old page but not sure if we'll need it
  // Old page had incomplete status on card option
  // Keeping for now until further UX discussions
  const alertErrors = pageErrors.map((error) => {
    return (
      error.message && (
        <Alert className={classes.error} type='error' key={error.id}>
          <p>{error.message}</p>
        </Alert>
      )
    );
  });

  const toggleEditVehicle = useCallback(
    (vehicleRef: string) => {
      // Don't allow edit click of another vehicle while form is up or loading
      if (isProcessing || isLoading || vehicleId) return;
      setIsProcessing(true);
      setIsEditMode(true);
      setVehicleId(vehicleRef);
      setIsProcessing(false);
    },
    [isLoading, isProcessing, vehicleId],
  );

  const handleSaveVehicle = useCallback(() => {
    // Reset Assignment when a vehicle is added but not in case of any edit and save
    if (vehicleId && !isEditMode) {
      resetAssignments();
    }

    setVehicleId(undefined);
  }, [isEditMode, resetAssignments, vehicleId]);

  // getNonDefaultValues for vehicle page form
  const getNonDefaultValues = useCallback(
    (vehicleRef: string, fields: Fields): AnswerValue[] => {
      const nonDefaultedValues = [
        fields.vin?.value,
        fields.year?.value,
        fields.make?.value,
        fields.model?.value,
        fields.series?.value,
        fields?.[`${vehicleRef}.features.safety.airbag.driver`]?.value,
        fields?.[`${vehicleRef}.features.safety.airbag.passenger`]?.value,
        fields?.[`${vehicleRef}.features.safety.airbag.side`]?.value,
        fields?.[`${vehicleRef}.features.safety.antiLockBrakes`]?.value,
        fields?.[`${vehicleRef}.features.safety.dayTimeRunningLights`]?.value,
        fields?.[`${vehicleRef}.features.safety.electronicStabilityControl`]?.value,
      ];
      const defaultedValues = [
        fields.annualMiles?.value !== defaultAnnualMileage,
        fields.kitCarReplica?.value, // boolean, false is default
        fields.coOwnsVehicle?.value, // boolean, false is default
        fields.antiTheftActive?.value, // boolean, false is default
        fields.antiTheftPassive?.value, // boolean, false is default
        !fields.safety?.value, // boolean, true is default
        fields.primaryUse?.value !== 'VEHICLE_USE.PRIMARY.COMMUTE', // string, 'VEHICLE_USE.PRIMARY.COMMUTE' is default
        garageAddressRef?.value !== vehicleDataBeforeEdit?.selectedGarageRef, // string, defaulted in AddEditVehicleForm
      ];
      const nonDefaultedNonNullValue = nonDefaultedValues.filter(
        (value): value is AnswerValue => value != null,
      );
      const defaultedValuesChanged = defaultedValues.filter(
        (value): value is AnswerValue => value !== false,
      );

      return nonDefaultedNonNullValue.concat(defaultedValuesChanged);
    },
    [garageAddressRef?.value, vehicleDataBeforeEdit?.selectedGarageRef],
  );

  const handleDeleteVehicle = withLoading<string>(
    async (vehicleRef: string, vehicleFields?: Fields) => {
      let showConfirmation = true;

      if (vehicleFields) {
        const nonDefaultValues = getNonDefaultValues(vehicleRef, vehicleFields as Fields);

        // Show confirmation modal if any non-defaulted fields have values
        showConfirmation = nonDefaultValues.length > 0;
      }

      trackClick({ action: 'delete_vehicle_button', label: vehicleRef });

      trackSapiAnalyticsEvent({
        element: 'choice.reviewVehicleAndDriverPage.DeleteVehicleButton',
        event: 'click',
        eventDetail: vehicleRef,
      });

      if (showConfirmation) {
        setRemoveVehicleRef(vehicleRef);
      } else {
        await removeUnusedVehicleRef(vehicleRef);
        // Reset vehicle assignments when a vehicle is deleted.
        resetAssignments();
        // Close form
        setVehicleId(undefined);
      }
    },
  );

  // TODO: answers are patched on change of the fields, there is not backing out changes on an edit; discuss with IAN
  const handleCancelVehicle = useEvent(async (vehicleRef: string, vehicleFields?: Fields) => {
    // Helper function to validate, update, and patch fields
    const validateUpdateAndPatchField = (field: Field | undefined, value: AnswerValue): void => {
      if (field) {
        field.validateUpdateAndPatch(value);
      }
    };
    const {
      vin,
      year,
      make,
      model,
      series,
      primaryUse,
      vehicleComprehensive,
      coOwnsVehicle,
      kitCarReplica,
      keptAtRiskAddress,
      annualMiles,
      antiTheftActive,
      antiTheftPassive,
      safety,
    } = vehicleFields as Fields;
    if (!isEditMode) {
      // Get non-default values
      const nonDefaultValues = getNonDefaultValues(vehicleRef, vehicleFields as Fields);

      // show confirmation modal, on cancel, if any non-defaulted fields have values
      const showConfirmation = nonDefaultValues.length > 0;

      if (showConfirmation) {
        handleDeleteVehicle(vehicleRef);
      } else {
        await removeUnusedVehicleRef(vehicleRef);
        setRemoveVehicleRef('');
        // Close form
        setVehicleId(undefined);
      }
    } else {
      // Close form if in edit mode
      setVehicleId(undefined);

      // cancel functionality for each vehicle
      // reseting the vehicle values when user click on cancel even after edited the vehicle values.
      if (!isEmpty(vehicleFields)) {
        validateUpdateAndPatchField(vin as Field, vehicleDataBeforeEdit?.vin);
        validateUpdateAndPatchField(year as Field, vehicleDataBeforeEdit?.year);
        validateUpdateAndPatchField(make as Field, vehicleDataBeforeEdit?.make);
        validateUpdateAndPatchField(model as Field, vehicleDataBeforeEdit?.model);
        validateUpdateAndPatchField(series as Field, vehicleDataBeforeEdit?.series);
        validateUpdateAndPatchField(primaryUse as Field, vehicleDataBeforeEdit?.primaryUse);
        validateUpdateAndPatchField(
          vehicleComprehensive as Field,
          vehicleDataBeforeEdit?.vehicleComprehensive,
        );
        validateUpdateAndPatchField(coOwnsVehicle as Field, vehicleDataBeforeEdit?.coOwnsVehicle);
        validateUpdateAndPatchField(kitCarReplica as Field, vehicleDataBeforeEdit?.kitCarReplica);
        validateUpdateAndPatchField(annualMiles as Field, vehicleDataBeforeEdit?.annualMiles);
        validateUpdateAndPatchField(
          antiTheftActive as Field,
          vehicleDataBeforeEdit?.antiTheftActive,
        );
        validateUpdateAndPatchField(
          antiTheftPassive as Field,
          vehicleDataBeforeEdit?.antiTheftPassive,
        );
        validateUpdateAndPatchField(
          keptAtRiskAddress as Field,
          vehicleDataBeforeEdit?.keptAtRiskAddress,
        );
        validateUpdateAndPatchField(
          vehicleFields?.[`${vehicleRef}.features.safety.airbag.driver`] as Field,
          vehicleDataBeforeEdit?.airbagDriver,
        );
        validateUpdateAndPatchField(
          vehicleFields?.[`${vehicleRef}.features.safety.airbag.passenger`] as Field,
          vehicleDataBeforeEdit?.airbagPassenger,
        );
        validateUpdateAndPatchField(
          vehicleFields?.[`${vehicleRef}.features.safety.airbag.side`] as Field,
          vehicleDataBeforeEdit?.airbagSide,
        );
        validateUpdateAndPatchField(
          vehicleFields?.[`${vehicleRef}.features.safety.antiLockBrakes`] as Field,
          vehicleDataBeforeEdit?.antiLockBrakes,
        );
        validateUpdateAndPatchField(
          vehicleFields?.[`${vehicleRef}.features.safety.dayTimeRunningLights`] as Field,
          vehicleDataBeforeEdit?.dayTimeRunningLights,
        );
        validateUpdateAndPatchField(
          vehicleFields?.[`${vehicleRef}.features.safety.electronicStabilityControl`] as Field,
          vehicleDataBeforeEdit?.electronicStabilityControl,
        );
        validateUpdateAndPatchField(safety as Field, vehicleDataBeforeEdit?.safetyFeatures);

        validateUpdateAndPatchField(
          garageAddressRef as Field,
          vehicleDataBeforeEdit?.selectedGarageRef,
        );
      }
    }
  });

  const saveVehicles = useCallback(async () => {
    setIsSubmitting(true);
    if (await checkIncompleteVehicles()) {
      scrollToTop();
      setIsSubmitting(false);

      return;
    }
    if (vehiclesValues.length > 0) {
      await updateVehiclesAndDrivers(vehiclesValues, driverRefs, [], []);
      dispatch(updatePageStatus(NavStatus.VALID));
      if (onNext) {
        await onNext();
      }
    } else {
      dispatch(updatePageStatus(NavStatus.INVALID));
      goToFirstError();
    }
    setIsSubmitting(false);
    // we don't want to re-render for driverQuestions
  }, [
    checkIncompleteVehicles,
    vehiclesValues,
    updateVehiclesAndDrivers,
    driverRefs,
    dispatch,
    onNext,
  ]);

  const handleAddVehicle = useCallback(async () => {
    setIsProcessing(true);
    trackSapiAnalyticsEvent({
      element: 'choice.reviewVehicleAndDriverPage.AddAnotherVehicleButton',
      event: 'click',
      eventDetail: 'true',
    });

    // This avoids showing previous vehicle info in dialog
    const newVehicle = addVehicle();
    const { vehicleRef } = newVehicle;
    await newVehicle.done;

    keptAtRiskAddress.reset();
    trackClick({ action: 'add_vehicle_link', label: vehicleRef });
    setVehicleId(vehicleRef);
    setIsProcessing(false);
    setIsEditMode(false); // adding a new vehicle so it's not an edit
  }, [addVehicle, keptAtRiskAddress]);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  // On back navigation remove ref for vehicle and delete all answers related to the new ref.
  // Since for every new vehicle we initialize the page with new vehicle ref and patch it as answers.
  // We will lose all data for that particular vehicle (as per the requirements)
  useBackAndForwardNav(async () => {
    if (!isEditMode && vehicleId) await removeUnusedVehicleRef(vehicleId);
  });

  // Controls the whether the edit vehicle form is open
  useEffect(() => {
    if (!vehicleId) {
      if (!vehicleRefs.length) {
        setVehicleId(vehiclesValues[0]); // preexisting check
      } else {
        // if the vehicle refs exists, and no vehicle has been saved
        if (!vehicleItems[vehicleRefs[vehicleRefs.length - 1]]?.year) {
          // check if there is a value for year of the latest vehcicle and if no year, open the form
          setVehicleId(vehicleRefs[vehicleRefs.length - 1]);
        }
      }
    }
  }, [vehicleId, vehicleRefs, vehiclesValues, vehicleItems]);

  useEffect(() => {
    dispatch(fetchInquiry({})).then(() => {
      setFetchingInquiry(false);
    });
  }, [dispatch]);

  const renderVehicleItem = useCallback(
    (item: string) => (
      <>
        <Resource.Item
          xs={4}
          title='Year, make, model, and series'
          value={`${vehicleItems[item]?.year ?? ''} ${vehicleItems[item]?.make ?? ''} ${
            vehicleItems[item]?.model ?? ''
          }`}
        />
        <Resource.Item xs={3} title='VIN' value={vehicleItems[item]?.vin} />
        <Resource.Item
          xs={2}
          title='Annual miles'
          value={
            vehicleItems[item]?.annualMiles
              ? (+vehicleItems[item].annualMiles).toLocaleString()
              : ''
          }
        />
      </>
    ),
    [vehicleItems],
  );

  const onCloseDialogAction = useCallback(() => {
    setRemoveVehicleRef('');
  }, []);

  const removedVehicleInfo = vehicleItems[removeVehicleRef];
  const getRemovedVehicleName = (removedVehicleInfo?: {
    year?: string;
    make?: string;
    model?: string;
  }): string => {
    if (removedVehicleInfo?.year && removedVehicleInfo?.make && removedVehicleInfo?.model) {
      return `${removedVehicleInfo.year} ${removedVehicleInfo.make} ${removedVehicleInfo.model}`;
    }

    return 'vehicle';
  };

  // Helper function to capitalize the first letter of a string
  const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const removedVehicleName = getRemovedVehicleName(removedVehicleInfo);
  const onRemoveDialogAction = useCallback(async () => {
    const snackBarMessage = `${removedVehicleName} deleted.`;

    await removeUnusedVehicleRef(removeVehicleRef);

    // EDSP-18567 Need to remove safety feature fields,
    // otherwise they are being validated when you add a new vehicle
    removeByFields({
      [`${allVehicleFields[removeVehicleRef]['safety'].key}`]:
        allVehicleFields[removeVehicleRef]['safety'],
      [`${allVehicleFields[removeVehicleRef]['safetyAntiLockBrakes'].key}`]:
        allVehicleFields[removeVehicleRef]['safetyAntiLockBrakes'],
      [`${allVehicleFields[removeVehicleRef]['driverSafetyAirbag'].key}`]:
        allVehicleFields[removeVehicleRef]['driverSafetyAirbag'],
      [`${allVehicleFields[removeVehicleRef]['passengerSafetyAirbag'].key}`]:
        allVehicleFields[removeVehicleRef]['passengerSafetyAirbag'],
      [`${allVehicleFields[removeVehicleRef]['sideSafetyAirbag'].key}`]:
        allVehicleFields[removeVehicleRef]['sideSafetyAirbag'],
      [`${allVehicleFields[removeVehicleRef]['safetyDayTimeRunningLights'].key}`]:
        allVehicleFields[removeVehicleRef]['safetyDayTimeRunningLights'],
      [`${allVehicleFields[removeVehicleRef]['safetyElectronicStabilityControl'].key}`]:
        allVehicleFields[removeVehicleRef]['safetyElectronicStabilityControl'],
    });

    setRemoveVehicleRef('');
    resetAssignments();

    // close form
    setVehicleId(undefined);
    setSnackbarDefaultMessage(capitalizeFirstLetter(snackBarMessage));
  }, [
    removedVehicleName,
    removeUnusedVehicleRef,
    removeVehicleRef,
    removeByFields,
    allVehicleFields,
    resetAssignments,
  ]);

  const removeVehicleDialog = removeVehicleRef && (
    <Dialog
      actionButtonLabel='Delete vehicle'
      titleText={`Delete ${removedVehicleName}?`}
      textButtonLabel='Cancel'
      open
      onClose={onCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onRemoveDialogAction}
      hideTitleCloseButton
      hideDivider
    >
      <p>{`Deleting the ${removedVehicleName} will discard all associated content.`}</p>
    </Dialog>
  );

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setSnackbarDefaultMessage('');
      }
    },
    [],
  );

  const snackbarDefault = (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={!!snackbarDefaultMessage}
      autoHideDuration={3000}
      message={snackbarDefaultMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  return (
    <div className={classes.root}>
      {removeVehicleDialog}
      {snackbarDefault}
      {isLoading || (isProcessing && <CircularProgress />)}
      <Form showBackdrop={isSubmitting || isProcessing}>
        {alertErrors}
        <Resource.List
          items={vehicleRefs}
          pageErrors={pageErrors}
          editItemRef={vehicleId}
          onEdit={toggleEditVehicle}
          onDelete={handleDeleteVehicle}
          onCancel={handleCancelVehicle}
          onNext={handleSaveVehicle}
          // eslint-disable-next-line react/jsx-no-bind
          form={(formProps) => (
            <AddEditVehicleForm
              {...formProps}
              setVehicleDataBeforeEdit={setVehicleDataBeforeEdit}
              isEdit={isEditMode}
              maskRequiredFieldError={maskRequiredFieldError}
              setMaskRequiredFieldError={setMaskRequiredFieldError}
            />
          )}
          renderListItem={renderVehicleItem}
        />
        <Resource.AddButton
          onClick={handleAddVehicle}
          data-testid='autoVehicleAdd'
          isProcessing={isProcessing}
          trackingName={GoogleAnalyticsLabels.CONTINUE}
          trackingLabel='vehicle_details_page_continue'
          analyticsElement='choice.vehicleDetailsPage.continueButton'
          disabled={!!vehicleId || isLoading}
          className={classes.addAnotherButton}
        >
          Add another vehicle
        </Resource.AddButton>
        {!hideNavButtons && (
          <Grid container>
            <>
              <Grid item xs={12}>
                {!fetchingInquiry && (
                  <NextPageInstructions divider>{nextPageInstructions}</NextPageInstructions>
                )}
              </Grid>
              <Grid className={classes.buttonsPanel} container item xs={12}>
                <div className={classes.addButton}>
                  <Button
                    className={classes.next}
                    variant='primary'
                    onClick={saveVehicles}
                    data-testid='autoVehicleContinue'
                    // This stops the onBlur from firing only when this button is clicked
                    // and the button retains its coordinates for the mouseup to eventually
                    // register it as a click event. If you want the onBlur as well, you can
                    // fire it yourself from the onClick handler. Touch should keep working.
                    onMouseDown={handleMouseDown}
                    isProcessing={isSubmitting}
                    trackingName={GoogleAnalyticsLabels.CONTINUE}
                    trackingLabel='vehicle_details_page_continue'
                    analyticsElement='choice.vehicleDetailsPage.continueButton'
                    disabled={!!vehicleId || fetchingInquiry} // disable continue while form group is up
                  >
                    Continue
                  </Button>
                </div>
              </Grid>
            </>
          </Grid>
        )}
      </Form>
    </div>
  );
};

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PrimarySecondaryButtons' })(() => ({
  continueButton: {
    textAlign: 'center',
    width: '100%',
  },
  clearButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
  },
  clearButton: {
    display: 'flex',
    textAlign: 'center',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

import { useCallback, useRef } from 'react';

import { Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { useForm } from '@ecp/features/sales/shared/store';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import {
  FinishedBasementLivingAreaQuestion,
  FullBathQuestion,
  HeatingSystemQuestion,
  HomeUnderConstructionQuestion,
  LivingAreaQuestion,
  RenovationDocumentProvidedIndQuestion,
  RenovationInLast15YearsQuestion,
  RenovationYearQuestion,
  WashingMachineLocationQuestion,
} from '@ecp/sales/lob/property';

import { PlumbingReplacedYearQuestion, WiringReplacedYearQuestion } from '../../components';
import { useStyles } from './HomeInteriorPageForm.styles';

export interface HomeInteriorProps {
  onNext: () => Promise<void>;
}

export const HomeInteriorPageForm: React.FC<HomeInteriorProps> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const conditions = useGetConditionValues();

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: conditions(),
  });

  const handleSubmit = useCallback(async () => {
    if (validateForm().isValid) {
      await patchFormValues();
      trackSapiAnalyticsEvent({
        element: 'choice.homeInteriorPage.saveAndContinueButton',
        event: 'click',
        eventDetail: 'true',
      });
      await onNext();
    }
  }, [validateForm, patchFormValues, onNext]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <GridItem topSpacing='lg' xs={12} data-testid='fullBathSection'>
            <FullBathQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <FinishedBasementLivingAreaQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <LivingAreaQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <RenovationInLast15YearsQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <RenovationYearQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <RenovationDocumentProvidedIndQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12} data-testid='homeUnderConstructionSection'>
            <HomeUnderConstructionQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12} data-testid='washingMachineLocationSection'>
            <WashingMachineLocationQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12} data-testid='plumbingReplacedYearSection'>
            <PlumbingReplacedYearQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12} data-testid='wiringReplacedYearQuestionSection'>
            <WiringReplacedYearQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12} data-testid='primaryHeatingSystemSection'>
            <HeatingSystemQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <Divider />
          </GridItem>
          <GridItem topSpacing='lg' xs={12}>
            <h3>Thanks! This finishes the details on the inside of the home.</h3>
          </GridItem>
          <Grid container item xs={12}>
            <Button
              id='submit'
              variant='primary'
              onClick={handleSubmit}
              data-testid='showMyDiscounts'
              isProcessing={isPatchFormInProgress}
              classes={{ root: classes.nextButton }}
              className={classes.next}
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='home_interior_continue'
            >
              Save & continue
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

import { useCallback } from 'react';

import { getReadOnlyDisplayValue, ReadOnlyField } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  getDrivers,
  getField,
  getPrimaryInsuredPersonRef,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';

interface Props extends QuestionProps {
  personRef: string;
  readOnly?: boolean;
}

export const MaritalStatusQuestion: React.FC<Props> = (props) => {
  const {
    personRef,
    label,
    id = 'MaritalStatus',
    trackingName = 'marital_status',
    trackingLabel,
    readOnly = false,
    groupLabel,
  } = props;
  const dispatch = useDispatch();
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const maritalStatus = usePersonField('married');
  const drivers = useSelector(getDrivers);
  const primaryInsuredPersonRef = useSelector(getPrimaryInsuredPersonRef);
  const getFieldByKey = useSelector(
    (state: RootStore) => (key: string) => getField(state, { key, dispatch }),
  );
  const relationshipToApplicant = usePersonField('relationshipToApplicant');
  const maritalStatusChange = useCallback(
    (value: string) => {
      maritalStatus.props.actionOnChange(value);
      if (
        personRef === primaryInsuredPersonRef &&
        !['MARITAL_STATUS.MARRIED', 'MARITAL_STATUS.SEPARATED'].includes(value)
      ) {
        drivers.forEach((driver) => {
          const driverRelationship = driver.relationshipToApplicant;
          const driverPerson = driver.personRef;
          const driverPersonRelationshipToPNI = getFieldByKey(
            `${driverPerson}.relationshipToApplicant`,
          );
          // If PNI is changed from married to unmarried
          // the relationship option for spouse disappears for each other person,
          // but we also need to clear that selection from the store
          if (driverRelationship === 'RELATIONSHIP_TO_APPLICANT.SPOUSE') {
            driverPersonRelationshipToPNI.validateUpdateAndPatch('');
          }
        });
      }
    },
    [drivers, getFieldByKey, maritalStatus.props, personRef, primaryInsuredPersonRef],
  );

  useAddFields({ maritalStatus });
  let maritalStatusOptions = maritalStatus.props.options;
  if (relationshipToApplicant.value === 'RELATIONSHIP_TO_APPLICANT.SPOUSE') {
    maritalStatusOptions = maritalStatus.props.options?.filter(
      (status) =>
        status.value === 'MARITAL_STATUS.MARRIED' || status.value === 'MARITAL_STATUS.SEPARATED',
    );
  }

  if (!maritalStatus.exists) return null;

  if (readOnly) {
    return (
      <ReadOnlyField
        label={label || ''}
        value={getReadOnlyDisplayValue(maritalStatus)}
        id='maritalStatus'
      />
    );
  }

  return (
    <Select
      {...(maritalStatus.props as OptionProps)}
      label={label}
      fullWidth={false}
      id={id}
      inputButtonAriaLabel='Marital Status'
      trackingName={trackingName}
      groupLabel={groupLabel}
      trackingLabel={trackingLabel}
      actionOnChange={maritalStatusChange}
      options={maritalStatusOptions}
    />
  );
};

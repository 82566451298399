import { TooltipWithIcon } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

interface Props extends QuestionProps {
  driverRef: string;
  nameOrYou?: string;
  labelOverride?: string;
  variant?: 'yesNoButton' | 'yesNoRadioCard';
}

const FINANCIAL_HELP_TEXT =
  'A financial responsibility filing provides proof of liability coverage as required by law in your state.';

export const DriverFinancialResponsibilityQuestion: React.FC<Props> = (props) => {
  const {
    driverRef,
    nameOrYou,
    trackingName = 'financial_responsibility_question',
    trackingLabel,
    labelOverride,
    variant = 'yesNoButton',
  } = props;
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const needFinancialForm = useDriverField('sr22FinancialRespFiling');

  useInitValues({ [needFinancialForm.key]: false });
  useAddFields({ needFinancialForm });

  if (!needFinancialForm.exists) return null;
  const isYesNoRadioCard = variant === 'yesNoRadioCard';

  return (
    <RadioGroupWithOptions
      id='financialResponsibilityForm'
      {...needFinancialForm.props}
      label={
        <>
          {labelOverride
            ? labelOverride
            : `${nameOrYou} need a financial responsibility (SR-22 or FR-44) form?`}
          <TooltipWithIcon title={FINANCIAL_HELP_TEXT} />
        </>
      }
      adjustableGrid={isYesNoRadioCard}
      adjustableGridProps={isYesNoRadioCard ? { forceNumItemsPerRow: 2 } : undefined}
      variant={variant}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
    />
  );
};

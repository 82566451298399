import { useCallback, useState } from 'react';

import { addTpiResult } from '../api/tpiAdd';
import { fetchTpiResults } from '../api/tpiSearch';
import type { AddTPIProps, SearchTPIInputFields, UseThirdPartyInterestRetrunType } from '../types';

export const useThirdPartyInterest = (): UseThirdPartyInterestRetrunType => {
  const [isSearching, setIsSearching] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const searchTPI = useCallback(
    async ({ searchType, companyName, city, state, product }: SearchTPIInputFields) => {
      setIsSearching(true);
      const result = await fetchTpiResults({
        toggleValue: searchType,
        searchCompanyName: companyName,
        searchCityName: city,
        searchStateName: state,
        product,
      });
      setIsSearching(false);

      return result;
    },
    [],
  );

  const addUncontrolledTPI = useCallback(async (props: AddTPIProps) => {
    setIsAdding(true);
    await addTpiResult({ ...props });

    setIsAdding(false);
  }, []);

  return {
    searchTPI,
    addUncontrolledTPI,
    isSearching,
    isAdding,
  };
};

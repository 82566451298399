import {
  AdditionalInsuredCoverage,
  AdditionalPremisesCoverage,
  CareFacilityCoverage,
  DockCollapseCoverage,
  DwellingCosmeticHailCoverage,
  DwellingUndamagedVinylCoverage,
  EarthquakeCoverage,
  EarthquakeLossCoverage,
  EquipmentBreakdownCoverage,
  HiddenWaterCoverage,
  IdentityFraudExpenseCoverage,
  InlandFloodCoverage,
  ItemizedPersonalPropertyCoverage,
  JewelryCoverage,
  LandlordCoverage,
  LivestockCoverage,
  NamedPerilIncreasedLimitCoverage,
  PersonalInjuryCoverage,
  PetCoverage,
  RenovationCoverage,
  RentedFarmlandCoverage,
  RoofReplacementCostCoverage,
  ScheduledStructuresOffPremiseCoverage,
  ServiceLineCoverage,
  WaterBackupCoverage,
} from '@ecp/sales/lob/property';
import type { OptionsMetadata } from '@ecp/types';

import { PropertyPolicyCoverageGroup } from '../../../utils';

export const PropertyPolicyCoveragesMeta: { [productKey: string]: OptionsMetadata } = {
  'amfam-adv.home': {
    'sectionOneDeductibles.otherPerils': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.DEDUCTIBLES,
      subCoverages: {
        'sectionOneDeductibles.otherPerils': {
          title: 'Property Deductible',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'sectionOneDeductibles.windHail': {
          title: 'Windstorm Or Hail Deductible',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'dwelling.limit': {
      title: 'Coverage A - Dwelling',
      parentGroup: PropertyPolicyCoverageGroup.COVERAGESFORDWELLING,
      subCoverages: {
        'dwelling.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'TextField',
            displayAsDollarAmount: true,
          },
        },
        'dwelling.replacementCost': {
          title: 'Percentage (% ) of Replacement Cost',
          displayInfo: {
            displayElement: 'ReadOnly',
            displayAsPercentage: true,
          },
        },
        'dwelling.valuation': {
          title: 'Valuation Method',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
        'dwelling.perils': {
          title: 'Covered Perils',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'otherStructuresBlanket.limit': {
      title: 'Coverage B - Other Structures Blanket',
      parentGroup: PropertyPolicyCoverageGroup.COVERAGESFORDWELLING,
      subCoverages: {
        'otherStructuresBlanket.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'TextField',
            displayAsDollarAmount: true,
          },
        },
        'otherStructuresBlanket.valuation': {
          title: 'Valuation Method',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'personalProperty.limit': {
      title: 'Coverage C - Personal Property',
      // primaryText: `Personal Property or Possessions coverage covers your belongings if they're stolen or damaged in the event of a covered loss.`,
      // secondaryText: `This includes clothing, TVs, appliances, furniture, etc.`,
      parentGroup: PropertyPolicyCoverageGroup.COVERAGESFORDWELLING,
      subCoverages: {
        'personalProperty.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'TextField',
            displayAsDollarAmount: true,
          },
        },
        'personalProperty.otherLimit': {
          title: 'Percentage(%)  of Coverage A - Dwelling Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'personalProperty.valuation': {
          title: 'Valuation Method',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'personalProperty.perils': {
          title: 'Covered Perils',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'lossOfUse.dwellingLimit': {
      title: 'Coverage D - Loss Of Use',
      parentGroup: PropertyPolicyCoverageGroup.COVERAGESFORDWELLING,
      subCoverages: {
        'lossOfUse.dwellingLimit': {
          title: 'Percentage (%) of Coverage A - Dwelling Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'lossOfUse.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
            displayAsDollarAmount: true,
          },
        },
      },
    },
    'personalLiability.limit': {
      title: 'Coverage E - Personal Liability',
      parentGroup: PropertyPolicyCoverageGroup.EXTENDEDPROTECTION,
      subCoverages: {
        'personalLiability.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'medical.limit': {
      title: 'Coverage F - Medical Expense',
      parentGroup: PropertyPolicyCoverageGroup.EXTENDEDPROTECTION,
      subCoverages: {
        'medical.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'animalLiability.limit': {
      title: 'Dangerous Dog and Exotic Animal Liability',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'animalLiability.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'fireService.limit': {
      title: 'Fire Department Service Charge',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'fireService.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'moldCoverage.sectionILimit': {
      title: 'Fungi Or Bacteria',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'moldCoverage.sectionILimit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'additionalDwelling.limit': {
      title: 'Increased Dwelling Limit',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'additionalDwelling.limit': {
          title: 'Limit',
          secondaryText: 'Additional Percentage for Coverage A Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'lossAssessment.limit': {
      title: 'Loss Assessments',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'lossAssessment.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'ordinanceOrLaw.limit': {
      title: 'Ordinance Or Law',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'ordinanceOrLaw.limit': {
          title: 'Limit',
          secondaryText: 'As a Percentage for Coverage A Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
      stateOptions: {
        CO: {
          subCoverages: {
            'ordinanceOrLaw.limit': {
              title: 'Limit',
              secondaryText: 'As a Percentage for Coverage A Limit',
              displayInfo: {
                displayElement: 'ReadOnly',
              },
            },
          },
        },
        MN: {
          subCoverages: {
            'ordinanceOrLaw.limit': {
              title: 'Limit',
              secondaryText: 'As a Percentage for Coverage A Limit',
              displayInfo: {
                displayElement: 'ReadOnly',
              },
            },
          },
        },
        WI: {
          subCoverages: {
            'ordinanceOrLaw.limit': {
              title: 'Limit',
              secondaryText: 'As a Percentage for Coverage A Limit',
              displayInfo: {
                displayElement: 'ReadOnly',
              },
            },
          },
        },
      },
    },
    'replacementCost.selected': {
      title: 'Personal Property Replacement Cost',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'replacementCost.selected': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'pollutionCleanup.limit': {
      title: 'Pollutant Cleanup And Removal',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'pollutionCleanup.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'diminishing.selected': {
      title: 'Diminishing Deductible',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'equipmentBreakdown.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.home.coverages.equipmentBreakdown.premiumAmount',
      displayInfo: {
        displayElement: <EquipmentBreakdownCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'identityFraudExpense.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.home.coverages.identityFraudExpense.premiumAmount',
      displayInfo: {
        displayElement: <IdentityFraudExpenseCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'itemizedPersonalProperty.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.home.coverages.itemizedPersonalProperty.premiumAmount',
      displayInfo: {
        displayElement: <ItemizedPersonalPropertyCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'jewelry.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      displayInfo: {
        displayElement: <JewelryCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'waterBackup.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.WATERPROTECTION,
      displayInfo: {
        displayElement: <WaterBackupCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'personalInjury.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      displayInfo: {
        displayElement: <PersonalInjuryCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'serviceLine.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.home.coverages.serviceLine.premiumAmount',
      displayInfo: {
        displayElement: <ServiceLineCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'wildfireDefenseSystem.selected': {
      title: 'Wildfire Defense',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      subCoverages: {
        'wildfireDefenseSystem.selected': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'outsideWaterSource.limit': {
      title: 'Water Coverage from an Outside Water Source',
      parentGroup: PropertyPolicyCoverageGroup.WATERPROTECTION,
      subCoverages: {
        'outsideWaterSource.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
            displayAsDollarAmount: true,
          },
        },
      },
    },
    'hiddenWater.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.WATERPROTECTION,
      displayInfo: {
        displayElement: <HiddenWaterCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'inlandFlood.selected': {
      title: 'Inland Flood',
      parentGroup: PropertyPolicyCoverageGroup.WATERPROTECTION,
      displayInfo: {
        displayElement: <InlandFloodCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'specialEvents.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.home.coverages.specialEvents.premiumAmount',
      displayInfo: {
        displayElement: <AdditionalInsuredCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'additionalPremises.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.home.coverages.additionalPremises.premiumAmount',
      displayInfo: {
        displayElement: <AdditionalPremisesCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'renovation.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <RenovationCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'freezeHotTub.selected': {
      title: 'Freezing of Whirlpool, Hot Tub, or Spa',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'earthquake.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <EarthquakeCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'earthquakeLoss.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <EarthquakeLossCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'landlord.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <LandlordCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'livestock.selected': {
      title: 'Limited Livestock',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <LivestockCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'careFacility.selected': {
      title: 'Nursing/Assisted Living Facility Personal Property and Liability',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <CareFacilityCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'namedPerilIncreasedLimit.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.home.coverages.namedPerilIncreasedLimit.premiumAmount',
      displayInfo: {
        displayElement: <NamedPerilIncreasedLimitCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'pet.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <PetCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'rentedFarmland.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.home.coverages.rentedFarmland.premiumAmount',
      displayInfo: {
        displayElement: <RentedFarmlandCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'scheduledStructuresOffPremise.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.home.coverages.scheduledStructuresOffPremise.premiumAmount',
      displayInfo: {
        displayElement: (
          <ScheduledStructuresOffPremiseCoverage carrierName='amfam-adv' product='home' />
        ),
      },
    },
    'dwellingCosmeticHail.selected': {
      title: 'Dwelling Cosmetic Hail Damage for Metal Roof or Sliding',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <DwellingCosmeticHailCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'roofReplacementCost.selected': {
      title: 'Roof Replacement Cost Coverage for Windstorm and Hail',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      displayInfo: {
        displayElement: <RoofReplacementCostCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'dockCollapse.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <DockCollapseCoverage carrierName='amfam-adv' product='home' />,
      },
    },
    'undamagedSidingMatch.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <DwellingUndamagedVinylCoverage carrierName='amfam-adv' product='home' />,
      },
    },
  },
  'amfam-adv.renters': {
    'personalLiability.limit': {
      title: 'Coverage E - Personal Liability',
      parentGroup: PropertyPolicyCoverageGroup.EXTENDEDPROTECTION,
      subCoverages: {
        'personalLiability.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'medical.limit': {
      title: 'Coverage F - Medical Expense',
      parentGroup: PropertyPolicyCoverageGroup.EXTENDEDPROTECTION,
      subCoverages: {
        'medical.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'personalProperty.limit': {
      title: 'Coverage C - Personal Property',
      // primaryText: `Personal Property or Possessions coverage covers your belongings if they're stolen or damaged in the event of a covered loss.`,
      // secondaryText: `This includes clothing, TVs, appliances, furniture, etc.`,
      parentGroup: PropertyPolicyCoverageGroup.COVERAGESFORDWELLING,
      subCoverages: {
        'personalProperty.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'TextField',
            displayAsDollarAmount: true,
          },
        },
        'personalProperty.valuation': {
          title: 'Valuation Method',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'personalProperty.increaseLimit': {
          title: 'Renewal increase',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'personalProperty.perils': {
          title: 'Covered Perils',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'lossOfUse.limit': {
      title: 'Coverage D - Loss Of Use',
      parentGroup: PropertyPolicyCoverageGroup.COVERAGESFORDWELLING,
      subCoverages: {
        'lossOfUse.propertyLimit': {
          title: 'Percentage (%) of Coverage C - Personal Property',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'lossOfUse.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
            displayAsDollarAmount: true,
          },
        },
      },
    },
    'sectionOneDeductibles.otherPerils': {
      title: 'Property Deductible',
      primaryText: 'Property Deductible',
      secondaryText: '',
      parentGroup: PropertyPolicyCoverageGroup.DEDUCTIBLES,
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'buildingAdditions.limit': {
      title: 'Building Additions And Alterations',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'buildingAdditions.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'TextField',
            displayAsDollarAmount: true,
          },
        },
      },
    },
    'animalLiability.limit': {
      title: 'Dangerous Dog and Exotic Animal Liability',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'animalLiability.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
            displayAsDollarAmount: true,
          },
        },
      },
    },
    'fireService.limit': {
      title: 'Fire Department Service Charge',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'fireService.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'lossAssessment.limit': {
      title: 'Loss Assessments',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'lossAssessment.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'moldCoverage.sectionILimit': {
      title: 'Fungi Or Bacteria',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'moldCoverage.sectionILimit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'equipmentBreakdown.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.renters.coverages.equipmentBreakdown.premiumAmount',
      displayInfo: {
        displayElement: <EquipmentBreakdownCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'identityFraudExpense.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.renters.coverages.identityFraudExpense.premiumAmount',
      displayInfo: {
        displayElement: <IdentityFraudExpenseCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'itemizedPersonalProperty.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.renters.coverages.itemizedPersonalProperty.premiumAmount',
      displayInfo: {
        displayElement: (
          <ItemizedPersonalPropertyCoverage carrierName='amfam-adv' product='renters' />
        ),
      },
    },
    'jewelry.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      displayInfo: {
        displayElement: <JewelryCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'outsideWaterSource.limit': {
      title: 'Water Coverage From an Outside Water Source',
      parentGroup: PropertyPolicyCoverageGroup.WATERPROTECTION,
      subCoverages: {
        'outsideWaterSource.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
            displayAsDollarAmount: true,
          },
        },
      },
    },
    'specialEvents.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.renters.coverages.specialEvents.premiumAmount',
      displayInfo: {
        displayElement: <AdditionalInsuredCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'additionalPremises.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.renters.coverages.additionalPremises.premiumAmount',
      displayInfo: {
        displayElement: <AdditionalPremisesCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'freezeHotTub.selected': {
      title: 'Freezing of Whirlpool, Hot Tub, or Spa',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'earthquake.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <EarthquakeCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'earthquakeLoss.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <EarthquakeLossCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'namedPerilIncreasedLimit.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.renters.coverages.namedPerilIncreasedLimit.premiumAmount',
      displayInfo: {
        displayElement: (
          <NamedPerilIncreasedLimitCoverage carrierName='amfam-adv' product='renters' />
        ),
      },
    },
    'pet.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <PetCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'rentedFarmland.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.renters.coverages.rentedFarmland.premiumAmount',
      displayInfo: {
        displayElement: <RentedFarmlandCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'scheduledStructuresOffPremise.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.renters.coverages.scheduledStructuresOffPremise.premiumAmount',
      displayInfo: {
        displayElement: (
          <ScheduledStructuresOffPremiseCoverage carrierName='amfam-adv' product='renters' />
        ),
      },
    },
    'sinkholeCollapse.selected': {
      title: 'Sinkhole Collapse',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'replacementCost.selected': {
      title: 'Personal Property Replacement Cost',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'replacementCost.selected': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'pollutionCleanup.limit': {
      title: 'Pollutant Cleanup And Removal',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'pollutionCleanup.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'waterBackup.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.WATERPROTECTION,
      displayInfo: {
        displayElement: <WaterBackupCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'personalInjury.selected': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      displayInfo: {
        displayElement: <PersonalInjuryCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'diminishing.selected': {
      title: 'Diminishing Deductible',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'inlandFlood.selected': {
      title: 'Inland Flood',
      parentGroup: PropertyPolicyCoverageGroup.WATERPROTECTION,
      displayInfo: {
        displayElement: <InlandFloodCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'livestock.selected': {
      title: 'Limited Livestock',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <LivestockCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'careFacility.selected': {
      title: 'Nursing/Assisted Living Facility Personal Property and Liability',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
      displayInfo: {
        displayElement: <CareFacilityCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
  },
};

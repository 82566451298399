import { useCallback, useRef, useState } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { useEvent } from '@ecp/utils/react';

import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import type {
  DriversLicenseNumberAndState,
  ValidateVinResult,
} from '@ecp/features/sales/quotes/auto';
import {
  useValidateDriverLicenseNumberForMutipleDrivers,
  useValidateVin,
} from '@ecp/features/sales/quotes/auto';
import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import {
  getDeltaField,
  getDrivers,
  getField,
  getIsBundleForOfferProductsSelected,
  getOfferSetId,
  getPLPCQuoteNumber,
  getVehicles,
  submitDelta,
  updateAnswers,
  useForm,
  useRouteToPLPC,
  useValidateCostcoMembershipField,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Driver, Vehicle } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { type AutoProduct, LineOfBusiness, type ProductName } from '@ecp/features/shared/product';
import { PolicyCenterDialog } from '@ecp/sales/lob/property';

import { useShouldRouteToPLPC, useUpdatedAnswers } from '../../utils';
import { AutoDeltaPageFormQuestions } from './AutoDeltaFormQuestions';
import { useStyles } from './AutoDeltaPageForm.styles';

export interface Props {
  onNext: () => Promise<void>;
  autoProduct: AutoProduct;
  policyType: ProductName;
}

export const AutoDeltaPageForm: React.FC<Props> = (props) => {
  const { onNext, autoProduct, policyType } = props;
  const { classes } = useStyles();
  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const conditions = useGetConditionValues();
  const dispatch = useDispatch();

  // Vehicle Data
  const vehicles: Vehicle[] = useSelector(getVehicles);
  // Driver Data
  const drivers: Driver[] = useSelector(getDrivers);

  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const validateDriverLicenseNumbers = useValidateDriverLicenseNumberForMutipleDrivers();
  const validateVinNum = useValidateVin();
  const valdiateCostcoMembershipField = useValidateCostcoMembershipField();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: conditions(),
  });
  const offerSetId = useSelector(getOfferSetId);
  const getVehicleDeltaVin = useSelector((state: RootStore) => (vehicleRef: string) => {
    const key = getDeltaField(state, vehicleRef, 'vin');

    return getField(state, { key, dispatch });
  });

  // Start - Route to PLPC section
  const [canOpenDialog, setCanOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const plpcQuoteNumber = useSelector((state: RootStore) => getPLPCQuoteNumber(state, policyType));
  // operations to perform routing to PLPC
  const routeToPLPC = useRouteToPLPC();
  // check if quote should be routed to PLPC
  const shouldRouteToPLPC = useShouldRouteToPLPC();
  // flip answers back to defaults before route to PLPC
  const updatedAnswers = useUpdatedAnswers(policyType);

  const handleRouteToPLPCDialogAction = useCallback(async () => {
    if (!plpcQuoteNumber) {
      setCanOpenDialog(false);

      return;
    }
    await dispatch(updateAnswers({ answers: updatedAnswers }));
    await routeToPLPC(setIsSubmitting, LineOfBusiness.AUTO, plpcQuoteNumber, true);
  }, [dispatch, plpcQuoteNumber, routeToPLPC, updatedAnswers]);

  const handleCancelButtonOnClick = useCallback(async () => {
    setCanOpenDialog(false);

    return;
  }, []);
  // End - Route to PLPC section

  // We need to loop through vehicles once again on the delta page to make sure we have valid vins
  // for all of them before letting a user continue onwards because of scenarios where a user does
  // not blur out of a vin field and clicks continue resulting in the user being able to proceed
  // further because vin validation does not occur.
  const vinValidation = useEvent(async () => {
    const results: ReturnType<typeof validateVinNum>[] = [];
    vehicles.forEach(async (singleVehicle: Vehicle) => {
      const vin = getVehicleDeltaVin(singleVehicle.ref);
      const value = vin.value;

      // only validate vehicles that aren't masked
      if (value && !isMasked(value)) {
        results.push(validateVinNum(`${value}`, singleVehicle));
      }
    });
    const response = await Promise.all(results);
    // if any value in the array isnt an empty string will mean there is a validation error
    // and we must stop user from continuing.
    const hasVinError = response.some((res: ValidateVinResult) => res.vinErr !== '');

    return hasVinError;
  });

  const handleSubmit = useEvent(async () => {
    if (validateForm().isValid) {
      setIsSubmitInProgress(true);

      const isDriverLicenseNumberValid = await validateDriverLicenseNumbers(
        drivers as unknown as DriversLicenseNumberAndState,
      );

      if (!isDriverLicenseNumberValid) {
        setIsSubmitInProgress(false);

        return;
      }

      if (valdiateCostcoMembershipField()) {
        setIsSubmitInProgress(false);

        return;
      }

      const hasVinValidationErrors = await vinValidation();
      if (hasVinValidationErrors) {
        setIsSubmitInProgress(false);

        return;
      }

      await patchFormValues();

      // check if quote should be routed to PLPC once the form is valid
      if (shouldRouteToPLPC(policyType)) {
        setCanOpenDialog(true);

        return;
      }
      if (isBundle) {
        if (!offerSetId) {
          datadogLog({
            logType: 'error',
            message: 'offerSetId missing',
            context: {
              logOrigin:
                'apps/sales/edsp-asp/src/lob/autoLine/forms/AutoDeltaForm/AutoDeltaForm.tsx',
              functionOrigin: 'handleSubmit',
            },
          });
          throw new Error('offerSetId missing');
        }
        await dispatch(submitDelta({ policyTypes: [policyType] }));
      }

      trackSapiAnalyticsEvent({
        element: 'choice.autoDeltaPage.continueButton',
        event: 'click',
        eventDetail: 'true',
      });

      await onNext();
      setIsSubmitInProgress(false);
    }
  });

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress || isSubmitInProgress}>
        <AutoDeltaPageFormQuestions
          drivers={drivers}
          vehicles={vehicles}
          product={autoProduct}
          policyType={policyType}
        />
        <NextPageInstructions divider>
          Thanks. Next you’ll see your updated quote.
        </NextPageInstructions>
        <div className={classes.carrierButtons}>
          <Button
            variant='primary'
            onClick={handleSubmit}
            isProcessing={isPatchFormInProgress}
            disabled={isPatchFormInProgress}
            trackingName={GoogleAnalyticsLabels.CONTINUE}
            trackingLabel='auto_delta_continue'
            data-testid='continue'
            analyticsElement='choice.autoDeltaPage.continueButton'
          >
            Save & Continue
          </Button>
        </div>
      </Form>
      {canOpenDialog && (
        <PolicyCenterDialog
          dialogStat={canOpenDialog}
          dialogSubmit={handleRouteToPLPCDialogAction}
          isProcessing={isSubmitting}
          toggleDialog={handleCancelButtonOnClick}
        />
      )}
    </div>
  );
};

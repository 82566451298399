import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldSelectComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './EarthquakeLossCoverage.styles';

export const EarthquakeLossCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();
  const [earthquakeLossCoverageField, limitField, deductibleField] = useGroupedCoverageFields(
    props.carrierName,
    props.product,
    'earthquakeLoss',
    ['selected', 'limit', 'deductible'],
  );
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(earthquakeLossCoverageField, [
    limitField,
    deductibleField,
  ]);

  const handleEarthquakeLossCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );
  if (!earthquakeLossCoverageField.exists) return null;

  const isEarthquakeLossCoverageSelected = castToBoolean(earthquakeLossCoverageField.value);

  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='earthquakeLossCoverage'
          field={earthquakeLossCoverageField}
          isCoverageSelected={isEarthquakeLossCoverageSelected}
          handleCoverageChange={handleEarthquakeLossCoverageChange}
        />
        {isEarthquakeLossCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            <CoverageFieldSelectComponent field={limitField} />
            <CoverageFieldSelectComponent field={deductibleField} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

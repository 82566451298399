import {
  GraphicAutoCoverageAccidentalDeathAndDismembermentImageUrl,
  GraphicAutoCoverageBodilyInjuryImageUrl,
  GraphicAutoCoverageCollisionImageUrl,
  GraphicAutoCoverageComprehensiveImageUrl,
  GraphicAutoCoverageDamageInjuryImageUrl,
  GraphicAutoCoverageFirstPartyBenefitsImageUrl,
  GraphicAutoCoverageMedicalExpenseImageUrl,
  GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
  GraphicAutoCoveragePropertyDamageImageUrl,
  GraphicAutoCoverageRentalReimbursementImageUrl,
  GraphicAutoCoverageSupplementalSpousalLiabilityImageUrl,
  GraphicAutoCoverageTortOptionImageUrl,
  GraphicAutoCoverageTotalDisabilityImageUrl,
  GraphicAutoCoverageUnderinsuredUninsuredMotoristImageUrl,
  GraphicAutoCoverageUninsuredMotoristImageUrl,
  GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
  GraphicAutoCoverageUninsuredMotoristPropertyDamageLimitImageUrl,
} from '@ecp/themes/base';
import type { OptionsMetadata } from '@ecp/types';

import { PersonalInjuryProtectionMetadata } from '../PersonalInjuryProtection';

// !TODO Replace the signature with Record<Product & '${string}.auto', OptionsMetadata>
// metadata for vehicle deductible card on coverage page
export const CarrierVehicleDeductibleMetadata: { [productKey: string]: OptionsMetadata } = {
  'amfam-adv.auto': {},
  'amfam.auto': {
    comprehensive: {
      primaryImage: GraphicAutoCoverageComprehensiveImageUrl,
      title: 'Comprehensive Coverage',
      primaryText:
        'May cover damages to your covered auto not caused by a collision with another vehicle. Covered incidents such as theft, vandalism, fire, windshield replacement, hail or hitting an animal.',
      secondaryText:
        'It also pays for damage to your vehicle caused by natural disasters such as fire and flood. For older vehicles with low cash value, you might consider rejecting or selecting a higher deductible.',
      subCoverages: {
        comprehensiveGlassDeductible: {
          title: 'Full Glass Deductible',
        },
      },
      stateOptions: {
        VA: {
          title: 'Other Than Collision',
        },
        MA: {
          subCoverages: {
            comprehensiveGlassDeductible: {
              title: 'Glass deductible',
              primaryText:
                'In cases of glass breakage, the selected deductible will apply. In cases where there is glass breakage and other damage covered by Comprehensive coverage (Part 9), both deductibles will apply.',
            },
          },
        },
      },
    },
    collision: {
      primaryImage: GraphicAutoCoverageCollisionImageUrl,
      refFieldKey: 'comprehensive',
      title: 'Collision Coverage',
      primaryText:
        'May cover damages to your vehicle if it collides with another vehicle or object in a covered loss. Insurance will not pay for repairs or a replacement unless you have Collision Coverage.',
      secondaryText:
        'For older vehicles with low cash value, you might consider rejecting this coverage or selecting a higher deductible.',
      subCoverages: {
        collisionWaiver: {
          title: 'Deductible Waiver',
        },
        collisionOption: {
          title: 'Collision Options',
          primaryText:
            'Coverage pays to repair your car if it’s damaged, up to the coverage limit. With Standard you are covered regardless of fault and a deductible may apply. With Broad Form you pay a deductible only when you are more then 50% at fault. Limited Collision policies are not available online, please contact us if you’d like this coverage.',
        },
      },
      stateOptions: {
        MA: {
          subCoverages: {
            collisionWaiver: {
              title: 'Deductible Waiver',
              primaryText:
                'Buying collision coverage with a deductible waiver means that the collision deductible you choose will not apply when an accident is caused by another identifiable driver.',
            },
          },
        },
      },
    },
  },
  'connect.auto': {
    Comprehensive: {
      primaryImage: GraphicAutoCoverageComprehensiveImageUrl,
      title: 'Comprehensive Coverage',
      primaryText:
        'May cover damages to your covered auto not caused by a collision with another vehicle. Covered incidents such as theft, vandalism, fire, windshield replacement, hail or hitting an animal.',
      secondaryText:
        'It also may pay for damage to your vehicle caused by natural disasters such as fire and flood. For older vehicles with low cash value, you might consider rejecting or selecting a higher deductible.',
      stateOptions: {
        KY: {
          primaryText:
            'May cover damages to your covered auto not caused by a collision with another vehicle. Covered incidents such as theft, vandalism, fire, windshield replacement, hail or hitting an animal. Full glass maybe covered without a deductible.',
        },
      },
    },
    Collision: {
      primaryImage: GraphicAutoCoverageCollisionImageUrl,
      title: 'Collision Coverage',
      primaryText:
        'May cover damages to your vehicle if it collides with another vehicle or object in a covered loss. Insurance will not pay for repairs or a replacement unless you have Collision Coverage.',
      secondaryText:
        'For older vehicles with low cash value, you might consider rejecting this coverage or selecting a higher deductible.',
    },
  },
};

// metadata for auto policy coverage card on coverage page
export const CarrierAutoPolicyMetadata: { [productKey: string]: OptionsMetadata } = {
  'amfam-adv.auto': {
    'bodilyInjury.limit': {
      title: 'Bodily Injury Liability Coverage',
      primaryText:
        'Coverage may pay damages owed to others as a result of physical injuries, sickness, disease or death of any person for which you are legally liable as a result of a covered accident.',
      secondaryText: 'Per Person / Per Occurrence',
      limitText: 'Per Person Limit / Per Occurrence Limit',
      hasLimits: true,
      premiumAmountNode: 'amfam-adv.auto.coverages.policy.bodilyInjury.premiumAmount',
      subCoverages: {
        'bodilyInjury.limit': {
          title: 'Per Person Limit / Per Occurrence Limit',
          primaryText:
            'Coverage may pay damages owed to others as a result of physical injuries, sickness, disease or death of any person for which you are legally liable as a result of a covered accident.',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'propertyDamage.limit': {
      title: 'Property Damage Liability',
      primaryText:
        'Coverage may pay for damage to other people’s property resulting from an accident caused by your vehicle for which you are legally responsible and is covered under your policy.',
      secondaryText: 'Per Occurrence',
      limitText: 'Limit',
      premiumAmountNode: 'amfam-adv.auto.coverages.policy.propertyDamage.premiumAmount',
      subCoverages: {
        'propertyDamage.limit': {
          title: 'Per Occurrence Limit',
          primaryText:
            'Coverage may pay for damage to other people’s property resulting from an accident caused by your vehicle for which you are legally responsible and is covered under your policy.',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'medical.limit': {
      title: 'Medical Expense',
      primaryText:
        'Coverage may pay for expenses for all passengers in the insured vehicle for medical or funeral expenses resulting from a covered vehicle related accident.',
      secondaryText: 'Each Person',
      displayInfo: {
        displayElement: 'DropDown',
      },
      premiumAmountNode: 'amfam-adv.auto.coverages.policy.medical.premiumAmount',
      subCoverages: {
        'medical.limit': {
          title: 'Each Person Limit',
          primaryText:
            'Coverage may pay for damage to other people’s property resulting from an accident caused by your vehicle for which you are legally responsible and is covered under your policy.',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'medical.deductible': {
          title: 'Deductible',
          primaryText: 'Deductible Amount paid for medical coverage',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    /**
     * SAPI has pipBasic, pipExcess as common keys for all 7 states but PLPC has different keys for each
     *   state appended with state codes Ex: PAPipCovKS, PAPipCovMN.
     * SAPI sends pipBasic, so to identify state-specific PLPC key from SAPI we're appending state code
     *   from coverageOption.
     */
    'pipBasicKs.option': {
      title: 'Personal Injury Protection Limit',
      primaryText:
        'Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.',
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'pipBasicMn.option': {
      title: 'Personal Injury Protection',
      subCoverages: {
        'pipBasicMn.option': {
          title: 'Limit',
          primaryText:
            'Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'pipBasicMn.stacking': {
          title: 'PIP Stacking',
          primaryText: 'PIP Stacking',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'pipBasicMn.workLoss': {
          title: 'Exclude Work Loss',
          primaryText: 'Exclude Work Loss',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'pipBasicOr.option': {
      title: 'Personal Injury Protection Limit',
      primaryText:
        'Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.',
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'pipBasicNd.option': {
      title: 'Personal Injury Protection Limit',
      primaryText:
        'Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.',
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'pipBasicUt.option': {
      title: 'Personal Injury Protection',
      subCoverages: {
        'pipBasicUt.option': {
          title: 'Limit',
          primaryText:
            'Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'pipBasicUt.excludeWorkLoss': {
          title: 'Exclude Work Loss',
          primaryText: 'PIP Exclude Work Loss',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'pipBasicWa.option': {
      title: 'Personal Injury Protection Limit',
      primaryText:
        'Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.',
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'totalDisability.text': {
      title: 'Total Disability Coverage',
      displayInfo: {
        displayElement: 'TextField',
      },
    },
    'underInsuredMotoristBi.limit': {
      title: 'Underinsured Motorist Bodily Injury Liability',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who does not have sufficient liability insurance limits to cover the full amount of a covered loss.',
      limitText: 'Per Person Limit / Per Occurrence Limit',
      secondaryText: 'Per Person / Per Occurrence',
      hasLimits: true,
      displayInfo: {
        displayElement: 'DropDown',
      },
      premiumAmountNode: 'amfam-adv.auto.coverages.policy.underInsuredMotoristBi.premiumAmount',
    },
    'uninsuredMotoristBi.limit': {
      title: 'Uninsured Motorist Bodily Injury Liability',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who did not have liability. Applies to damages for you as well as passengers in your vehicle for a covered loss.',
      limitText: 'Per Person Limit / Per Occurrence Limit',
      secondaryText: 'Per Person / Per Occurrence',
      hasLimits: true,
      displayInfo: {
        displayElement: 'DropDown',
      },
      premiumAmountNode: 'amfam-adv.auto.coverages.policy.uninsuredMotoristBi.premiumAmount',
    },
    'uninsuredUnderInsuredCombined.limit': {
      title: 'Uninsured/Under Motorist Bodily Injury Combined',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who did not have liability. Applies to damages for you as well as passengers in your vehicle for a covered loss.',
      secondaryText: 'Per Person / Per Occurrence',
      limitText: 'Per Person Limit / Per Occurrence Limit',
      hasLimits: true,
      displayInfo: {
        displayElement: 'DropDown',
      },
      premiumAmountNode:
        'amfam-adv.auto.coverages.policy.uninsuredUnderInsuredCombined.premiumAmount',
    },
    'accidentalDeath.limit': {
      title: 'Accidental Death & Dismemberment',
      primaryText:
        'This coverage may provide benefits if an insured person is killed or injured while occupying or when struck by a land motor vehicle during a covered loss.',
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'accidentForgiveness.selected': {
      title: 'Accident Forgiveness (Purchased) Coverage',
      primaryText: 'Accident Forgiveness (Purchased) Coverage Text.',
      displayInfo: {
        displayElement: 'Checkbox',
      },
    },
    'diminishing.amount': {
      title: 'Diminishing Deductible',
      primaryText: 'Diminishing Deductible Coverage Text.',
      displayInfo: {
        displayElement: 'TextField',
      },
    },
  },
  'amfam.auto': {
    bodilyInjury: {
      primaryImage: GraphicAutoCoverageBodilyInjuryImageUrl,
      title: 'Bodily Injury Limit',
      primaryText:
        'Coverage may pay damages owed to others as a result of physical injuries, sickness, disease or death of any person for which you are legally liable as a result of a covered accident.',
      secondaryText:
        'It covers legal defense if another party in the accident files a lawsuit against you. The first amount shown is the coverage limit per person; the second amount is the coverage limit per accident.',
      subCoverages: {
        bodilyInjuryPunitiveDamage: {
          title: 'Punitive Damages',
          primaryText:
            'Punitive damages are awarded because of aggravating circumstances in order to penalize, punish, or deter a defendant. They may be awarded only in such tort actions in which it is proven by clear and convincing evidence that the defendant’s actions showed willful misconduct, malice, fraud, wantonness, oppression, or that entire want of care which would raise the presumption of conscious indifference to consequences.',
        },
        bodilyInjuryIncreasedFamilyLimits: {
          title: 'Increased BI Limits for Family',
          primaryText:
            'There is a premium charge to increase the liability limit for claims of family members.',
        },
      },
      stateOptions: {
        GA: {
          primaryText:
            'This coverage pays damages owed to others as a result of physical injuries, sickness, disease or death of any person for which you are legally liable as a result of an auto accident. Protects the named insureds, spouse and relatives as defined in the policy and others driving your auto with your permission.',
          secondaryText:
            'It covers legal defense if another party in the accident files a lawsuit against you. The first amount shown is the coverage limit per person; the second amount is the coverage limit per accident. Punitive damages are awarded because of aggravating circumstances in order to penalize, punish, or deter a defendant.',
        },
        MA: {
          title: 'Optional Bodily Injury To Others',
        },
        NJ: {
          primaryText:
            'Coverage may pay damages owed to others as a result of physical injuries, sickness, disease or death of any person for which you are legally liable as a result of a covered accident. If you’d like to purchase a Basic policy contact our Care Center.',
        },
      },
    },
    propertyDamage: {
      primaryImage: GraphicAutoCoveragePropertyDamageImageUrl,
      title: 'Property Damage Limit',
      primaryText:
        'Coverage may pay for damage to other people’s property resulting from an accident caused by your vehicle for which you are legally responsible and is covered under your policy.',
      secondaryText:
        'Property Damage coverage helps protect your assets by covering the losses so you don’t have to pay for them out-of-pocket. Also covers legal defense if another party files a lawsuit against you.',
      subCoverages: {
        propertyDamageIncreasedFamilyLimits: {
          title: 'Increased PD Limits for Family',
          primaryText:
            'There is a premium charge to increase the Property Damage Limit for claims of family members.',
        },
      },
      stateOptions: {
        MA: {
          title: 'Damage to Someone Else’s Property',
        },
      },
    },
    medical: {
      primaryImage: GraphicAutoCoverageMedicalExpenseImageUrl,
      title: 'Medical Expense',
      primaryText:
        'Coverage may pay for expenses for all passengers in the insured vehicle for medical or funeral expenses resulting from a covered vehicle related accident.',
      secondaryText:
        'Coverage applies after any available personal health insurance has been exhausted, and the limit is the most that may be paid to each insured person per accident.',
      stateOptions: {
        MA: {
          title: 'Medical Payments',
        },
      },
    },
    uninsuredMotorist: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristImageUrl,
      title: 'Uninsured Motorist Bodily Injury',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who did not have liability. Applies to damages for you as well as passengers in your vehicle for a covered loss.',
      secondaryText:
        'The first amount is the coverage limit per person; the second amount is the coverage limit per accident.',
      subCoverages: {
        uninsuredMotoristAddedOnReduced: {
          title: 'Added On or Reduced',
          primaryText:
            'With Added On, the limits of the Uninsured Motorist Coverage carried by the insured are added on to the liability limit carried by the underinsured, at-fault party. With Reduced, the limits of the Uninsured Motorist Coverage carried by the insured are reduced by the limits of the underinsured, at–fault party.',
        },
        uninsuredMotoristStacking: {
          title: 'Stacking',
          primaryText:
            'Stacking allows you to combine the UM/UIM Bodily Injury coverage limits for you and members of your household when two or more vehicles are insured under your policy.',
        },
        enhancedUnderinsuredMotorist: {
          title: 'Enhanced Underinsured Motorist Bodily Injury',
          primaryText:
            'Pays for legally collectable damages for bodily injury or death if you, or passenger in your auto, is injured or killed by an at-fault uninsured or underinsured auto. These Limits will not be offset or reduced by payments made by a responsible third-party’s liability policy.',
        },
      },
      stateOptions: {
        MA: {
          title: 'Bodily Injury Caused by an Uninsured Auto',
          primaryText:
            'May cover damages to your vehicle if it collides with another vehicle or object in a covered loss. Insurance will not pay for repairs or a replacement unless you have Collision Coverage.',
          secondaryText:
            'For older vehicles with low cash value, you might consider rejecting this coverage or selecting a higher deductible.',
        },
        PA: {
          primaryText:
            'Pays for legally collectible damages for bodily injury or death caused by a driver who does not have liability insurance',
        },
        GA: {
          primaryText:
            'Pays for legally collectible damages for bodily injury or death caused by a driver who does not have liability insurance',
          secondaryText:
            'This is only a general description of terms and available coverages, not a statement of contract. All coverages are subject to all policy provisions and applicable endorsements.',
        },
        MS: {
          title: 'Uninsured Motorist Bodily Injury Limit',
          subCoverages: {
            uninsuredMotoristStacking: {
              title: 'Stacking',
              primaryText:
                'You may elect stacking of your Uninsured Motorist Bodily Injury coverage for an additional charge. In the case of a covered loss, Stacking will multiply the benefits you may receive by the number of vehicles insured under this policy.',
            },
          },
        },
      },
    },
    uninsuredPropertyDamageLineLimit: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
      title: 'Uninsured Motorist Property Damage',
      primaryText:
        'May cover damage to your vehicle in a covered loss caused by a driver who did not have insurance. This coverage is not available if you select Collision Coverage.',
      secondaryText:
        'A deductible applies before your insurance will pay for an Uninsured Motorist Property Damage coverage claim.',
      subCoverages: {
        uninsuredPropertyDamageAddedOnReduced: {
          title: 'Added on/Reduced',
          primaryText:
            'In Added On, the limits of the Uninsured Motorist Coverage carried by the insured are added on to the liability limit carried by the underinsured, at-fault party. With Reduced, the limits of the Uninsured Motorist Coverage carried by the insured are reduced by the limits of the underinsured, at–fault party.',
        },
        uninsuredPropertyDamageLineDeductible: {
          title: 'Deductible',
          primaryText:
            'A deductible applies before your insurance will pay for an Uninsured Motorist Property Damage coverage claim.',
        },
      },
      stateOptions: {
        GA: {
          primaryText:
            'A deductible applies before your insurance will pay for an Uninsured Motorist Property Damage coverage claims.',
          secondaryText:
            'Added On/Reduced: In Added On, the limits of the Uninsured Motorist Coverage carried by the insured are added on to the liability limit carried by the underinsured, at-fault party. With Reduced, the limits of the Uninsured Motorist Coverage carried by the insured are reduced by the limits of the underinsured, at–fault party.',
          subCoverages: {
            uninsuredPropertyDamageLineAddedOnReduced: {
              title: 'Added on/Reduced',
              primaryText:
                'In Added On, the limits of the Uninsured Motorist Coverage carried by the insured are added on to the liability limit carried by the underinsured, at-fault party. With Reduced, the limits of the Uninsured Motorist Coverage carried by the insured are reduced by the limits of the underinsured, at–fault party.',
            },
            uninsuredPropertyDamageLineDeductible: {
              title: 'Deductible',
              primaryText:
                'A deductible applies before your insurance will pay for an Uninsured Motorist Property Damage coverage claim.',
            },
          },
        },
        MD: {
          subCoverages: {
            uninsuredPropertyDamageLineDeductible: {
              title: 'Deductible',
              primaryText:
                'The Deductible is the amount you must pay out-of-pocket before your insurance will pay for any eligible expenses under Uninsured Motorist Property Damage coverage.',
            },
            enhancedUnderinsuredPropertyDamage: {
              title: 'Enhanced Underinsured Motorist Property Damage',
              primaryText:
                'Coverage reimburses the insured for damage to or destruction of the insured vehicle caused by an at-fault uninsured or underinsured motorist.',
            },
          },
        },
        DC: {
          primaryText:
            'This coverage provides protection against loss or damage to the insured vehicle caused by a motorist who does not have insurance or by an unidentified motorist who has left the scene.',
          secondaryText:
            'A deductible applies to losses covered by Uninsured or Underinsured Motorist – Property Damage coverage.',
          subCoverages: {
            uninsuredPropertyDamageLineDeductible: {
              title: 'Deductible',
              primaryText:
                'A deductible applies to losses covered by Uninsured or Underinsured Motorist – Property Damage coverage.',
            },
          },
        },
        MS: {
          title: 'Uninsured Motorist Property Damage',
          subCoverages: {
            uninsuredPropertyDamageLineDeductible: {
              title: 'Deductible',
              primaryText: 'A deductible applies to Uninsured Motorist Property Damage Limit',
            },
            uninsuredPropertyDamageLineStacking: {
              title: 'Stacking',
              primaryText:
                'You may elect stacking of your Uninsured Motorist Property Damage coverage for an additional charge. In the case of a covered loss, Stacking will multiply the benefits you may receive by the number of vehicles insured under this policy.',
            },
          },
        },
      },
    },
    underinsuredPropertyDamageLineLimit: {
      primaryImage: GraphicAutoCoverageUnderinsuredUninsuredMotoristImageUrl,
      title: 'Underinsured Motorist Property Damage',
      primaryText:
        'Coverage provides protection against loss or damage to the insured vehicle caused by a motorist who does not have sufficient liability limits or by an unidentified motorist who has left the scene.',
      secondaryText:
        'Please refer to your policy details for specific coverage information for your state.',
      subCoverages: {
        underinsuredPropertyDamageLineDeductible: {
          title: 'Deductible',
          primaryText:
            'A deductible applies to losses covered by Uninsured or Underinsured Motorist – Property Damage coverage.',
        },
      },
      stateOptions: {
        DC: {
          secondaryText:
            'A deductible applies to losses covered by Uninsured or Underinsured Motorist – Property Damage coverage.',
        },
      },
    },
    underinsuredMotorist: {
      primaryImage: GraphicAutoCoverageUnderinsuredUninsuredMotoristImageUrl,
      title: 'Underinsured Motorist Bodily Injury',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who does not have sufficient liability insurance limits to cover the full amount of a covered loss.',
      secondaryText:
        'Applies to damages for you as well as passengers in your insured vehicle. The first amount is the coverage limit per person; the second amount is the coverage limit per accident.',
      subCoverages: {
        underinsuredMotoristStacking: {
          title: 'Stacking',
          primaryText:
            'Stacking allows you to combine the UM/UIM Bodily Injury coverage limits for you and members of your household when two or more vehicles are insured under your policy.',
        },
      },
      stateOptions: {
        PA: {
          primaryText:
            'Pays for legally collectible damages for bodily injury or death caused by a driver who does not have sufficient liability insurance limits to cover the full amount of the loss.',
        },
        MA: {
          title: 'Bodily Injury Caused by an Underinsured Auto',
        },
      },
    },
    uninsuredUnderinsuredMotorist: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristImageUrl,
      title: 'Uninsured or Underinsured Motorist Bodily Injury',
      primaryText:
        'Pays for legally collectible damages for bodily injury or death (but not property damage) if you, or any person riding in your auto, is injured or killed by an at-fault uninsured or underinsured auto.',
      secondaryText:
        'There are two coverage limits shown for each option (for example, $100,000/$300,000). The first amount represents the coverage limit per person; the second amount represents the coverage limit per accident.',
      subCoverages: {
        uninsuredUnderinsuredMotoristBodilyInjuryStacking: {
          title: 'Stacking',
          primaryText:
            'Allows you to combine the UM/UIM Bodily Injury coverage limits for you and members of your household when two or more vehicles are insured under your policy.',
        },
        uninsuredUnderinsuredMotoristBodilyInjuryOptions: {
          title: 'Options',
          primaryText:
            'If you choose conversion coverage, you may receive up to the full amount of your Uninsured or Underinsured Motorist coverage regardless of money paid by other sources (e.g. the other driver’s liability coverage).',
        },
      },
      stateOptions: {
        NM: {
          secondaryText:
            'You may elect to purchase Stacking coverage for an additional charge. In the case of a covered loss, Stacking will multiply the benefits you receive by the number of vehicles insured under this policy.',
        },
        RI: {
          primaryText:
            'This coverage pays for legally collectable damages for bodily injury or death (but not property damage) if you, or any person riding in your auto, is injured or killed by an at-fault uninsured or underinsured driver',
        },
        NY: {
          title: 'Supplementary UM/UIM Bodily Injury',
          primaryText:
            'Pays for damages that the insured is legally entitled to recover from another motorist due to bodily injury sustained in an accident that is caused by a motorist who doesn’t have insurance or doesn’t have enough insurance to compensate the insured.',
          secondaryText: 'Subject to exclusions, conditions, limits and other provisions.',
        },
        VA: {
          title: 'Uninsured Motorist Bodily Injury',
          primaryText:
            'Pays for legally collectible damages for bodily injury or death (but not property damage) if you, or any person riding in your auto, is injured or killed by an at-fault uninsured auto.',
          subCoverages: {
            uninsuredUnderinsuredMotoristBodilyInjuryStacking: {
              title: 'Stacking',
              primaryText:
                'Allows you to combine the UM/UIM Bodily Injury coverage limits for you and members of your household when two or more vehicles are insured under your policy.',
            },
            uninsuredUnderinsuredMotoristBodilyInjuryOptions: {
              title: 'Options',
              primaryText:
                'If you choose conversion coverage, you may receive up to the full amount of your Uninsured Motorist coverage regardless of money paid by other sources (e.g. the other driver’s liability coverage).',
            },
          },
        },
        NC: {
          title: 'Combined Uninsured or Underinsured Motorist Bodily Injury',
          primaryText:
            'Pays for legally collectable damages for injury or death if you, or any person in your auto, is injured or killed by an at-fault uninsured or underinsured  driver',
          secondaryText: 'Please select the coverage limit desired',
        },
        CO: {
          title: 'Uninsured or Underinsured Motorist',
        },
        VT: {
          title: 'Uninsured Motorist Bodily Injury',
        },
      },
    },
    deathAndDismemberment: {
      primaryImage: GraphicAutoCoverageAccidentalDeathAndDismembermentImageUrl,
      title: 'Accidental Death & Dismemberment',
      primaryText:
        'This coverage may provide benefits if an insured person is killed or injured while occupying or when struck by a land motor vehicle during a covered loss.',
      secondaryText:
        'Accidental Death & Dismemberment may pay the injured person, or his or her beneficiaries, a specified amount if the loss occurs within a specified timeframe of the accident and is a direct result of the auto accident.',
    },
    personalInjuryProtectionBasicPip: {
      primaryImage: GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
      title: 'Basic Personal Injury Protection (Exists as PIP)',
      primaryText:
        'This coverage pays for medical expenses, wage loss, replacement services, certain survivor’s losses, and funeral expenses up to the specified amount for injuries resulting from a motor accident regardless of fault.',
      secondaryText:
        'Provides benefits for eligible family household members who have not rejected PIP. A deductible amount applies to each accident. Guest Coverage provides benefits for guest passengers in your covered vehicle when all family members have rejected PIP in writing with the State of KY. Added PIP provides additional limits above Basic PIP coverage.',
      subCoverages: {
        personalInjuryProtectionBasicPipDeductible: {
          title: 'Deductible',
          primaryText:
            'Applies to each accident and to the total of any payments made for Personal Injury Protection',
        },
        personalInjuryProtectionAddedPip: {
          title: 'Added Personal Injury Protection',
          primaryText:
            'Provides additional limits above the Basic Personal Injury Protection coverage. If eligible members of the households have previously rejected Basic PIP this coverage can be bought back upon buying back Basic PIP. Subject to exclusions, conditions, limits and other provisions.',
        },
        personalInjuryProtectionBasicPipGuest: {
          title: 'Guest Coverages',
          primaryText:
            'Provides benefits for guest passengers of your covered vehicle when all eligible family household members have rejected Basic Personal Injury Protection in writing with the State of Kentucky.',
        },
        personalInjuryProtectionTort: {
          title: 'Tort',
          primaryText:
            'Full Tort provides unrestricted right to seek financial compensation for injuries caused by other drivers. Coverage includes medical, out-of-pocket expenses & compensation for pain & suffering. Limited Tort limits your right of financial compensation except for medical & out-of-pocket expenses.',
        },
      },
      stateOptions: {
        NY: {
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
          subCoverages: {
            personalInjuryProtectionOBELCoverage: {
              title: 'Optional Basic Economic Loss',
              primaryText:
                'Optional coverage adds an additional limit to the Basic Personal Injury Protection Benefits coverage for basic economic loss, loss of earnings from work, psychiatric, physical or occupational therapy, and rehabilitation',
            },
            personalInjuryProtectionAdditionalCoverage: {
              title: 'Additional PIP Coverage',
              primaryText:
                'Provides additional benefits that reimburse for extended economic loss on account of personal injuries sustained by an eligible injured person and caused by an accident arising out of the use or operation of a motor vehicle during the policy period. It also provides out of state guest passenger coverage.',
            },
            personalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'A deductible applies to each accident and to the total of any payments made for benefits that fall under this coverage for a named insured or relative.',
            },
            noFaultWorkLossDeduction: {
              title: 'No Fault Work Loss Reduction',
              primaryText:
                'You may qualify for a discount if you or a member of your household opts to select an employee sponsored Wage Continuation Program for your primary coverage instead of the Work Loss coverage offered under this coverage.',
            },
          },
        },
        KY: {
          title: 'Basic Personal Injury Protection',
        },
      },
    },
    tort: {
      primaryImage: GraphicAutoCoverageTortOptionImageUrl,
      title: 'Tort Option',
      primaryText:
        'State law gives each member of the household the right to accept or reject limitations on their right to sue. If you accept and when injuries exceed the thresholds, you retain the right to sue for expenses not included in No-Fault coverage.',
      secondaryText:
        'The Motor Vehicle Reparations Act, KRS 304.39 (sometimes referred to as the No-Fault Law) has two components: Personal Injury Protection (PIP) coverage and limitations on an individual’s right to sue and be sued (Tort Rights).',
      stateOptions: {
        NJ: {
          title: 'Tort Coverage',
          primaryText:
            'With Limited Right to Sue, you agree not to sue the person who caused an auto accident for your pain and suffering unless you sustain an allowable permanent injury or death (Choosing this option does not affect your ability to sue for economic damages such as medical expenses and lost wages.)',
          secondaryText:
            'With Full Right to Sue, you retain the right to sue the person who caused an auto accident for pain and suffering for any injury.',
        },
        PA: {
          title: 'Tort Coverage',
          primaryText:
            'With Limited Right to Sue, you agree not to sue the person who caused an auto accident for your pain and suffering unless you sustain an allowable permanent injury or death (Choosing this option does not affect your ability to sue for economic damages such as medical expenses and lost wages.)',
          secondaryText:
            'With Full Right to Sue, you retain the right to sue the person who caused an auto accident for pain and suffering for any injury.',
        },
      },
    },
    uninsuredUnderinsuredPropertyDamageLineLimit: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
      title: 'Uninsured or Underinsured Motorist Property Damage',
      primaryText:
        'This coverage reimburses the insured for damage to or destruction of the insured vehicle and the insured person’s personal property contained in the insured vehicle caused by a liable uninsured or underinsured motorist.',
      secondaryText:
        'You may elect to purchase Stacking coverage for an additional charge. In the case of a covered loss, Stacking will multiply the benefits you receive by the number of vehicles insured under this policy.',
      subCoverages: {
        uninsuredUnderinsuredPropertyDamageLineStacking: {
          title: 'Stacking',
          primaryText:
            'Allows you to combine the UM/UIM Property Damage coverage limits for you and members of your household when two or more vehicles are insured under your policy.',
        },
        uninsuredUnderinsuredPropertyDamageLineDeductible: {
          title: 'Deductible',
          primaryText:
            'Applies to each incident and to the total of any payments made for this coverage.',
        },
      },
      stateOptions: {
        VA: {
          title: 'Uninsured Motorist Property Damage',
          primaryText:
            'This coverage reimburses the insured for damage to or destruction of the insured vehicle and the insured person’s personal property contained in the insured vehicle caused by a liable uninsured motorist.',
        },
        VT: {
          title: 'Uninsured Motorist Property Damage',
        },
      },
    },
    firstPartyBenefits: {
      primaryImage: GraphicAutoCoverageFirstPartyBenefitsImageUrl,
      title: 'First Party Benefits',
      primaryText:
        'Provides coverage for medical expenses, income loss, accidental death and funeral expenses resulting from an auto accident, regardless of fault. It covers the policyholder, drivers listed on the policy and relatives living in the same household as the policyholder.',
      secondaryText:
        'This coverage can be chosen separately by selecting “Individual” or can be purchased as a package by selecting “Combination”.',
      subCoverages: {
        firstPartyBenefitsMedicalExpense: {
          title: 'Medical Expense',
          primaryText:
            'Expenses incurred for reasonable and necessary medical treatment and rehabilitative services',
        },
        firstPartyBenefitsIncomeLossBenefits: {
          title: 'Income Loss',
          primaryText:
            'Coverage helps compensate covered drivers and passengers for wages lost as a result of an accident subject to the terms and limits of the policy.',
        },
        firstPartyBenefitsAccidentalDeathBenefits: {
          title: 'Accidental Death',
          primaryText:
            'This benefit is paid if the policyholder, other driver or passenger dies from auto accident related injuries.',
        },
        firstPartyBenefitsFuneralBenefits: {
          title: 'Funeral Expense',
          primaryText:
            'Covers funeral, burial, or cremation expenses of a deceased policyholder, other covered driver or passenger who dies from car accident related injuries.',
        },
        firstPartyBenefitsExtraordinaryMedicalBenefits: {
          title: 'Extraordinary Medical Expense',
          primaryText:
            'Covers funeral, burial, or cremation expenses of a deceased policyholder, other covered driver or passenger who dies from car accident related injuries.',
        },
        firstPartyBenefitsCombinationBenefits: {
          title: 'Total Benefit Limit',
          primaryText:
            'This limit will apply to losses covered under First Party Benefits Coverage. Other limits may apply for individual coverages.',
        },
      },
    },
    personalInjuryProtection: {
      primaryImage: GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
      title: 'Personal Injury Protection',
      primaryText:
        'Provides no-fault benefits including medical expense, income loss, essential services expenses, and funeral expenses.',
      secondaryText:
        'Provides no-fault benefits when you or passengers in your car get hurt in an accident.',
      subCoverages: {
        personalInjuryProtectionWorkLoss: {
          title: 'Work Loss Option',
          primaryText:
            'Work Loss can be waived if neither you or your spouse received any earned income from employment during the 31 days prior to applying and do not anticipate earned income for at least 180 days from inception date. You will be asked to sign a statement verifying that you meet these requirements.',
        },
        personalInjuryProtectionWorkLossDeductible: {
          title: 'Work Loss Deductible',
          primaryText: 'A deductible applies before your insurance will pay for a coverage claim.',
        },
        personalInjuryProtectionStacking: {
          title: 'Stacking',
          primaryText:
            'In the case of a covered loss, Stacking will multiply the Personal Injury Protection benefits you receive by the number of vehicles insured under this policy.',
        },
        personalInjuryProtectionLevel: {
          title: 'Sub Coverage PIP Options',
          primaryText:
            'When you ’Waive’ this insurance there is no coverage for the named insured or members of the insured’s family residing in the household. Selection of ’Full’ covers the named insured and family members residing in the household along with anyone injured while in the named insured’s vehicle.',
        },
        workLossBenefits: {
          title: 'Work Loss Benefits',
          primaryText:
            'Pays for wage loss – up to the coverage limit – incurred by an insured person who sustains bodily injury due to a motor vehicle accident and who, as a result, is disabled and unable to work. Available to named insured(s) age 60 or older.',
        },
        personalInjuryProtectionDeductible: {
          title: 'Deductible',
          primaryText:
            'A deductible applies to each accident and to the total of any payments made for benefits that fall under this coverage for a named insured or relative.',
        },
        personalInjuryProtectionExcessAttendantCare: {
          title: 'Excess Attendant Care',
          primaryText:
            'Excess attendant care is additional coverage purchased for attendant care above the PIP medical coverage limit selected for your policy',
        },
        // example : amfam.auto.coverages.policy.pipExcludeDriver-0ntkc2ya
        'pipExcludeDriver-<driver.id>': {
          title: 'Exclude at least one driver from PIP',
          primaryText:
            'Lower limits have less expensive premiums then options with higher or unlimited PIP coverage. You can select to exclude specific drivers (Option 4) or exclude all drivers (Option 6) if the excluded named insured, spouse or resident relatives have qualified health coverage or are covered under another policy with PIP coverage.',
          isExcludeDriver: true,
        },
      },
      stateOptions: {
        UT: {
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].UT,
        },
        MA: {
          primaryText:
            'Coverage may pay expenses the eligible injured person incurs for medical expenses no matter who causes the accident.',
          secondaryText:
            'It may also include coverage for loss of income, essential services and funeral expenses.',
          subCoverages: {
            personalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'Personal Injury Protection coverage offers selectable per person/per occurrence limits.',
            },
            personalInjuryProtectionDeductibleOption: {
              title: 'Deductible Option',
              primaryText:
                'There are two deductible options for Personal Injury Protection:  You and your spouse or You and your household.',
            },
          },
        },
        KS: {
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].KS,
          title: 'Personal Injury Protection Options',
          primaryText:
            'Personal Injury Protection (referred to as PIP) provides no-fault benefits including medical expense, income loss, essential services expenses, and funeral expenses.',
          secondaryText:
            'Provides no-fault benefits when you or passengers in your car get hurt in an accident.',
        },
        MN: {
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].MN,
        },
        ND: {
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].ND,
        },
        OR: {
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].OR,
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
        },
        WA: {
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].WA,
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
        },
        TX: {
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
        },
        KY: {
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
        },
        MI: {
          title: 'Personal Injury Protection (PIP - Per Person/Per Accident)',
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Lower limits have less expensive premiums then options with higher or unlimited PIP coverage. You can select to exclude specific drivers (Option 4) or exclude all drivers (Option 6) if the excluded driver(s) have qualified health coverage or are covered under another policy with PIP coverage.',
          subCoverages: {
            'pipExcludeDriver-<driver.id>': {
              title: 'Exclude at least one driver from PIP',
              primaryText:
                'When excluding driver(s) you may need to provide proof from your health insurer or employer identifying individuals covered under the insurance; proof that the policy does not exclude or limit auto accident injuries and proof that the individual deductible meets the state criteria. Medicare & Medicaid participants may need proof of enrollment.',
              isExcludeDriver: true,
            },
          },
        },
        DE: {
          title: 'Personal Injury Protection Per Person/Per Accident',
          primaryText:
            'Personal Injury Protection provides no-fault benefits when you or passengers in your car get hurt in an accident.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
          subCoverages: {
            personalInjuryProtectionDeductibleOption: {
              title: 'Deductible option',
              primaryText:
                'Personal Injury Protection coverage offers selectable per person/per occurrence limits.  There are two deductible options for Personal Injury Protection. 1) you and your spouse or 2) you and your household',
            },
            PersonalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'The deductible selected will apply to losses covered under Personal Injury Protection.',
            },
            PersonalInjuryProtectionDeductibleAppliesTo: {
              title: 'Deductible Applies To',
            },
          },
        },
        FL: {
          title: 'Personal Injury Protection Per Person',
          primaryText:
            'Personal injury protection provides no-fault benefits when you or passengers in your car get hurt in an accident.',
          secondaryText: 'Subject to exclusions, conditions, limits and other provisions.',
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].FL,
          subCoverages: {
            personalInjuryProtectionOption: {
              title: 'PIP Option ',
              primaryText:
                'Coverage provides benefits to you (the named insured), your spouse, any dependent relatives, and persons who are occupants of your insured vehicle or persons struck by your insured vehicle.',
            },
            personalInjuryProtectionDeductibleOption: {
              title: 'Deductible option',
              primaryText:
                'Option A applies to the named insured and spouse residing in the same household. Option B applies to the named insured, spouse, and all dependent resident relatives residing in the same household.',
            },
            personalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'A deductible applies to each accident and to the total of any payments made for this coverage for any eligible family household member.',
            },
            personalInjuryProtectionWorkLoss: {
              title: 'Exclude Work Loss',
              primaryText:
                'Allows you to opt out of coverage for loss of gross income and loss of earning capacity if you are unable to work due to accident-related injuries.',
            },
            personalInjuryProtectionWorkLossOption: {
              title: 'Exclude Work Loss Option',
              primaryText:
                'Option 1 applies to the named insured and spouse residing in the same household. Option 2 applies to the named insured, spouse, and all dependents resident relatives residing in the same household.',
            },
          },
        },
        HI: {
          title: 'Personal Injury Protection Benefits Limit',
          primaryText:
            'Pays for appropriate and reasonable medical treatment and associated expenses that are necessary as a result of accidental harm and which are comparable to the requirements for prepaid health care plans.',
          secondaryText:
            'Limits and exclusions may apply. Please refer to your policy details for specific coverage information for your state.',
          subCoverages: {
            personalInjuryProtectionMedicalDeductible: {
              title: 'Personal Injury Protection Benefits Deductible',
              primaryText:
                'The Personal Injury Protection deductible is a per accident deductible that applies to you and your spouse, relatives residing in your household, and any other person who was injured as a passenger in a motor vehicle accident involving your insured car.',
            },
            personalInjuryProtectionWageLossBenefits: {
              title: 'Wage Loss Benefits',
              primaryText:
                'Pays for wage loss incurred by an insured person who sustains bodily injury due to a motor vehicle accident and who, as a result, is disabled and unable to work.',
            },
            personalInjuryProtectionDeathBenefits: {
              title: 'Death Benefits',
              primaryText:
                'Pays benefits upon the death of an insured person that results from bodily injury due to a motor vehicle accident. We will pay death benefits to the surviving spouse; to dependent children if there is no surviving spouse; or to the estate of the insured person if there is no surviving spouse or dependent children.',
            },
            personalInjuryProtectionFuneralBenefits: {
              title: 'Funeral Expense Benefits',
              primaryText:
                'Pays funeral expense benefits – up to the coverage limit – for funeral expenses upon the death of an insured person resulting from bodily injury due to a motor vehicle accident.',
            },
            personalInjuryProtectionAltHealthCareBenefits: {
              title: 'Alternative Health Care Benefits',
              primaryText:
                'Pays alternative health care benefits – up to the coverage limit – incurred by an insured person who sustains bodily injury due to a motor vehicle accident.',
            },
          },
        },
      },
    },
    personalInjuryProtectionMedRehabExpenses: {
      primaryImage: GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
      title: 'Personal Injury Protection Medical & Rehabilitation Expenses',
      primaryText:
        'Pays for certain expenses resulting from an auto accident, regardless of fault.  It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
      secondaryText:
        'Includes coverage for reasonable charges incurred by a named insured for necessary products, services, accommodations, recovery, or rehabilitation (provided by a licensed medical provider).  Please refer to your policy details for specific coverage information for your state.',
      subCoverages: {
        personalInjuryProtectionWorkLossBenefits: {
          title: 'Work Loss Benefits',
          primaryText:
            'Includes reimbursement for loss of income for work an insured person would have performed after the date of the accident had he or she not been injured in said accident.',
        },
        personalInjuryProtectionFuneralBenefits: {
          title: 'Funeral Benefits',
          primaryText:
            'Includes payment of the actual costs up to the Funeral Benefits limit for each insured person, paid to the survivors to help cover the funeral and other funeral-related costs.',
        },
      },
    },
    personalInjuryProtectionExtraPackageCoverage: {
      primaryImage: GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
      title: 'Extra PIP Package Coverage ',
      primaryText:
        'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
      secondaryText:
        'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
      personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].NJ,
      subCoverages: {
        personalInjuryProtectionExtraPackageCoverageSelf: {
          title: 'Extra PIP Package Coverage selected for',
        },
        personalInjuryProtectionMedical: {
          title: 'Medical Expense',
          primaryText:
            'Coverage provides protection for expenses incurred for reasonable and necessary medical treatment and rehabilitative services as a result of a covered accident.',
        },
        personalInjuryProtectionDeductible: {
          title: 'Medical Expense Deductible',
          primaryText:
            'Deductible selected will apply to losses covered under Medical Expense coverage.',
        },
        personalInjuryProtectionExtendedMedicalExpense: {
          title: 'Extended Medical Expense',
          primaryText:
            'Reimburses medical expenses sustained by a covered person in an accident involving a highway vehicle that is not owned by or furnished or available for the regular use by you or a relative.',
        },
        personalInjuryProtectionExtendedMedicalDeductible: {
          title: 'Extended Medical Expense Deductible',
          primaryText:
            'Deductible selected will apply to losses covered under Medical Expense coverage.',
        },
        personalInjuryProtectionHealthInsurer: {
          title: 'PIP Health Insurer',
          primaryText:
            'For PIP Coverages related to medical expenses due to an accident you may choose ‘Health Insurer as Primary‘ meaning your healthcare insurer is the primary insurer (Medicare & Medicaid do not qualify) or you may choose ‘PIP as Primary‘ and our insurance company will be the primary insurer.',
          isPipSubCoverage: true,
        },
        healthInsurerName1: {
          title: 'Health insurer name',
          isPipSubCoverage: true,
        },
        healthInsurerPolicyNumber1: {
          title: 'Policy number',
          isPipSubCoverage: true,
        },
        healthInsurerName2: {
          title: 'Health insurer name 2',
          isPipSubCoverage: true,
        },
        healthInsurerPolicyNumber2: {
          title: 'Policy number 2',
          isPipSubCoverage: true,
        },
      },
    },
    personalInjuryProtectionMedicalMain: {
      primaryImage: GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
      title: 'Personal Injury Protection Benefits Limit',
      primaryText:
        'Pays for appropriate and reasonable medical treatment and associated expenses that are necessary as a result of accidental harm and which are comparable to the requirements for prepaid health care plans.',
      secondaryText:
        'Limits and exclusions may apply. Please refer to your policy details for specific coverage information for your state.',
      subCoverages: {
        personalInjuryProtectionMedicalDeductible: {
          title: 'Personal Injury Protection Benefits Deductible',
          primaryText:
            'The Personal Injury Protection deductible is a per accident deductible that applies to you and your spouse, relatives residing in your household, and any other person who was injured as a passenger in a motor vehicle accident involving your insured car.',
        },
        personalInjuryProtectionWageLossBenefits: {
          title: 'Wage Loss Benefits',
          primaryText:
            'Pays for wage loss incurred by an insured person who sustains bodily injury due to a motor vehicle accident and who, as a result, is disabled and unable to work.',
        },
        personalInjuryProtectionDeathBenefits: {
          title: 'Death Benefits',
          primaryText:
            'Pays benefits upon the death of an insured person that results from bodily injury due to a motor vehicle accident. We will pay death benefits to the surviving spouse; to dependent children if there is no surviving spouse; or to the estate of the insured person if there is no surviving spouse or dependent children.',
        },
        personalInjuryProtectionFuneralBenefits: {
          title: 'Funeral Expense Benefits',
          primaryText:
            'Pays funeral expense benefits – up to the coverage limit – for funeral expenses upon the death of an insured person resulting from bodily injury due to a motor vehicle accident.',
        },
        personalInjuryProtectionAltHealthCareBenefits: {
          title: 'Alternative Health Care Benefits',
          primaryText:
            'Pays alternative health care benefits – up to the coverage limit – incurred by an insured person who sustains bodily injury due to a motor vehicle accident.',
        },
      },
    },
    supplementalSpousalLiabilityCoverage: {
      primaryImage: GraphicAutoCoverageSupplementalSpousalLiabilityImageUrl,
      title: 'Supplemental Spousal Liability Coverage',
      primaryText:
        'Coverage provides Bodily Injury Liability coverage under an existing motor vehicle insurance policy, and covers costs related to the death of or bodily injury to the insured’s spouse, even in instances when the injured spouse must prove the culpable conduct of the insured spouse.',
      secondaryText:
        'Provides coverage for a spouse’s injuries or death caused by an accident due to the negligence of the other spouse. Subject to exclusions, conditions, limits and other provisions.',
    },
    coverageForRentedVehicles: {
      primaryImage: GraphicAutoCoverageRentalReimbursementImageUrl,
      title: 'Coverage for Rented Vehicles',
      primaryText:
        'Provides coverage for the insured’s obligations in the event of damage to, or loss of any vehicle rented by the insured, including loss of use and diminished value.',
      secondaryText: 'Subject to exclusions, conditions, limits and other provisions.',
      stateOptions: {
        AK: {
          title: 'Rental Vehicle Physical Damage',
          primaryText:
            'Coverage is offered as a stand alone coverage when Comprehensive and Collision are not selected for the vehicles insured on this policy. If Comprehensive and Collision are selected, this coverage is automatically included.',
          secondaryText: 'A deductible of $500 may apply for each qualified incident.',
        },
        NC: {
          primaryText:
            'May cover the reimbursement of rental fees for renting an auto, for a specified limit, as a temporary substitute for the insured auto while it is being repaired due to a covered loss.',
          secondaryText:
            'This endorsement provides coverage for you or a family member who rents a rental vehicle on a daily basis for less than 22 consecutive days subject to a $250 deductible.',
        },
      },
    },
    limitedPropertyDamage: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageLimitImageUrl,
      title: 'Limited Property Damage',
      primaryText:
        'Covers up to $1,000 in property damage when an insured is 50% or more at fault for damages to another person’s vehicle.',
      secondaryText: 'Subject to exclusions, conditions, limits and other provisions.',
    },
    incomeLossBenefits: {
      primaryImage: GraphicAutoCoverageFirstPartyBenefitsImageUrl,
      title: 'Income Loss Benefits',
      primaryText:
        'This coverage helps compensate covered drivers and passengers for wages lost as the result of an accident or covered incident.',
      secondaryText:
        'This is only a general description of terms and available coverages, not a statement of contract. All coverages are subject to all policy provisions and applicable endorsements.',
    },
    totalDisability: {
      primaryImage: GraphicAutoCoverageTotalDisabilityImageUrl,
      title: 'Total Disability',
      primaryText:
        'Total Disability will pay the injured person (if gainfully employed) $60 per week, up to 52 weeks. If not gainfully employed this coverage amount is $30 per week, for up to 52 weeks.',
      secondaryText:
        'There is a waiting period that begins within and extends beyond 14 days of the accident and your disability must be the direct result of the accident and not due to any other cause.',
    },
    nonVehicularPropertyDamage: {
      primaryImage: GraphicAutoCoveragePropertyDamageImageUrl,
      title: 'Non-Vehicular Property Damage',
      primaryText:
        'Coverage is limited to $10,000 for any one accident for damage to property but excluding damage to motor vehicles, aircraft, watercraft, self-propelled mobile equipment, and any property in or upon any of the aforementioned.',
      secondaryText:
        'Non-vehicular property damage - Coverage is limited to $10,000 for any one accident for damage to property but excluding damage to motor vehicles, aircraft, watercraft, self-propelled mobile equipment, and any property in or upon any of the aforementioned.',
    },
    personalInjuryProtectionAccidentalDeathBenefits: {
      primaryImage: GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
      title: 'Personal Injury Protection Accidental Death Benefits',
      primaryText:
        'Personal Injury Protection provides no-fault benefits including medical and hospital expenses, income disability, and accidental death benefits.',
      secondaryText:
        'This coverage will provide benefits if the accident causes the death of an insured person within one year from the date of the accident. The Bodily Injury must be the sole cause of death.',
      subCoverages: {
        personalInjuryProtectionIncomeDisabilityBenefits: {
          title: 'Income Disability Benefits',
          primaryText:
            'Coverage provides 70% of lost income from work the insured person would have performed due to disability resulting from a motor vehicle accident, commencing eight days after the day of the accident, for up to 52 weeks. Subject to a maximum of $140/week. May pay up to $70/week for essential services ordinarily performed by an injured non-income earner.',
        },
        personalInjuryProtectionMedicalAndHospitalBenefits: {
          title: 'Medical and Hospital Benefits',
          primaryText:
            'Coverage pays for medically necessary expenses and professional services prescribed by a licensed medical provider for the treatment of the bodily injury. We will pay covered medical and hospital benefits, up to $5,000, incurred for services rendered within two years from the date of the accident.',
        },
      },
    },
  },
  'connect.auto': {
    BodilyInjury: {
      primaryImage: GraphicAutoCoverageBodilyInjuryImageUrl,
      title: 'Bodily Injury',
      primaryText:
        'Coverage may pay damages owed to others as a result of physical injuries, sickness, disease or death of any person for which you are legally liable as a result of a covered accident.',
      secondaryText:
        'It covers legal defense if another party in the accident files a lawsuit against you. The first amount shown is the coverage limit per person; the second amount is the coverage limit per accident.',
    },
    PropertyDamage: {
      primaryImage: GraphicAutoCoveragePropertyDamageImageUrl,
      title: 'Property Damage',
      primaryText:
        'Coverage may pay for damage to other people’s property resulting from an accident caused by your vehicle for which you are legally responsible and is covered under your policy.',
      secondaryText:
        'Property Damage Liability coverage helps protect your assets by covering the losses so you don’t have to pay for them out-of-pocket. Also covers legal defense if another party files a lawsuit against you.',
    },
    BodilyInjuryPropertyDamage: {
      primaryImage: GraphicAutoCoverageDamageInjuryImageUrl,
      title: 'Bodily Injury & Property Damage',
      primaryText:
        'May cover damages owed to others related to Bodily Injury (BI) & Property Damage (PD) for which you are legally liable as a result of a covered auto accident.',
      secondaryText:
        'BI pays damages as a result of physical injuries, sickness, disease or death. PD pays damages to other people’s property. The limits shown represent dollar limits per person and per accident. BI/PD also pays legal defense.',
    },
    MedicalExpense: {
      primaryImage: GraphicAutoCoverageMedicalExpenseImageUrl,
      title: 'Medical Expense',
      primaryText:
        'Coverage may pay for expenses for all passengers in the insured vehicle for medical or funeral expenses resulting from a covered vehicle related accident.',
      secondaryText:
        'Coverage applies after any available personal health insurance has been exhausted, and the limit is the most that may be paid to each insured person per accident.',
    },
    UninsuredMotorist: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristImageUrl,
      title: 'Uninsured Motorist',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who did not have liability. Applies to damages for you as well as passengers in your vehicle for a covered loss.',
      secondaryText:
        'The first amount is the coverage limit per person; the second amount is the coverage limit per accident.',
      subCoverages: {
        UninsuredMotoristCoverageType: {
          title: 'UM Coverage Type',
          primaryText:
            'Stacked coverage allows the insured to combine the UM coverage limit for one vehicle with the UM coverage limit of other vehicles in the event of a covered loss.',
        },
      },
    },
    UnderInsuredMotorist: {
      primaryImage: GraphicAutoCoverageUnderinsuredUninsuredMotoristImageUrl,
      title: 'Underinsured Motorist',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who does not have sufficient liability insurance limits to cover the full amount of a covered loss.',
      secondaryText:
        'Applies to damages for you as well as passengers in your insured vehicle. The first amount is the coverage limit per person; the second amount is the coverage limit per accident.',
      subCoverages: {
        // V3 naming
        UnderInsuredMotoristCoverageType: {
          title: 'UIM Coverage Type',
          primaryText:
            'Stacked coverage allows the insured to combine the UIM coverage limit for one vehicle with the UIM coverage limit of other vehicles in the event of a covered loss. Does not apply if only 1 vehicle.',
        },
        // V4 naming
        'underInsuredMotoristCoverageType.coverageValue': {
          title: 'UIM Coverage Type',
          primaryText:
            'Stacked coverage allows the insured to combine the UIM coverage limit for one vehicle with the UIM coverage limit of other vehicles in the event of a covered loss. Does not apply if only 1 vehicle.',
        },
      },
    },
    UninsuredMotoristUnderInsuredMotorist: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristImageUrl,
      title: 'Uninsured or Underinsured Motorist',
      primaryText:
        'May pay for bodily injury damages or death caused by a driver that did not have sufficient liability coverage. This applies to damages for you and any passengers in the vehicle for a covered loss.',
      secondaryText:
        'The first amount is the coverage limit per person; the second amount is the coverage limit per accident.',
      subCoverages: {
        UninsuredMotoristCoverageType: {
          title: 'Uninsured Motorist Type',
        },
      },
      stateOptions: {
        MD: {
          primaryText:
            'You may select from two types of coverage, Uninsured Motorist or Enhanced Underinsured Motorist.',
          secondaryText:
            'Uninsured Motorist provides protection when the at-fault vehicle is uninsured or underinsured. If there is a claim the coverage limit is reduced by the amount of any available coverage of the at-faults insurer. Enhanced Underinsured Motorist provides the same coverage but is not reduced by a claim.',
        },
        CT: {
          primaryText:
            'Provides coverage for injuries incurred by an insured caused by operators of an uninsured motor vehicle. It may include payments for certain medical expenses, lost wages, and pain and suffering.',
          secondaryText:
            'Payouts for Uninsured Motorist w/ Conversion Underinsured Motorist are not reduced by payments received from other coverages. Payouts for Uninsured Motorist w/ Standard Underinsured Motorist are reduced by payments receive from disability benefits/carriers or workers compensation for injuries.',
        },
        FL: {
          title: 'Uninsured Motorist',
          primaryText:
            'May cover expenses for bodily injury damages or death caused by a driver who did not have liability. Applies to damages for you as well as passengers in your vehicle for a covered loss.',
          subCoverages: {
            UninsuredMotoristCoverageType: {
              title: 'Uninsured Motorist Type',
              primaryText:
                'Stacked coverage allows the insured to combine the UM coverage limit for one vehicle with the UM coverage limit of other vehicles in the event of a covered loss. Does not apply if only 1 vehicle.',
            },
          },
        },
      },
    },
    UninsuredMotoristUnderInsuredMotoristUMPD: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
      title: 'Uninsured/Underinsured',
      primaryText:
        'May cover damage to you and your vehicle in a covered loss caused by a driver who did not have insurance.',
      secondaryText:
        'The first amount is the coverage limit per person; the second is the coverage limit per accident and the third is the coverage limit for damage to your vehicle. Coverage is available in two forms. Added On (to at-fault liability limits) or Reduced (at-fault liability limits).',
      subCoverages: {
        UninsuredMotoristCoverageType: {
          title: 'Type',
        },
      },
      stateOptions: {
        VA: {
          title: 'Uninsured/Underinsured Motorist/Uninsured Motorist Property Damage',
          primaryText:
            'May pay for damages for a covered loss caused by a driver with insufficient liability coverage. The first amount is the coverage limit per person; the second is per accident and the third is for damage to your vehicle.',
          secondaryText: (
            <>
              <p>
                <strong>Standard:</strong> Pays up to the Uninsured Motorist (UM) limits for your
                policy in addition to the at-fault driver’s liability limits.
              </p>
              <p>
                <strong>Alternative:</strong> Pays only the difference between the at-fault driver’s
                liability limits and the UM coverage limits for your policy.
              </p>
            </>
          ),
        },
      },
    },
    PersonalInjuryProtectionLimit: {
      primaryImage: GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
      title: 'Personal Injury Protection Limit',
      primaryText:
        'Coverage may pay expenses the eligible injured person incurs for medical expenses no matter who causes the accident.',
      secondaryText:
        'It may also include coverage for loss of income, essential services and funeral expenses.',
      subCoverages: {
        PersonalInjuryProtectionMedicalExpense: {
          title: 'Additional Medical Expense',
          primaryText:
            'Additional coverage that covers the eligible person’s medical expenses due to injuries during an auto accident.',
        },
        PersonalInjuryProtectionWorkLoss: {
          title: 'Work Loss',
          primaryText:
            'Additional coverage for income loss protection if you or your family are involved in an accident & unable to work. Reject only if you or a family member is 65+ or 60+, retired & receive a pension.',
        },
        PersonalInjuryProtectionDeathDisability: {
          title: 'Additional Death and Disability',
          primaryText:
            'Additional coverage for the insured’s economic loss due to injuries or death in an auto accident. This includes work loss, essential services and survivor loss benefits.',
        },
        PersonalInjuryProtectionDeductible: {
          title: 'Deductible',
          primaryText:
            'Amount you will pay out-of-pocket before your insurance will pay for any eligible medical expense under PIP coverage. Applies to named insureds and dependent residence relatives.',
        },
        PersonalInjuryProtectionDeductibleAppliesTo: {
          title: 'Additional Coverage for Household Members',
          primaryText:
            'Additional Personal Injury Protection coverage for just the Named Insured or Named Insured spouse and resident relatives.',
        },
      },
      stateOptions: {
        UT: {
          primaryText:
            'Coverage may pay expenses to the eligible injured person for medical expenses no matter who causes the accident. This may also cover income loss when involved in an auto accident and unable to work.',
          secondaryText:
            'This covers the named insured, spouse or resident relative. Work Loss is included with PIP but can be waived if neither you or your spouse has received any earned income from employment during the 31 days prior to applying or anticipate any earned income from employment for at least 180 days.',
        },
        MA: {
          primaryText:
            'Coverage may pay expenses to the eligible injured person for medical expenses no matter who causes the accident. This may also cover income loss when involved in an auto accident and unable to work.',
          secondaryText:
            'The PIP Deductible is the amount you must pay out-of-pocket before insurance will pay for eligible medical expenses. The deductible applies to the named insureds and dependent resident relatives.',
          subCoverages: {
            PersonalInjuryProtectionDeductible: {
              title: 'Deductible',
            },
            PersonalInjuryProtectionDeductibleAppliesTo: {
              title: 'Deductible Applies To',
            },
          },
        },
        MN: {
          primaryText:
            'Coverage may pay expenses to the eligible injured person for medical expenses no matter who causes the accident.',
          secondaryText:
            'Covers the insured’s medical expenses, loss of income, essential services, survivor’s loss and funeral expenses. May cover insured if injured while in another vehicle or if struck as a pedestrian.',
        },
        KY: {
          primaryText:
            'Pays expenses the eligible injured person incurs for medical expenses no matter who causes the accident. It may also include coverage for loss of income, essential services and funeral expenses.',
          secondaryText:
            'Additional coverage for the named insured and resident relatives listed on the policy: for work loss due to injuries or death in an auto accident. The limit you select, plus the PIP limit, is the most that may be paid per accident.',
          subCoverages: {
            PersonalInjuryProtectionMedicalExpense: {
              title: 'Additional Personal Injury Protection',
            },
          },
        },
        NJ: {
          primaryText:
            'Pays expenses the eligible insured person incurs for medical expenses no matter who caused the accident.',
          secondaryText:
            'May also include coverage for loss of income, essential services and funeral expenses.',
          subCoverages: {
            PersonalInjuryProtectionMedicalExpense: {
              title: 'Extended Medical Expense',
              primaryText:
                'Provides coverage for a Named Insured and Residence Relative while occupying certain commercial vehicles or if struck by a commercial vehicle as a pedestrian. Choosing this coverage has a $1,000 limit included, you may increase up to $10,000.',
            },
            PersonalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'The amount you must pay out-of-pocket before your insurance will pay for any eligible medical expenses under PIP coverage. The deductible applies to the named insureds and depending residence relatives (those related to named insured, and who live in the household).',
            },
            PersonalInjuryProtectionDeductibleAppliesTo: {
              title: 'Additional Coverage for Household Members',
              primaryText:
                'Additional Personal Injury Protection coverage for just the Named Insured or Named Insured spouse and resident relatives.',
            },
            PersonalInjuryProtectionAdditionalRelatives: {
              title: 'Additional Resident Relatives',
              primaryText:
                'If covering additional resident relatives select the number of resident relatives to be covered under additional PIP, not including your spouse.',
            },
            PersonalInjuryProtectionLawsuit: {
              title: 'Lawsuit Option',
              primaryText:
                'Unlimited Lawsuit allows you & household members on the policy the right to seek compensation for pain & suffering or economic damages not covered by PIP, health insurance, workers compensation or other disability insurance. Limited Lawsuit limits your ability to sue for any items listed above.',
            },
            PersonalInjuryProtectionHealthInsurer: {
              title: 'PIP as Primary',
              primaryText:
                'For PIP Coverages related to medical expenses due to an accident you may choose ‘Health Insurer as Primary’ meaning your healthcare insurer is the primary insurer (Medicare & Medicaid do not qualify) or you may choose ‘PIP as Primary” and our insurance company will be the primary insurer.',
              isPipSubCoverage: true,
            },
            HealthInsuranceName1: {
              title: 'Health insurer name',
              isPipSubCoverage: true,
            },
            HealthInsurancePolicyNumber1: {
              title: 'Policy number',
              isPipSubCoverage: true,
            },
            HealthInsuranceName2: {
              title: 'Health insurer name 2',
              isPipSubCoverage: true,
            },
            HealthInsurancePolicyNumber2: {
              title: 'Policy number 2',
              isPipSubCoverage: true,
            },
            PersonalInjuryProtectionPackageOption: {
              title: 'Additional Limits',
              primaryText:
                'Provides additional coverage limits for the insured’s income continuation, essential services, death benefits and funeral expenses due to injuries or death from in an auto accident. The limit you select is the most that may be paid per accident after the PIP coverage limit has been exceeded.',
            },
          },
        },
        PA: {
          primaryText:
            'Pays medical expenses for the insured person’s injuries no matter who caused the accident. Coverage applies even if injured while riding in another vehicle or if struck as a pedestrian.',
          secondaryText:
            'Pennsylvania law requires $5,000 of Basic First Party Benefits. This limit is the most that may be paid to each insured on the policy per accident.',
          title: 'First Party Benefits',
          subCoverages: {
            PersonalInjuryProtectionMedicalExpense: {
              title: 'Additional Medical Expense',
              primaryText:
                'Covers medical expenses from an accident. Extraordinary Benefits includes medical expenses in excess of $100K with a lifetime limit of $1M. The limit selected is the most to be paid per accident after first party benefits. Combination is all of PIP with a limit of $177,500/accident up to 3 years.',
            },
            PersonalInjuryProtectionWorkLoss: {
              title: 'Work Loss',
              primaryText:
                'Provides work loss protection when insured is involved in an accident and unable to work. Provides up to 80% of gross income per accident (and a maximum of the limit selected).',
            },
            PersonalInjuryProtectionDeathDisability: {
              title: 'Accidental Death',
              primaryText:
                'Provides benefits, paid to the insured’s family or beneficiary, when the death of an insured or family member is a result of an auto accident.',
            },
            PersonalInjuryProtectionFuneralExpense: {
              title: 'Funeral Expense',
              primaryText:
                'Pays funeral and funeral related expenses when the insured’s death is the result of an auto accident.',
            },
            PersonalInjuryProtectionTort: {
              title: 'Tort',
              primaryText:
                'Full Tort provides unrestricted right to seek financial compensation for injuries caused by other drivers. Coverage includes medical, out-of-pocket expenses & compensation for pain & suffering. Limited Tort limits your right of financial compensation except for medical & out-of-pocket expenses.',
            },
          },
        },
        OR: {
          primaryText:
            'Pays basic economic loss for injuries no matter who caused the accident. Covers medical expenses, loss of income, essential services & funeral expenses.',
          secondaryText:
            'Coverage includes both the insured and those in the vehicle at the time of the accident, or if hit as a pedestrian.',
          subCoverages: {
            PersonalInjuryProtectionDeductible: {
              title: 'Personal Injury Protection Deductible',
              primaryText:
                'PIP Deductible is the amount you pay out-of-pocket before insurance pays for eligible medical expenses. The deductible applies to named insureds and dependent resident relatives.',
            },
          },
        },
        DC: {
          primaryText:
            'Pays expenses the eligible injured person incurs for medical expenses no matter who causes the accident. It may also include coverage for loss of income and funeral expenses.',
          subCoverages: {
            PersonalInjuryProtectionMedicalExpense: {
              title: 'Medical & Rehabilitation Expense',
              primaryText:
                'Includes coverage for reasonably necessary products, services and accommodations for care, recovery or rehabilitation. This also covers the insured in injured while riding in someone else’s car at the time of the accident or if struck as a pedestrian.',
            },
            PersonalInjuryProtectionWorkLoss: {
              title: 'Work Loss',
              primaryText:
                'Provides protection for income loss when an insured is involved in an auto accident and is unable to work. It also covers expenses for ordinary and necessary tasks the insured would have performed for personal or family benefit had it not been for the bodily injury.',
            },
            PersonalInjuryProtectionFuneralExpense: {
              title: 'Funeral Expense',
              primaryText:
                'Pays funeral and funeral related expenses when the insured person’s death is the result of an auto accident.',
            },
          },
        },
        WA: {
          primaryText:
            'Pays expenses the eligible injured person incurs for medical expenses no matter who causes the accident. It may also include coverage for loss of income, essential services and funeral expenses.',
        },
        NY: {
          primaryText:
            'Pays basic economic loss expenses for the eligible person’s injuries (including passengers in the insured vehicle and pedestrians struck by the insured vehicle) no matter who caused the auto accident.',
          secondaryText:
            'Provides coverage for medical expenses, loss of income, essential services and funeral expenses. New York law requires you to have $50,000 of PIP coverage and a $2,000 death benefit per person. This limit is the most that may be paid to each eligible person per accident.',
          subCoverages: {
            PersonalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'The amount you must pay out-of-pocket before your insurance will pay for any eligible expenses under PIP coverage. The deductible applies to the Named Insureds and Dependent Resident Relatives.',
            },
            PersonalInjuryProtectionDeathDisability: {
              title: 'Death Benefit',
              primaryText:
                'Provides additional coverage, paid to an insured person’s estate, when his or her death is the result of an auto accident. The minimum limit allowed for this coverage is $2,000. You may increase the minimum by $3,000, for a total coverage limit of $5,000.',
            },
            PersonalInjuryProtectionOBEL: {
              title: 'Optional Basic Economic Loss (OBEL)',
              primaryText:
                'This coverage is paid after the Basic PIP Medical Expense coverage and additional PIP coverage limits have been exceeded. These combined limits are the most that may be paid per accident.',
            },
          },
        },
        AR: {
          primaryText:
            'Pays expenses the eligible injured person incurs for medical expenses, accidental death and income loss due to an auto accident. The coverage applies to all individuals in the vehicle at the time of the accident.',
          secondaryText: '',
          subCoverages: {
            PersonalInjuryProtectionMedicalExpense: {
              title: 'Personal Injury Protection - Medical',
              primaryText:
                'Medical payments will be paid for expenses incurred within two years from the date of the accident for: hospital, x-ray, professional nursing, dental, surgical, ambulance, prosthetic & funeral expenses; & any non-medical remedial care and treatment with a recognized religious method of healing.',
            },
            PersonalInjuryProtectionWorkLoss: {
              title: 'Personal Injury Protection - Work Loss',
              primaryText:
                'Loss of income from work that would have been performed by insured if not injured. If a non-earner expenses occurred in obtaining ordinary & necessary services instead of those that the insured would have performed, without income & for benefit of them or family if not injured.',
            },
            PersonalInjuryProtectionDeathDisability: {
              title: 'Personal Injury Protection - Accidental Death',
              primaryText:
                'A death benefit will be paid if bodily injury resulting from the accident causes the death of an insured person within one year from the date of the accident. The bodily injury must be the sole cause of death.',
            },
          },
        },
        FL: {
          primaryText:
            'Pays expenses the eligible injured person incurs for medical expenses no matter who causes the accident. It may also include coverage for loss of income, essential services and funeral expenses.',
          secondaryText: '',
          subCoverages: {
            PersonalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'The amount you must pay out-of-pocket before insurance pays for any eligible medical expenses under PIP. The deductible may apply to named insured only or include dependent resident relatives.',
            },
            PersonalInjuryProtectionWorkLoss: {
              title: 'Work Loss Rejection',
              primaryText:
                'You may select coverage for the lost income of the Named Insured or Dependent Resident Relatives who are involved in an auto accident and unable to work.',
            },
            PersonalInjuryProtectionDeductibleAppliesTo: {
              title: 'Deductible Applies To',
              primaryText:
                'You may select who the deductible applies to if deductible is other than zero.',
            },
          },
        },
        HI: {
          primaryText:
            'Pays for injuries no matter who caused the accident. The deductible chosen applies to the named insureds & resident relatives. A $10,000 medical limit is the max you may receive per accident.',
          secondaryText:
            'Optional PIP coverage includes: Wage Loss, Death Benefits, Funeral Expenses and Alternative Provider Expenses – Pays naturopathic, acupuncture, and non-medical remedial care and treatment which relies on "spiritual means" through prayer for healing.',
          subCoverages: {
            PersonalInjuryProtectionMedicalExpense: {
              title: 'Alternative Provider Expense',
              primaryText:
                ' Pays for naturopathic, acupuncture, and non-medical remedial care and treatment rendered in accordance with the teachings, faith or belief of any group which relies on "spiritual means" through prayer for healing.',
            },
            PersonalInjuryProtectionWorkLoss: {
              title: 'Wage Loss',
              primaryText:
                'May cover lost wages up to 80% of the insured’s gross earnings. Payments from worker’s compensation and PIP shall not exceed 80% of the insured’s monthly PIP wage loss coverage.',
            },
            PersonalInjuryProtectionFuneralExpense: {
              title: 'Funeral Expense',
              primaryText:
                'Pays funeral and funeral related expenses when the insured’s death is the result of an auto accident.',
            },
            PersonalInjuryProtectionDeathDisability: {
              title: 'Death Benefit',
              primaryText:
                'Provides coverage for the named insureds in the event death occurs from an auto accident. Payments will be paid to the surviving spouse and dependent children. In the event there is not a surviving spouse or dependent children, the death benefit is paid to the injured person’s estate.',
            },
          },
        },
        DE: {
          primaryText:
            'Pays expenses the eligible injured person incurs. If within the state coverage applies to all individuals in the vehicle, if outside the state coverage is provided by everyone’s individual policy.',
          secondaryText:
            'It may also include coverage for medical expenses, loss of income, essential services, funeral expenses up to $5,000 and Property Damage Coverage up to $10,000.',
          subCoverages: {
            PersonalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'The amount you must pay out-of-pocket before your insurance will pay for any eligible medical expenses under PIP coverage. The deductible applies to the named insureds and depending residence relatives (those related to named insured, and who live in the household).',
            },
            PersonalInjuryProtectionDeductibleAppliesTo: {
              title: 'Deductible Applies To',
            },
          },
        },
        KS: {
          personalInjuryTable: PersonalInjuryProtectionMetadata['connect.auto'].KS,
          primaryText:
            'This coverage may pay for certain expenses related to an eligible insured’s injuries resulting from a motor vehicle accident, regardless of fault.',
          secondaryText: '',
        },
      },
    },
    UninsuredMotoristPropertyDamageLimit: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
      title: 'Uninsured Motorist Property Damage Limit',
      primaryText:
        'May cover damages to your vehicle in a covered loss caused by a driver who did not have insurance.',
      secondaryText:
        'The first amount is the coverage limit per person; the second amount is the coverage limit per accident.',
      stateOptions: {
        NJ: {
          primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageLimitImageUrl,
          title: 'Uninsured Motorist Property Damage Limit',
          primaryText:
            'May cover damages to your vehicle in a covered loss caused by a driver who did not have insurance.',
          secondaryText:
            'A $500 deductible applies before your insurance will pay for an Uninsured Motorist Property Damage coverage claim.',
        },
        TN: {
          primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageLimitImageUrl,
          title: 'Uninsured Motorist Property Damage Limit',
          primaryText:
            'May cover damages to your vehicle in a covered loss caused by a driver who did not have insurance.',
          secondaryText:
            'A $200 deductible applies before your insurance will pay for an Uninsured Motorist Property Damage coverage claim.',
        },
      },
    },
    UnderInsuredMotoristPropertyDamageLimit: {
      primaryImage: GraphicAutoCoverageUnderinsuredUninsuredMotoristImageUrl,
      title: 'Underinsured Motorist Property Damage',
      primaryText:
        'May cover damages to your vehicle in a covered loss caused by a driver who does not have sufficient liability limits or by an unidentified motorist who has left the scene.',
      stateOptions: {
        WA: {
          primaryText:
            'Coverage for damage to your vehicle caused by a driver who did not have enough insurance. A deductible may apply before your insurance pays for a claim.',
        },
      },
    },
    SupplementalSpousalLiability: {
      primaryImage: GraphicAutoCoverageSupplementalSpousalLiabilityImageUrl,
      title: 'Supplemental Spousal Liability Coverage',
      primaryText:
        'When elected, this coverage allows the policy Bodily Injury (BI) liability coverage to cover an insured spouse in the event they are found responsible for the death or injury to their spouse in an auto accident if the medical bills exhaust their PIP coverage.',
      secondaryText:
        'The spouse is injured in the vehicle while the other spouse is driving, and the spouse driving is at fault. The injured spouse (passenger) may collect under the BI portion of the policy.',
    },
    IncomeLoss: {
      primaryImage: GraphicAutoCoverageFirstPartyBenefitsImageUrl,
      title: 'Income Loss Benefits',
      primaryText:
        'Provides income loss protection if an insured is involved in an auto accident and is unable to work. Selection of this coverage will provide benefits of $100 per person/per week.',
    },
  },
  'connect-boost.auto': {
    BodilyInjury: {
      primaryImage: GraphicAutoCoverageBodilyInjuryImageUrl,
      title: 'Bodily Injury',
      primaryText:
        'Coverage may pay damages owed to others as a result of physical injuries, sickness, disease or death of any person for which you are legally liable as a result of a covered accident.',
      secondaryText:
        'It covers legal defense if another party in the accident files a lawsuit against you. The first amount shown is the coverage limit per person; the second amount is the coverage limit per accident.',
    },
    PropertyDamage: {
      primaryImage: GraphicAutoCoveragePropertyDamageImageUrl,
      title: 'Property Damage',
      primaryText:
        'Coverage may pay for damage to other people’s property resulting from an accident caused by your vehicle for which you are legally responsible and is covered under your policy.',
      secondaryText:
        'Property Damage Liability coverage helps protect your assets by covering the losses so you don’t have to pay for them out-of-pocket. Also covers legal defense if another party files a lawsuit against you.',
    },
    BodilyInjuryPropertyDamage: {
      primaryImage: GraphicAutoCoverageDamageInjuryImageUrl,
      title: 'Bodily Injury & Property Damage',
      primaryText:
        'May cover damages owed to others related to Bodily Injury (BI) & Property Damage (PD) for which you are legally liable as a result of a covered auto accident.',
      secondaryText:
        'BI pays damages as a result of physical injuries, sickness, disease or death. PD pays damages to other people’s property. The limits shown represent dollar limits per person and per accident. BI/PD also pays legal defense.',
    },
    MedicalExpense: {
      primaryImage: GraphicAutoCoverageMedicalExpenseImageUrl,
      title: 'Medical Expense',
      primaryText:
        'Coverage may pay for expenses for all passengers in the insured vehicle for medical or funeral expenses resulting from a covered vehicle related accident.',
      secondaryText:
        'Coverage applies after any available personal health insurance has been exhausted, and the limit is the most that may be paid to each insured person per accident.',
    },
    UninsuredMotorist: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristImageUrl,
      title: 'Uninsured Motorist',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who did not have liability. Applies to damages for you as well as passengers in your vehicle for a covered loss.',
      secondaryText:
        'The first amount is the coverage limit per person; the second amount is the coverage limit per accident.',
      subCoverages: {
        UninsuredMotoristCoverageType: {
          title: 'UM Coverage Type',
          primaryText:
            'Stacked coverage allows the insured to combine the UM coverage limit for one vehicle with the UM coverage limit of other vehicles in the event of a covered loss.',
        },
      },
    },
    UnderInsuredMotorist: {
      primaryImage: GraphicAutoCoverageUnderinsuredUninsuredMotoristImageUrl,
      title: 'Underinsured Motorist',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who does not have sufficient liability insurance limits to cover the full amount of a covered loss.',
      secondaryText:
        'Applies to damages for you as well as passengers in your insured vehicle. The first amount is the coverage limit per person; the second amount is the coverage limit per accident.',
      subCoverages: {
        // V3 naming
        UnderInsuredMotoristCoverageType: {
          title: 'UIM Coverage Type',
          primaryText:
            'Stacked coverage allows the insured to combine the UIM coverage limit for one vehicle with the UIM coverage limit of other vehicles in the event of a covered loss. Does not apply if only 1 vehicle.',
        },
        // V4 naming
        'underInsuredMotoristCoverageType.coverageValue': {
          title: 'UIM Coverage Type',
          primaryText:
            'Stacked coverage allows the insured to combine the UIM coverage limit for one vehicle with the UIM coverage limit of other vehicles in the event of a covered loss. Does not apply if only 1 vehicle.',
        },
      },
    },
    UninsuredMotoristUnderInsuredMotorist: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristImageUrl,
      title: 'Uninsured or Underinsured Motorist',
      primaryText:
        'May pay for bodily injury damages or death caused by a driver that did not have sufficient liability coverage. This applies to damages for you and any passengers in the vehicle for a covered loss.',
      secondaryText:
        'The first amount is the coverage limit per person; the second amount is the coverage limit per accident.',
      subCoverages: {
        UninsuredMotoristCoverageType: {
          title: 'Uninsured Motorist Type',
        },
      },
      stateOptions: {
        MD: {
          primaryText:
            'You may select from two types of coverage, Uninsured Motorist or Enhanced Underinsured Motorist.',
          secondaryText:
            'Uninsured Motorist provides protection when the at-fault vehicle is uninsured or underinsured. If there is a claim the coverage limit is reduced by the amount of any available coverage of the at-faults insurer. Enhanced Underinsured Motorist provides the same coverage but is not reduced by a claim.',
        },
        CT: {
          primaryText:
            'Provides coverage for injuries incurred by an insured caused by operators of an uninsured motor vehicle. It may include payments for certain medical expenses, lost wages, and pain and suffering.',
          secondaryText:
            'Payouts for Uninsured Motorist w/ Conversion Underinsured Motorist are not reduced by payments received from other coverages. Payouts for Uninsured Motorist w/ Standard Underinsured Motorist are reduced by payments receive from disability benefits/carriers or workers compensation for injuries.',
        },
        FL: {
          title: 'Uninsured Motorist',
          primaryText:
            'May cover expenses for bodily injury damages or death caused by a driver who did not have liability. Applies to damages for you as well as passengers in your vehicle for a covered loss.',
          subCoverages: {
            UninsuredMotoristCoverageType: {
              title: 'Uninsured Motorist Type',
              primaryText:
                'Stacked coverage allows the insured to combine the UM coverage limit for one vehicle with the UM coverage limit of other vehicles in the event of a covered loss. Does not apply if only 1 vehicle.',
            },
          },
        },
      },
    },
    UninsuredMotoristUnderInsuredMotoristUMPD: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
      title: 'Uninsured/Underinsured',
      primaryText:
        'May cover damage to you and your vehicle in a covered loss caused by a driver who did not have insurance.',
      secondaryText:
        'The first amount is the coverage limit per person; the second is the coverage limit per accident and the third is the coverage limit for damage to your vehicle. Coverage is available in two forms. Added On (to at-fault liability limits) or Reduced (at-fault liability limits).',
      subCoverages: {
        UninsuredMotoristCoverageType: {
          title: 'Type',
        },
      },
      stateOptions: {
        VA: {
          title: 'Uninsured/Underinsured Motorist/Uninsured Motorist Property Damage',
          primaryText:
            'May pay for damages for a covered loss caused by a driver with insufficient liability coverage. The first amount is the coverage limit per person; the second is per accident and the third is for damage to your vehicle.',
          secondaryText: (
            <>
              <p>
                <strong>Standard:</strong> Pays up to the Uninsured Motorist (UM) limits for your
                policy in addition to the at-fault driver’s liability limits.
              </p>
              <p>
                <strong>Alternative:</strong> Pays only the difference between the at-fault driver’s
                liability limits and the UM coverage limits for your policy.
              </p>
            </>
          ),
        },
      },
    },
    PersonalInjuryProtectionLimit: {
      primaryImage: GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
      title: 'Personal Injury Protection Limit',
      primaryText:
        'Coverage may pay expenses the eligible injured person incurs for medical expenses no matter who causes the accident.',
      secondaryText:
        'It may also include coverage for loss of income, essential services and funeral expenses.',
      subCoverages: {
        PersonalInjuryProtectionMedicalExpense: {
          title: 'Additional Medical Expense',
          primaryText:
            'Additional coverage that covers the eligible person’s medical expenses due to injuries during an auto accident.',
        },
        PersonalInjuryProtectionWorkLoss: {
          title: 'Work Loss',
          primaryText:
            'Additional coverage for income loss protection if you or your family are involved in an accident & unable to work. Reject only if you or a family member is 65+ or 60+, retired & receive a pension.',
        },
        PersonalInjuryProtectionDeathDisability: {
          title: 'Additional Death and Disability',
          primaryText:
            'Additional coverage for the insured’s economic loss due to injuries or death in an auto accident. This includes work loss, essential services and survivor loss benefits.',
        },
        PersonalInjuryProtectionDeductible: {
          title: 'Deductible',
          primaryText:
            'Amount you will pay out-of-pocket before your insurance will pay for any eligible medical expense under PIP coverage. Applies to named insureds and dependent residence relatives.',
        },
        PersonalInjuryProtectionDeductibleAppliesTo: {
          title: 'Additional Coverage for Household Members',
          primaryText:
            'Additional Personal Injury Protection coverage for just the Named Insured or Named Insured spouse and resident relatives.',
        },
      },
      stateOptions: {
        UT: {
          primaryText:
            'Coverage may pay expenses to the eligible injured person for medical expenses no matter who causes the accident. This may also cover income loss when involved in an auto accident and unable to work.',
          secondaryText:
            'This covers the named insured, spouse or resident relative. Work Loss is included with PIP but can be waived if neither you or your spouse has received any earned income from employment during the 31 days prior to applying or anticipate any earned income from employment for at least 180 days.',
        },
        MA: {
          primaryText:
            'Coverage may pay expenses to the eligible injured person for medical expenses no matter who causes the accident. This may also cover income loss when involved in an auto accident and unable to work.',
          secondaryText:
            'The PIP Deductible is the amount you must pay out-of-pocket before insurance will pay for eligible medical expenses. The deductible applies to the named insureds and dependent resident relatives.',
          subCoverages: {
            PersonalInjuryProtectionDeductible: {
              title: 'Deductible',
            },
            PersonalInjuryProtectionDeductibleAppliesTo: {
              title: 'Deductible Applies To',
            },
          },
        },
        MN: {
          primaryText:
            'Coverage may pay expenses to the eligible injured person for medical expenses no matter who causes the accident.',
          secondaryText:
            'Covers the insured’s medical expenses, loss of income, essential services, survivor’s loss and funeral expenses. May cover insured if injured while in another vehicle or if struck as a pedestrian.',
        },
        KY: {
          primaryText:
            'Pays expenses the eligible injured person incurs for medical expenses no matter who causes the accident. It may also include coverage for loss of income, essential services and funeral expenses.',
          secondaryText:
            'Additional coverage for the named insured and resident relatives listed on the policy: for work loss due to injuries or death in an auto accident. The limit you select, plus the PIP limit, is the most that may be paid per accident.',
          subCoverages: {
            PersonalInjuryProtectionMedicalExpense: {
              title: 'Additional Personal Injury Protection',
            },
          },
        },
        NJ: {
          primaryText:
            'Pays expenses the eligible insured person incurs for medical expenses no matter who caused the accident.',
          secondaryText:
            'May also include coverage for loss of income, essential services and funeral expenses.',
          subCoverages: {
            PersonalInjuryProtectionMedicalExpense: {
              title: 'Extended Medical Expense',
              primaryText:
                'Provides coverage for a Named Insured and Residence Relative while occupying certain commercial vehicles or if struck by a commercial vehicle as a pedestrian. Choosing this coverage has a $1,000 limit included, you may increase up to $10,000.',
            },
            PersonalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'The amount you must pay out-of-pocket before your insurance will pay for any eligible medical expenses under PIP coverage. The deductible applies to the named insureds and depending residence relatives (those related to named insured, and who live in the household).',
            },
            PersonalInjuryProtectionDeductibleAppliesTo: {
              title: 'Additional Coverage for Household Members',
              primaryText:
                'Additional Personal Injury Protection coverage for just the Named Insured or Named Insured spouse and resident relatives.',
            },
            PersonalInjuryProtectionAdditionalRelatives: {
              title: 'Additional Resident Relatives',
              primaryText:
                'If covering additional resident relatives select the number of resident relatives to be covered under additional PIP, not including your spouse.',
            },
            PersonalInjuryProtectionLawsuit: {
              title: 'Lawsuit Option',
              primaryText:
                'Unlimited Lawsuit allows you & household members on the policy the right to seek compensation for pain & suffering or economic damages not covered by PIP, health insurance, workers compensation or other disability insurance. Limited Lawsuit limits your ability to sue for any items listed above.',
            },
            PersonalInjuryProtectionHealthInsurer: {
              title: 'PIP as Primary',
              primaryText:
                'For PIP Coverages related to medical expenses due to an accident you may choose ‘Health Insurer as Primary’ meaning your healthcare insurer is the primary insurer (Medicare & Medicaid do not qualify) or you may choose ‘PIP as Primary” and our insurance company will be the primary insurer.',
              isPipSubCoverage: true,
            },
            HealthInsuranceName1: {
              title: 'Health insurer name',
              isPipSubCoverage: true,
            },
            HealthInsurancePolicyNumber1: {
              title: 'Policy number',
              isPipSubCoverage: true,
            },
            HealthInsuranceName2: {
              title: 'Health insurer name 2',
              isPipSubCoverage: true,
            },
            HealthInsurancePolicyNumber2: {
              title: 'Policy number 2',
              isPipSubCoverage: true,
            },
            PersonalInjuryProtectionPackageOption: {
              title: 'Additional Limits',
              primaryText:
                'Provides additional coverage limits for the insured’s income continuation, essential services, death benefits and funeral expenses due to injuries or death from in an auto accident. The limit you select is the most that may be paid per accident after the PIP coverage limit has been exceeded.',
            },
          },
        },
        PA: {
          primaryText:
            'Pays medical expenses for the insured person’s injuries no matter who caused the accident. Coverage applies even if injured while riding in another vehicle or if struck as a pedestrian.',
          secondaryText:
            'Pennsylvania law requires $5,000 of Basic First Party Benefits. This limit is the most that may be paid to each insured on the policy per accident.',
          title: 'First Party Benefits',
          subCoverages: {
            PersonalInjuryProtectionMedicalExpense: {
              title: 'Additional Medical Expense',
              primaryText:
                'Covers medical expenses from an accident. Extraordinary Benefits includes medical expenses in excess of $100K with a lifetime limit of $1M. The limit selected is the most to be paid per accident after first party benefits. Combination is all of PIP with a limit of $177,500/accident up to 3 years.',
            },
            PersonalInjuryProtectionWorkLoss: {
              title: 'Work Loss',
              primaryText:
                'Provides work loss protection when insured is involved in an accident and unable to work. Provides up to 80% of gross income per accident (and a maximum of the limit selected).',
            },
            PersonalInjuryProtectionDeathDisability: {
              title: 'Accidental Death',
              primaryText:
                'Provides benefits, paid to the insured’s family or beneficiary, when the death of an insured or family member is a result of an auto accident.',
            },
            PersonalInjuryProtectionFuneralExpense: {
              title: 'Funeral Expense',
              primaryText:
                'Pays funeral and funeral related expenses when the insured’s death is the result of an auto accident.',
            },
            PersonalInjuryProtectionTort: {
              title: 'Tort',
              primaryText:
                'Full Tort provides unrestricted right to seek financial compensation for injuries caused by other drivers. Coverage includes medical, out-of-pocket expenses & compensation for pain & suffering. Limited Tort limits your right of financial compensation except for medical & out-of-pocket expenses.',
            },
          },
        },
        OR: {
          primaryText:
            'Pays basic economic loss for injuries no matter who caused the accident. Covers medical expenses, loss of income, essential services & funeral expenses.',
          secondaryText:
            'Coverage includes both the insured and those in the vehicle at the time of the accident, or if hit as a pedestrian.',
          subCoverages: {
            PersonalInjuryProtectionDeductible: {
              title: 'Personal Injury Protection Deductible',
              primaryText:
                'PIP Deductible is the amount you pay out-of-pocket before insurance pays for eligible medical expenses. The deductible applies to named insureds and dependent resident relatives.',
            },
          },
        },
        DC: {
          primaryText:
            'Pays expenses the eligible injured person incurs for medical expenses no matter who causes the accident. It may also include coverage for loss of income and funeral expenses.',
          subCoverages: {
            PersonalInjuryProtectionMedicalExpense: {
              title: 'Medical & Rehabilitation Expense',
              primaryText:
                'Includes coverage for reasonably necessary products, services and accommodations for care, recovery or rehabilitation. This also covers the insured in injured while riding in someone else’s car at the time of the accident or if struck as a pedestrian.',
            },
            PersonalInjuryProtectionWorkLoss: {
              title: 'Work Loss',
              primaryText:
                'Provides protection for income loss when an insured is involved in an auto accident and is unable to work. It also covers expenses for ordinary and necessary tasks the insured would have performed for personal or family benefit had it not been for the bodily injury.',
            },
            PersonalInjuryProtectionFuneralExpense: {
              title: 'Funeral Expense',
              primaryText:
                'Pays funeral and funeral related expenses when the insured person’s death is the result of an auto accident.',
            },
          },
        },
        WA: {
          primaryText:
            'Pays expenses the eligible injured person incurs for medical expenses no matter who causes the accident. It may also include coverage for loss of income, essential services and funeral expenses.',
        },
        NY: {
          primaryText:
            'Pays basic economic loss expenses for the eligible person’s injuries (including passengers in the insured vehicle and pedestrians struck by the insured vehicle) no matter who caused the auto accident.',
          secondaryText:
            'Provides coverage for medical expenses, loss of income, essential services and funeral expenses. New York law requires you to have $50,000 of PIP coverage and a $2,000 death benefit per person. This limit is the most that may be paid to each eligible person per accident.',
          subCoverages: {
            PersonalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'The amount you must pay out-of-pocket before your insurance will pay for any eligible expenses under PIP coverage. The deductible applies to the Named Insureds and Dependent Resident Relatives.',
            },
            PersonalInjuryProtectionDeathDisability: {
              title: 'Death Benefit',
              primaryText:
                'Provides additional coverage, paid to an insured person’s estate, when his or her death is the result of an auto accident. The minimum limit allowed for this coverage is $2,000. You may increase the minimum by $3,000, for a total coverage limit of $5,000.',
            },
            PersonalInjuryProtectionOBEL: {
              title: 'Optional Basic Economic Loss (OBEL)',
              primaryText:
                'This coverage is paid after the Basic PIP Medical Expense coverage and additional PIP coverage limits have been exceeded. These combined limits are the most that may be paid per accident.',
            },
          },
        },
        AR: {
          primaryText:
            'Pays expenses the eligible injured person incurs for medical expenses, accidental death and income loss due to an auto accident. The coverage applies to all individuals in the vehicle at the time of the accident.',
          secondaryText: '',
          subCoverages: {
            PersonalInjuryProtectionMedicalExpense: {
              title: 'Personal Injury Protection - Medical',
              primaryText:
                'Medical payments will be paid for expenses incurred within two years from the date of the accident for: hospital, x-ray, professional nursing, dental, surgical, ambulance, prosthetic & funeral expenses; & any non-medical remedial care and treatment with a recognized religious method of healing.',
            },
            PersonalInjuryProtectionWorkLoss: {
              title: 'Personal Injury Protection - Work Loss',
              primaryText:
                'Loss of income from work that would have been performed by insured if not injured. If a non-earner expenses occurred in obtaining ordinary & necessary services instead of those that the insured would have performed, without income & for benefit of them or family if not injured.',
            },
            PersonalInjuryProtectionDeathDisability: {
              title: 'Personal Injury Protection - Accidental Death',
              primaryText:
                'A death benefit will be paid if bodily injury resulting from the accident causes the death of an insured person within one year from the date of the accident. The bodily injury must be the sole cause of death.',
            },
          },
        },
        FL: {
          primaryText:
            'Pays expenses the eligible injured person incurs for medical expenses no matter who causes the accident. It may also include coverage for loss of income, essential services and funeral expenses.',
          secondaryText: '',
          subCoverages: {
            PersonalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'The amount you must pay out-of-pocket before insurance pays for any eligible medical expenses under PIP. The deductible may apply to named insured only or include dependent resident relatives.',
            },
            PersonalInjuryProtectionWorkLoss: {
              title: 'Work Loss Rejection',
              primaryText:
                'You may select coverage for the lost income of the Named Insured or Dependent Resident Relatives who are involved in an auto accident and unable to work.',
            },
            PersonalInjuryProtectionDeductibleAppliesTo: {
              title: 'Deductible Applies To',
              primaryText:
                'You may select who the deductible applies to if deductible is other than zero.',
            },
          },
        },
        HI: {
          primaryText:
            'Pays for injuries no matter who caused the accident. The deductible chosen applies to the named insureds & resident relatives. A $10,000 medical limit is the max you may receive per accident.',
          secondaryText:
            'Optional PIP coverage includes: Wage Loss, Death Benefits, Funeral Expenses and Alternative Provider Expenses – Pays naturopathic, acupuncture, and non-medical remedial care and treatment which relies on "spiritual means" through prayer for healing.',
          subCoverages: {
            PersonalInjuryProtectionMedicalExpense: {
              title: 'Alternative Provider Expense',
              primaryText:
                ' Pays for naturopathic, acupuncture, and non-medical remedial care and treatment rendered in accordance with the teachings, faith or belief of any group which relies on "spiritual means" through prayer for healing.',
            },
            PersonalInjuryProtectionWorkLoss: {
              title: 'Wage Loss',
              primaryText:
                'May cover lost wages up to 80% of the insured’s gross earnings. Payments from worker’s compensation and PIP shall not exceed 80% of the insured’s monthly PIP wage loss coverage.',
            },
            PersonalInjuryProtectionFuneralExpense: {
              title: 'Funeral Expense',
              primaryText:
                'Pays funeral and funeral related expenses when the insured’s death is the result of an auto accident.',
            },
            PersonalInjuryProtectionDeathDisability: {
              title: 'Death Benefit',
              primaryText:
                'Provides coverage for the named insureds in the event death occurs from an auto accident. Payments will be paid to the surviving spouse and dependent children. In the event there is not a surviving spouse or dependent children, the death benefit is paid to the injured person’s estate.',
            },
          },
        },
        DE: {
          primaryText:
            'Pays expenses the eligible injured person incurs. If within the state coverage applies to all individuals in the vehicle, if outside the state coverage is provided by everyone’s individual policy.',
          secondaryText:
            'It may also include coverage for medical expenses, loss of income, essential services, funeral expenses up to $5,000 and Property Damage Coverage up to $10,000.',
          subCoverages: {
            PersonalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'The amount you must pay out-of-pocket before your insurance will pay for any eligible medical expenses under PIP coverage. The deductible applies to the named insureds and depending residence relatives (those related to named insured, and who live in the household).',
            },
            PersonalInjuryProtectionDeductibleAppliesTo: {
              title: 'Deductible Applies To',
            },
          },
        },
        KS: {
          personalInjuryTable: PersonalInjuryProtectionMetadata['connect.auto'].KS,
          primaryText:
            'This coverage may pay for certain expenses related to an eligible insured’s injuries resulting from a motor vehicle accident, regardless of fault.',
          secondaryText: '',
        },
      },
    },
    UninsuredMotoristPropertyDamageLimit: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
      title: 'Uninsured Motorist Property Damage Limit',
      primaryText:
        'May cover damages to your vehicle in a covered loss caused by a driver who did not have insurance.',
      secondaryText:
        'The first amount is the coverage limit per person; the second amount is the coverage limit per accident.',
      stateOptions: {
        NJ: {
          primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageLimitImageUrl,
          title: 'Uninsured Motorist Property Damage Limit',
          primaryText:
            'May cover damages to your vehicle in a covered loss caused by a driver who did not have insurance.',
          secondaryText:
            'A $500 deductible applies before your insurance will pay for an Uninsured Motorist Property Damage coverage claim.',
        },
        TN: {
          primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageLimitImageUrl,
          title: 'Uninsured Motorist Property Damage Limit',
          primaryText:
            'May cover damages to your vehicle in a covered loss caused by a driver who did not have insurance.',
          secondaryText:
            'A $200 deductible applies before your insurance will pay for an Uninsured Motorist Property Damage coverage claim.',
        },
      },
    },
    UnderInsuredMotoristPropertyDamageLimit: {
      primaryImage: GraphicAutoCoverageUnderinsuredUninsuredMotoristImageUrl,
      title: 'Underinsured Motorist Property Damage',
      primaryText:
        'May cover damages to your vehicle in a covered loss caused by a driver who does not have sufficient liability limits or by an unidentified motorist who has left the scene.',
      stateOptions: {
        WA: {
          primaryText:
            'Coverage for damage to your vehicle caused by a driver who did not have enough insurance. A deductible may apply before your insurance pays for a claim.',
        },
      },
    },
    SupplementalSpousalLiability: {
      primaryImage: GraphicAutoCoverageSupplementalSpousalLiabilityImageUrl,
      title: 'Supplemental Spousal Liability Coverage',
      primaryText:
        'When elected, this coverage allows the policy Bodily Injury (BI) liability coverage to cover an insured spouse in the event they are found responsible for the death or injury to their spouse in an auto accident if the medical bills exhaust their PIP coverage.',
      secondaryText:
        'The spouse is injured in the vehicle while the other spouse is driving, and the spouse driving is at fault. The injured spouse (passenger) may collect under the BI portion of the policy.',
    },
    IncomeLoss: {
      primaryImage: GraphicAutoCoverageFirstPartyBenefitsImageUrl,
      title: 'Income Loss Benefits',
      primaryText:
        'Provides income loss protection if an insured is involved in an auto accident and is unable to work. Selection of this coverage will provide benefits of $100 per person/per week.',
    },
  },
};

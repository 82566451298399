import { datadogLog } from '@ecp/utils/logger';

import { fetchApi, wrapThunkActionWithErrHandler } from '@ecp/features/sales/shared/store';

import { setProducerApiPostSuccess } from './actions';
import { getProducerDetails } from './api';

export const fetchProducerDetails = wrapThunkActionWithErrHandler(
  () => async (dispatch, getState) => {
    await dispatch(
      fetchApi({
        fn: async () => {
          try {
            const response = await getProducerDetails();

            await dispatch(setProducerApiPostSuccess(response));
          } catch (error) {
            const e = error as Error;

            datadogLog({
              logType: 'error',
              message: `Error fetching Producer details - ${e?.message}`,
              context: {
                logOrigin: 'libs/features/sales/shared/store/lib/src/geo/thunks.ts',
                functionOrigin: 'fetchProducerDetailsThunkAction',
                responseError: JSON.stringify(e),
              },
              error: e,
            });
          }
        },
      }),
    );
  },
  'fetchProducerDetailsThunkAction',
);

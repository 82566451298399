import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { Events, trackEvent } from '@ecp/utils/flags';
import type { GeoAddress } from '@ecp/utils/geo';
import { useEvent } from '@ecp/utils/react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import {
  PagePath,
  useNavigateToNextPage,
  useNavigateToPage,
} from '@ecp/features/sales/shared/routing';
import {
  createAddressForUpdate,
  getLineOfBusiness,
  makeSurePrefillFlowInStore,
  updateAnswers,
  updatePageStatus,
  useField,
  usePrimaryAddress,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import { useValidateAddress } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Field, Fields } from '@ecp/types';

import { AgreementContent } from '../../components';
import { InsuredInformationPageForm } from '../../forms';
import { useStyles } from '../InsuredInformationPage/InsuredInformationPage.styles';

export interface AddressFields extends Fields {
  primaryAddress: {
    primaryLine1: Field;
    primaryLine2: Field;
    primaryCity: Field;
    primaryState: Field;
    primaryZipcode: Field;
  };
}

export const InsuredInformationPage: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const primaryAddressRef = usePrimaryAddressRef();
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const navigateToNextPage = useNavigateToNextPage();
  const address = useField('static.address');
  const primaryAddress = usePrimaryAddress();
  const [isValidAddress, setIsValidAddress] = useState<boolean>(true);
  const [geoAddressSuggestions, setGeoAddressSuggestions] = useState<GeoAddress[]>([]);
  const [showAddressSuggestion, setShowAddressSuggestion] = useState<boolean>(false);

  const validateAddress = useValidateAddress(primaryAddressRef);
  // TODO: We might need a better way to handle this. A/B test metric tracking function for personal info page view
  useEffect(() => {
    trackEvent(Events.PERSONAL_PAGE);
  }, []);

  // If a user doesn't select a suggested address and chooses to enter the address manually all fields need to be combined and validated when a user submits the form.
  const handleNext = useCallback(async () => {
    const { parsedAddress, isValidAddress, geoAddressSuggestions } = await validateAddress(
      primaryAddressRef,
    );

    if (parsedAddress && isValidAddress) {
      const addressToBeSaved = createAddressForUpdate(parsedAddress);
      setIsValidAddress(true);

      address.props.actionOnComplete(primaryAddress);

      await dispatch(updateAnswers({ answers: { ...addressToBeSaved } }));

      dispatch(updatePageStatus(NavStatus.VALID, PagePath.PERSON_ADDRESS));

      await dispatch(makeSurePrefillFlowInStore());

      await navigateToNextPage();

      return;
    } else {
      if (
        geoAddressSuggestions &&
        Array.isArray(geoAddressSuggestions) &&
        geoAddressSuggestions.length > 0
      ) {
        setGeoAddressSuggestions(geoAddressSuggestions);
        setShowAddressSuggestion(true);
      } else {
        setIsValidAddress(false);
        setShowAddressSuggestion(false);
      }
    }
  }, [
    validateAddress,
    primaryAddressRef,
    address.props,
    primaryAddress,
    dispatch,
    navigateToNextPage,
  ]);

  const navigateToPrivacyPolicyPage = useNavigateToPage(PagePath.PRIVACY_POLICY);

  const navigateToPrivacyPolicy = useEvent(() => {
    trackClick({ action: 'BodyTextPrivacyPolicyLink', label: 'PrivacyPolicy' });
    navigateToPrivacyPolicyPage();
  });

  return (
    <Page
      title='Insured Information'
      analyticsElement='choice.personPage.page'
      sidebarProps={{
        drawer: <NavbarDrawer pagePath={PagePath.PERSON} />,
      }}
    >
      <InsuredInformationPageForm
        onNext={handleNext}
        geoAddressSuggestions={geoAddressSuggestions}
        lineOfBusiness={lineOfBusiness}
        isValidAddress={isValidAddress}
        showAddressSuggestion={showAddressSuggestion}
        setShowAddressSuggestion={setShowAddressSuggestion}
        setIsValidAddress={setIsValidAddress}
      />
      <Grid item xs={12}>
        <AgreementContent
          className={classes.agreementContent}
          onNavigateToPrivacyPolicy={navigateToPrivacyPolicy}
        />
      </Grid>
    </Page>
  );
};

import { Box, Divider, Grid, Typography } from '@mui/material';

import { GridItem } from '@ecp/components';
import { getVehicleDescription, useVehicleValues } from '@ecp/features/sales/quotes/auto';
import { IconCardAuto } from '@ecp/themes/partners/amfam-adv';

import { useThirdPartyInterestPageContext } from '../../../../../common/components';
import { useStyles } from './AutoAddTPIVehicleHeader.styles';

export const AutoAddTPIVehicleHeader: React.FC = () => {
  const { classes } = useStyles();

  const { currentSelectedVehicleRef, selectedProductFromAddEditButton } =
    useThirdPartyInterestPageContext();

  const vehicle = useVehicleValues(currentSelectedVehicleRef || '');
  if (!vehicle || selectedProductFromAddEditButton !== 'auto') return null;

  return (
    <>
      <GridItem topSpacing='lg' xs={12} className={classes.productTitleSection}>
        <Box>
          <div className={classes.productIcon}>
            <IconCardAuto />
          </div>
        </Box>
        <Typography className={classes.productTitle}>Auto Policy</Typography>
      </GridItem>
      <Divider className={classes.divider} />
      <Grid container className={classes.containerDetails} key={vehicle.ref}>
        <GridItem xs={12} md={6}>
          <Typography className={classes.contentTitle}>{getVehicleDescription(vehicle)}</Typography>
          {vehicle.vin && (
            <Typography className={classes.vehicleVin}>{`VIN: ${vehicle.vin}`}</Typography>
          )}
        </GridItem>
      </Grid>
    </>
  );
};

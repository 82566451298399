import { useCallback } from 'react';

import type { AccordionProps } from '@mui/material';
import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { getAnswer } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUIExpandMore } from '@ecp/themes/base';

import { usePropertyField } from '../../utils';
import { HomeReplacementCostCard } from '../HomeReplacementCostCard';
import { useStyles } from './HomeReplacementCostAccordion.styles';

export const HomeReplacementCostAccordion: React.FC = () => {
  const { classes } = useStyles();

  const replacementCost = useSelector((state: RootStore) =>
    getAnswer(state, 'property.replacementCost'),
  );
  const replacementCostValuationId = usePropertyField('replacementCostValuationId');

  const handleExpandCollapse = useCallback<NonNullable<AccordionProps['onChange']>>(
    (_event, expanded) => {
      trackClick({
        action: `show_hide_Home_Repalacement_cost`,
        label: expanded ? 'Show' : 'Hide',
      });
    },
    [],
  );
  if (
    !replacementCost ||
    !replacementCostValuationId?.exists ||
    !replacementCostValuationId.props.value
  )
    return null;

  return (
    <GridItem topSpacing='xs' xs={12} className={classes.addSpacing}>
      <MuiAccordion
        defaultExpanded={false}
        className={classes.accordionContainer}
        onChange={handleExpandCollapse}
      >
        <AccordionSummary
          className={classes.accordionSummaryHeader}
          expandIcon={<IconUIExpandMore id='_CoveragesAccordionExpandIcon' />}
        >
          <h3>Replacement Cost</h3>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsBkg}>
          <HomeReplacementCostCard />
        </AccordionDetails>
      </MuiAccordion>
    </GridItem>
  );
};

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PersonalInjuryProtectionUtahCoverage' })((theme) => ({
  formControlCheckBox: {
    marginRight: 30,
  },
  coverageName: {
    ...theme.typography.body4,
    color: theme.palette.text.primary,
  },
  formControlLabel: {
    padding: 20,
  },
  mainCoverage: {
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
  },
  selectedMainCoverage: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
  },
  label: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    padding: '25px 0px 0px 0px',
  },
  toolTip: {
    position: 'relative',
    padding: 0,
    marginTop: -1,
  },
}));

import { useCallback } from 'react';

import { Divider, Grid, Typography } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { Alert, GridItem, RadioGroupWithOptions, Snackbar, SnackbarAlert } from '@ecp/components';
import { Button, Dialog } from '@ecp/features/sales/shared/components';
import { useField } from '@ecp/features/sales/shared/store';
import { LineOfBusiness } from '@ecp/features/shared/product';
import type { AnswerValue, Field } from '@ecp/types';

import { AutoTPICard } from '../../../../lob/autoLine';
import { PropertyTPICard } from '../../../../lob/propertyLine';
import { useThirdPartyInterestPageContext } from '../ThirdPartyInterestContextProvider';
import { useStyles } from './ThirdPartyInterestPageDetails.styles';

export const ThirdPartyInterestPageDetails: React.FC = () => {
  const { classes } = useStyles();
  const hasThirdPartyInterestInd: Field = useField('static.delta.hasThirdPartyInterestInd');

  const {
    openRemoveAllTpisDialog,
    triggerRerender,
    tpiSnackbarActionInfoType,
    tpiSnackbarActionInfoMessage,
    isSubmitting,
    selectedProductOnOffer,
    pageErrors,
    showSearch,
    showAddEditTpi,
    onCloseRemoveAllTpiDialogAction,
    handleInfoSnackbarClose,
    onHasThirdPartyInterestRadioChange,
    onSubmitOfTPIPage,
  } = useThirdPartyInterestPageContext();

  const handleDialogClose = useCallback(
    () => onCloseRemoveAllTpiDialogAction(false, hasThirdPartyInterestInd),
    [hasThirdPartyInterestInd, onCloseRemoveAllTpiDialogAction],
  );
  const handleDialogConfirm = useCallback(
    () => onCloseRemoveAllTpiDialogAction(true, hasThirdPartyInterestInd),
    [hasThirdPartyInterestInd, onCloseRemoveAllTpiDialogAction],
  );

  const handleHasThirdPartyInterestRadioChange = useCallback(
    (value: AnswerValue) => onHasThirdPartyInterestRadioChange(value, hasThirdPartyInterestInd),
    [hasThirdPartyInterestInd, onHasThirdPartyInterestRadioChange],
  );
  const handleOnSubmit = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement> | undefined) =>
      onSubmitOfTPIPage(hasThirdPartyInterestInd.value),
    [hasThirdPartyInterestInd, onSubmitOfTPIPage],
  );

  const removeDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText='Remove All TPIs?'
      textButtonLabel='Cancel'
      open={openRemoveAllTpisDialog}
      onClose={handleDialogClose}
      buttonPlacement='right'
      actionButtonOnClick={handleDialogConfirm}
      isProcessing={isSubmitting}
    >
      <div>Are you sure you want to remove all Third Party Interests?</div>
    </Dialog>
  );

  const snackbarDefault = tpiSnackbarActionInfoType === 'REMOVE' && (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={!!tpiSnackbarActionInfoType}
      autoHideDuration={2000}
      message={tpiSnackbarActionInfoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const snackbarSuccess = tpiSnackbarActionInfoType === 'ADD' && (
    <SnackbarAlert
      open
      autoHideDuration={2000}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
      severity='success'
      message={tpiSnackbarActionInfoMessage}
      hideActionButton
    />
  );

  const alertErrors = pageErrors.map((error) => {
    return (
      error.message && (
        <GridItem topSpacing='sm' xs={12}>
          <Alert withIcon type='error'>
            {error.message}
          </Alert>
        </GridItem>
      )
    );
  });

  if (showSearch || showAddEditTpi) return null;

  return (
    <Grid container className={classes.content}>
      <GridItem topSpacing='lg' xs={12}>
        {removeDialog}
        {snackbarDefault}
        {snackbarSuccess}
        <RadioGroupWithOptions
          {...hasThirdPartyInterestInd.props}
          value={hasThirdPartyInterestInd.value ? hasThirdPartyInterestInd.value : false}
          label='Do any policies have third-party interests that need to be included?'
          variant='yesNoButton'
          trackingName='PriorInsurance'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          actionOnComplete={handleHasThirdPartyInterestRadioChange}
        />
      </GridItem>
      {hasThirdPartyInterestInd.value && (
        <>
          <Divider className={classes.divider} />
          <GridItem xs={12}>
            <Typography className={classes.contentTitle}>
              Add a third-party interest where applicable.
            </Typography>
            <Typography className={classes.contentSubTitle}>
              At least one TPI must be added.
            </Typography>
          </GridItem>
          {alertErrors}
          <GridItem xs={12}>
            {(selectedProductOnOffer === LineOfBusiness.AUTO ||
              selectedProductOnOffer === LineOfBusiness.BUNDLE) && (
              <AutoTPICard key={triggerRerender} />
            )}
            {(selectedProductOnOffer === LineOfBusiness.HOME ||
              selectedProductOnOffer === LineOfBusiness.BUNDLE) && (
              <PropertyTPICard key={triggerRerender} />
            )}
          </GridItem>
        </>
      )}
      <>
        <Divider className={classes.divider} />
        <GridItem>
          <Button
            className={classes.next}
            variant='primary'
            onClick={handleOnSubmit}
            isProcessing={isSubmitting}
            trackingName='tpi_details_continue'
            trackingLabel={GoogleAnalyticsLabels.CONTINUE}
          >
            Continue
          </Button>
        </GridItem>
      </>
    </Grid>
  );
};

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoAddTPIVehicleHeader' })((theme) => ({
  contentTitle: {
    ...theme.typography.body4,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  vehicleVin: { ...theme.typography.body2, color: theme.palette.text.secondary },
  containerDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  divider: {
    margin: '40px 0px 40px 0px',
    width: '100%',
  },
  productTitleSection: {
    display: 'flex',
    alignItems: 'center',
  },
  productIcon: {
    '& svg': {
      width: 40,
      height: 40,
      ...theme.mixins.setColorSvg(theme.palette.icon.hover),
      marginRight: 16,
    },
  },
  productTitle: {
    ...theme.typography.body4Bold,
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '32px',
  },
  contentSubTitle: { ...theme.typography.body2, color: theme.palette.text.secondary },
}));

import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { env } from '@ecp/env';
import { Form } from '@ecp/features/sales/shared/components';
import {
  getNavigateToPrimaryFlowStepOnResume,
  getUpdatedPageflowsFromConfig,
} from '@ecp/features/sales/shared/routing';
import {
  getOffersForSelectedLob,
  getPolicyStartDates,
  isOfferOk,
  submitPolicyType,
  updateAnswers,
  updateLobOrder,
  useHomeSniRef,
  usePersonValues,
  useRentersSniRef,
  useSniRef,
} from '@ecp/features/sales/shared/store';
import type { Offers, ThunkAction } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import {
  type AutoProduct,
  LineOfBusiness,
  type PropertyProduct,
} from '@ecp/features/shared/product';

import { QuoteResumeContent } from './QuoteResumeContent';
import { useStyles } from './QuoteResumeErrorPageForm.styles';
interface Props {
  isBundle: boolean;
  offers: Offers;
  autoProduct?: AutoProduct;
  propertyProduct?: PropertyProduct;
  autoPLPCQuoteNumber?: string;
  propertyPLPCQuoteNumber?: string;
}

interface SubmitParams {
  lineOfBusiness: LineOfBusiness;
  offers: Offers;
  areOffersBad?: boolean;
  product?: AutoProduct | PropertyProduct;
}

const doSubmit =
  ({ lineOfBusiness, offers, areOffersBad, product }: SubmitParams): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    if (areOffersBad) {
      const jobNumber = product && offers[product]?.details?.quoteNumber;
      if (!jobNumber) return;
      // Route the bad/error product to PLPC
      window.open(`${env.advancePolicyCenterRedirectUrl}?JobNumber=${jobNumber}`, '_self');
    } else {
      // Remove the other product when continuing in ASP
      await dispatch(
        updateAnswers({
          answers: {
            'product.lob': lineOfBusiness,
          },
        }),
      );
      await dispatch(submitPolicyType());
      await dispatch(updateLobOrder(lineOfBusiness, true));
      await dispatch(getUpdatedPageflowsFromConfig({ modifiedLOB: lineOfBusiness }));
      await dispatch(getNavigateToPrimaryFlowStepOnResume());
    }
  };

export const QuoteResumeErrorPageForm: React.FC<Props> = (props) => {
  const {
    isBundle,
    offers,
    autoProduct,
    propertyProduct,
    autoPLPCQuoteNumber,
    propertyPLPCQuoteNumber,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  // Section related to SNI
  const autoSniRef = useSniRef();
  const homeSniRef = useHomeSniRef();
  const rentersSniRef = useRentersSniRef();
  const { firstName: autoSniFirstName, lastName: autoSniLastName } = usePersonValues(autoSniRef);
  const { firstName: homeSniFirstName, lastName: homeSniLastName } = usePersonValues(homeSniRef);
  const { firstName: rentersSniFirstName, lastName: rentersSniLastName } =
    usePersonValues(rentersSniRef);

  // Section related to offers
  const offersForSelectedLob = useSelector(getOffersForSelectedLob);
  const policyStartDates = useSelector(getPolicyStartDates);

  // Check if the offers eligibity is not OK
  const areAutoOffersBad = autoProduct && offers[autoProduct] && isOfferOk(offers[autoProduct]);
  const arePropertyOffersBad =
    propertyProduct && offers[propertyProduct] && isOfferOk(offers[propertyProduct]);

  const handleQuoteContinue = useCallback(
    async (
      lineOfBusiness: LineOfBusiness,
      areOffersBad?: boolean,
      product?: AutoProduct | PropertyProduct,
    ) => {
      await dispatch(
        doSubmit({
          lineOfBusiness,
          offers,
          areOffersBad,
          product,
        }),
      );
    },
    [dispatch, offers],
  );

  const handleAutoQuoteContinue = useCallback(() => {
    handleQuoteContinue(LineOfBusiness.AUTO, areAutoOffersBad, autoProduct);
  }, [handleQuoteContinue, areAutoOffersBad, autoProduct]);

  const handleHomeQuoteContinue = useCallback(() => {
    handleQuoteContinue(LineOfBusiness.HOME, arePropertyOffersBad, propertyProduct);
  }, [handleQuoteContinue, arePropertyOffersBad, propertyProduct]);

  const handleRentersQuoteContinue = useCallback(() => {
    handleQuoteContinue(LineOfBusiness.RENTERS, arePropertyOffersBad, propertyProduct);
  }, [handleQuoteContinue, arePropertyOffersBad, propertyProduct]);

  return (
    <div className={classes.root}>
      <Form>
        <Grid container>
          {offersForSelectedLob?.auto && (
            <QuoteResumeContent
              productName='Auto'
              policyStartDate={policyStartDates.auto}
              isBundle={isBundle}
              handleContinue={handleAutoQuoteContinue}
              sniFirstName={autoSniFirstName}
              sniLastName={autoSniLastName}
              areOffersBad={areAutoOffersBad}
              plpcQuoteNumber={autoPLPCQuoteNumber}
            />
          )}
          {offersForSelectedLob?.home && (
            <QuoteResumeContent
              productName='Home'
              policyStartDate={policyStartDates.property}
              isBundle={isBundle}
              handleContinue={handleHomeQuoteContinue}
              sniFirstName={homeSniFirstName}
              sniLastName={homeSniLastName}
              areOffersBad={arePropertyOffersBad}
              plpcQuoteNumber={propertyPLPCQuoteNumber}
            />
          )}
          {offersForSelectedLob?.renters && (
            <QuoteResumeContent
              productName='Renters'
              policyStartDate={policyStartDates.property}
              isBundle={isBundle}
              handleContinue={handleRentersQuoteContinue}
              sniFirstName={rentersSniFirstName}
              sniLastName={rentersSniLastName}
              areOffersBad={arePropertyOffersBad}
              plpcQuoteNumber={propertyPLPCQuoteNumber}
            />
          )}
        </Grid>
      </Form>
    </div>
  );
};

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleForm' })((theme) => ({
  ...theme.mixins.formAll,
  buttonsPanel: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  addButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: 20,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  cancelButton: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  error: {
    marginBottom: 8,
  },
  snackBarWidth: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
  addAnotherButton: {
    height: 'auto',
    padding: '4px 10px',
    textTransform: 'none',
    border: `1px solid ${theme.palette.primary.border}`,
  },
}));

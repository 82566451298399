import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SearchTPIErrorMessage' })((theme) => ({
  errorContainer: {
    '& p': {
      ...theme.typography.body2,
      color: theme.palette.error.main,
    },
    '& svg': {
      verticalAlign: 'middle',
      marginRight: 5,
    },
  },
  arrowRight: theme.mixins.setColorSvg(theme.palette.error.main),
  svgContainer: { maxWidth: 20 },
}));

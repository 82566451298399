import { createContext, useContext } from 'react';

import type { ThirdPartyInterestPageContextProps } from './types';

export const ThirdPartyInterestPageContext = createContext<
  ThirdPartyInterestPageContextProps | undefined
>(undefined);

export const ThirdPartyInterestPageProvider = ThirdPartyInterestPageContext.Provider;

export const useThirdPartyInterestPageContext = (): ThirdPartyInterestPageContextProps => {
  const context = useContext(ThirdPartyInterestPageContext);
  if (!context) {
    throw new Error(
      'useThirdPartyInterestPageContext must be used within a useThirdPartyInterestPageContext',
    );
  }

  return context;
};

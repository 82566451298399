import { useCallback } from 'react';

import { isMasked } from '@ecp/utils/common';

import type { SelectProps } from '@ecp/components';
import { GridItem } from '@ecp/components';
import {
  checkAndClearFields,
  useValidateVinNumber,
  useVehicleFields,
  validateVinFormat,
} from '@ecp/features/sales/quotes/auto';
import { yearOptions } from '@ecp/features/sales/quotes/auto';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import type { AnswerValue } from '@ecp/types';

import { useVehicleInfo } from '../../../utils/vehicleOptions';
import { useStyles } from '../VehicleInfoQuestions.styles';

interface Props extends QuestionProps {
  setMaskRequiredFieldError: (value: boolean) => void;
  vehicleRef: string;
}

export const VehicleYearQuestion: React.FC<Props> = (props) => {
  const { vehicleRef, setMaskRequiredFieldError } = props;
  const { classes } = useStyles();
  const { make, model, series, year, vin, stubVin, vehicleDetailId, vehicleInfoOrVin, msrpPrice } =
    useVehicleFields(vehicleRef);
  const { setInfoError, fetchVehicleInfo } = useVehicleInfo(
    make,
    model,
    series,
    vin,
    stubVin,
    vehicleDetailId,
    msrpPrice,
  );

  const handleYearChange: NonNullable<SelectProps['actionOnChange']> = useCallback(
    (value) => {
      setMaskRequiredFieldError(true);
      setInfoError('');
      checkAndClearFields([make, model, series, stubVin, vehicleDetailId]);
      year.props.actionOnChange(value);
      if (isMasked(vin.value)) {
        checkAndClearFields([vin]);
      }
    },
    [
      setMaskRequiredFieldError,
      setInfoError,
      vehicleDetailId,
      make,
      model,
      series,
      year.props,
      vin,
      stubVin,
    ],
  );
  const validateVinNumber = useValidateVinNumber(vin);

  const handleYearComplete = useCallback(
    async (value: AnswerValue): Promise<void> => {
      year.props.actionOnComplete(value);
      if (vehicleInfoOrVin.value !== 'vin') return;

      const vinValue = vin.value as string;

      const vinFormatValidationError = validateVinFormat(vinValue);
      if (vinFormatValidationError || isMasked(vinValue)) return;

      await fetchVehicleInfo(value as string, vinValue);
      validateVinNumber();
    },
    [year.props, vin.value, vehicleInfoOrVin.value, fetchVehicleInfo, validateVinNumber],
  );

  if (!year.exists) return null;

  return (
    <GridItem topSpacing='sm' xs={12} className={classes.vehicleInfoSelect}>
      <Select
        {...year.props}
        id='VehicleYear'
        label='Vehicle year'
        actionOnChange={handleYearChange}
        actionOnComplete={handleYearComplete}
        options={yearOptions}
        data-testid='year'
        trackingName={
          vehicleInfoOrVin.value === 'vehicleInfo' ? 'vehicle_year_selection' : 'vin_year_selection'
        }
      />
    </GridItem>
  );
};

import { useCallback, useRef, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import {
  RENTERS_POLICY_HOUSEHOLD_LOSSES,
  RENTERS_SECONDARY_INSURED_PERSON_REF,
} from '@ecp/features/sales/shared/constants';
import { SourceOfSaleQuestion } from '@ecp/features/sales/shared/questions';
import {
  getPLPCQuoteNumber,
  submitProofDraft,
  updateAnswers,
  useForm,
  useRouteToPLPC,
} from '@ecp/features/sales/shared/store';
import type {
  RootStore,
  ThunkAction,
  ValidateFormResult,
} from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { ProductName } from '@ecp/features/shared/product';
import { getProductNameFromUnbundledLob, LineOfBusiness } from '@ecp/features/shared/product';
import {
  BusinessOnPremisesQuestion,
  DaycareOnPremisesQuestion,
  HigherThanThreeStoriesQuestion,
  HomeBuiltOnSlopeQuestion,
  NoOfOccupantsQuestion,
  NumberOfDaysRentedQuestion,
  OccupancyTypeQuestion,
  PolicyCenterDialog,
  RentedToOthersQuestion,
  SingleOrMultiFamilyQuestion,
} from '@ecp/sales/lob/property';

import { HouseholdPriorLossQuestion, SecondaryNamedInsuredQuestions } from '../../../../common';
import { useStyles } from './RentersPropertyDetailsForm.styles';
import { useShouldRouteToPLPC, useUpdatedAnswers } from './utils';

interface SubmitParams {
  policyType: ProductName;
  onNext: () => Promise<void>;
  patchFormValues: () => Promise<string>;
  setIsSubmitting: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
  setCanOpenDialog?: (value: boolean) => void;
  shouldRouteToPLPC?: (lob: ProductName) => boolean;
}

interface Props {
  onNext: () => Promise<void>;
}

const doSubmit =
  ({
    policyType,
    onNext,
    patchFormValues,
    setIsSubmitting,
    validateForm,
    setCanOpenDialog,
    shouldRouteToPLPC,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    setIsSubmitting(true);
    if (validateForm().isValid) {
      await patchFormValues();

      // check if quote should be routed to PLPC once the form is valid
      if (shouldRouteToPLPC?.(policyType)) {
        setIsSubmitting(false);
        setCanOpenDialog?.(true);

        return;
      }

      await dispatch(
        submitProofDraft({
          policyTypes: [policyType],
        }),
      );
      await onNext();
    }

    setIsSubmitting(false);
  };

export const RentersPropertyDetailsForm: React.FC<Props> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const getFields = useGetFields();
  const dispatch = useDispatch();
  const getConditions = useGetConditionValues();
  const getInitValues = useGetInitValues();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: getConditions(),
  });

  // Start - Route to PLPC section
  const [canOpenDialog, setCanOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const policyType = getProductNameFromUnbundledLob(LineOfBusiness.RENTERS);
  const plpcQuoteNumber = useSelector((state: RootStore) => getPLPCQuoteNumber(state, policyType));
  // operations to perform routing to PLPC
  const routeToPLPC = useRouteToPLPC();
  // check if quote should be routed to PLPC
  const shouldRouteToPLPC = useShouldRouteToPLPC();
  // flip answers back to defaults before route to PLPC
  const updatedAnswers = useUpdatedAnswers(policyType);

  const handleRouteToPLPCDialogAction = useCallback(async () => {
    if (!plpcQuoteNumber) {
      setCanOpenDialog(false);

      return;
    }
    await dispatch(updateAnswers({ answers: updatedAnswers }));
    await routeToPLPC(setIsSubmitting, LineOfBusiness.RENTERS, plpcQuoteNumber);
  }, [dispatch, plpcQuoteNumber, routeToPLPC, updatedAnswers]);

  const handleCancelButtonOnClick = useCallback(async () => {
    setCanOpenDialog(false);

    return;
  }, []);
  // End - Route to PLPC section

  const handleSubmit = useCallback(async () => {
    await dispatch(
      doSubmit({
        policyType,
        onNext,
        patchFormValues,
        setIsSubmitting,
        validateForm,
        setCanOpenDialog,
        shouldRouteToPLPC,
      }),
    );
  }, [shouldRouteToPLPC, policyType, dispatch, onNext, patchFormValues, validateForm]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <h2>Eligibility</h2>

          <GridItem topSpacing='sm' xs={12}>
            <SourceOfSaleQuestion product='renters' />
          </GridItem>

          <GridItem topSpacing='sm' xs={12}>
            <NoOfOccupantsQuestion label='How many people live in the rental unit?' />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <HouseholdPriorLossQuestion
              priorLossKey={RENTERS_POLICY_HOUSEHOLD_LOSSES}
              priorLossHelpLabel='Has a member of the household had a property loss in the past 5 years?'
              secondaryText=''
            />
          </GridItem>

          <GridItem topSpacing='sm' xs={12}>
            <SingleOrMultiFamilyQuestion label='What type of rental unit is it?' />
          </GridItem>

          <GridItem topSpacing='sm' xs={12}>
            <OccupancyTypeQuestion label='How will this rental unit be used?' />
          </GridItem>

          <Divider orientation='horizontal' className={classes.horizontalDivider} />

          <GridItem topSpacing='sm' xs={12}>
            <HigherThanThreeStoriesQuestion />
          </GridItem>

          <GridItem topSpacing='sm' xs={12}>
            <HomeBuiltOnSlopeQuestion />
          </GridItem>

          <Divider orientation='horizontal' className={classes.horizontalDivider} />

          <GridItem topSpacing='sm' xs={12}>
            <RentedToOthersQuestion label='Is the rental unit rented to others?' />
          </GridItem>

          <GridItem topSpacing='sm' xs={12}>
            <NumberOfDaysRentedQuestion />
          </GridItem>

          <GridItem topSpacing='sm' xs={12}>
            <DaycareOnPremisesQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <BusinessOnPremisesQuestion />
          </GridItem>

          <Divider orientation='horizontal' className={classes.horizontalDivider} />
          <GridItem topSpacing='lg' xs={12}>
            <SecondaryNamedInsuredQuestions
              productName={policyType}
              secondaryInsuredQuestionKey={RENTERS_SECONDARY_INSURED_PERSON_REF}
              showAddNewPerson
            />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <Button
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress || isSubmitting}
              className={classes.next}
              data-testid='continue'
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='person_living_continue'
              analyticsElement='choice.personLivingPage.continueButton'
              type='submit'
            >
              Continue
            </Button>
          </GridItem>
        </Grid>
      </Form>
      {canOpenDialog && (
        <PolicyCenterDialog
          dialogStat={canOpenDialog}
          dialogSubmit={handleRouteToPLPCDialogAction}
          isProcessing={isSubmitting}
          toggleDialog={handleCancelButtonOnClick}
        />
      )}
    </div>
  );
};

import { useCallback, useEffect } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { updatePageStatus } from '@ecp/features/sales/shared/store';
import { getOfferProductsSelectedByType } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import { PropertyDeltaPageForm } from '../../forms';

export const RentersDeltaPage: React.FC = () => {
  const dispatch = useDispatch();

  const { property: propertyProduct } = useSelector(getOfferProductsSelectedByType);
  const productName = propertyProduct && getProductNameFromProduct(propertyProduct);

  useEffect(() => {
    trackSapiAnalyticsEvent({
      element: 'choice.rentersDeltaPage.page',
      event: 'render',
      eventDetail: 'true',
    });
  }, []);

  const nextPage = useNavigateToNextPage();

  const handleNext = useCallback(async () => {
    await nextPage();
    // EDSP-11442 Quote page is skipped while offer state is bindable.
    // Adding page status INVALID and inProgress false will make the inactive
    dispatch(updatePageStatus(NavStatus.INVALID, PagePath.QUOTES, false));
  }, [dispatch, nextPage]);

  if (!propertyProduct || !productName) return null;

  return (
    <Page
      sidebarProps={{
        drawer: <NavbarDrawer pagePath={PagePath.RENTERS_DELTA} />,
      }}
      title='Renters Additional Information'
      mainWithoutSidebar
    >
      <PropertyDeltaPageForm
        onNext={handleNext}
        product={propertyProduct}
        policyType={productName}
      />
    </Page>
  );
};

import { useCallback } from 'react';

import { Grid, Typography } from '@mui/material';
import type { Control } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import {
  City,
  CompanyName,
  PrimarySecondaryButtons,
  SearchTPIErrorMessage,
  SearchType,
  State,
} from '../../components';
import { useThirdPartyInterest } from '../../hooks';
import type { Product, TPIInputFields } from '../../types';
import { type SearchResult, TPISearchType } from '../../types';

export type SearchTPIProps = {
  handleSearch: (params: { tpiSearchResult: Array<SearchResult> }) => void;
  handleCancel?: () => Promise<void>;
  product: Product;
};

export const SearchTPI: React.FC<SearchTPIProps> = (props) => {
  const { handleSearch, product } = props;
  const { searchTPI, isSearching } = useThirdPartyInterest();
  const {
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
    control,
  } = useForm<TPIInputFields>({
    defaultValues: { searchType: TPISearchType.STARTS_WTH },
  });

  const onClickSearch = useCallback(async () => {
    await handleSubmit(async (data) => {
      const tpiSearchResult = await searchTPI({ ...data, product });
      await handleSearch({ tpiSearchResult });
    })();
  }, [handleSearch, handleSubmit, searchTPI, product]);

  const onClickCancel = useCallback(async () => {
    reset();
    clearErrors();
    !!props?.handleCancel && (await props?.handleCancel());
  }, [clearErrors, props, reset]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography variant='h3'>Search for an Interested Party</Typography>
        </Grid>
        <Grid item xs={12} md={12} maxWidth={180}>
          <SearchType control={control} />
        </Grid>
        <Grid item container xs={12} md={12}>
          <Grid item xs={12} md={6}>
            <CompanyName control={control} />
          </Grid>
        </Grid>
        <Grid item container xs={12} md={12} columnGap={2}>
          <Grid item xs={12} md={4}>
            <City control={control as unknown as Control} optional />
          </Grid>
          <Grid item xs={12} md={2} textAlign='center'>
            <State control={control as unknown as Control} optional />
          </Grid>
        </Grid>
        <Grid item container xs={12} md={4} spacing={2}>
          <PrimarySecondaryButtons
            primaryButtonLabel='Search'
            secondaryButtonLabel='Cancel'
            secondaryOnClick={onClickCancel}
            primaryOnClick={onClickSearch}
            showSecondaryButton={!!props?.handleCancel}
            isProcessing={isSearching}
          />
        </Grid>
      </Grid>
      <SearchTPIErrorMessage message={errors.root?.message} />
    </>
  );
};

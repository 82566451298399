import { useCallback } from 'react';

import type { AccordionProps } from '@mui/material';
import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { IconUIExpandMore } from '@ecp/themes/partners/amfam-adv';

import type { DiscountsAccordionProps } from '../../types';
import { PolicySummaryDiscountsCard } from '../PolicySummaryDiscountsCard';
import { useStyles } from './DiscountsAccordion.styles';

export const DiscountsAccordion: React.FC<DiscountsAccordionProps> = (props) => {
  const { footer, product, showError, title, trackingName } = props;
  const { classes } = useStyles();

  const handleExpandCollapse = useCallback<NonNullable<AccordionProps['onChange']>>(
    (_event, expanded) => {
      trackClick({
        action: `show_hide_${trackingName?.toLowerCase()}`,
        label: expanded ? 'Show' : 'Hide',
      });
    },
    [trackingName],
  );
  if (!props.discountItems) return null;

  return (
    <GridItem topSpacing='xs' xs={12} className={classes.addSpacing}>
      <MuiAccordion
        defaultExpanded={false}
        className={classes.accordionContainer}
        onChange={handleExpandCollapse}
      >
        <AccordionSummary
          className={classes.accordionSummaryHeader}
          expandIcon={<IconUIExpandMore id='_CoveragesAccordionExpandIcon' />}
        >
          <h3 data-testid={title} className={showError ? classes.accordionHeaderError : undefined}>
            {title}
          </h3>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsBkg}>
          <PolicySummaryDiscountsCard
            items={props.discountItems}
            footer={footer}
            product={product}
          />
        </AccordionDetails>
      </MuiAccordion>
    </GridItem>
  );
};

import type { Dispatch, SetStateAction } from 'react';

import { FormControl, FormControlLabel, FormHelperText, Grid } from '@mui/material';

import { castToBoolean, parseDollar } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import { GridItem, NumberFormat, ReadOnlyField, TooltipWithIcon } from '@ecp/components';
import { Checkbox, Select, TextField } from '@ecp/features/sales/shared/components';
import type { CoverageItem } from '@ecp/features/sales/shared/types';
import type { DisplayCoverageType } from '@ecp/types';

import { useStyles } from './CoverageCardItems.styles';

type CoverageCardItemsProps = Pick<
  CoverageItem,
  'field' | 'title' | 'primaryText' | 'secondaryText'
> & {
  isSubCoverage?: boolean;
  displayInfo?: DisplayCoverageType;
  setSnackbarMessages?: Dispatch<SetStateAction<string[]>>;
};

export const CoverageCardItems: React.FC<CoverageCardItemsProps> = (props) => {
  const {
    field,
    isSubCoverage,
    primaryText,
    secondaryText,
    title,
    displayInfo,
    setSnackbarMessages,
  } = props;
  const { classes } = useStyles();

  const handleCheckboxCoverageChange = useEvent((_, checked) => {
    const answerType = field?.question.answerType;
    /**
     * actionOnComplete was defined in the coverage field build function; It needs to be called to validate all forms.
     *   Otherwise cross coverage rules do not work correctly.
     */
    field?.props.actionOnComplete(answerType === 'String' ? checked.toString() : checked);
  });

  if (!field?.props) return null;

  const displayType = displayInfo?.displayElement;
  const displayAsDollarAmount = displayInfo?.displayAsDollarAmount;
  const displayAsPercentage = displayInfo?.displayAsPercentage;

  if (!displayType) return null;

  const labelWithTooltip = (
    <div className={isSubCoverage ? classes.label : classes.coverageName}>
      {title}
      {primaryText && <TooltipWithIcon className={classes.toolTip} title={primaryText} />}
    </div>
  );

  const isfieldTruthy = castToBoolean(field.props.value);

  switch (displayInfo.displayElement) {
    case 'Checkbox':
      return (
        <div className={classes.root}>
          <Grid container gap={1} justifyItems='space-between'>
            <Grid
              item
              xs={12}
              className={
                !isSubCoverage && isfieldTruthy
                  ? classes.selectedMainCoverage
                  : classes.checkboxContainer
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.formControlCheckBox}
                    id={field.props.name}
                    checked={isfieldTruthy}
                    onChange={handleCheckboxCoverageChange}
                    trackingName={field.props.name}
                    trackingLabel={field.props.value}
                  />
                }
                label={labelWithTooltip}
                className={classes.formControlLabel}
              />
            </Grid>
            <FormControl
              className={classes.formControl}
              error={!!field.props.error}
              fullWidth
              variant='outlined'
              role='group'
            >
              {field.props.error && (
                <FormHelperText role='alert' data-testid='hidden-helper'>
                  {field.props.error}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </div>
      );
    case 'DropDown':
      return (
        <div className={classes.root}>
          <Grid container justifyItems='space-between'>
            <GridItem xs={12}>
              <Select
                {...field.props}
                label={labelWithTooltip}
                className={classes.coveragesDropdown}
                labelId={`${field.props.name}-label`}
                id={field.props.name}
                value={field.props.value}
                actionOnChange={field.props.actionOnComplete}
                fullWidth
                trackingName={field.props.name}
                trackingLabel={field.props.value}
              />
              <div className={classes.secondaryText}>{secondaryText}</div>
            </GridItem>
          </Grid>
        </div>
      );
    case 'ReadOnly':
      return (
        <div className={classes.root}>
          <Grid container justifyItems='space-between'>
            <GridItem xs={12}>
              <ReadOnlyField
                id={field.props.name}
                label={title}
                helperText={primaryText} // This takes care of the tooltip
                value={
                  field.props.options?.length === 1
                    ? field.props.options[0].label.toString()
                    : field.props.options?.length && field.props.options?.length > 1
                    ? field.props.options?.find((l) => l.value === field.value)?.label.toString()
                    : displayAsDollarAmount
                    ? parseDollar(field.value?.toString())
                    : displayAsPercentage && field.value && !Number.isNaN(field.value)
                    ? `${parseInt(field.value.toString())}%`
                    : field.value
                }
                classes={classes} // This is needed to send label styles
              />
            </GridItem>
          </Grid>
          <div className={classes.secondaryText}>{secondaryText}</div>
        </div>
      );
    case 'TextField': {
      return (
        <div className={classes.root}>
          <Grid container justifyItems='space-between'>
            <GridItem xs={12}>
              {displayAsDollarAmount ? (
                <NumberFormat
                  {...field.props}
                  label={labelWithTooltip}
                  id={field.props.name}
                  prefix='$'
                  thousandSeparator
                  decimalScale={0}
                  trackingName={field.props.name}
                  trackingLabel={field.props.value}
                />
              ) : (
                <TextField
                  {...field.props}
                  label={labelWithTooltip}
                  id={field.props.name}
                  trackingName={field.props.name}
                  trackingLabel={field.props.value}
                />
              )}
            </GridItem>
          </Grid>
        </div>
      );
    }
    default: {
      let result = displayType;
      // If the coverage has a custom component, extend the props for dynamic flexibility
      if (displayInfo?.displayElement?.props?.injectField) {
        result = (
          <displayInfo.displayElement.type
            {...displayInfo.displayElement.props}
            // Keys are not always static; this is a must for vehicle level coverages that contain unique IDs in the key
            coverageItemField={field}
            title={title}
            primaryText={primaryText}
            secondaryText={secondaryText}
            setSnackbarMessages={setSnackbarMessages}
          />
        );
      }

      return (
        <div className={classes.root}>
          <Grid container justifyItems='space-between'>
            <GridItem xs={12}>{result}</GridItem>
          </Grid>
        </div>
      );
    }
  }
};

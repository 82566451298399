import { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { GridItem } from '@ecp/components';
import { Form, Resource } from '@ecp/features/sales/shared/components';
import { setFormErrorsChangedByField, updateAddedRef } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, Option } from '@ecp/types';

import {
  CoverageFieldReadOnlyComponent,
  CoverageFieldSelectComponent,
  CoverageFieldTextboxComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './ItemizedPersonalPropertyCoverage.styles';
import {
  useAddEditItemizedPersonalPropertyFormFields,
  useUniqueDescriptionForItemizedPersonalProperty,
} from './itemizedPersonalPropertyCoverageUtil';

// TODO - Fix the options which are relevant.
export const evaluatePossibleTypeFieldValues = (category: string): string[] => {
  switch (category) {
    case 'Jewelry':
      return ['Jewelry'];
    case 'Sports Equipments':
      return [
        'Sports Equipment - Tennis',
        'Sports Equipment - Ski Boots',
        'Sports Equipment - Snow Boards',
      ];
    case 'Collectables':
      return ['Collectable Item - Postage Stamps', 'Collectable Item - Coins'];
    case 'Tools and Equipments':
      return ['Tools and Equipment - Carpentry', 'Tools and Equipment - Telescope'];
    default:
      return [];
  }
};

export type ItemizedPersonalPropertyFormProps = {
  itemRef: string;
  onNext: () => void;
  onCancel: (id: string) => void;
  isNewItem: boolean;
};

export const ItemizedPersonalPropertyForm: React.FC<ItemizedPersonalPropertyFormProps> = (
  props,
) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { itemRef: editItemizedPersonalPropertyRef, isNewItem, onNext: onSave, onCancel } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const checkUniqueDescription = useUniqueDescriptionForItemizedPersonalProperty();
  const { fields, patchFormValues, validateForm, isPatchFormInProgress } =
    useAddEditItemizedPersonalPropertyFormFields(editItemizedPersonalPropertyRef);
  const { categoryField, typeField, descriptionField, valueField } = fields;

  const handleCancelItemizedPersonalProperty = useEvent(async () => {
    onCancel(editItemizedPersonalPropertyRef);
  });

  const handleSaveItemizedPersonalProperty = useEvent(async () => {
    const { isValid } = validateForm();
    if (isValid) {
      // Add check for duplicate description
      if (
        !checkUniqueDescription(
          categoryField.value,
          typeField.value,
          descriptionField.value,
          editItemizedPersonalPropertyRef,
        )
      ) {
        dispatch(
          setFormErrorsChangedByField({
            key: descriptionField?.key,
            errors: ['Invalid description'],
          }),
        );

        return;
      }
      setIsSubmitting(true);
      await patchFormValues();

      // In case of new item, add the newly created ref.
      if (isNewItem) {
        await dispatch(
          updateAddedRef({
            type: 'property.itemizedPersonalProperty.ref',
            newRef: editItemizedPersonalPropertyRef,
          }),
        );
      }
      onSave();
      setIsSubmitting(false);
    }

    trackClick({
      action: 'ItemizedPersonalPropertySaveButton',
      label: `${categoryField?.value}`,
    });
  });

  // Type field options are dependent on Category field
  // So, it's important to evaluate that.
  typeField.props.options = useMemo(() => {
    if (!categoryField?.value) return [];
    else {
      const possibleTypeValues = evaluatePossibleTypeFieldValues(String(categoryField.value));

      return (
        typeField?.question?.options?.filter((option: Option<string> | undefined) =>
          possibleTypeValues.includes(option?.value),
        ) || []
      );
    }
  }, [categoryField.value, typeField?.question?.options]);

  // This is only used while adding the item.
  // The field is disabled in case of edits.
  // No patch answers call, only changes to form store.
  categoryField.props.actionOnComplete = useCallback(
    async (value: AnswerValue): Promise<void> => {
      categoryField.validateAndUpdate(value);

      // If there is only one available type for the selected category,
      // then save that type as form selection else nullify the type field
      const possibleTypeValues = evaluatePossibleTypeFieldValues(String(value));
      if (possibleTypeValues.length === 1) {
        typeField.update(possibleTypeValues[0]);
      } else {
        typeField.update(null);
      }
    },
    [categoryField, typeField],
  );

  // No patch answers call, only changes to form store.
  typeField.props.actionOnComplete = useCallback(
    async (value: AnswerValue): Promise<void> => {
      typeField.validateAndUpdate(value);
    },
    [typeField],
  );

  // No patch answers call, only changes to form store.
  descriptionField.props.actionOnComplete = useCallback(
    async (value: AnswerValue): Promise<void> => {
      descriptionField.validateAndUpdate(value);
      // The description must be unique or different when the
      // same property type is chosen / selected more than once.
      if (
        !checkUniqueDescription(
          categoryField.value,
          typeField.value,
          value,
          editItemizedPersonalPropertyRef,
        )
      ) {
        dispatch(
          setFormErrorsChangedByField({
            key: descriptionField?.key,
            errors: ['Invalid description'],
          }),
        );
      }
    },
    [
      descriptionField,
      categoryField.value,
      checkUniqueDescription,
      dispatch,
      editItemizedPersonalPropertyRef,
      typeField.value,
    ],
  );

  // No patch answers call, only changes to form store.
  valueField.props.actionOnComplete = useCallback(
    async (value: AnswerValue): Promise<void> => {
      valueField.validateAndUpdate(value);
    },
    [valueField],
  );

  // If Property type has only one match to the property category, the respective property type option is prefilled and read-only
  const typeComponent =
    typeField.props.options.length !== 1 ? (
      <CoverageFieldSelectComponent field={typeField} />
    ) : (
      <CoverageFieldReadOnlyComponent field={typeField} />
    );

  // Disable category field in case of edits
  const ItemizedPersonalPropertyFormFields = (
    <Grid container item xs={12} spacing={4}>
      <CoverageFieldSelectComponent field={categoryField} disabled={!isNewItem} />
      {typeComponent}
      <CoverageFieldTextboxComponent field={descriptionField} />
      <CoverageFieldTextboxComponent field={valueField} displayAsDollarAmount />
      {/** checkbox to  be added */}
    </Grid>
  );

  return (
    <Form className={classes.fullWidth}>
      {ItemizedPersonalPropertyFormFields}
      <Grid container justifyContent='flex-end' className={classes.marginTop}>
        <GridItem>
          <Resource.CancelButton
            onClick={handleCancelItemizedPersonalProperty}
            trackingName='remove_itemized_personal_property'
            trackingLabel='remove_itemized_personal_property'
          >
            CANCEL
          </Resource.CancelButton>
          <Resource.SaveButton
            onClick={handleSaveItemizedPersonalProperty}
            isProcessing={isPatchFormInProgress || isSubmitting}
            trackingName='save_itemized_personal_property'
            trackingLabel='save_itemized_personal_property'
          >
            SAVE ITEM
          </Resource.SaveButton>
        </GridItem>
      </Grid>
    </Form>
  );
};

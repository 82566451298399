import { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import { Pagination } from '@ecp/components';

import { TPIResultOptions } from '../../components';
import type { SearchResult, TPI, TPISearchSelectionInputFields } from '../../types';
import { chunkTPISearchResultsArray } from '../../utils';

export const SearchTPIResults: React.FC<{
  tpiResults: Array<SearchResult>;
  pageSize?: number;
  onSelectionChange: (params: { tpi: TPI }) => void;
}> = ({ tpiResults, pageSize = 20, onSelectionChange }) => {
  const { control, reset } = useForm<TPISearchSelectionInputFields>();
  const [pageNumber, setPageNumber] = useState(1);
  const { tpiPaginationOptions, tpiDataPerPage } = useMemo(
    () => chunkTPISearchResultsArray({ results: tpiResults, pageSize }),
    [pageSize, tpiResults],
  );

  const handleOnChangePagination = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      reset();
      setPageNumber(page);
    },
    [reset],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <TPIResultOptions
          pageNumber={pageNumber}
          options={tpiPaginationOptions}
          tpiDataPerPage={tpiDataPerPage}
          control={control}
          handleOnChange={onSelectionChange}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={12}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Pagination
          page={pageNumber}
          onChange={handleOnChangePagination}
          count={tpiPaginationOptions?.length}
        />
      </Grid>
    </Grid>
  );
};

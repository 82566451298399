import { memo, useCallback } from 'react';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { Button, GridItem } from '@ecp/components';
import { useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { IconUIEdit } from '@ecp/themes/partners/amfam-adv';

import { PolicySummaryDriverCard, PolicySummaryVehicleCard } from '../../../lob/autoLine';
import type { PolicySummaryCardProps } from '../../types';
import { PolicySummaryCoveragesCard } from '../PolicySummaryCoveragesCard';
import { PolicySummaryDiscountsCard } from '../PolicySummaryDiscountsCard';
import { useStyles } from './PolicySummaryCard.styles';

export const PolicySummaryCard: React.FC<PolicySummaryCardProps> = memo(
  ({ summaryType, title, coverageGroups, discountItems, trackingName, product, path = '' }) => {
    const { classes } = useStyles();

    const navigateToEditCoveragePage = useNavigateToPage(path);
    const navigateToEditCoverage = useCallback(async () => {
      await navigateToEditCoveragePage();
      trackClick({ action: trackingName, label: `Sent to ${path}` });
    }, [navigateToEditCoveragePage, trackingName, path]);

    if (summaryType === 'discounts' && !discountItems?.length) return null;

    return (
      <GridItem topSpacing='xs' xs={12} className={classes.root}>
        <GridItem className={classes.coveragesSummaryHeader}>
          <h3 data-testid={title}>{title}</h3>
          {path && (
            <Button
              onClick={navigateToEditCoverage}
              icon={<IconUIEdit />}
              variant='iconTextMedium'
              className={classes.editButton}
            >
              Edit
            </Button>
          )}
        </GridItem>
        {summaryType === 'driver' && (
          <GridItem>
            <PolicySummaryDriverCard />
          </GridItem>
        )}
        {summaryType === 'vehicle' && (
          <GridItem>
            <PolicySummaryVehicleCard />
          </GridItem>
        )}
        {summaryType === 'discounts' && (
          <GridItem>
            <PolicySummaryDiscountsCard items={discountItems} product={product} />
          </GridItem>
        )}
        {summaryType === 'coverages' && coverageGroups?.length && (
          <GridItem>
            <PolicySummaryCoveragesCard coverageGroups={coverageGroups} />
          </GridItem>
        )}
      </GridItem>
    );
  },
);

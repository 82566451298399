import { useEffect, useState } from 'react';

import { FormControlLabel, Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';

import { GridItem, ReadOnlyField, TooltipWithIcon } from '@ecp/components';
import { Checkbox, RadioGroupWithOptions, Select } from '@ecp/features/sales/shared/components';
import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';
import { getDriverInfo, useFieldWithPrefix, usePniRef } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { DriverBasicInfo } from '@ecp/features/sales/shared/types';

import { useStyles } from './PersonalInjuryProtectionUtahCoverage.styles';

export const PersonalInjuryProtectionUtahCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();
  const { carrierName, product } = props;
  const [isExcludeWorkLossQualified, setIsExcludeWorkLossQualified] = useState(false);
  const coveragePrefix = `${carrierName}.${product}.coverages.policy`;
  const personalInjuryProtectionOption = useFieldWithPrefix(coveragePrefix)('pipBasicUt.option');
  const excludeWorkLossSubCov = useFieldWithPrefix(coveragePrefix)('pipBasicUt.excludeWorkLoss');
  const pniRef = usePniRef();

  const usePNIPersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const maritalStatus = usePNIPersonField('married');
  const employmentStatus = usePNIPersonField('employed');
  const driversInfo: DriverBasicInfo[] = useSelector(getDriverInfo);
  const spouseDriver = driversInfo?.filter((driver) => driver.relationshipToApplicant === 'Spouse');

  const useSpousePersonField = useFieldWithPrefix(spouseDriver[0]?.personRef, 'person.<id>');
  const spouseEmploymentStatus = useSpousePersonField('employed');

  const labelWithTooltip = (
    <div className={classes.coverageName}>
      Personal Injury Protection
      <TooltipWithIcon
        className={classes.toolTip}
        title='Helps reimburse you and your passengers for certain expenses resulting from a motor vehicle accident (such as medical expenses, work loss and funeral expenses), regardless of who is at fault for the accident.'
      />
    </div>
  );

  const subCoverageLabel = (labelText: string): React.ReactElement => {
    return <div className={classes.label}>{labelText}</div>;
  };

  useEffect(() => {
    /**
     * Qualifying rules
     * Marital Status = Not married and Employment status = Unemployed
     * Marital Status = Married and Employment status = Unemployed
     * and Spouse employment status = Unemployed
     */
    const unmarriedCondition = maritalStatus.value !== 'MARITAL_STATUS.MARRIED';
    const marriedCondition =
      maritalStatus.value === 'MARITAL_STATUS.MARRIED' &&
      spouseEmploymentStatus.value === 'EMPLOYMENT_STATUS.UNEMPLOYED';
    if (
      employmentStatus.value === 'EMPLOYMENT_STATUS.UNEMPLOYED' &&
      (unmarriedCondition || marriedCondition)
    ) {
      setIsExcludeWorkLossQualified(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container justifyItems='space-between'>
        <GridItem
          xs={12}
          className={
            personalInjuryProtectionOption.value
              ? classes.selectedMainCoverage
              : classes.mainCoverage
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                className={classes.formControlCheckBox}
                id='PersonalInjuryProtectionUtahCoverage'
                checked={!!personalInjuryProtectionOption.value}
                // EDSP-9565 We do not have 'selected' field for auto coverages
                // Current plan is to implement selected field for all coverages
                // and checkbox value would connect there instead of personalInjuryProtectionOption.value
                // This works because pip is required in Utah, so personalInjuryProtectionOption.value
                // comes through automatically with a value.
                disabled
                trackingName='PersonalInjuryProtectionUtahCoverage'
                trackingLabel={personalInjuryProtectionOption?.value?.toString()}
              />
            }
            label={labelWithTooltip}
            className={classes.formControlLabel}
          />
        </GridItem>
        {personalInjuryProtectionOption.value && (
          <Grid container item>
            <GridItem xs={12}>
              <Select
                {...personalInjuryProtectionOption.props}
                id='personalInjuryProtectionOption'
                label={subCoverageLabel('Personal Injury Protection Limit')}
              />
            </GridItem>

            <GridItem xs={12}>
              {isExcludeWorkLossQualified && (
                <RadioGroupWithOptions
                  {...excludeWorkLossSubCov.props}
                  id='ExcludeWorkLossSubCovUT'
                  value={excludeWorkLossSubCov.value}
                  label={subCoverageLabel('Exclude Work Loss')}
                  variant='button'
                  trackingName='ExcludeWorkLossSubCovUT'
                  options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                />
              )}
              {!isExcludeWorkLossQualified && (
                <ReadOnlyField
                  {...excludeWorkLossSubCov.props}
                  label='Exclude Work Loss'
                  helperText='PIP Exclude Work Loss'
                  value={castToBoolean(excludeWorkLossSubCov.value) ? 'Yes' : 'No'}
                  id='PersonalInjuryProtectionUtahCoverageReadOnly'
                  classes={classes}
                />
              )}
            </GridItem>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

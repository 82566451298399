import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldRadioGroupComponent,
  CoverageFieldSelectComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './WaterBackupCoverage.styles';

export const WaterBackupCoverageHome: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();
  const [
    waterBackupCoverageField,
    deductibleField,
    dwellingLimitField,
    contentLimitsField,
    applianceLimitField,
    contentLimitField,
  ] = useGroupedCoverageFields(props.carrierName, props.product, 'waterBackup', [
    'selected',
    'deductible',
    'dwellingLimit',
    'contentLimits',
    'applianceLimit',
    'contentLimit',
  ]);

  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(waterBackupCoverageField, [
    deductibleField,
    dwellingLimitField,
    contentLimitsField,
    applianceLimitField,
    contentLimitField,
  ]);

  const handleWaterBackupCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );
  if (!waterBackupCoverageField.exists) return null;

  const isWaterBackupCoverageSelected = castToBoolean(waterBackupCoverageField.value);

  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='waterBackupCoverage'
          field={waterBackupCoverageField}
          isCoverageSelected={isWaterBackupCoverageSelected}
          handleCoverageChange={handleWaterBackupCoverageChange}
        />
        {isWaterBackupCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            <CoverageFieldSelectComponent field={deductibleField} />
            <CoverageFieldSelectComponent field={dwellingLimitField} />
            <CoverageFieldRadioGroupComponent
              coverageName={contentLimitsField.props.name}
              field={contentLimitsField}
              sortByKey
              handleSubCoverageChange={contentLimitsField.props.actionOnComplete}
            />
            {contentLimitsField.value &&
              (contentLimitsField.value === 'ALL' ? (
                <CoverageFieldSelectComponent field={contentLimitField} />
              ) : (
                <CoverageFieldSelectComponent field={applianceLimitField} />
              ))}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

import { useCallback } from 'react';

import { createSelector } from 'reselect';

import type { DriverType } from '@ecp/features/sales/quotes/auto';
import { getDriverLabel } from '@ecp/features/sales/quotes/auto';
import { getField, updateAnswers } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Driver, VehicleBasic } from '@ecp/features/sales/shared/types';
import type { AnswerValue, Field, Fields } from '@ecp/types';

import type { AssignmentQuestionItem } from './types';

export const useGetLineItems = (
  driverAssignmentFields: Fields,
  occasionalDriverAssignmentFields: Fields,
  drivers: Driver[],
  vehicles: VehicleBasic[],
): AssignmentQuestionItem[] => {
  return useCallback((): AssignmentQuestionItem[] => {
    const fieldAnswers = Object.keys(driverAssignmentFields).map((key) => {
      return { key, value: driverAssignmentFields[key]?.value as AnswerValue };
    });

    const fieldKeys = Object.keys(driverAssignmentFields);
    const occasionalFieldKeys = Object.keys(occasionalDriverAssignmentFields);

    /**
     * If there are more drivers than vehicles
     * then we want to display each vehicle as the label and allow the user to choose a driver
     */
    if (drivers.length > vehicles.length) {
      return vehicles.map((vehicle, index) => {
        const questionKey = fieldKeys[index];
        const question = driverAssignmentFields[questionKey] as Field;
        const occasionalQuestionKey = occasionalFieldKeys[index];
        const occasionalQuestion = occasionalDriverAssignmentFields[occasionalQuestionKey] as Field;

        return {
          assignmentType: 'vehicle',
          question,
          occasionalQuestion,
          dropDownOptions: drivers.map((driver) => ({
            value: driver.ref,
            label: getDriverLabel(driver),
          })),
          vehicle,
        };
      });
    }

    /**
     * Otherwise we want to display each driver and allow the user to select a vehicle
     * to assign to that driver.
     */
    return drivers.map((driver) => {
      const filteredAnswers = fieldAnswers.filter((field) => field.value === driver.ref);
      const answerFound = filteredAnswers.length > 0;

      let questionKey = '';

      if (answerFound) {
        questionKey = filteredAnswers[0].key;
      } else {
        for (let i = 0; i < fieldKeys.length; i += 1) {
          if (!driverAssignmentFields[fieldKeys[i]]?.question?.value) {
            questionKey = fieldKeys[i];
            break;
          }
        }
      }

      const question = driverAssignmentFields[questionKey] as Field;
      const occasionalQuestion = occasionalDriverAssignmentFields[questionKey] as Field;

      return {
        assignmentType: 'driver',
        question,
        occasionalQuestion,
        dropDownOptions: vehicles.map((vehicle) => ({
          value:
            answerFound && questionKey.includes(vehicle.ref || '') ? driver.ref : vehicle.ref || '',
          label: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
        })),
        driver,
      };
    });
  }, [driverAssignmentFields, vehicles, drivers, occasionalDriverAssignmentFields])();
};

export const useGetDriverAssignmentFields = (
  vehicles: VehicleBasic[],
  driverType: DriverType,
): Fields => {
  const dispatch = useDispatch();
  const selectors = vehicles.map((vehicle) => (state: RootStore) => {
    const field = getField(state, {
      key: `${vehicle.ref}.${driverType}.driver.ref`,
      questionKey: `vehicle.<id>.${driverType}.driver.ref`,
      dispatch,
    });

    return field;
  });

  const fieldsSelector = createSelector(selectors, (...fields) => {
    return fields.reduce((acc, field) => {
      if (!field.exists) return acc;
      acc[field.key] = field;

      return acc;
    }, {} as Fields);
  });

  return useSelector(fieldsSelector);
};

export const useResetAllItems = (lineItems: AssignmentQuestionItem[]): (() => Promise<void>) => {
  const dispatch = useDispatch();
  const resetAssignments = useCallback(async () => {
    for (const item of lineItems) {
      await dispatch(
        updateAnswers({
          answers: { [item.question.key]: null },
        }),
      );
      if (item.occasionalQuestion && item.occasionalQuestion.exists) {
        await dispatch(
          updateAnswers({
            answers: { [item.occasionalQuestion.key]: null },
          }),
        );
      }
    }
  }, [dispatch, lineItems]);

  return resetAssignments;
};

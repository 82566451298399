import { useCallback, useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { GridItem, ReadOnlyField, Snackbar, SnackbarAlert } from '@ecp/components';
import {
  useIsSniValue,
  useMilitaryDeployments,
  useRemoveUnUsedRefForMilitaryDeployments,
} from '@ecp/features/sales/quotes/auto';
import {
  Dialog,
  RadioGroupWithOptions,
  Resource,
  type ResourceFormProps,
} from '@ecp/features/sales/shared/components';
import {
  getAnswer,
  getPolicyStartDates,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, PageErrors } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import {
  useAddPriorInsurance,
  useGetPriorInsuranceInfo,
  useRemoveAllPriorInsurance,
  useRemoveUnusedPriorRefs,
} from '../../utils';
import { DriverLevelLapseQuestion } from '../DriverLevelLapseQuestion';
import { MilitaryDeploymentsQuestion } from '../MilitaryDeploymentsQuestion';
import { useStyles } from './InsuranceQuestions.styles';
import { PriorInsuranceQuestions } from './PriorInsuranceQuestions';

export interface InsuranceQuestionsProps {
  driverRef: string;
  personRef: string;
  isPni: boolean;
  allowAddSNI: boolean;
  unambiguousName?: string;
  pageErrors: PageErrors[];
  setIsFormOpen?: (arg: boolean) => void;
}

export interface CurrentInsuranceFields {
  state: Field;
  carrier: Field;
  carrierNameText: Field;
  years: Field;
  policyInceptionDate: Field;
  endDate: Field;
  lapse: Field;
  limit: Field;
  vehicleComprehensive: Field;
  vehicleCollision: Field;
}

export interface PriorInsuranceSnapshot {
  state: AnswerValue;
  carrier: AnswerValue;
  carrierNameText: AnswerValue;
  policyEndDate: AnswerValue;
  policyInceptionDate: AnswerValue;
  'coverages.policy.bodilyInjury': AnswerValue;
  'coverages.vehicle.comprehensive': AnswerValue;
  'coverages.vehicle.collision': AnswerValue;
}

const useGetFields = (driverRef: string, priorInsuranceRef: string): CurrentInsuranceFields => {
  const userPriorInsuranceField = useFieldWithPrefix(priorInsuranceRef, 'priorInsurance.<id>');

  return {
    state: userPriorInsuranceField('state'),
    carrier: userPriorInsuranceField('carrierName'),
    carrierNameText: userPriorInsuranceField('carrierNameText'),
    years: userPriorInsuranceField('years'),
    policyInceptionDate: userPriorInsuranceField('policyInceptionDate'),
    endDate: userPriorInsuranceField('policyEndDate'),
    lapse: userPriorInsuranceField('lapse'),
    limit: userPriorInsuranceField('coverages.policy.bodilyInjury'),
    vehicleComprehensive: userPriorInsuranceField('coverages.vehicle.comprehensive'),
    vehicleCollision: userPriorInsuranceField('coverages.vehicle.collision'),
  };
};

export const InsuranceQuestions: React.FC<InsuranceQuestionsProps> = (props) => {
  const { driverRef, personRef, isPni, unambiguousName, allowAddSNI, pageErrors, setIsFormOpen } =
    props;
  const { classes } = useStyles();

  const priorInsuranceRefsKey = `${driverRef}.priorInsurance.ref`;
  const priorInsuranceRefsValue =
    (useSelector((state: RootStore) => getAnswer(state, priorInsuranceRefsKey)) as Array<string>) ||
    [];
  const [priorInsuranceRef, setPriorInsuranceRef] = useState(''); // Form is open when there is ref here; default to not open
  const [isMilitaryFormOpen, setIsMilitaryFormOpen] = useState<boolean>(false); // Military form is open when there is ref; default to not open; elevated here to make it accessible to setIsFormOpen
  const [isEditMode, setIsEditMode] = useState(false); // this signifies that you clicked the edit button to edit an existing ref
  const [priorInsuranceActionInfoType, setPriorPolicyActionInfoType] = useState<
    'NONE' | 'ADD' | 'REMOVE'
  >('NONE');

  const [isAddingOrRemovingRef, setIsAddingOrRemovingRef] = useState(false);
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const insuranceFields = useGetFields(driverRef, priorInsuranceRef);
  const [hasPriorInsurance, setHasPriorInsurance] = useState(priorInsuranceRefsValue?.length > 0); // set the default question visibility to false unless any of the prior carrier fields have a value
  const [priorInsuranceSnapshot, setPriorInsuranceSnapshot] = useState<
    PriorInsuranceSnapshot | undefined
  >(undefined);
  const addPriorInsurance = useAddPriorInsurance(driverRef);
  const removePriorRef = useRemoveUnusedPriorRefs(driverRef);
  const removeAllPriorRefs = useRemoveAllPriorInsurance(driverRef);
  const priorInsuranceInfo = useGetPriorInsuranceInfo(driverRef);
  const [removeModalProperties, setRemoveModalProperties] = useState<{
    open: boolean;
    ref: string;
  }>({
    open: false,
    ref: '',
  });
  const [removeMilitaryModal, setMilitaryRemoveModal] = useState<boolean>(false);

  const {
    state,
    carrier,
    carrierNameText,
    years,
    policyInceptionDate,
    endDate,
    lapse,
    limit,
    vehicleCollision,
    vehicleComprehensive,
  } = insuranceFields;

  const isSniValue = useIsSniValue(personRef);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const removeMilitaryDeployments = useRemoveUnUsedRefForMilitaryDeployments(driverRef);
  const militaryDeploymentsList = useMilitaryDeployments(driverRef);
  const [hasMilitaryDeployment, setHasMilitaryDeployment] = useState(
    !!militaryDeploymentsList?.length,
  );

  const initializePriorInsurance = useEvent(async (value: AnswerValue) => {
    setHasPriorInsurance(!!value);
    setIsAddingOrRemovingRef(true);
    const newPriorInsurance = addPriorInsurance();
    const { priorInsuranceRef } = newPriorInsurance;
    await newPriorInsurance.done;
    setPriorInsuranceRef(priorInsuranceRef);
    setIsAddingOrRemovingRef(false);
  });

  const removeAllSavedMilitaryDeploymentsFromDriver = useEvent(() => {
    militaryDeploymentsList.forEach((militarDeployments) =>
      removeMilitaryDeployments(militarDeployments),
    );
    initializePriorInsurance(true);
    setMilitaryRemoveModal(false);
    setOpenSnackBar(true);
  });

  useEffect(() => {
    const priorInsuranceFormOpen = !!priorInsuranceRef || isAddingOrRemovingRef;
    if (setIsFormOpen) setIsFormOpen(priorInsuranceFormOpen || isMilitaryFormOpen);
  }, [isMilitaryFormOpen, isAddingOrRemovingRef, priorInsuranceRef, setIsFormOpen]);

  const {
    props: { value: name = '' },
  } = usePersonField('firstName');
  const nameOrYou = unambiguousName || 'you';
  const hasYouInLabel = name === '';

  useEffect(() => {
    trackClick({ action: 'current_bi_limit_selection', label: limit.props.value });
  }, [limit.props.value]);

  useEffect(() => {
    if (endDate.props.value !== 'Invalid date')
      trackClick({ action: 'current_policy_end_date', label: endDate.props.value });
  }, [endDate.props.value]);

  const handleDialogClose = useEvent((ref: string | undefined) => {
    if (ref) {
      removePriorRef(ref);
    } else {
      removeAllPriorRefs();
      setHasPriorInsurance(false);
    }
    setRemoveModalProperties({ open: false, ref: '' });
    setPriorPolicyActionInfoType('REMOVE');
    setPriorInsuranceRef('');
    setIsAddingOrRemovingRef(false);
  });

  const closeSnackBar = useEvent(() => {
    setOpenSnackBar(false);
  });

  const { open, ref } = removeModalProperties;

  const handlePriorInsuranceDialogClose = useCallback(() => {
    setRemoveModalProperties({ open: false, ref: '' });
    setIsAddingOrRemovingRef(false);
  }, [setRemoveModalProperties, setIsAddingOrRemovingRef]);

  const handlePriorInsuranceDialogActionClick = useCallback(() => {
    handleDialogClose(ref);
  }, [handleDialogClose, ref]);

  const handlePriorInsuranceDialogCancelClick = useCallback(() => {
    setRemoveModalProperties({ open: false, ref: '' });
    setIsAddingOrRemovingRef(false);
  }, [setRemoveModalProperties, setIsAddingOrRemovingRef]);

  const removePriorInsuranceDialog = (
    <Dialog
      className={classes.root}
      actionButtonLabel={
        ref || priorInsuranceRefsValue.length === 1 ? 'REMOVE POLICY' : 'REMOVE POLICIES'
      }
      textButtonLabel='Cancel'
      titleText={
        ref || priorInsuranceRefsValue.length === 1
          ? 'Remove prior insurance'
          : 'Remove all prior insurance'
      }
      open={open}
      onClose={handlePriorInsuranceDialogClose}
      actionButtonOnClick={handlePriorInsuranceDialogActionClick}
      textButtonOnClick={handlePriorInsuranceDialogCancelClick}
      buttonPlacement='right'
      hideTitleCloseButton
      hideDivider
    >
      <p>Any associated content will be removed.</p>
    </Dialog>
  );

  const ptitleText =
    militaryDeploymentsList.length === 1
      ? 'Remove military deployment?'
      : 'Remove all military deployments?';

  const handleClose = useCallback(() => {
    setMilitaryRemoveModal(false);
  }, [setMilitaryRemoveModal]);

  const removeMilitaryDeploymentDialog = (
    <Dialog
      className={classes.root}
      titleText={ptitleText}
      actionButtonLabel='Remove'
      textButtonLabel='Cancel'
      actionButtonOnClick={removeAllSavedMilitaryDeploymentsFromDriver}
      open={removeMilitaryModal}
      onClose={handleClose}
      buttonPlacement='right'
      hideTitleCloseButton
      hideDivider
    >
      <p>Any associated content will be removed.</p>
    </Dialog>
  );

  // default to No when no prior insurance exists.
  useEffect(() => {
    if (priorInsuranceRefsValue.length === 0) {
      setHasPriorInsurance(false);
    }
  }, [priorInsuranceRefsValue.length]);

  const showConfirmation = useCallback((): boolean => {
    return !!(
      state?.value ||
      carrier?.value ||
      carrierNameText?.value ||
      years?.value ||
      policyInceptionDate?.value ||
      endDate?.value ||
      lapse?.value ||
      limit?.value ||
      vehicleCollision?.value ||
      vehicleComprehensive?.value
    );
  }, [
    carrier?.value,
    carrierNameText?.value,
    endDate?.value,
    lapse?.value,
    limit?.value,
    policyInceptionDate?.value,
    state?.value,
    vehicleCollision?.value,
    vehicleComprehensive?.value,
    years?.value,
  ]);

  const handlehasPriorInsuranceChange = useCallback(
    async (value: AnswerValue) => {
      if (!value && hasPriorInsurance) {
        // user changed from YES to NO, so do not show dialog if prior insurance doen't exists.
        if (priorInsuranceRefsValue.length === 0) {
          setRemoveModalProperties({
            open: false,
            ref: '',
          });
          setHasPriorInsurance(false);
          // user changed from YES to NO and there is only one prior insurance
          // verify any non-defaulted fields are undefined and skip displaying confirmation dialog
          // priorInsuranceRef is true when edit fields are displayed, isEditMode is true when editing, but not when newly added
        } else if (
          priorInsuranceRef !== '' &&
          !isEditMode &&
          priorInsuranceRefsValue.length === 1 &&
          !showConfirmation()
        ) {
          setIsAddingOrRemovingRef(true);
          removeAllPriorRefs();
          setHasPriorInsurance(false);
          setRemoveModalProperties({ open: false, ref: '' });
          setPriorInsuranceRef('');
          setIsAddingOrRemovingRef(false);
        } else {
          // user changed from YES to NO, so show dialog to remove any prior insurance if exists.
          setRemoveModalProperties({
            open: true,
            ref: '',
          });
        }
      } else if (value && priorInsuranceRefsValue.length < 1 && !hasPriorInsurance) {
        // If the user has added military deployment then click yes to having prior provide a message
        if (militaryDeploymentsList.length > 0) {
          //  verify both military deployment dates fields are undefined and skip displaying confirmation dialog
          if (
            militaryDeploymentsList.length === 1 &&
            militaryDeploymentsList[0].activeMilitaryDeployDate === undefined &&
            militaryDeploymentsList[0].militaryReturnDate === undefined
          ) {
            militaryDeploymentsList.forEach((militaryDeployment) =>
              removeMilitaryDeployments(militaryDeployment),
            );
            initializePriorInsurance(true);
          } else {
            setMilitaryRemoveModal(true);
          }
        } else {
          if (!priorInsuranceRef) initializePriorInsurance(true);
        }
      } else if (value && priorInsuranceRef) {
        // If the form is already open, do not open another form
        return;
      } else {
        initializePriorInsurance(!!value);
      }
    },
    [
      hasPriorInsurance,
      priorInsuranceRefsValue.length,
      priorInsuranceRef,
      isEditMode,
      showConfirmation,
      removeAllPriorRefs,
      militaryDeploymentsList,
      initializePriorInsurance,
      removeMilitaryDeployments,
    ],
  );

  const handleAddPriorInsurance = useEvent(async () => {
    setIsAddingOrRemovingRef(true);
    const newPriorInsurance = addPriorInsurance();
    const { priorInsuranceRef } = newPriorInsurance;
    await newPriorInsurance.done;
    setPriorInsuranceRef(priorInsuranceRef);
    setIsAddingOrRemovingRef(false);
    setIsEditMode(false);
  });

  const handleRemovePolicy = useEvent(async (selectedPriorRef: string) => {
    setIsAddingOrRemovingRef(true);
    setRemoveModalProperties({
      open: true,
      ref: selectedPriorRef,
    });
  });

  // Need to feed refs by themselves to the Resource component, so we need to sort the order of the refs here
  const refsSortedByEndDate = priorInsuranceInfo
    .sort((a, b) => new Date(b.policyEndDate).valueOf() - new Date(a.policyEndDate).valueOf())
    .map((priorInsurance) => priorInsurance.ref);
  const latestEndDate = priorInsuranceInfo.find(
    (priorInsurance) => priorInsurance.ref === refsSortedByEndDate[0],
  )?.policyEndDate;
  const autoPolicyEffectiveDate = useSelector(getPolicyStartDates).auto;
  const hasGapInInsurance =
    !hasPriorInsurance || dayjs(latestEndDate).isBefore(dayjs(autoPolicyEffectiveDate)); // if the endDate is at least one day before the effective date, it's a lapse
  const hasPriorInsurancePrefill = useEvent(
    (ref: string) =>
      priorInsuranceInfo.find((priorInsurance) => priorInsurance.ref === ref)?.source === 'Prefill',
  );
  const hasAnyPrefill = refsSortedByEndDate.find((ref: string) => hasPriorInsurancePrefill(ref));
  const renderPriorRefItem = useEvent((item: string) => {
    const priorInsuranceItem = priorInsuranceInfo.find(
      (priorInsurance) => priorInsurance.ref === item,
    );

    return (
      <>
        <Resource.Item
          xs={2}
          title='Policy Effective'
          value={
            priorInsuranceItem &&
            priorInsuranceItem.policyInceptionDate &&
            priorInsuranceItem.policyEndDate
              ? `${dayjs(priorInsuranceItem.policyInceptionDate).format('MM/DD/YYYY')} - ${dayjs(
                  priorInsuranceItem.policyEndDate,
                ).format('MM/DD/YYYY')}`
              : ' '
          }
        />
        <Resource.Item
          xs={2}
          title='Insurer'
          value={
            priorInsuranceItem?.carrierName !== 'Other - Not Listed'
              ? priorInsuranceItem?.carrierName
              : priorInsuranceItem?.carrierNameText
          }
        />
        <Resource.Item
          xs={2}
          title='BI Limits'
          value={priorInsuranceItem ? priorInsuranceItem['coverages.policy.bodilyInjury'] : ' '}
        />
        <Resource.Item
          xs={2}
          title='Other Coverage'
          value={
            priorInsuranceItem &&
            (() => {
              let coverageContent = '';
              if (priorInsuranceItem['coverages.vehicle.comprehensive']) {
                coverageContent = 'Comprehensive';
              }
              if (priorInsuranceItem['coverages.vehicle.collision']) {
                coverageContent += ' Collision';
              }

              return coverageContent;
            })()
          }
        />
        <Resource.Item
          xs={2}
          title='Source'
          value={priorInsuranceItem?.source === 'Prefill' ? 'Pre-Fill Report' : 'Insured'}
        />
      </>
    );
  });

  const toggleEditPriorInsurance = useEvent((selectedPriorRef: string) => {
    // Don't allow edit / click of another form while current form is up or loading
    if (isAddingOrRemovingRef || priorInsuranceRef) return;
    setIsEditMode(true);
    setIsAddingOrRemovingRef(true);
    setPriorInsuranceRef(selectedPriorRef);
    setIsAddingOrRemovingRef(false);
  });

  useEffect(() => {
    if (priorInsuranceRef) {
      setPriorInsuranceSnapshot({
        state: state.value,
        carrier: carrier.value,
        carrierNameText: carrierNameText.value,
        policyEndDate: endDate.value,
        policyInceptionDate: policyInceptionDate.value,
        'coverages.policy.bodilyInjury': limit.value,
        'coverages.vehicle.comprehensive': vehicleComprehensive.value,
        'coverages.vehicle.collision': vehicleCollision.value,
      });
    } else {
      setPriorInsuranceSnapshot(undefined);
    }
    // Only execute once prior ref state has updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priorInsuranceRef]);

  const handleCancelAdd = useEvent(async (selectedPriorRef: string) => {
    if (!isEditMode) {
      // Don't show confirmation modal, on cancel, if any non-defaulted fields have values
      if (showConfirmation()) {
        handleRemovePolicy(selectedPriorRef);
      } else {
        setPriorInsuranceRef('');
        await removePriorRef(selectedPriorRef);
      }
    } else if (priorInsuranceSnapshot) {
      await Promise.all([
        state.validateUpdateAndPatch(priorInsuranceSnapshot.state || ''),
        carrier.validateUpdateAndPatch(priorInsuranceSnapshot.carrier || ''),
        carrierNameText.validateUpdateAndPatch(priorInsuranceSnapshot.carrierNameText || null),
        endDate.validateUpdateAndPatch(priorInsuranceSnapshot.policyEndDate || ''),
        limit.validateUpdateAndPatch(priorInsuranceSnapshot['coverages.policy.bodilyInjury'] || ''),
        vehicleCollision.validateUpdateAndPatch(
          !!priorInsuranceSnapshot['coverages.vehicle.collision'],
        ),
        vehicleComprehensive.validateUpdateAndPatch(
          !!priorInsuranceSnapshot['coverages.vehicle.comprehensive'],
        ),
        policyInceptionDate.validateUpdateAndPatch(
          priorInsuranceSnapshot.policyInceptionDate || '',
        ),
      ]);
      setPriorInsuranceRef('');
      setIsEditMode(false);
    }
  });

  const handleSavePriorRef = useEvent(() => {
    // Fields are already saved, so just close form
    setPriorInsuranceRef('');
    setPriorPolicyActionInfoType('ADD'); // snackbar
    setIsEditMode(false);
  });

  const handleInfoSnackbarClose = useEvent(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setPriorPolicyActionInfoType('NONE');
      }
    },
  );

  const snackbarDefault = priorInsuranceActionInfoType === 'REMOVE' && (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open
      autoHideDuration={3000}
      message='Insurance removed.'
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const snackbarSuccess = priorInsuranceActionInfoType === 'ADD' && (
    <SnackbarAlert
      open
      autoHideDuration={3000}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
      severity='success'
      message='Insurance saved.'
      hideActionButton
    />
  );
  const priorCarrierLabel = `${
    hasYouInLabel ? 'Do' : 'Does'
  } ${nameOrYou} have prior auto insurance?`;

  const renderForm = useEvent((formProps: ResourceFormProps) => {
    return (
      <PriorInsuranceQuestions
        {...formProps}
        isPni
        driverRef={driverRef}
        priorInsuranceRef={priorInsuranceRef}
        pageErrors={pageErrors}
      />
    );
  });

  const renderInsuranceQuestions = (): React.ReactElement => (
    <>
      {removePriorInsuranceDialog}
      {removeMilitaryDeploymentDialog}
      <GridItem topSpacing='sm' bottomSpacing='lg' xs={12}>
        {hasAnyPrefill && (
          <ReadOnlyField label={priorCarrierLabel} value='Yes' id='hasPriorInsurance' />
        )}
        {!hasAnyPrefill && (
          <RadioGroupWithOptions
            value={hasPriorInsurance}
            actionOnComplete={handlehasPriorInsuranceChange}
            label={priorCarrierLabel}
            id='HasPriorInsurance'
            variant='yesNoButton'
            data-testid='hasPriorInsurance'
            trackingName='PriorInsurance'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
          />
        )}
      </GridItem>
      {isAddingOrRemovingRef && <CircularProgress />}
      {/* show the prior insurance questions if selected as true. */}
      {hasPriorInsurance && (
        <GridItem xs={12} bottomSpacing='sm'>
          <Resource.List
            items={refsSortedByEndDate}
            pageErrors={pageErrors}
            editItemRef={priorInsuranceRef}
            onEdit={toggleEditPriorInsurance}
            onDelete={handleRemovePolicy}
            onCancel={handleCancelAdd}
            hideDelete={hasPriorInsurancePrefill}
            hideEdit={hasPriorInsurancePrefill}
            onNext={handleSavePriorRef}
            form={renderForm}
            renderListItem={renderPriorRefItem}
          />
          <Resource.AddButton
            onClick={handleAddPriorInsurance}
            data-testid='autoPriorRefAdd'
            isProcessing={isAddingOrRemovingRef}
            disabled={!!priorInsuranceRef || isAddingOrRemovingRef}
          >
            ADD ANOTHER POLICY
          </Resource.AddButton>
        </GridItem>
      )}
      {snackbarDefault}
      {snackbarSuccess}
      {/* TODO: Remove this logic once we have the hide expression working that SAPI sends */}

      {!hasPriorInsurance && (
        <MilitaryDeploymentsQuestion
          driverRef={driverRef}
          personRef={personRef}
          hasMilitaryDeployment={hasMilitaryDeployment}
          setHasMilitaryDeployment={setHasMilitaryDeployment}
          setIsMilitaryFormOpen={setIsMilitaryFormOpen}
        />
      )}
      {hasGapInInsurance && !hasMilitaryDeployment && (
        <GridItem topSpacing='lg' xs={8}>
          <DriverLevelLapseQuestion driverRef={driverRef} name={nameOrYou} />
        </GridItem>
      )}
    </>
  );

  return (
    <>
      {isPni && renderInsuranceQuestions()}
      {!isPni && isSniValue && allowAddSNI && renderInsuranceQuestions()}
      <GridItem xs={12}>
        {openSnackBar && (
          <Snackbar
            classes={{ root: `${classes.snackBarWidth}` }}
            message='Deployment removed.'
            vertical='bottom'
            horizontal='center'
            autoHideDuration={3000}
            open={openSnackBar}
            onClose={closeSnackBar}
          />
        )}
      </GridItem>
    </>
  );
};

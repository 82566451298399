import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Pagination' })((theme) => ({
  root: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main, // Blue background for selected item
      color: theme.palette.common.white, // White text for selected item
    },
    '& .MuiPaginationItem-firstLast > svg > path': {
      fill: theme.palette.text.primary,
    },
    '& .MuiPaginationItem-previousNext> svg > path': {
      fill: theme.palette.text.primary,
    },
    '& .Mui-disabled': {
      color: theme.palette.text.disabled,
    },
    '& .MuiPaginationItem-text': theme.typography.body1,
    '&.MuiPagination-root': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  image: {
    width: '100%',
    height: '100%',
  },
}));

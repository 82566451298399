import { Box } from '@mui/material';
import type { Control } from 'react-hook-form';
import { useController } from 'react-hook-form';

import { Toggle } from '@ecp/components';

import { toggleOptions } from '../../constants';
import type { TPIInputFields } from '../../types';
import { useStyles } from './SearchType.styles';

export const SearchType: React.FC<{ control: Control<TPIInputFields> }> = ({ control }) => {
  const { field } = useController({ name: 'searchType', control, rules: { required: true } });
  const { classes } = useStyles();

  return (
    <Box component='div' className={classes.toggle}>
      <Toggle {...field} size='small' options={toggleOptions} fullWidth={false} />
    </Box>
  );
};

import { useEffect } from 'react';

import { env } from '@ecp/env';
import { Page } from '@ecp/features/sales/shared/components';
import {
  getIsBundleForOfferProductsSelected,
  getOfferProductsSelectedByType,
  getOffers,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { QuoteResumeErrorPageForm } from '../../forms';

/**
 * This page will be rendered only when their is an offer and offers has an error when resumed.
 * of all the fields.
 *
 * Note:- This is a temp solution to take user to a custom designed error page if the session retreive gave back the    PrimaryFlowStep as RESUME_FAILED
 */

export const QuoteResumeErrorPage: React.FC = () => {
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const { auto: autoProduct, property: propertyProduct } = useSelector(
    getOfferProductsSelectedByType,
  );
  const offers = useSelector(getOffers);
  const autoQuoteNumber = autoProduct && offers[autoProduct]?.details?.quoteNumber;
  const propertyQuoteNumber = propertyProduct && offers[propertyProduct]?.details?.quoteNumber;

  useEffect(() => {
    if (!isBundle) {
      const plpcQuoteNumber = autoQuoteNumber || propertyQuoteNumber;
      window.open(`${env.advancePolicyCenterRedirectUrl}?JobNumber=${plpcQuoteNumber}`, '_self');
    }
  }, [isBundle, autoQuoteNumber, propertyQuoteNumber]);

  return (
    <div>
      <Page title='Resume Quote' analyticsElement='choice.quoteResumeError.page'>
        <QuoteResumeErrorPageForm
          isBundle={isBundle}
          offers={offers}
          autoProduct={autoProduct}
          propertyProduct={propertyProduct}
          autoPLPCQuoteNumber={autoQuoteNumber}
          propertyPLPCQuoteNumber={propertyQuoteNumber}
        />
      </Page>
    </div>
  );
};

import { useCallback } from 'react';

import { datadogLog } from '@ecp/utils/logger';

import {
  collectRefProperties,
  createRef,
  deleteInquiryRef,
  getAllValues,
  getAnswer,
  getAnswers,
  getInquiryLoaded,
  getPrimaryInsuredDriverRef,
  getPriorInsuranceRefsForDriver,
  getQuestions,
  getValueForPrefix,
  setNavIncidentRefChanged,
  updateAddedRef,
  updateAnswers,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Field } from '@ecp/types';

export interface DriverInsuranceFields {
  isCurrentlyInsured: Field;
  lapse: Field;
  priorRef: Field;
}

export interface PriorInsuranceFields {
  state: Field;
  carrier: Field;
  carrierNameText: Field;
  years: Field;
  endDate: Field;
  limit: Field;
  vehicleComprehensive: Field;
  vehicleCollision: Field;
  policyInceptionDate: Field;
}

export interface PriorInsurance {
  ref: string;
  carrierName: string;
  carrierNameText: string;
  policyInceptionDate: string;
  policyEndDate: string;
  'coverages.policy.bodilyInjury': string;
  'coverages.vehicle.comprehensive': string;
  'coverages.vehicle.collision': string;
  state: string;
  source: string;
}

export interface AddInsuranceResult {
  done: Promise<string>;
  priorInsuranceRef: string;
}

export const useAddPriorInsurance = (driverRef?: string): (() => AddInsuranceResult) => {
  const dispatch = useDispatch();
  const inquiryLoaded = useSelector(getInquiryLoaded);

  const driverInsuranceRefs = useSelector((state: RootStore) =>
    getPriorInsuranceRefsForDriver(state, { driverRef }),
  );

  return useCallback(() => {
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin: 'apps/sales/edsp-asp/src/lob/autoLine/utils/priorInsuranceUtil.ts',
          functionOrigin: 'useAddPriorInsurance/useCallback',
        },
      });
      throw new Error('inquiry not loaded');
    }

    let priorInsuranceRef = driverRef ? dispatch(createRef('priorInsurance')) : '';
    while (driverInsuranceRefs.includes(priorInsuranceRef)) {
      priorInsuranceRef = dispatch(createRef('priorInsurance'));
    }

    const priorRefsKey = `${driverRef}.priorInsurance.ref`;
    dispatch(
      updateAddedRef({
        type: `${priorRefsKey}`,
        newRef: priorInsuranceRef,
      }),
    );
    const updateIncidentNav = dispatch(setNavIncidentRefChanged(priorInsuranceRef || ''));
    const done = Promise.all([updateIncidentNav]).then((): string => priorInsuranceRef);

    return { done, priorInsuranceRef };
  }, [dispatch, inquiryLoaded, driverInsuranceRefs, driverRef]);
};

export const useRemoveUnusedPriorRefs = (
  driverRef: string,
): ((priorRef: string) => Promise<void>) => {
  const dispatch = useDispatch();
  const priorInsuranceInfo = useGetPriorInsuranceInfo(driverRef);

  return useCallback(
    async (priorRef: string) => {
      if (driverRef) {
        const hasPrefillSource =
          priorInsuranceInfo.find((priorInsurance) => priorInsurance.ref === priorRef)?.source ===
          'Prefill';
        if (!hasPrefillSource) {
          await dispatch(deleteInquiryRef(priorRef));
        }
      }
    },
    [dispatch, driverRef, priorInsuranceInfo],
  );
};

export const useRemoveAllPriorInsurance = (driverRef: string): (() => void) => {
  const dispatch = useDispatch();
  const priorRefsValue = useSelector((state: RootStore) =>
    getPriorInsuranceRefsForDriver(state, { driverRef }),
  );
  const priorInsuranceInfo = useGetPriorInsuranceInfo(driverRef);

  return useCallback(() => {
    priorRefsValue
      .filter(
        (priorRef) =>
          priorInsuranceInfo.find((priorInsurance) => priorInsurance.ref === priorRef)?.source !==
          'Prefill',
      )
      .forEach(async (priorRef) => {
        await dispatch(deleteInquiryRef(priorRef));
      });
  }, [priorRefsValue, dispatch, priorInsuranceInfo]);
};

export const useGetPriorInsuranceInfo = (
  driverRef?: string,
): Pick<
  PriorInsurance,
  | 'ref'
  | 'state'
  | 'policyInceptionDate'
  | 'policyEndDate'
  | 'carrierName'
  | 'carrierNameText'
  | 'coverages.policy.bodilyInjury'
  | 'coverages.vehicle.comprehensive'
  | 'coverages.vehicle.collision'
  | 'source'
>[] => {
  const driverInsuranceRefs = useSelector((state: RootStore) =>
    getPriorInsuranceRefsForDriver(state, { driverRef }),
  );

  const answers = useSelector((state: RootStore) => getAnswers(state));
  const questions = useSelector((state: RootStore) => getQuestions(state));
  const priorInsuranceInfo = driverInsuranceRefs.map((ref) =>
    collectRefProperties(answers, questions, ref, {
      state: '',
      policyInceptionDate: '',
      policyEndDate: '',
      carrierName: '',
      carrierNameText: '',
      'coverages.policy.bodilyInjury': '',
      'coverages.vehicle.comprehensive': '',
      'coverages.vehicle.collision': '',
      ref,
      source: '',
    }),
  );

  return priorInsuranceInfo;
};

// Copy value from pni current insurance.
// Might be useful for SNI.
// TODO: delete if not used for SNI.
export const useCopyPriorInsurance = (driverRef: string): (() => void) => {
  const dispatch = useDispatch();
  const pniDriverRef = useSelector(getPrimaryInsuredDriverRef);
  const pniCurrentInsuranceRef = useSelector((state: RootStore) =>
    getAnswer(state, `static.${pniDriverRef}.current.insurance.ref`),
  ) as string;
  const targetRef = useSelector((state: RootStore) =>
    getAnswer(state, `static.${driverRef}.current.insurance.ref`),
  ) as string;
  const allValues = useSelector(getAllValues);
  const getString = getValueForPrefix<string>(pniCurrentInsuranceRef, allValues);

  return useCallback(() => {
    dispatch(
      updateAnswers({
        answers: {
          [`${targetRef}.state`]: getString('state'),
          [`${targetRef}.carrier`]: getString('carrier'),
          [`${targetRef}.carrierNameText`]: getString('carrierNameText'),
          [`${targetRef}.years`]: getString('years'),
          [`${targetRef}.endDate`]: getString('endDate'),
          [`${targetRef}.limit`]: getString('limit'),
          [`${targetRef}.vehicleComprehensive`]: getString('vehicleComprehensive'),
          [`${targetRef}.vehicleCollision`]: getString('vehicleCollision'),
        },
      }),
    );
  }, [targetRef, getString, dispatch]);
};

export const useGetDriverInsuranceFields = (driverRef: string): DriverInsuranceFields => {
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');

  return {
    isCurrentlyInsured: useDriverField('isCurrentlyInsured'),
    lapse: useDriverField('lapse'),
    priorRef: useDriverField('priorInsurance.ref'),
  };
};

export const priorInsuranceAnswerKeys = {
  state: 'state',
  carrier: 'carrierName',
  carrierNameText: 'carrierNameText',
  years: 'years',
  endDate: 'policyEndDate',
  limit: 'coverages.policy.bodilyInjury',
  vehicleComprehensive: 'coverages.vehicle.comprehensive',
  vehicleCollision: 'coverages.vehicle.collision',
  policyInceptionDate: 'policyInceptionDate',
};

export const useGetPriorInsuranceFields = (priorRef: string): PriorInsuranceFields => {
  const usePriorField = useFieldWithPrefix(priorRef, 'priorInsurance.<id>');

  return {
    state: usePriorField(priorInsuranceAnswerKeys.state),
    carrier: usePriorField(priorInsuranceAnswerKeys.carrier),
    carrierNameText: usePriorField(priorInsuranceAnswerKeys.carrierNameText),
    years: usePriorField(priorInsuranceAnswerKeys.years),
    endDate: usePriorField(priorInsuranceAnswerKeys.endDate),
    limit: usePriorField(priorInsuranceAnswerKeys.limit),
    vehicleComprehensive: usePriorField(priorInsuranceAnswerKeys.vehicleComprehensive),
    vehicleCollision: usePriorField(priorInsuranceAnswerKeys.vehicleCollision),
    policyInceptionDate: usePriorField(priorInsuranceAnswerKeys.policyInceptionDate),
  };
};

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DriversForm' })((theme) => ({
  ...theme.mixins.formAll,
  buttonsPanel: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  addButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: 20,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  addAnotherButton: {
    height: 'auto',
    padding: '4px 10px',
    textTransform: 'none',
    border: `1px solid ${theme.palette.primary.border}`,
  },
  cancelButton: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  subTextWrapper: {
    ...theme.typography.body2,
    color: theme.palette.text.tertiary,
    marginBottom: 40,
  },
  errorSubTextWrapper: {
    color: theme.palette.error.darkText,
    marginBottom: 40,
  },
  error: {
    marginBottom: 8, // needs to be 40 after last error, see errorContainer
    width: '100%',
  },
  maritalWarning: {
    border: `2px solid ${theme.palette.error.main}`,
    background: theme.palette.error.light,
    borderRadius: 3,
    padding: 12,
    marginTop: 15,
  },
  snackBarWidth: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
  errorsContainer: {
    marginBottom: 32, // should be 40, 32 +  8 on marginBottom on error
    width: '100%',
  },
}));

import { useState } from 'react';

import { Grid } from '@mui/material';

import { assertString } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import { GridItem } from '@ecp/components';
import { useAddFields, useRemoveByFields } from '@ecp/features/sales/form';
import { AddressQuestions } from '@ecp/features/sales/shared/components';
import type { AddressOptions, AddressUpdateParams } from '@ecp/features/sales/shared/store';
import {
  createRef,
  getPrimaryInsuredAddressRefTrue,
  updateAddress,
  useGetAllAddressOptions,
  useMailingAddressRef,
} from '@ecp/features/sales/shared/store';
import { useAddressFields } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

export const MailingAddressQuestion: React.FC = () => {
  const dispatch = useDispatch();

  const primaryInsuredAddressRef = useSelector(getPrimaryInsuredAddressRefTrue);
  const primaryMailingAddressRef = useMailingAddressRef();
  // we must assume insured and mailing address have been previously setup for this component to work
  assertString('primaryInsuredAddressRef', primaryInsuredAddressRef);
  assertString('primaryMailingAddressRef', primaryMailingAddressRef);

  // if the user selectes 'Add New Address' we will use a new addresRef
  // call useState so that way we don't do this more than once
  const [draftRef] = useState(() => dispatch(createRef('address')));

  const [selectedMailingAddressRef, setSelectedMailingAddressRef] =
    useState(primaryMailingAddressRef);
  const addressOptions: AddressOptions[] = useGetAllAddressOptions(
    draftRef,
    selectedMailingAddressRef,
  );

  const isAddressFieldsDisabled = selectedMailingAddressRef !== draftRef;

  // we need the address field in order to add it to the form
  const { address } = useAddressFields(selectedMailingAddressRef);

  // add to the form
  useAddFields(address);

  // when the component is given a new reference, we need to remove
  // it from the form so that it doesn't continue to validate
  const removeByFields = useRemoveByFields();

  const onMailingAddressChange = useEvent(async (selectedMailingAddressRef: string) => {
    setSelectedMailingAddressRef(selectedMailingAddressRef);

    // address is still pointing at the old address ref,
    // so remove this from the form now,
    // then when the component rerenders,
    // useAddFields(address) will have updated by then
    // and we can add the new address to the form.
    // FIXME: perhaps the child component (<AddressQuestions/>)
    // should just do this instead
    removeByFields(address);
    const addressUpdateParams: AddressUpdateParams = {
      existingAddressRef: primaryMailingAddressRef,
      selectedAddressRef: selectedMailingAddressRef,
      addressType: 'MAILING',
    };

    await dispatch(updateAddress(addressUpdateParams));
  });

  return (
    <GridItem>
      <Grid>
        <GridItem>
          <AddressQuestions
            selectedAddressRef={selectedMailingAddressRef}
            isAddressFieldsDisabled={isAddressFieldsDisabled}
            addressOptionsLabel='Mailing Address'
            addressLinelabel='New Mailing Address'
            trackingName='mailing_address'
            addressType='MAILING'
            uniqueAddressOptions={addressOptions}
            onAddressChange={onMailingAddressChange}
            isValidAddress
          />
        </GridItem>
      </Grid>
    </GridItem>
  );
};

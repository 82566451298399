import { datadogLog } from '@ecp/utils/logger';

import { env } from '@ecp/env';

import type { AddTPIProps } from '../types';
import { generateSecureRandomId, getCommonHeadersForTpiRequest } from '../utils';

export const addTpiResult = async ({
  addCompanyName,
  addAddress,
  unit,
  addCityName,
  addStateName,
  zipCode,
  userId,
  product,
}: AddTPIProps): Promise<void> => {
  const url = `${env?.ecpDalTpiRoot}/add?line=${product}`;
  const body = JSON.stringify({
    address: {
      address1: addAddress,
      address2: unit,
      city: addCityName,
      state: addStateName,
      zip5: zipCode,
    },
    bank: {
      name1: addCompanyName,
    },
    correlationId: `${env.static?.applicationName ?? ''}:${generateSecureRandomId()}`,
    userId,
  });

  const response = await fetch(url.toString(), {
    method: 'POST',
    redirect: 'follow',
    headers: {
      ...getCommonHeadersForTpiRequest(),
      'content-type': 'application/json',
    },
    body,
  });

  if (!response.ok) {
    datadogLog({
      logType: 'error',
      message: `Failed to save TPI result`,
      context: {
        logOrigin: 'libs/features/sales/tpi/src/features/thirdPartyInterest/tpiAdd.tsx',
        contextType: 'POST error',
        functionOrigin: 'addTpiResult',
      },
    });
    throw new Error('Failed to save TPI result');
  }
};

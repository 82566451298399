import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { useEvent } from '@ecp/utils/react';

import { Checkbox, GridItem, ReadOnlyField } from '@ecp/components';
import { useAddConditionValues, useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { SECONDARY_INSURED_PERSON_REF } from '@ecp/features/sales/shared/constants';
import {
  createRef,
  getIsBundleForOfferProductsSelected,
  useField,
  useFieldWithPrefix,
  usePniRef,
  useUpdateSniInsuredType,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { SecondaryNamedInsuredDropdownQuestion } from '@ecp/features/sales/shell';
import { getProductDisplayName, type ProductName } from '@ecp/features/shared/product';
import type { Field } from '@ecp/types';

import { useGetNonPniPersons, useGetSniFormFields, useRemoveSniRef } from '../../utils';
import { AddEditSecondaryNamedInsured } from './AddEditSecondaryNamedInsured';
import { useStyles } from './SecondaryNamedInsuredQuestions.styles';

export interface SecondaryNamedInsuredQuestionsProps {
  secondaryInsuredQuestionKey: string;
  productName: ProductName;
  showAddNewPerson: boolean;
}
export const SecondaryNamedInsuredQuestions: React.FC<SecondaryNamedInsuredQuestionsProps> = (
  props,
) => {
  const { productName, secondaryInsuredQuestionKey, showAddNewPerson } = props;
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const { classes, cx } = useStyles();
  const [sniPersonRef, setSniPersonRef] = useState('');
  const dispatch = useDispatch();

  const pniRef = usePniRef();
  const hasSecondaryNamedInsured: Field = useField('static.hasSecondaryNamedInsured');
  const staticSelectedHouseHoldPersonRef = useField(`static.selected.houseHoldPerson.ref`);
  const staticSelectedSniNewlyAddedPersonRef = useField(`static.selected.sniNewlyAddedPerson.ref`);
  const autoSniFieldValue = useField(SECONDARY_INSURED_PERSON_REF).props.value?.trim();
  const useAutoField = useFieldWithPrefix(autoSniFieldValue, 'person.<id>');
  const driverFirstName = useAutoField('firstName');
  const driverLastName = useAutoField('lastName');
  const secondaryInsuredPerson = useField(secondaryInsuredQuestionKey);
  const [isAutoSniSelected, setIsAutoSniSelected] = useState(false);
  const [isAnotherPerson, setIsAnotherPerson] = useState(false);
  const removeSniFields = useRemoveSniRef(sniPersonRef);
  useInitValues({ [`${hasSecondaryNamedInsured.key}`]: false });
  useAddFields({ [`${hasSecondaryNamedInsured.key}`]: hasSecondaryNamedInsured });

  const nonPNIPersons = useGetNonPniPersons(pniRef);
  const updateSniInsuredType = useUpdateSniInsuredType(secondaryInsuredPerson.props.value);
  const sniNewPersonFormFields = useGetSniFormFields(sniPersonRef);
  const autoSNIFormFields = useGetSniFormFields(autoSniFieldValue);
  const isAnotherPersonSelected = sniPersonRef === secondaryInsuredPerson.props.value;
  const productDisplayName = getProductDisplayName(productName);

  const {
    firstName: newPersonFirstName,
    lastName: newPersonLastName,
    dateOfBirth: newPersonDOB,
    gender: newPersonGender,
    married: newPersonMarried,
    occupantType: newPersonOccupant,
    relationshipToApplicant: newPersonRelationshipToApplicant,
  } = sniNewPersonFormFields;

  useAddConditionValues({
    conditionalFields: [
      newPersonFirstName,
      newPersonLastName,
      newPersonDOB,
      newPersonGender,
      newPersonMarried,
      newPersonOccupant,
      newPersonRelationshipToApplicant,
    ],
    isExcluded: () => !isAnotherPerson || !hasSecondaryNamedInsured.value || isAutoSniSelected,
  });

  const houseHoldFormFields = useGetSniFormFields(
    staticSelectedHouseHoldPersonRef.props.value || '',
  );

  const {
    married: houseHoldMarried,
    occupantType: houseHoldOccupant,
    relationshipToApplicant: houseHoldRelationshipToApplicant,
  } = houseHoldFormFields;

  useAddConditionValues({
    conditionalFields: [houseHoldMarried, houseHoldOccupant, houseHoldRelationshipToApplicant],
    isExcluded: () =>
      isAnotherPersonSelected || !hasSecondaryNamedInsured.value || isAutoSniSelected,
  });

  const {
    married: autoSniMarried,
    occupantType: autoSniOccupant,
    relationshipToApplicant: autoSniRelationshipToApplicant,
  } = autoSNIFormFields;

  useAddConditionValues({
    conditionalFields: [autoSniMarried, autoSniOccupant, autoSniRelationshipToApplicant],
    isExcluded: () => !hasSecondaryNamedInsured.value || !isAutoSniSelected,
  });

  useEffect(() => {
    if (isAnotherPerson && !sniPersonRef) {
      const sniRef = dispatch(createRef('person'));
      setSniPersonRef(sniRef);
      secondaryInsuredPerson.props.actionOnComplete(sniRef);
      staticSelectedSniNewlyAddedPersonRef.props.actionOnComplete(sniRef);
      updateSniInsuredType();
    } else if (isAnotherPerson && sniPersonRef) {
      secondaryInsuredPerson.props.actionOnComplete(sniPersonRef);
      staticSelectedSniNewlyAddedPersonRef.props.actionOnComplete(sniPersonRef);
      updateSniInsuredType();
    } else if (!hasSecondaryNamedInsured.value || !isAnotherPerson) {
      removeSniFields();
    }
  }, [
    dispatch,
    hasSecondaryNamedInsured.value,
    isAnotherPerson,
    removeSniFields,
    secondaryInsuredPerson.props,
    sniPersonRef,
    staticSelectedSniNewlyAddedPersonRef.props,
    updateSniInsuredType,
  ]);

  const handleHasSniSelection = useCallback(
    async (value: boolean) => {
      hasSecondaryNamedInsured?.validateUpdateAndPatch(value);
      if (!value) {
        setIsAnotherPerson(false);
        setIsAutoSniSelected(false);
        secondaryInsuredPerson.props.actionOnComplete(null);
      }
    },
    [hasSecondaryNamedInsured, secondaryInsuredPerson.props],
  );

  const handleAutoSniCheckbox = useEvent(async () => {
    if (!isAutoSniSelected) {
      secondaryInsuredPerson.props.actionOnComplete(autoSniFieldValue);
      setIsAutoSniSelected(true);
      setIsAnotherPerson(false);
    } else {
      setIsAutoSniSelected(false);
      secondaryInsuredPerson.props.actionOnComplete('');
    }
  });

  return (
    <Grid container>
      <GridItem xs={12}>
        <RadioGroupWithOptions
          {...hasSecondaryNamedInsured.props}
          label={`Does the ${productDisplayName} policy need a secondary named insured (SNI)?`}
          variant='yesNoButton'
          trackingName='hasSecondaryNamedInsured'
          actionOnComplete={handleHasSniSelection}
        />
      </GridItem>
      {hasSecondaryNamedInsured.value && isBundle && autoSniFieldValue && (
        <GridItem topSpacing='sm' xs={12}>
          <Checkbox
            onChange={handleAutoSniCheckbox}
            checked={isAutoSniSelected}
            disabled={!autoSniFieldValue}
          />
          <span className={cx(classes.questionSubLabel, !autoSniFieldValue && classes.disabled)}>
            Use the SNI from the Auto policy
          </span>
        </GridItem>
      )}
      {hasSecondaryNamedInsured.value && isBundle && isAutoSniSelected && (
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <ReadOnlyField
            label=''
            value={`${driverFirstName.props.value} ${driverLastName.props.value}`}
          />
        </GridItem>
      )}
      {hasSecondaryNamedInsured.value && !isAutoSniSelected && (
        <GridItem xs={12} md={6}>
          <SecondaryNamedInsuredDropdownQuestion
            sniQuestionLabel=''
            secondaryText=''
            sniFieldRef={secondaryInsuredQuestionKey}
            nonPNIPersons={nonPNIPersons}
            showAddNewPerson={showAddNewPerson}
            setIsAnotherPerson={setIsAnotherPerson}
          />
        </GridItem>
      )}

      {secondaryInsuredPerson.props.value && (
        <GridItem topSpacing='sm'>
          <AddEditSecondaryNamedInsured
            secondaryInsuredQuestionKey={secondaryInsuredQuestionKey}
            isAnotherPerson={isAnotherPerson}
            sniPersonRef={sniPersonRef}
            lob={productName}
          />
        </GridItem>
      )}
    </Grid>
  );
};

interface MetadataItem {
  displayText: string;
  documentName: AdditionalDocumentName;
}

interface LobDocuments {
  [documentType: string]: MetadataItem;
}

interface Metadata {
  [product: string]: LobDocuments;
}

export type AdditionalDocumentName =
  | 'EvidenceOfInsurance'
  | 'PremiumStatementFullPay'
  | 'PremiumStatementMonthly';

const AdditionalDocuments: Metadata = {
  'amfam-adv.home': {
    EvidenceOfInsurance: {
      displayText: 'Evidence of Insurance',
      documentName: 'EvidenceOfInsurance',
    },
    PremiumStatementFullPay: {
      displayText: 'Statement of Premium with Full Pay Discount',
      documentName: 'PremiumStatementFullPay',
    },
    PremiumStatementMonthly: {
      displayText: 'Statement of Premium without Full Pay Discount',
      documentName: 'PremiumStatementMonthly',
    },
  },
  'amfam-adv.renters': {
    EvidenceOfInsurance: {
      displayText: 'Evidence of Insurance',
      documentName: 'EvidenceOfInsurance',
    },
  },
};

export default AdditionalDocuments;

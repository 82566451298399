import { useCallback, useMemo } from 'react';

import { ensureStringArray } from '@ecp/utils/common';

import {
  createRef,
  deleteAnswers,
  getAllValues,
  getAnswer,
  getUserValues,
  removeAndDeleteRef,
  useFieldWithPrefix,
  useForm,
} from '@ecp/features/sales/shared/store';
import type {
  RootStore,
  ValidateFormParams,
  ValidateFormResult,
} from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, Field } from '@ecp/types';

import { useInitAndAddField } from '../../utils';

export type ItemizedPersonalPropertyItem = {
  ref: string;
  category: string;
  type: string;
  description: string;
  value: string;
};

// Use this hook to remove all the itemized personal property items and associated refs.
// This hook is used when the user opts out of the coverage after adding multiple items.
export const useRemoveAllItemizedPersonalPropertyItems = (): { (): Promise<void> } => {
  const allValues = useSelector(getAllValues);
  const dispatch = useDispatch();

  const removeAllItemizedPersonalPropertyItems = async (): Promise<void> => {
    const answerKeysStartingWithItemizedPersonalProperty = Object.keys(allValues).filter((key) =>
      key.startsWith('itemizedPersonalProperty.'),
    );
    answerKeysStartingWithItemizedPersonalProperty.push('property.itemizedPersonalProperty.ref');
    await dispatch(deleteAnswers({ keys: answerKeysStartingWithItemizedPersonalProperty }));
  };

  return removeAllItemizedPersonalPropertyItems;
};

export const useFirstAndCreateItemizedPersonalProperty = (): {
  newFirstRef: string;
  createNewItemizedPersonalPropertyRef: () => string;
} => {
  const dispatch = useDispatch();

  const allValues = useSelector(getAllValues);
  const itemizedPersonalPropertyRefList: string[] = ensureStringArray(
    allValues['property.itemizedPersonalProperty.ref'],
  );

  const firstItemizedpersonalPropertyRef = itemizedPersonalPropertyRefList[0];
  // This memoization is important so that the hook doesn't rerender
  // everytime anything change in the store and falls to an infinite loop.
  const newFirstRef = useMemo(() => {
    return !firstItemizedpersonalPropertyRef ? dispatch(createRef('itemizedPersonalProperty')) : '';
  }, [dispatch, firstItemizedpersonalPropertyRef]);

  const createNewItemizedPersonalPropertyRef = (): string => {
    return dispatch(createRef('itemizedPersonalProperty'));
  };

  return {
    newFirstRef,
    createNewItemizedPersonalPropertyRef,
  };
};

// This is the hook which is both used in the main coverage component
// and the associated add/edit form component. The main coverage component
// uses evaluateDataChanged and resetForm capabilities to drive
// different behaviors and snackbar messages.
// The fields, initializations and forms are used in the add/edit form component.
export const useAddEditItemizedPersonalPropertyFormFields = (
  editItemizedPersonalizedPropertyRef: string,
): {
  fields: { [key: string]: Field<AnswerValue> };
  validateForm: (params?: ValidateFormParams) => ValidateFormResult;
  patchFormValues: () => Promise<string>;
  resetForm: () => void;
  isPatchFormInProgress: boolean;
  evaluateDataChanged: () => boolean;
} => {
  const itemizedPersonalPropertyFieldPrefix = useFieldWithPrefix(
    editItemizedPersonalizedPropertyRef,
    'itemizedPersonalProperty.<id>',
  );
  const categoryField = itemizedPersonalPropertyFieldPrefix('category');
  const typeField = itemizedPersonalPropertyFieldPrefix('type');
  const descriptionField = itemizedPersonalPropertyFieldPrefix('description');
  const valueField = itemizedPersonalPropertyFieldPrefix('value');

  useInitAndAddField(categoryField, true);
  useInitAndAddField(typeField, true);
  useInitAndAddField(descriptionField, true);
  useInitAndAddField(valueField, true);

  const fields = {
    categoryField,
    typeField,
    descriptionField,
    valueField,
  };

  const { validateForm, patchFormValues, resetForm, isPatchFormInProgress } = useForm({
    fields,
  });

  const userValues = useSelector(getUserValues);
  const categoryAnswer = useSelector((state: RootStore) => getAnswer(state, categoryField.key));
  const typeAnswer = useSelector((state: RootStore) => getAnswer(state, typeField.key));
  const descriptionAnswer = useSelector((state: RootStore) =>
    getAnswer(state, descriptionField.key),
  );
  const valueAnswer = useSelector((state: RootStore) => getAnswer(state, valueField.key));

  const evaluateDataChanged = useCallback((): boolean => {
    // This is from the Answers store which is actually saved via network call.
    const savedAnswers = {
      [categoryField.key]: categoryAnswer,
      [typeField.key]: typeAnswer,
      [descriptionField.key]: descriptionAnswer,
      [valueField.key]: valueAnswer,
    };

    // user values reads data from form store which tracks what user manipulated in the form data.
    if (!Object.keys(userValues).length) {
      return false;
    }

    // Null and undefined should be treated same, hence != and !==
    // eslint-disable-next-line eqeqeq
    return Object.entries(userValues).some(([key, value]) => value != savedAnswers[key]);
  }, [
    userValues,
    categoryAnswer,
    categoryField.key,
    typeAnswer,
    typeField.key,
    descriptionAnswer,
    descriptionField.key,
    valueAnswer,
    valueField.key,
  ]);

  return {
    fields,
    validateForm,
    patchFormValues,
    resetForm,
    isPatchFormInProgress,
    evaluateDataChanged,
  };
};

export const useGetItemizedPersonalPropertyItem = (): ((
  itemRef: string,
) => ItemizedPersonalPropertyItem) => {
  const allValues = useSelector(getAllValues);

  return useCallback(
    (itemRef: string) => ({
      ref: itemRef,
      category: String(allValues[`${itemRef}.category`]),
      type: String(allValues[`${itemRef}.type`]),
      description: String(allValues[`${itemRef}.description`]),
      value: String(allValues[`${itemRef}.value`]),
    }),
    [allValues],
  );
};

export const useItemizedPropertyRefList = (): string[] => {
  return useSelector((state: RootStore) =>
    ensureStringArray(getAnswer(state, 'property.itemizedPersonalProperty.ref')),
  );
};

export const useJewelryItems = (): string[] => {
  const itemizedPersonalPropertyRefList = useItemizedPropertyRefList();
  const getItemizedPersonalPropertyItem = useGetItemizedPersonalPropertyItem();
  const jewelryItems = itemizedPersonalPropertyRefList.filter(
    (itemRef) => getItemizedPersonalPropertyItem(itemRef).category === 'Jewelry',
  );

  return jewelryItems;
};

export const useJewelryAndOtherThanJewelryItemsTotal = (): [number, number] => {
  const itemizedPersonalPropertyRefList = useItemizedPropertyRefList();
  const getItemizedPersonalPropertyItem = useGetItemizedPersonalPropertyItem();

  return itemizedPersonalPropertyRefList.reduce(
    ([jewelryTotal, nonJewelryTotal], currentref) => {
      const item = getItemizedPersonalPropertyItem(currentref);
      if (item.category === 'Jewelry') {
        jewelryTotal += parseFloat(item.value.trim());
      } else {
        nonJewelryTotal += parseFloat(item.value.trim());
      }

      return [jewelryTotal, nonJewelryTotal];
    },
    [0, 0],
  );
};

export const useOtherThanJewelryItems = (): string[] => {
  const itemizedPersonalPropertyRefList = useItemizedPropertyRefList();
  const getItemizedPersonalPropertyItem = useGetItemizedPersonalPropertyItem();
  const otherThanJewelryItems = itemizedPersonalPropertyRefList.filter(
    (itemRef) => getItemizedPersonalPropertyItem(itemRef).category !== 'Jewelry',
  );

  return otherThanJewelryItems;
};

export const useRemoveItemizedPersonalProperty = ([
  jewelryDeductibleField,
  otherThanJewelryDeductibleField,
  selectedCoverageFieldChangeHandler,
]: [Field, Field, (isCoverageSelected: boolean) => Promise<void>]): ((
  ref: string,
) => Promise<void>) => {
  const dispatch = useDispatch();
  const jewelryItems = useJewelryItems();
  const otherThanJewelryItems = useOtherThanJewelryItems();

  return useCallback(
    async (ref: string) => {
      // If ref is the only item in the itemized personal property list,
      // then opt out from the main coverage and nullify any selection
      if (
        jewelryItems.length + otherThanJewelryItems.length === 1 &&
        (jewelryItems[0] === ref || otherThanJewelryItems[0] === ref)
      ) {
        await selectedCoverageFieldChangeHandler(false);
      }

      // If deleting the one and only jewelry item, then nullify the jewelry deductible
      if (jewelryItems.length === 1 && jewelryItems[0] === ref) {
        await jewelryDeductibleField.updateAndPatch(null);
      }

      // If deleting the one and only other than jewelry item, then nullify the other than jewelry deductible
      if (otherThanJewelryItems.length === 1 && otherThanJewelryItems[0] === ref) {
        await otherThanJewelryDeductibleField.updateAndPatch(null);
      }
      await dispatch(removeAndDeleteRef({ refType: 'property.itemizedPersonalProperty.ref', ref }));
    },
    [
      dispatch,
      jewelryItems,
      otherThanJewelryItems,
      jewelryDeductibleField,
      otherThanJewelryDeductibleField,
      selectedCoverageFieldChangeHandler,
    ],
  );
};

// Only allow unique description for the items for the same type of items.
// If the category is "Jewelry", the type is always "Jewelry".
// Hence, just try to find items with the same description
// returned by useJewelryItems.
// If the category is not Jewelry, then for all the otherThanJewelryItems,
// find items with same type and description.
export const useUniqueDescriptionForItemizedPersonalProperty = (): ((
  itemCategory: AnswerValue,
  itemType: AnswerValue,
  itemDescription: AnswerValue,
  inputRef: string,
) => boolean) => {
  const getItemizedPersonalPropertyItem = useGetItemizedPersonalPropertyItem();
  const jewelryItemsRefList = useJewelryItems();
  const otherThanJewelryItemsRefList = useOtherThanJewelryItems();

  return useCallback(
    (
      itemCategory: AnswerValue,
      itemType: AnswerValue,
      itemDescription: AnswerValue,
      inputRef: string,
    ): boolean => {
      switch (itemCategory) {
        case 'Jewelry': {
          return !jewelryItemsRefList.some(
            (itemRef) =>
              itemRef !== inputRef &&
              getItemizedPersonalPropertyItem(itemRef).description === itemDescription?.toString(),
          );
        }
        default: {
          return !otherThanJewelryItemsRefList.some((itemRef) => {
            const itemDetails = getItemizedPersonalPropertyItem(itemRef);

            return (
              itemRef !== inputRef &&
              itemDetails.type === itemType?.toString() &&
              itemDetails.description === itemDescription?.toString()
            );
          });
        }
      }
    },
    [getItemizedPersonalPropertyItem, jewelryItemsRefList, otherThanJewelryItemsRefList],
  );
};

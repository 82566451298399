import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'HomeReplacementCostAccordion' })((theme) => ({
  addSpacing: {
    ...theme.mixins.addSpacing,
    marginTop: 30,
  },
  accordionContainer: {
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: '4px',
    boxShadow: 'none',
  },
  accordionSummaryHeader: {
    '&.MuiAccordionSummary-gutters': {
      padding: '8px 20px 8px 20px',
    },
  },
  accordionHeaderError: {
    color: theme.palette.error.main,
  },
  accordionDetailsBkg: {
    display: 'block',
    padding: 20,
  },
}));

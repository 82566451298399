import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldSelectComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './JewelryCoverage.styles';

export const JewelryCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();

  // Get the coverage selected fields and all the related subcoverage fields
  const [jewelryCoverageField, limitField] = useGroupedCoverageFields(
    props.carrierName,
    props.product,
    'jewelry',
    ['selected', 'limit'],
  );

  // Define the logic around subcoverage fields which are
  // impacted by opt-in/ opt-out feature for main coverage field
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(jewelryCoverageField, [
    limitField,
  ]);

  const handleJewelryCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );

  if (!jewelryCoverageField.exists) return null;

  const isJewelryCoverageSelected = castToBoolean(jewelryCoverageField.value);

  // TODO Probably with a new story - premium amount
  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='jewelryCoverage'
          field={jewelryCoverageField}
          isCoverageSelected={isJewelryCoverageSelected}
          handleCoverageChange={handleJewelryCoverageChange}
        />
        <Grid container item className={classes.subCoverageRoot}>
          {isJewelryCoverageSelected && <CoverageFieldSelectComponent field={limitField} />}
        </Grid>
      </Grid>
    </div>
  );
};

import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { uuid } from '@ecp/utils/common';
import { Headers } from '@ecp/utils/network';

import { env } from '@ecp/env';

export const getCommonHeadersForTpiRequest = (): HeadersInit => {
  return {
    [Headers.TRACE_ID]: uuid(),
    [Headers.SOURCE_ID]: env.static?.applicationName ?? '',
    [Headers.SESSION_ID]: interactionId.get(),
    [Headers.AUTH_KEY]: env.static?.authKey ?? '',
  };
};

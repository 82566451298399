import { useCallback, useEffect, useRef, useState } from 'react';

import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { ensureZipCode } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import { env } from '@ecp/env';
import { useGetConditionValues } from '@ecp/features/sales/form';
import { Form } from '@ecp/features/sales/shared/components';
import {
  NavStatus,
  PARTNER_EXPERIENCE_ID,
  STATE_CODE_PREFIX,
  STATIC_INITIAL_PRIMARY_INSURED_ADDRESS_REF_FROM_RELATE,
  STATIC_INITIAL_PRIMARY_INSURED_MAILING_ADDRESS_REF_FROM_RELATE,
} from '@ecp/features/sales/shared/constants';
import {
  fetchCityAndStateSuggestionsThunkAction,
  getFieldErrors,
  getInquiryLoaded,
  setFormErrorsChangedByField,
  setZipLookupBypassed,
  submitPolicyType,
  updateAnswers,
  updatePageStatus,
  useForm,
  useGetPartnerFields,
  useGetProductFields,
  useInitialPrimaryAddressRefFromRelate,
  useMailingAddressRef,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import { useAddressFields } from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, AnswerValue } from '@ecp/features/sales/shared/types';
import type { ExperienceId } from '@ecp/partners';
import type { Fields } from '@ecp/types';

import { CallToAction } from './CallToAction';
import { useStyles } from './LandingPageForm.styles';
import { PartnerQuestions } from './PartnerQuestions';
import type { ProductQuestionsProps } from './ProductQuestions';
import { ProductQuestions } from './ProductQuestions';

interface LandingPageFormProps {
  onStartNewQuote: () => Promise<void>;
}

interface SubmitParams extends Pick<ProductQuestionsProps, 'validateZipCodeAndGetStateCity'> {
  onStartNewQuote: LandingPageFormProps['onStartNewQuote'];
  patchFormValues: () => Promise<string>;
  productFields: ReturnType<typeof useGetProductFields>;
  setIsNewQuoteInProgress: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
}

const getResetStateCity = (primaryAddressRef: string): Answers => ({
  [`${primaryAddressRef}.state`]: '',
  [`${primaryAddressRef}.city`]: '',
});

const startNewQuoteAction =
  ({
    onStartNewQuote,
    patchFormValues,
    productFields,
    setIsNewQuoteInProgress,
    validateForm,
    validateZipCodeAndGetStateCity,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      setTimeout(async () => {
        setIsNewQuoteInProgress(true);
        if (!validateForm().isValid) {
          setIsNewQuoteInProgress(false);

          return resolve();
        }
        const stateCity = await validateZipCodeAndGetStateCity(productFields.zipCode.value);
        await dispatch(
          updateAnswers({
            answers: {
              ...stateCity,
              [productFields.zipCode.key]: productFields.zipCode.value,
            },
            // If we already submitted zip/state/city (via field blur),
            // these values will be automatically excluded via preprocessAnswers in updateAnswers
            // and SAPI won't respond with "product not eligible" error,
            // as they only pass this error each time we submit state answer.
            // We want to force-include these answers.
            force: true,
          }),
        );
        let zipCodeFieldErrors = getFieldErrors(getState(), productFields.zipCode.key);
        if (zipCodeFieldErrors.length) {
          setIsNewQuoteInProgress(false);

          return resolve();
        }

        await patchFormValues();
        // Should validate the zip code after patchFormValues
        // here to keep form errors intact and accurate.
        // SAPI returns error only while trying to patch
        // unsupported state and city and not for zip.
        await validateZipCodeAndGetStateCity(productFields.zipCode.value);

        zipCodeFieldErrors = getFieldErrors(getState(), productFields.zipCode.key);

        if (zipCodeFieldErrors.length) {
          setIsNewQuoteInProgress(false);

          return resolve();
        }

        await dispatch(submitPolicyType());

        dispatch(updatePageStatus(NavStatus.VALID));
        await onStartNewQuote();
        setIsNewQuoteInProgress(false);

        return resolve();
      }, 0);
    });

export const LandingPageForm: React.FC<LandingPageFormProps> = (props) => {
  const { onStartNewQuote } = props;
  const productFields = useGetProductFields();
  const partnerFields = useGetPartnerFields();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const primaryAddressRef = usePrimaryAddressRef();
  const primaryMailingAddressRef = useMailingAddressRef();
  const initialPrimaryInsuredRiskAddressesRefValueFromRelate =
    useInitialPrimaryAddressRefFromRelate();
  const inquiryLoaded = useSelector(getInquiryLoaded);
  const [isNewQuoteInProgress, setIsNewQuoteInProgress] = useState(false);
  // Need to check if at least one inquiry has been requested, otherwise spinner will always show since undefined === undefined
  const initValues = useRef({});
  const getCondition = useGetConditionValues();
  const { validateForm, patchFormValues } = useForm({
    initValues,
    fields: { ...partnerFields, ...(productFields as unknown as Fields) },
    conditions: getCondition(),
  });
  const {
    address: { zipcode },
  } = useAddressFields(primaryAddressRef);

  const validateZipCodeAndGetStateCity = useCallback(
    async (value: AnswerValue): Promise<Answers | null> => {
      const zipcode = ensureZipCode(value);
      if (!initialPrimaryInsuredRiskAddressesRefValueFromRelate) {
        await dispatch(
          updateAnswers({
            answers: {
              [STATIC_INITIAL_PRIMARY_INSURED_ADDRESS_REF_FROM_RELATE]: [primaryAddressRef],
              [STATIC_INITIAL_PRIMARY_INSURED_MAILING_ADDRESS_REF_FROM_RELATE]: [
                primaryMailingAddressRef,
              ],
            },
          }),
        );
      }
      if (!zipcode) return getResetStateCity(primaryAddressRef);

      // Prefill city, state and zipcode for Person Page form
      const cityAndStateSuggestionResult = await dispatch(
        fetchCityAndStateSuggestionsThunkAction(zipcode),
      );
      const cityAndStateSuggestion = cityAndStateSuggestionResult?.response;
      dispatch(setZipLookupBypassed(!!cityAndStateSuggestion?.bypass));
      if (cityAndStateSuggestion?.bypass) return getResetStateCity(primaryAddressRef);

      if (!cityAndStateSuggestion?.state) {
        dispatch(
          setFormErrorsChangedByField({
            key: productFields.zipCode.key,
            errors: ['Invalid Zip Code'],
          }),
        );
        datadogLog({
          logType: 'warn',
          message: `Invalid Zip Code - ${productFields.zipCode.value}`,
          context: {
            logOrigin: 'apps/sales/edsp-asp/src/common/forms/LandingPageForm/LandingPageForm.tsx',
            functionOrigin: 'validateZipCodeAndGetStateCity',
          },
        });

        return getResetStateCity(primaryAddressRef);
      }

      return {
        [`${primaryAddressRef}.state`]: `${STATE_CODE_PREFIX}${cityAndStateSuggestion.state}`,
        [`${primaryAddressRef}.city`]: cityAndStateSuggestion.city,
      };
    },
    [
      dispatch,
      initialPrimaryInsuredRiskAddressesRefValueFromRelate,
      primaryAddressRef,
      primaryMailingAddressRef,
      productFields.zipCode.key,
      productFields.zipCode.value,
    ],
  );

  const handleStartNewQuoteSubmit = useCallback(async () => {
    if (zipcode && !zipcode.errors.length && validateForm().isValid) {
      await dispatch(
        startNewQuoteAction({
          onStartNewQuote,
          patchFormValues,
          productFields,
          setIsNewQuoteInProgress,
          validateForm,
          validateZipCodeAndGetStateCity,
        }),
      );
    }
  }, [
    zipcode,
    validateForm,
    dispatch,
    onStartNewQuote,
    patchFormValues,
    productFields,
    validateZipCodeAndGetStateCity,
  ]);
  /* TODO: Need to revisit why below function is required as it is causing multiple session call */
  const handleExperienceIdChanged = useCallback(
    async (value: ExperienceId): Promise<void> => {
      // Reset product selection on exp Id change
      productFields.product.reset();

      interactionId.reset();
    },
    [productFields.product],
  );

  useEffect(() => {
    const updateExpId = async (): Promise<void> => {
      await dispatch(updateAnswers({ answers: { [PARTNER_EXPERIENCE_ID]: env.static.expId } }));
    };
    updateExpId();
  }, [dispatch]);

  return (
    <Form className={classes.root}>
      {/* TODO: Remove this question and all the components used in this */}
      <PartnerQuestions onExperienceIdChange={handleExperienceIdChanged} />

      <ProductQuestions
        validateZipCodeAndGetStateCity={validateZipCodeAndGetStateCity}
        disabled={!inquiryLoaded}
      />
      <CallToAction
        isNewQuoteInProgress={isNewQuoteInProgress}
        onStartNewQuoteClick={handleStartNewQuoteSubmit}
      />
    </Form>
  );
};

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AddTPISearch' })((theme) => ({
  cancelButton: {
    marginTop: '10px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  contentTitle: {
    ...theme.typography.body4,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  contentSubTitle: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    paddingTop: '48px',
  },
  divider: {
    margin: '40px 0px 40px 0px',
    width: '100%',
  },
  searchAddContainer: {
    border: `1px solid ${theme.palette.other.border}`,
    padding: 20,
    marginTop: 15,
    borderRadius: 8,
  },
}));

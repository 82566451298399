import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import type { ProductName } from '@ecp/features/shared/product';
import {
  DeltaSolarPanelsQuestion,
  DeltaWiringTypeOtherDescriptionQuestion,
  DeltaWiringTypeQuestion,
  HomeReplacementCostQuestion,
  NoOfAmpsQuestion,
} from '@ecp/sales/lob/property';

import { useStyles } from './HomeDeltaQuestionsCard.styles';

export interface HomeDeltaQuestionsSectionProps {
  policyType: ProductName;
}

export const HomeDeltaQuestionsCard: React.FC<HomeDeltaQuestionsSectionProps> = (props) => {
  const { policyType } = props;
  const { classes } = useStyles();

  if (policyType !== 'home') return null;

  return (
    <Grid container className={classes.body}>
      <GridItem xs={12}>
        <h2>Home Details</h2>
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <HomeReplacementCostQuestion />
      </GridItem>
      <GridItem topSpacing='md' xs={12}>
        <DeltaSolarPanelsQuestion />
      </GridItem>
      <GridItem topSpacing='md' xs={12}>
        <DeltaWiringTypeQuestion />
      </GridItem>
      <GridItem topSpacing='md' xs={12}>
        <DeltaWiringTypeOtherDescriptionQuestion />
      </GridItem>
      <GridItem topSpacing='md' xs={12}>
        <NoOfAmpsQuestion />
      </GridItem>
    </Grid>
  );
};

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({
  name: 'SendAndPreviewEmailQuotes',
})((theme) => ({
  dialogRoot: {
    maxWidth: '100%',
    '& .MuiDialogActions-root': {
      marginTop: 40,
    },
  },
  iframe: {
    border: 'none',
    width: '100%',
    height: '100%',
    minHeight: 900,
  },
}));

import { useState } from 'react';

import { assertString } from '@ecp/utils/common';
import type { GeoAddress } from '@ecp/utils/geo';
import { useEvent } from '@ecp/utils/react';

import { GridItem } from '@ecp/components';
import { useAddFields, useRemoveByFields } from '@ecp/features/sales/form';
import { AddressQuestions } from '@ecp/features/sales/shared/components';
import type { AddressUpdateParams } from '@ecp/features/sales/shared/store';
import {
  createRef,
  getPrimaryInsuredAddressInfo,
  getPrimaryInsuredAddressRefTrue,
  updateAddress,
  useGetAllAddressOptions,
  useMailingAddressRef,
} from '@ecp/features/sales/shared/store';
import { useAddressFields } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

interface Props {
  geoAddressSuggestions: GeoAddress[];
  isValidAddress: boolean;
  setIsValidAddress: (value: boolean) => void;
  showAddressSuggestion: boolean;
  setShowAddressSuggestion: (value: boolean) => void;
}

export const RiskAddressQuestion: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    isValidAddress,
    geoAddressSuggestions,
    setIsValidAddress,
    showAddressSuggestion,
    setShowAddressSuggestion,
  } = props;

  const primaryInsuredAddressRef = useSelector(getPrimaryInsuredAddressRefTrue);
  const primaryInsuredAddressInfo = useSelector(getPrimaryInsuredAddressInfo);
  const primaryMailingAddressRef = useMailingAddressRef();

  // we must assume insured and mailing address have been previously setup for this component to work
  assertString('primaryInsuredAddressRef', primaryInsuredAddressRef);
  assertString('primaryMailingAddressRef', primaryMailingAddressRef);

  // if the user selectes 'Add New Address' we will use a new addresRef
  // call useState so that way we don't do this more than once
  const [draftRef] = useState(() => dispatch(createRef('address')));

  // selectedAddressRef is the reference that is chosen by the user in the dropdown
  // this defaults to the primary insured address ref.
  const [selectedRiskAddressRef, setSelectedRiskAddressRef] = useState(primaryInsuredAddressRef);

  const addressOptions = useGetAllAddressOptions(draftRef, selectedRiskAddressRef);

  const isAddressFieldsDisabled = selectedRiskAddressRef !== draftRef;
  // we need the address field in order to add it to the form
  const { address } = useAddressFields(selectedRiskAddressRef);

  // add to the form
  useAddFields(address);

  // when the component is given a new reference, we need to remove
  // it from the form so that it doesn't continue to validate
  const removeByFields = useRemoveByFields();

  const onRiskAddressChange = useEvent(async (selectedAddressRef: string) => {
    setSelectedRiskAddressRef(selectedAddressRef);
    setIsValidAddress(true);

    // address is still pointing at the old address ref,
    // so remove this from the form now,
    // then when the component rerenders,
    // useAddFields(address) will have updated by then
    // and we can add the new address to the form.
    // FIXME: perhaps the child component (<AddressQuestions/>)
    // should just do this instead
    removeByFields(address);
    const addressUpdateParams: AddressUpdateParams = {
      existingAddressRef: primaryInsuredAddressRef,
      selectedAddressRef: selectedAddressRef,
      addressType: 'PRIMARY',
    };

    await dispatch(updateAddress(addressUpdateParams));
  });

  return (
    <GridItem>
      <AddressQuestions
        geoAddressSuggestions={geoAddressSuggestions}
        selectedAddressRef={selectedRiskAddressRef}
        isAddressOptionsDisabled={primaryInsuredAddressInfo.isLocked}
        isAddressFieldsDisabled={isAddressFieldsDisabled}
        addressOptionsLabel='Risk Address'
        addressLinelabel='New Risk Address'
        trackingName='risk_address'
        addressType='PRIMARY'
        isValidAddress={isValidAddress}
        uniqueAddressOptions={addressOptions}
        onAddressChange={onRiskAddressChange}
        setIsValidAddress={setIsValidAddress}
        showAddressSuggestion={showAddressSuggestion}
        setShowAddressSuggestion={setShowAddressSuggestion}
      />
    </GridItem>
  );
};

import { useCallback, useEffect } from 'react';

import { Grid, Typography } from '@mui/material';

import { GridItem } from '@ecp/components';
import { Button, Select, TextField } from '@ecp/features/sales/shared/components';
import {
  setFormErrorsChangedByField,
  updateAnswers,
  useAddTpiToVehicle,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/types';

import { AutoAddTPIVehicleHeader } from '../../../../lob/autoLine/components';
import { useThirdPartyInterestPageContext } from '../ThirdPartyInterestContextProvider';
import { useStyles } from './AddEditTPIQuestions.styles';

export const AddEditTPIQuestions: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const {
    handleSaveTpi,
    currentSelectedTpi,
    showAddEditTpi,
    currentSelectedVehicleRef,
    isEdit,
    setShowAddEditTpi,
    setCurrentSelectedTpi,
    setCurrentSelectedVehicleRef,
    handleBackOnAddEditTpi,
    setIsEdit,
  } = useThirdPartyInterestPageContext();

  const tpiRef = currentSelectedTpi ? `delta.${currentSelectedTpi.ref}` : '';
  const useThirdPartyInterestField = useFieldWithPrefix(tpiRef, 'delta.thirdPartyInterest.<id>');
  const addTpi = useAddTpiToVehicle(currentSelectedVehicleRef, currentSelectedTpi?.ref);
  const interestType = useThirdPartyInterestField('interestType');
  const titleHolderType = useThirdPartyInterestField('titleHolderType');
  const loanLeaseNumber = useThirdPartyInterestField('loanLeaseNumber');

  // TODO: add these on Home Dev
  // const billToTpi = useThirdPartyInterestField('billToTpi');
  // const renewalDecToTpi = useThirdPartyInterestField('renewalDecToTpi');

  const showLoanLease =
    loanLeaseNumber.exists &&
    ((interestType &&
      interestType.value === 'TITLE' &&
      titleHolderType &&
      titleHolderType.value === 'LESSOR') ||
      (interestType && interestType.value === 'LIEN'));

  // Used to Reset Fields when the are switched to not shown
  useEffect(() => {
    if (interestType.value !== 'TITLE') {
      titleHolderType.update(null); // Clear titleHolderType when interestType is not 'TITLE'
    }
    if (!showLoanLease) {
      loanLeaseNumber.update(null); // Clear loanLeaseNumber when conditions are not met
    }
  }, [interestType.value, titleHolderType.value, loanLeaseNumber, titleHolderType, showLoanLease]);

  // Manually hand Question validation because we only want to save answers fields to DAL on save Button press
  const questionsHaveValidationErrors = useCallback(() => {
    const interestTypeErrors = interestType.validate(interestType.value);
    if (interestTypeErrors && interestTypeErrors.length > 0) {
      dispatch(setFormErrorsChangedByField({ key: interestType.key, errors: interestTypeErrors }));

      return true;
    }
    const titleHolderTypeErrors = titleHolderType.validate(titleHolderType.value);
    if (titleHolderTypeErrors && titleHolderTypeErrors.length > 0) {
      dispatch(
        setFormErrorsChangedByField({ key: titleHolderType.key, errors: titleHolderTypeErrors }),
      );

      return true;
    }

    const loanLeaseNumberErrors = loanLeaseNumber.validate(loanLeaseNumber.value);
    if (loanLeaseNumberErrors && loanLeaseNumberErrors.length > 0) {
      dispatch(
        setFormErrorsChangedByField({ key: loanLeaseNumber.key, errors: loanLeaseNumberErrors }),
      );

      return true;
    }

    return false;
  }, [dispatch, interestType, loanLeaseNumber, titleHolderType]);

  const onSaveTPI = useCallback(async () => {
    if (questionsHaveValidationErrors()) return;
    if (currentSelectedTpi) {
      interestType.patch(interestType.value);
      titleHolderType.patch(titleHolderType.value);
      loanLeaseNumber.patch(loanLeaseNumber.value);
      if (!isEdit) {
        const tpiAddressRef = currentSelectedTpi.addressRef;
        addTpi();
        await dispatch(
          updateAnswers({
            answers: {
              [`${tpiRef}.name`]: currentSelectedTpi?.name,
              [`${tpiRef}.escrow`]: currentSelectedTpi?.escrow,
              [`${tpiRef}.serviceTpiId`]: currentSelectedTpi?.serviceTpiId,
              [`${tpiRef}.cdhId`]: currentSelectedTpi?.cdhId,
              [`${tpiRef}.address.ref`]: currentSelectedTpi.addressRef,
              // Make a base ThirdPartyInterest Object with attribute used for SAPI entity Add/Delete
              // example of mapping below: thirdPartyInterest.address.ref = abcd
              [`${currentSelectedTpi.ref}.address.ref`]: currentSelectedTpi.addressRef,
              [`${tpiAddressRef}.line1`]: currentSelectedTpi.addressLine1,
              [`${tpiAddressRef}.line2`]: currentSelectedTpi.addressLine2,
              [`${tpiAddressRef}.city`]: currentSelectedTpi.city,
              [`${tpiAddressRef}.state`]: `STATE_CODE.${currentSelectedTpi.state}`,
              [`${tpiAddressRef}.zipcode`]: currentSelectedTpi.zipCode,
              [`${tpiAddressRef}.addressTypes`]: ['UNASSIGNED'],
            },
          }),
        );
      }
      handleSaveTpi();
      setIsEdit(false);
      setShowAddEditTpi(false);
      setCurrentSelectedVehicleRef(null);
      setCurrentSelectedTpi(null);
    } else {
      throw Error('Error No Current Selected Tpi');
    }
  }, [
    questionsHaveValidationErrors,
    currentSelectedTpi,
    interestType,
    titleHolderType,
    loanLeaseNumber,
    isEdit,
    handleSaveTpi,
    setIsEdit,
    setShowAddEditTpi,
    setCurrentSelectedVehicleRef,
    setCurrentSelectedTpi,
    addTpi,
    dispatch,
    tpiRef,
  ]);

  const handleInterestTypeChange = useCallback(
    (value: AnswerValue) => {
      interestType.update(value);
    },
    [interestType],
  );

  const handleTitleHolderTypeChange = useCallback(
    (value: AnswerValue) => {
      titleHolderType.update(value);
    },
    [titleHolderType],
  );

  const handleLoanLeaseNumberChange = useCallback(
    (value: AnswerValue) => {
      loanLeaseNumber.update(value);
    },
    [loanLeaseNumber],
  );

  if (!(currentSelectedTpi && showAddEditTpi)) return null;

  return (
    <Grid container>
      <AutoAddTPIVehicleHeader />
      <Grid container className={classes.addContainer}>
        <GridItem xs={12} className={classes.infoTitle}>
          <Typography className={classes.contentTitle}>{currentSelectedTpi?.name}</Typography>
          <Typography className={classes.contentSubTitle}>
            {currentSelectedTpi?.addressLine1} {currentSelectedTpi?.addressLine2}
          </Typography>
          <Typography className={classes.contentSubTitle}>
            {currentSelectedTpi?.city}, {currentSelectedTpi?.state} {currentSelectedTpi?.zipCode}
          </Typography>
          {interestType.exists && (
            <GridItem topSpacing='sm' xs={6} md={6}>
              <Select
                {...interestType.props}
                actionOnComplete={handleInterestTypeChange}
                id='interestType'
                inputButtonAriaLabel='Interest Type'
                label='Interest Type'
                trackingName='interest_type_selection'
              />
            </GridItem>
          )}
          {interestType && interestType.value === 'TITLE' && titleHolderType.exists && (
            <GridItem topSpacing='sm' xs={6} md={6}>
              <Select
                {...titleHolderType.props}
                actionOnComplete={handleTitleHolderTypeChange}
                id='titleHolderType'
                inputButtonAriaLabel='Title Holder Type'
                label='Title Holder Type'
                trackingName='titleholder_type_selection'
              />
            </GridItem>
          )}
          {showLoanLease && (
            <GridItem topSpacing='sm' xs={6} md={6}>
              <TextField
                {...loanLeaseNumber.props}
                actionOnComplete={handleLoanLeaseNumberChange}
                ariaLabel='Loan / Lease Number'
                id='loanLeaseNumber'
                label='Loan / Lease Number'
                maxLength={60}
                trackingName='loanLeaseNumber_text'
              />
            </GridItem>
          )}
        </GridItem>
        <Grid container className={classes.buttonContainer}>
          <GridItem className={classes.cancelButton}>
            <Button
              className={classes.button}
              variant='iconTextMedium'
              onClick={handleBackOnAddEditTpi}
              trackingName='add_TPI_page_back'
            >
              Back
            </Button>
          </GridItem>
          <GridItem>
            <Button
              className={classes.button}
              variant='primary'
              onClick={onSaveTPI}
              trackingName='add_TPI_page_save'
            >
              SAVE TPI
            </Button>
          </GridItem>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { castToBoolean, parseDollar } from '@ecp/utils/common';
import { useEffectOnce, useEvent } from '@ecp/utils/react';

import { GridItem } from '@ecp/components';
import { Dialog, Resource } from '@ecp/features/sales/shared/components';
import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';
import { isAnyApiInProgress } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { useLoading } from '@ecp/features/sales/shared/utils/web';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldSelectComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './ItemizedPersonalPropertyCoverage.styles';
import {
  useAddEditItemizedPersonalPropertyFormFields,
  useFirstAndCreateItemizedPersonalProperty,
  useGetItemizedPersonalPropertyItem,
  useItemizedPropertyRefList,
  useJewelryAndOtherThanJewelryItemsTotal,
  useJewelryItems,
  useOtherThanJewelryItems,
  useRemoveAllItemizedPersonalPropertyItems,
  useRemoveItemizedPersonalProperty,
} from './itemizedPersonalPropertyCoverageUtil';
import { ItemizedPersonalPropertyForm } from './ItemizedPersonalPropertyForm';
import type { SnackBarInfo } from './SnackbarComponent';
import { SnackbarComponent } from './SnackbarComponent';

export const ItemizedPersonalPropertyCoverage: React.FC<CoverageQuestionProps> = (props) => {
  /* State variables section -- Begin */
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [removeAllDialogOpen, setRemoveAllDialogOpen] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarInfo>({ message: '', severity: '' });
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [editItemizedPersonalPropertyRef, setEditItemizedPersonalPropertyRef] = useState('');
  const [removeItemizedPersonalPropertyRef, setRemoveItemizedPersonalPropertyRef] = useState('');
  /* State variables section -- End */

  /* Custom hooks section -- Begin */
  const { classes } = useStyles();

  // Get the coverage selected fields and all the related subcoverage fields
  const [
    itemizedPersonalPropertyCoverageField,
    jewelryDeductibleField,
    otherThanJewelryDeductibleField,
  ] = useGroupedCoverageFields(props.carrierName, props.product, 'itemizedPersonalProperty', [
    'selected',
    'jewelryDeductible',
    'otherThanJewelryDeductible',
  ]);

  // Define the logic around subcoverage fields which are
  // impacted by opt-in/ opt-out feature for main coverage field
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(
    itemizedPersonalPropertyCoverageField,
    [jewelryDeductibleField, otherThanJewelryDeductibleField],
  );
  const { newFirstRef, createNewItemizedPersonalPropertyRef } =
    useFirstAndCreateItemizedPersonalProperty();
  const { resetForm, evaluateDataChanged } = useAddEditItemizedPersonalPropertyFormFields(
    editItemizedPersonalPropertyRef,
  );
  const shouldWait = useSelector(isAnyApiInProgress);
  const { isLoading } = useLoading(shouldWait);
  const itemizedPersonalPropertyRefList = useItemizedPropertyRefList();
  const getItemizedPersonalPropertyItem = useGetItemizedPersonalPropertyItem();
  const jewelryItemLists = useJewelryItems();
  const otherThanJewelryItemLists = useOtherThanJewelryItems();
  const removeAllItemizedPersonalPropertyItems = useRemoveAllItemizedPersonalPropertyItems();
  const removeItemizedPersonalProperty = useRemoveItemizedPersonalProperty([
    jewelryDeductibleField,
    otherThanJewelryDeductibleField,
    selectedCoverageFieldChangeHandler,
  ]);
  const [jewelryTotal, otherThanJewelryTotal] = useJewelryAndOtherThanJewelryItemsTotal();
  /* Custom hooks section -- End */

  // This useEffectOnce handles the scenario if the user opts in to the
  // coverage and sees the add/edit form but then again refreshes the
  // screen. The effect makes sure that add/edit form still displays.
  useEffectOnce(() => {
    if (newFirstRef) {
      setEditItemizedPersonalPropertyRef(newFirstRef);
    }
  });

  const handleItemizedPersonalPropertyCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      // Create the first ref when the coverage is selected.
      if (newChecked) {
        const newRef = createNewItemizedPersonalPropertyRef();
        setEditItemizedPersonalPropertyRef(newRef);
        selectedCoverageFieldChangeHandler(newChecked);
      } else {
        // If the main coverage is opted out and there exists itemized personal
        // property items, then show removeAll dialog. Otherwise, just opt out.
        if (itemizedPersonalPropertyRefList.length > 0) {
          setRemoveAllDialogOpen(true);
        } else {
          selectedCoverageFieldChangeHandler(newChecked);
        }
      }
    },
  );

  const handleRemoveDialogClose = useEvent(() => {
    setRemoveDialogOpen(false);
    setRemoveItemizedPersonalPropertyRef('');
  });

  const handleRemoveAllDialogClose = useEvent(() => {
    setRemoveAllDialogOpen(false);
  });

  const handleRemoveItemizedPersonalProperty = useEvent(() => {
    removeItemizedPersonalProperty(removeItemizedPersonalPropertyRef);
    trackClick({
      action: 'RemoveItemizedPersonalPropertyButton',
      label: 'RemoveItemizedPersonalProperty',
    });
    setSnackbarInfo({
      message: `${
        getItemizedPersonalPropertyItem(removeItemizedPersonalPropertyRef).description
      } removed.`,
      severity: 'info',
    });
    setRemoveDialogOpen(false);
    setRemoveItemizedPersonalPropertyRef('');
  });

  const handleRemoveAllItemizedPersonalProperty = useEvent(async () => {
    trackClick({
      action: 'RemoveItemizedPersonalPropertyButton',
      label: 'RemoveItemizedPersonalProperty',
    });
    setSnackbarInfo({ message: 'All itemized personal property removed.', severity: 'info' });
    setRemoveAllDialogOpen(false);
    await Promise.all([
      selectedCoverageFieldChangeHandler(false),
      removeAllItemizedPersonalPropertyItems(),
    ]);
  });

  const handleCancelDialogClose = useEvent(() => {
    setCancelDialogOpen(false);
  });

  const handleCancelDialogItemizedPersonalProperty = useEvent(() => {
    resetForm();
    trackClick({
      action: 'CancelDialogItemizedPersonalPropertyButton',
      label: 'CancelDialogItemizedPersonalProperty',
    });
    setEditItemizedPersonalPropertyRef('');
    // If the user was in the middle of adding the first item
    // and then cancelling, opt out of the main coverage
    if (itemizedPersonalPropertyRefList.length === 0) {
      selectedCoverageFieldChangeHandler(false);
    }
    setCancelDialogOpen(false);
    setSnackbarInfo({ message: 'Changes discarded.', severity: 'info' });
  });

  const handleSaveItemizedPersonalProperty = useEvent(() => {
    setSnackbarInfo({
      message: `${
        getItemizedPersonalPropertyItem(editItemizedPersonalPropertyRef).description
      } saved.`,
      severity: 'success',
    });
    setEditItemizedPersonalPropertyRef('');
  });

  const handleCancelDialogOpen = useEvent(() => {
    const hasDataChanged = evaluateDataChanged();

    // Show cancel dialog if edit has changes
    if (hasDataChanged) {
      setCancelDialogOpen(true);
    }
    // Don't show cancel dialog if edit has no changes
    else {
      setEditItemizedPersonalPropertyRef('');

      // If the user was in the middle of adding the first item
      // and then cancelling, opt out of the main coverage
      if (itemizedPersonalPropertyRefList.length === 0) {
        selectedCoverageFieldChangeHandler(false);
      }
    }
  });

  const handleEditItemizedPersonalProperty = useEvent((itemizedPersonalPropertyRef: string) => {
    if (editItemizedPersonalPropertyRef || isLoading) return;
    setEditItemizedPersonalPropertyRef(itemizedPersonalPropertyRef);
  });

  const handleRemoveDialogOpen = useEvent((itemizedPersonalPropertyRef: string) => {
    setRemoveDialogOpen(true);
    setRemoveItemizedPersonalPropertyRef(itemizedPersonalPropertyRef);
  });

  const handleAddItemizedPersonalProperty = useEvent(async () => {
    const newItemRef = createNewItemizedPersonalPropertyRef();
    setEditItemizedPersonalPropertyRef(newItemRef);
    trackClick({
      action: 'AddItemizedPersonalPropertyButton',
      label: 'AddItemizedPersonalProperty',
    });
  });

  const renderItemizedPersonalPropertyItem = useCallback(
    (itemRef: string): React.ReactElement | null => {
      const item = getItemizedPersonalPropertyItem(itemRef);

      return (
        <>
          <GridItem xs={12} className={classes.displayItemValue}>
            {item.description}
          </GridItem>
          <Grid item container xs={12} className={classes.label}>
            <GridItem xs={9}>{item.type}</GridItem>
            <GridItem xs={3}>{parseDollar(item.value)}</GridItem>
          </Grid>
        </>
      );
    },
    [getItemizedPersonalPropertyItem, classes.displayItemValue, classes.label],
  );

  const removeItemizedPersonalPropertyDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText={`Remove ${
        getItemizedPersonalPropertyItem(removeItemizedPersonalPropertyRef).description
      }?`} // Item name needs to be dynamic
      textButtonLabel='CANCEL'
      open={removeDialogOpen}
      onClose={handleRemoveDialogClose}
      buttonPlacement='right'
      actionButtonOnClick={handleRemoveItemizedPersonalProperty}
    >
      <p>Any associated content will be removed.</p>
    </Dialog>
  );

  const removeAllItemizedPersonalPropertyDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText='Remove all itemized personal property'
      textButtonLabel='CANCEL'
      open={removeAllDialogOpen}
      onClose={handleRemoveAllDialogClose}
      buttonPlacement='right'
      actionButtonOnClick={handleRemoveAllItemizedPersonalProperty}
    >
      <p>All itemized personal property will be removed.</p>
    </Dialog>
  );

  const cancelDialog = (
    <Dialog
      actionButtonLabel='DISCARD'
      titleText='Discard changes?'
      textButtonLabel='CANCEL'
      open={cancelDialogOpen}
      onClose={handleCancelDialogClose}
      buttonPlacement='right'
      actionButtonOnClick={handleCancelDialogItemizedPersonalProperty}
    >
      <p>Any unsaved changes will be discarded.</p>
    </Dialog>
  );

  if (!itemizedPersonalPropertyCoverageField.exists) return null;

  const isItemizedPersonalPropertyCoverageSelected = castToBoolean(
    itemizedPersonalPropertyCoverageField.value,
  );

  // The item is new if editItemizedPersonalPropertyRef is not saved in itemizedPersonalPropertyRefList yet.
  // itemizedPersonalPropertyRefList reads answers from Answers store which are already saved through a network call.
  const isNewItem =
    !!editItemizedPersonalPropertyRef &&
    !itemizedPersonalPropertyRefList.includes(editItemizedPersonalPropertyRef);

  /*
  Here we display two different resource list -> jewelry items and other than jewelry items,
  both of them read data from a single source - list for itemized personal property items.
  All the items get added to the master list but display is split across two separate lists
  as described below:
  The 1st resource list (Jewelry Item List) doesn't have Resource.AddButton.
  The 1st resource list has the responsibilty of displaying ItemizedPersonalPropertyForm when
  user clicks on Resource.AddButton from the 2nd resource list. This is managed via showEditForm
  prop which is assigned the boolean isNewItem.
  The 2nd reource list doesn't have the capability to show the form for adding new items. It
  displays the Resource.AddButton which the 1st resource list uses.
  */

  // TODO Probably with a new story - premium amount
  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='itemizedPersonalPropertyCoverage'
          isCoverageSelected={isItemizedPersonalPropertyCoverageSelected}
          handleCoverageChange={handleItemizedPersonalPropertyCoverageChange}
          field={itemizedPersonalPropertyCoverageField}
        />
        <SnackbarComponent snackbarInfo={snackbarInfo} handleClose={setSnackbarInfo} />
        {isItemizedPersonalPropertyCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            {isItemizedPersonalPropertyCoverageSelected && (
              <>
                {removeItemizedPersonalPropertyDialog}
                {removeAllItemizedPersonalPropertyDialog}
                {cancelDialog}
                <Grid container className={classes.marginTop}>
                  <Resource.List
                    items={jewelryItemLists}
                    pageErrors={[]}
                    editItemRef={editItemizedPersonalPropertyRef}
                    onEdit={handleEditItemizedPersonalProperty}
                    onDelete={handleRemoveDialogOpen}
                    onCancel={handleCancelDialogOpen}
                    onNext={handleSaveItemizedPersonalProperty}
                    editIconText='Edit'
                    deleteIconText='Remove'
                    showEditForm={isNewItem}
                    // eslint-disable-next-line react/jsx-no-bind
                    form={(formProps) => (
                      <ItemizedPersonalPropertyForm {...formProps} isNewItem={isNewItem} />
                    )}
                    listTitle={<div className={classes.listHeaderText}>Jewelry</div>}
                    renderListItem={renderItemizedPersonalPropertyItem}
                  />
                </Grid>
                {jewelryItemLists.length > 0 && (
                  <Grid item container xs={10.3} className={classes.label}>
                    <GridItem xs={9} className={classes.totalValueStyle}>
                      Total Value
                    </GridItem>
                    <GridItem xs={3}>{parseDollar(jewelryTotal)}</GridItem>
                  </Grid>
                )}
                <Grid container className={classes.marginTop}>
                  <Resource.List
                    items={otherThanJewelryItemLists}
                    pageErrors={[]}
                    editItemRef={editItemizedPersonalPropertyRef}
                    onEdit={handleEditItemizedPersonalProperty}
                    onDelete={handleRemoveDialogOpen}
                    onCancel={handleCancelDialogOpen}
                    onNext={handleSaveItemizedPersonalProperty}
                    editIconText='Edit'
                    deleteIconText='Remove'
                    // eslint-disable-next-line react/jsx-no-bind
                    form={(formProps) => (
                      <ItemizedPersonalPropertyForm {...formProps} isNewItem={isNewItem} />
                    )}
                    renderListItem={renderItemizedPersonalPropertyItem}
                    listTitle={<div className={classes.listHeaderText}>Other than jewelry</div>}
                  />
                  {otherThanJewelryItemLists.length > 0 && (
                    <Grid item container xs={10.3} className={classes.bottomLabel}>
                      <GridItem xs={9} className={classes.totalValueStyle}>
                        Total Value
                      </GridItem>
                      <GridItem xs={3}>{parseDollar(otherThanJewelryTotal)}</GridItem>
                    </Grid>
                  )}
                  {!editItemizedPersonalPropertyRef && (
                    <Resource.AddButton
                      onClick={handleAddItemizedPersonalProperty}
                      data-testid='itemizedPersonalPropertyAdd'
                      isProcessing={false}
                      trackingName='itemized_personal_property_add'
                      trackingLabel='itemized_personal_property_add'
                      disabled={!!editItemizedPersonalPropertyRef || isLoading}
                    >
                      ADD ANOTHER ITEM
                    </Resource.AddButton>
                  )}
                </Grid>
              </>
            )}
            {jewelryItemLists.length > 0 && (
              <CoverageFieldSelectComponent field={jewelryDeductibleField} />
            )}
            {otherThanJewelryItemLists.length > 0 && (
              <CoverageFieldSelectComponent field={otherThanJewelryDeductibleField} />
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

import { Box, Grid, Typography } from '@mui/material';

import { GridItem } from '@ecp/components';
import { getNoOfMortgages, getPrimaryInsuredAddressInfo } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconCardHome } from '@ecp/themes/partners/amfam-adv';

import { useStyles } from './PropertyTPICard.styles';

export const PropertyTPICard: React.FC = () => {
  const { classes } = useStyles();
  const noOfMortgages = useSelector(getNoOfMortgages);
  const addressInformation = useSelector(getPrimaryInsuredAddressInfo);

  if (!addressInformation) return null;

  return (
    <GridItem>
      <GridItem topSpacing='lg' xs={12} className={classes.productTitleSection}>
        <Box>
          <div className={classes.productIcon}>
            <IconCardHome />
          </div>
        </Box>
        <Typography className={classes.productTitle}>Home Policy</Typography>
      </GridItem>
      <Grid container className={classes.card}>
        <GridItem xs={12} md={6}>
          <Typography className={classes.contentTitle}>
            {addressInformation.line1 && (
              <>
                <div>
                  {addressInformation.line1}
                  {addressInformation.line2 ? ` ${addressInformation.line2},` : ','}{' '}
                </div>
                <div>
                  {`${addressInformation.city}, `}
                  {addressInformation.state}
                  {` ${addressInformation.zipcode}`}
                </div>
              </>
            )}
          </Typography>
          {noOfMortgages > 0 && (
            <Typography className={classes.contentSubTitle}>
              This home has {noOfMortgages} mortgages. All mortgagees must be added as a TPI.
            </Typography>
          )}
        </GridItem>
        <Typography className={classes.contentTitle}>HOME THIRD PARTY COMING SOON</Typography>
      </Grid>
    </GridItem>
  );
};

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoTPICardDetails' })((theme) => ({
  contentTitle: {
    ...theme.typography.body4Bold,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },

  actionButton: {
    padding: 10,
    ...theme.typography.body1Bold,
  },
  divider: {
    margin: '10px 0px 4px 0px',
    width: '100%',
  },
}));

import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldReadOnlyComponent,
  CoverageFieldSelectComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './InlandFloodCoverage.styles';

export const InlandFloodCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();

  // Get the coverage selected fields and all the related subcoverage fields
  const [
    inlandFloodCoverageField,
    additionalLivingField,
    basementField,
    fungiValueField,
    packageLimitField,
  ] = useGroupedCoverageFields(props.carrierName, props.product, 'inlandFlood', [
    'selected',
    'additionalLiving',
    'basement',
    'fungiValue',
    'packageLimit',
  ]);

  // Define the logic around subcoverage fields which are
  // impacted by opt-in/ opt-out feature for main coverage field
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(inlandFloodCoverageField, [
    additionalLivingField,
    basementField,
    fungiValueField,
    packageLimitField,
  ]);

  const handleInlandFloodCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );

  if (!inlandFloodCoverageField) return null;

  const isInlandFloodCoverageSelected = castToBoolean(inlandFloodCoverageField.value);

  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='inlandFloodCoverage'
          field={inlandFloodCoverageField}
          isCoverageSelected={isInlandFloodCoverageSelected}
          handleCoverageChange={handleInlandFloodCoverageChange}
        />
        {isInlandFloodCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            <CoverageFieldSelectComponent field={packageLimitField} />

            {packageLimitField.props.value && (
              <CoverageFieldReadOnlyComponent field={fungiValueField} />
            )}

            {packageLimitField.props.value && (
              <CoverageFieldReadOnlyComponent field={basementField} />
            )}

            {packageLimitField.props.value && (
              <CoverageFieldReadOnlyComponent field={additionalLivingField} />
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

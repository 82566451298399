import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels, trackRender } from '@ecp/utils/analytics/tracking';

import { Card } from '@ecp/components';
import type { CheckoutData } from '@ecp/features/sales/checkout';
import { Button } from '@ecp/features/sales/shared/components';
import { PaymentPlan } from '@ecp/features/sales/shared/constants';
import {
  getAreSomeSelectedProductsIndicative,
  getOfferProductsSelectedByType,
  getPolicyStartDates,
  getSapiAnalyticsSelectedOfferEventDetail,
  getSelectedPaymentPlan,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import { useIsCustomizeCoveragesEnabled } from '../../utils';
import { DownloadSendQuoteButtons } from '../DownloadSendQuoteButtons';
import { useStyles } from './PremiumBoxCard.styles';
import { PremiumBoxDetailsBundle } from './PremiumBoxDetailsBundle';
import { PremiumBoxDetailsMonoline } from './PremiumBoxDetailsMonoline';

export interface PremiumBoxProps {
  checkoutData: CheckoutData;
  buttonText?: string;
  buttonDisabled?: boolean;
  onCheckout?: () => void;
  shouldRecalc?: (value: boolean) => void;
  recalculate?: boolean;
  retrieveOffer?: () => Promise<void>;
  isProcessing?: boolean;
  buttonTrackingLabel?: string;
  showError?: boolean;
  checkoutElement?: string;
  sapiAnalyticsSelectedOfferEventDetail?: string;
  isMVRActive?: boolean;
  isCheckout?: boolean;
  setEmailActionInfoType: Dispatch<SetStateAction<{ status: string; message: string }>>;
}

export const PremiumBoxCard: React.FC<PremiumBoxProps> = (props) => {
  const {
    checkoutData: { auto: autoPremium, property: propertyPremium },
    onCheckout,
    buttonText,
    buttonDisabled,
    isProcessing,
    buttonTrackingLabel,
    checkoutElement = 'choice.policyCoveragePage.checkoutButton',
    isMVRActive,
    shouldRecalc,
    recalculate,
    retrieveOffer,
    sapiAnalyticsSelectedOfferEventDetail,
    showError,
    setEmailActionInfoType,
  } = props;
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const [showProcess, setShowProcess] = useState(false);
  const [recalculated, setRecalculated] = useState(false);
  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );

  const isBundle = !!(autoOfferProduct && autoPremium && propertyOfferProduct && propertyPremium);
  const isAutoOnly = !!(!isBundle && autoOfferProduct && autoPremium);
  const isPropertyOnly = !!(!isBundle && propertyOfferProduct && propertyPremium);

  const sapiAnalyticsEventDetails = useSelector(getSapiAnalyticsSelectedOfferEventDetail);
  const policyStartDates = useSelector(getPolicyStartDates);

  const areSomeSelectedProductsIndicative = useSelector(getAreSomeSelectedProductsIndicative);
  const premiumPlan = useSelector(getSelectedPaymentPlan);
  const ctaButtonVariant = areSomeSelectedProductsIndicative ? 'primary' : 'success';
  const isPolicySummary = useIsCustomizeCoveragesEnabled?.();

  // This makes sure the updated values are sent correctly
  // TODO - These state changes in useEffects are troublesome & needs to be revisited
  useEffect(() => {
    if (recalculated) {
      setRecalculated(false);
      trackRender({ action: 'RecalculatedCoverages', label: sapiAnalyticsEventDetails });

      trackSapiAnalyticsEvent({
        element: 'choice.policyCoveragePage.recalcButton',
        event: 'click',
        eventDetail: sapiAnalyticsEventDetails,
      });
    }
  }, [dispatch, recalculated, sapiAnalyticsEventDetails]);

  const recalculateClick = useCallback(async () => {
    if (shouldRecalc && retrieveOffer) {
      setShowProcess(true);
      retrieveOffer().then(() => {
        shouldRecalc(false);
        setShowProcess(false);
        setRecalculated(true);
      });
    }
  }, [shouldRecalc, retrieveOffer]);

  const getBody = (): React.ReactElement => {
    const checkoutViewCommonProps = {
      premiumPlan,
      recalculate,
      showProcess,
    };

    return (
      <Grid container className={classes.items} justifyContent='flex-start'>
        {isAutoOnly && (
          <PremiumBoxDetailsMonoline
            {...checkoutViewCommonProps}
            offerProduct={autoOfferProduct}
            effectiveDate={policyStartDates.auto}
          />
        )}
        {isPropertyOnly && (
          <PremiumBoxDetailsMonoline
            {...checkoutViewCommonProps}
            offerProduct={propertyOfferProduct}
            effectiveDate={policyStartDates.property}
          />
        )}
        {isBundle && (
          <PremiumBoxDetailsBundle
            {...checkoutViewCommonProps}
            autoOfferProduct={autoOfferProduct}
            propertyOfferProduct={propertyOfferProduct}
            isMVRActive={isMVRActive}
            autoEffectiveDate={policyStartDates.auto}
            propertyEffectiveDate={policyStartDates.property}
          />
        )}
      </Grid>
    );
  };

  const getFooter = (): React.ReactElement => (
    <Grid container className={classes.footer} justifyContent='center'>
      <div className={classes.carrierButtons}>
        <Button
          variant={ctaButtonVariant}
          data-testid='sidePurchase'
          onClick={onCheckout}
          classes={{
            root: cx(classes.button),
          }}
          disabled={buttonDisabled}
          isProcessing={isProcessing}
          trackingName={GoogleAnalyticsLabels.CONTINUE}
          trackingLabel={buttonTrackingLabel ?? sapiAnalyticsSelectedOfferEventDetail}
          analyticsElement={checkoutElement}
          analyticsEventDetail={sapiAnalyticsSelectedOfferEventDetail}
        >
          {buttonText}
        </Button>
      </div>
      {!isPolicySummary && premiumPlan === PaymentPlan.MONTHLY_PREMIUM && (
        <Grid item xs={12} className={classes.installmentStatement}>
          <p>Premium does not include installment fees</p>
        </Grid>
      )}
    </Grid>
  );

  const getRecalculateFooter = (): React.ReactElement => (
    <Grid container className={classes.footer} justifyContent='center'>
      <Button
        variant={ctaButtonVariant}
        disabled={showProcess}
        isProcessing={showProcess}
        className={cx(classes.button, classes.recalculateButton, {
          [classes.recalcButtonBg]: !showProcess,
        })}
        onClick={recalculateClick}
        trackingName='recalculate_button'
        trackingLabel={sapiAnalyticsEventDetails}
        data-testid='RecalculateButton'
      >
        Calculate
      </Button>
    </Grid>
  );

  const errorLayoutBody = (
    <Grid container className={classes.recalculateContainer}>
      <p className={classes.recalculateBody} role='alert'>
        Please review coverages and correct any errors
      </p>
    </Grid>
  );

  return (
    <Grid container className={classes.sidebarContainerRoot} spacing={2} direction='column'>
      <Grid item xs={12}>
        <div className={classes.cardContainer}>
          {showError ? (
            <Card
              body={errorLayoutBody}
              divider={false}
              classes={{
                root: classes.recalculateCardContainer,
              }}
            />
          ) : recalculate ? (
            <Card
              body={getBody()}
              divider={false}
              footer={getRecalculateFooter()}
              classes={{
                root: classes.premiumBoxCardContainer,
              }}
            />
          ) : (
            <Card
              body={getBody()}
              footer={getFooter()}
              divider={false}
              classes={{
                root: classes.premiumBoxCardContainer,
              }}
            />
          )}
        </div>
      </Grid>
      {!recalculate && (
        <DownloadSendQuoteButtons
          isProcessing={isProcessing}
          setEmailActionInfoType={setEmailActionInfoType}
        />
      )}
    </Grid>
  );
};

export type AutoCoveragesFormProps = {
  onFormValidChange?: (isFormValid: boolean) => void;
};

export enum AutoParentCoverageGroup {
  VEHICLE = 'VEHICLE',
  POLICY = 'POLICY',
}

export type AutoParentCoverageGroupKey = keyof typeof AutoParentCoverageGroup;

export enum AutoVehicleCoverageGroup {
  DEDUCTIBLES = 'DEDUCTIBLES',
  COVERAGESANDBENEFITS = 'COVERAGESANDBENEFITS',
  COVERAGEPACKAGES = 'COVERAGEPACKAGES',
}

export type AutoVehicleCoverageGroupKey = keyof typeof AutoVehicleCoverageGroup;

export enum AutoPolicyCoverageGroup {
  MANDATORY = 'MANDATORY',
  OPTIONAL = 'OPTIONAL',
}

export type AutoPolicyCoverageGroupKey = keyof typeof AutoPolicyCoverageGroup;

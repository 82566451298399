import { useCallback, useState } from 'react';

import { Button, Dialog } from '@ecp/features/sales/shared/components';

import { useStyles } from './IncidentQuestionsHelpText.styles';

type IncidentQuestionsHelpTextProps = {
  helperText: string;
  linkText: string;
  dialogContent: React.ReactNode;
};

export const IncidentQuestionsHelpText: React.FC<IncidentQuestionsHelpTextProps> = (
  props: IncidentQuestionsHelpTextProps,
) => {
  const { helperText, linkText, dialogContent } = props;
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      {helperText}
      <Button variant='text' className={classes.modalButton} onClick={handleOpen}>
        {linkText}
      </Button>

      <Dialog
        className={classes.root}
        actionButtonLabel='Close'
        titleText='Incident descriptions'
        open={open}
        onClose={handleClose}
        buttonPlacement='right'
        hideTitleCloseButton
        hideDivider
      >
        {dialogContent}
      </Dialog>
    </>
  );
};

import { useEvent } from '@ecp/utils/react';

import { Snackbar, SnackbarAlert } from '@ecp/components';

import { useStyles } from './ItemizedPersonalPropertyCoverage.styles';

export type SnackbarInfoSeverity = 'info' | 'success' | '';

export type SnackBarInfo = {
  message: string;
  severity: SnackbarInfoSeverity;
};

export type SnackbarComponentProps = {
  snackbarInfo: SnackBarInfo;
  handleClose: (snackbarInfo: SnackBarInfo) => void;
};

export const SnackbarComponent: React.FC<SnackbarComponentProps> = (props) => {
  const { classes } = useStyles();
  const { snackbarInfo, handleClose } = props;
  const { message, severity } = snackbarInfo;

  const onClose = useEvent((event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason !== 'clickaway') {
      handleClose({
        message: '',
        severity: '',
      });
    }
  });

  switch (severity) {
    case 'success':
      return (
        <SnackbarAlert
          classes={{ root: classes.snackBar }}
          open={!!message}
          autoHideDuration={3000}
          message={message}
          vertical='bottom'
          horizontal='center'
          onClose={onClose}
          severity={severity}
        />
      );
    case 'info':
      return (
        <Snackbar
          classes={{ root: classes.snackBar }}
          open={!!message}
          autoHideDuration={3000}
          message={message}
          vertical='bottom'
          horizontal='center'
          onClose={onClose}
        />
      );
    default:
      return null;
  }
};

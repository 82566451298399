import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AddEditTPIQuestions' })((theme) => ({
  contentTitle: {
    ...theme.typography.body4,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  contentSubTitle: { ...theme.typography.body2, color: theme.palette.text.secondary },
  infoTitle: {
    margin: '12px',
  },
  addContainer: {
    border: `1px solid ${theme.palette.other.border}`,
    padding: 20,
    marginTop: 15,
    borderRadius: 8,
  },
  cancelButton: {
    marginTop: '10px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
}));

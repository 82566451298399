import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldReadOnlyComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './ServiceLineCoverage.styles';

export const ServiceLineCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();

  // Get the coverage selected fields and all the related subcoverage fields
  const [serviceLineCoverageField, limitField, deductibleField] = useGroupedCoverageFields(
    props.carrierName,
    props.product,
    'serviceLine',
    ['selected', 'limit', 'deductible'],
  );

  // Define the logic around subcoverage fields which are
  // impacted by opt-in/ opt-out feature for main coverage field
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(serviceLineCoverageField, [
    limitField,
    deductibleField,
  ]);

  const handleServiceLineCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );

  if (!serviceLineCoverageField.exists) return null;

  const isServiceLineCoverageSelected = castToBoolean(serviceLineCoverageField.value);

  // TODO Probably with a new story - premium amount
  // TODO Check if div below is needed within this and similar coverage components
  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='serviceLineCoverage'
          field={serviceLineCoverageField}
          isCoverageSelected={isServiceLineCoverageSelected}
          handleCoverageChange={handleServiceLineCoverageChange}
        />
        {isServiceLineCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            <CoverageFieldReadOnlyComponent field={limitField} />
            <CoverageFieldReadOnlyComponent field={deductibleField} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

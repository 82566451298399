import { useCallback, useState } from 'react';

import { assertString } from '@ecp/utils/common';
import { type GeoAddress } from '@ecp/utils/geo';
import { useEvent } from '@ecp/utils/react';

import { GridItem, SnackbarAlert } from '@ecp/components';
import { useAddFields, useRemoveByFields } from '@ecp/features/sales/form';
import {
  GARAGE_ADDRESS_REF_SUFFIX,
  useAddressRefForVehicle,
  useDefaultVehicleAddressRef,
  useGarageField,
} from '@ecp/features/sales/quotes/auto';
import { AddressQuestions } from '@ecp/features/sales/shared/components';
import {
  createRef,
  getPrimaryInsuredAddressRefTrue,
  updateAnswers,
  useAddressFields,
  useAddressFieldValue,
  useGetAllAddressOptions,
  useMailingAddressRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, AnswerValue } from '@ecp/features/sales/shared/types';

interface Props {
  vehicleRef: string;
  isValidAddress: boolean;
  setIsValidAddress: (value: boolean) => void;
  showAddressSuggestion: boolean;
  setShowAddressSuggestion: (value: boolean) => void;
  geoAddressSuggestions?: GeoAddress[];
}

export const VehicleKeptAtQuestion: React.FC<Props> = (props) => {
  const {
    vehicleRef,
    isValidAddress,
    setIsValidAddress,
    showAddressSuggestion,
    setShowAddressSuggestion,
    geoAddressSuggestions,
  } = props;

  const dispatch = useDispatch();

  const garageState = useGarageField(vehicleRef, 'state');
  const garageAddressRef = useAddressRefForVehicle(vehicleRef);
  const [showOutOfStateError, setShowOutOfStateError] = useState(false);

  const defaultVehicleAddress = useDefaultVehicleAddressRef();
  const defaultVehicleAddressState = useAddressFieldValue(defaultVehicleAddress, 'state');

  const handleStateChange = useCallback(
    (value: AnswerValue) => {
      garageState.props.actionOnComplete(value);
      if (value !== defaultVehicleAddressState) {
        setShowOutOfStateError(true);
      }
    },
    [defaultVehicleAddressState, garageState.props],
  );

  const primaryInsuredAddressRef = useSelector(getPrimaryInsuredAddressRefTrue);
  const primaryMailingAddressRef = useMailingAddressRef();
  // we must assume insured and mailing address have been previously setup for this component to work
  assertString('primaryInsuredAddressRef', primaryInsuredAddressRef);
  assertString('primaryMailingAddressRef', primaryMailingAddressRef);

  // selectedAddressRef is the reference that is chosen by the user in the dropdown
  // this defaults to the primary address ref.
  const [selectedGaragedAddressRef, setSelectedGaragedAddressRef] = useState(
    garageAddressRef ? garageAddressRef : primaryInsuredAddressRef,
  );

  // if the user selectes 'Add New Address' we will use a new addresRef
  // call useState so that way we don't do this more than once
  const [draftRef] = useState(() => dispatch(createRef('address')));

  const uniqueGaragedAddressOptions = useGetAllAddressOptions(draftRef, selectedGaragedAddressRef);

  const isAddressFieldsDisabled = selectedGaragedAddressRef !== draftRef;

  // we need the address field in order to add it to the form
  const { address } = useAddressFields(selectedGaragedAddressRef);

  // add to the form
  useAddFields(address);

  // when the component is given a new reference, we need to remove
  // it from the form so that it doesn't continue to validate
  const removeByFields = useRemoveByFields();

  const handleGaragedAddressChange = useEvent(async (selectedAddressRef: string) => {
    setSelectedGaragedAddressRef(selectedAddressRef);
    setIsValidAddress(true);

    // address is still pointing at the old address ref,
    // so remove this from the form now,
    // then when the component rerenders,
    // useAddFields(address) will have updated by then
    // and we can add the new address to the form.
    // FIXME: perhaps the child component (<AddressQuestions/>)
    // should just do this instead
    removeByFields(address);
    const answers: Answers = {
      [`${vehicleRef}.${GARAGE_ADDRESS_REF_SUFFIX}`]: selectedAddressRef,
      [`${vehicleRef}.keptAtRiskAddress`]:
        selectedAddressRef === primaryInsuredAddressRef ? true : false,
    };
    await dispatch(
      updateAnswers({
        answers,
      }),
    );
  });

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        // Utilising the same close function for both remove snackbar and out of state error.
        //  Because both can't logically be open at the same time, this is fine.
        setShowOutOfStateError(false);
      }
    },
    [],
  );

  return (
    <>
      <GridItem>
        <AddressQuestions
          geoAddressSuggestions={geoAddressSuggestions}
          selectedAddressRef={garageAddressRef ? garageAddressRef : primaryInsuredAddressRef}
          isAddressFieldsDisabled={isAddressFieldsDisabled}
          addressOptionsLabel='Where is this vehicle garaged?'
          addressLinelabel='Street address'
          zipLabel='ZIP code'
          trackingName='street_address'
          addressType='GARAGE'
          isValidAddress={isValidAddress}
          uniqueAddressOptions={uniqueGaragedAddressOptions}
          onAddressChange={handleGaragedAddressChange}
          setIsValidAddress={setIsValidAddress}
          showAddressSuggestion={showAddressSuggestion}
          setShowAddressSuggestion={setShowAddressSuggestion}
          handleStateChange={handleStateChange}
        />
      </GridItem>
      {showOutOfStateError && (
        <SnackbarAlert
          message='We are unable to provide coverage for a vehicle kept outside of your residence state on
          this quote. To proceed with your in-state vehicles, please remove this vehicle.'
          vertical='bottom'
          horizontal='center'
          open
          onClose={handleInfoSnackbarClose}
          severity='error'
        />
      )}
    </>
  );
};

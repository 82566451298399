interface Metadata {
  defaultValue: boolean | number | string;
  routeToPCValue: boolean | number | string;
}

const routeToPCQuestions: { renters: Record<string, Metadata>; home: Record<string, Metadata> } = {
  renters: {
    dayCareOnPremisesInd: {
      defaultValue: false,
      routeToPCValue: true,
    },
  },
  home: {
    dayCareOnPremisesInd: {
      defaultValue: false,
      routeToPCValue: true,
    },
    hasSolidFuelInd: {
      defaultValue: false,
      routeToPCValue: true,
    },
    isHistoricHome: {
      defaultValue: false,
      routeToPCValue: true,
    },
    noOfMortgages: {
      defaultValue: 1,
      routeToPCValue: 4,
    },
    addOtherStructuresInd: {
      defaultValue: false,
      routeToPCValue: true,
    },
    material: {
      defaultValue: '',
      routeToPCValue: 'ROOF.MATERIAL.T_LOCK',
    },
  },
};

export default routeToPCQuestions;

import dayjs from 'dayjs';

import { INCIDENT_TYPE_VIOLATION } from '@ecp/features/sales/quotes/auto';
import type { AnswerValue } from '@ecp/types';

import metadata from './metadata';

const incidentSinceDate = dayjs().subtract(6, 'year').format('MM/YYYY');
const violationSinceDate = dayjs().subtract(10, 'year').format('MM/YYYY');

export const getIncidentFieldLabel = (driverName: string, stateCode: string): string => {
  switch (stateCode) {
    case 'CA':
      return `Has ${driverName} had any accidents, tickets or claims since ${incidentSinceDate} or alcohol/drug-related traffic violations since ${violationSinceDate}?`;
    default:
      return `Has ${driverName} had any accidents, tickets, or claims since ${incidentSinceDate}?`;
  }
};

export const hasIncidentClaimAmountQuestion = (stateCode: string): boolean =>
  ['PA'].includes(stateCode) || metadata.renderDamageAmountAsText;

// Check if the incident source is either 'acl' or 'mvr'
export const prefillIncidentSources = ['acl', 'mvr'];
export const isIncidentFromPrefill = (incidentSource: AnswerValue): boolean =>
  prefillIncidentSources.includes(incidentSource);

export interface IncidentTypeDetails {
  incidentTypeDate: string;
  typeOfIncident: string; // The type of incident (e.g., violation, loss)
  incidentTypeDescription: string; // A description of the incident
  incidentTypeSaveButtonText: string; // The text for the save button
  incidentTypeInfoMessage: string; // The message displayed after saving the incident
}
//
const lossDisplayDetails = {
  incidentTypeDate: 'Loss Date',
  typeOfIncident: 'loss',
  incidentTypeDescription: 'Loss Description',
  incidentTypeSaveButtonText: 'SAVE LOSS',
  incidentTypeInfoMessage: 'Loss saved.',
};

const violationDisplayDetails = {
  incidentTypeDate: 'Violation Date',
  typeOfIncident: 'violation',
  incidentTypeDescription: 'Violation Description',
  incidentTypeSaveButtonText: 'SAVE VIOLATION',
  incidentTypeInfoMessage: 'Violation saved.',
};

/**
 * Get the render details of the incident
 * type based on the incidentTypeValue
 */
export const getIncidentTypeDetails = (incidentTypeValue: AnswerValue): IncidentTypeDetails => {
  if (incidentTypeValue === INCIDENT_TYPE_VIOLATION) {
    return violationDisplayDetails;
  }

  return lossDisplayDetails;
};

interface CoverageTitle {
  coverageTitle: string;
}

interface RentersCoverageMetadata {
  personalProperty: CoverageTitle;
  personalLiability: CoverageTitle;
  propertyDeductible: CoverageTitle;
}

interface HomeCoverageMetadata extends RentersCoverageMetadata {
  dwelling: CoverageTitle;
}

interface AutoCoverageMetadata {
  bodilyInjury: CoverageTitle;
  propertyDamage: CoverageTitle;
  medicalExpense: CoverageTitle;
  underInsuredMotoristBi: CoverageTitle;
  uninsuredMotoristBi: CoverageTitle;
  uninsuredUnderInsuredCombined: CoverageTitle;
  pip: CoverageTitle;
}

interface EmailDiscountSection {
  initialSection: string;
  middleSection: string;
  lineBreak: string;
}

export const RentersCoverageMetadata: RentersCoverageMetadata = {
  personalProperty: {
    coverageTitle: 'Coverage C - Personal Property',
  },
  personalLiability: {
    coverageTitle: 'Coverage E - Personal Liability',
  },
  propertyDeductible: {
    coverageTitle: 'Property Deductible',
  },
};

export const HomeCoverageMetadata: HomeCoverageMetadata = {
  ...RentersCoverageMetadata,
  dwelling: {
    coverageTitle: 'Coverage A',
  },
};

export const AutoCoverageMetadata: AutoCoverageMetadata = {
  bodilyInjury: {
    coverageTitle: 'Bodily Injury Liability Coverage',
  },
  propertyDamage: {
    coverageTitle: 'Property Damage Liability',
  },
  medicalExpense: {
    coverageTitle: 'Medical Expense',
  },
  underInsuredMotoristBi: {
    coverageTitle: 'Underinsured Motorist Bodily Injury Liability',
  },
  uninsuredMotoristBi: {
    coverageTitle: 'Uninsured Motorist Bodily Injury Liability',
  },
  uninsuredUnderInsuredCombined: {
    coverageTitle: 'Uninsured/Under Motorist Bodily Injury Combined',
  },
  pip: {
    coverageTitle: 'Personal Injury Protection',
  },
};

export const DiscountHTMLString: EmailDiscountSection = {
  initialSection: `<tr> <td style="padding-top: 15px;"> <p style="color:#4c4c4c; font-family: Arial, sans-serif; font-weight:bold; font-size:14px; line-height:15px;">xDiscount</p> </td> <td style="padding-top: 15px;" align="right"> <p style="color:#4c4c4c; font-family: Arial, sans-serif; font-weight:bold; font-size:14px; line-height:15px;">Yes</p> </td> </tr>`,
  middleSection: `<tr> <td style="padding-top: 5px;"> <p style="color:#4c4c4c; font-family: Arial, sans-serif; font-weight:bold; font-size:14px; line-height:15px;">xDiscount</p> </td> <td style="padding-top: 5px;" align="right"> <p style="color:#4c4c4c; font-family: Arial, sans-serif; font-weight:bold; font-size:14px; line-height:15px;">Yes</p> </td> </tr>`,
  lineBreak: `<tr> <td colspan="2" bgcolor="#DADADA" height="1" width="100%" style="font-size: 1px; line-height: 1px;"> &nbsp; </td> </tr>`,
};

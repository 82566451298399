import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { getProducerID, useField } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { ProductName } from '@ecp/features/shared/product';

import metadata from './metadata';
import { useStyles } from './SourceOfSaleQuestion.style';

interface Props {
  product: ProductName;
}

export const SourceOfSaleQuestion: React.FC<Props> = ({ product }) => {
  const { classes } = useStyles();
  const sourceOfSaleField = useField(`policy.${product}.sourceOfSale`);
  const producerId = useSelector(getProducerID);
  const label = 'What is the source of this sale?';

  const isCareCenterRep = producerId === metadata.enableForProducerId;

  if (!sourceOfSaleField || !isCareCenterRep) return null;

  return (
    <RadioGroupWithOptions
      {...sourceOfSaleField.props}
      className={classes.sourceOfSaleWidth}
      id='sourceOfSale'
      label={label}
      variant='radioCard'
      cardSize='small'
      row={false}
      trackingName='source_of_sale_question'
    />
  );
};

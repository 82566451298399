import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useLocation } from '@ecp/utils/routing';

import { Page } from '@ecp/features/sales/shared/components';
import { PagePath } from '@ecp/features/sales/shared/routing';

export const InPersonSignConfirmPage: React.FC = () => {
  const { action } = useParams();
  const location = useLocation(PagePath.INPERSON_SIGN_CONFIRM);
  const DOCUSIGN_SUCCESS = 'SUCCESSFUL';
  const DOCUSIGN_FAILED = 'FAILED';

  useEffect(() => {
    const { event, envelopeId, recipientId } = location.search;
    if (event === 'OnSigningComplete') {
      window.parent.postMessage(
        { status: DOCUSIGN_SUCCESS, envelopeId: envelopeId, recipientId: recipientId },
        '*',
      );
    } else {
      window.parent.postMessage({ status: DOCUSIGN_FAILED }, '*');
    }
  }, [action, location.search]);

  return (
    <div>
      <Page title='In Person Click Signature'>
        <p>Signature captured successfully through Docusign, Please close and return to quote.</p>
      </Page>
    </div>
  );
};

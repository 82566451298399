import { type Dispatch, type SetStateAction, useCallback, useEffect, useState } from 'react';

import { DialogContent, DialogContentText, Grid } from '@mui/material';
import dayjs from 'dayjs';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { Button, GridItem, RadioGroupWithOptions, Snackbar, SnackbarAlert } from '@ecp/components';
import {
  useAddMilitaryDeployments,
  useAndEnsureCurrentMilitaryDeploymentsRef,
  useAttachMilitaryDeploymentsToDriver,
  useGetmilitaryDeploymentsFields,
  useMilitaryDeployment,
  useMilitaryDeployments,
  useRemoveUnUsedRefForMilitaryDeployments,
} from '@ecp/features/sales/quotes/auto';
import { DatePicker, Dialog, Resource } from '@ecp/features/sales/shared/components';
import { setFormErrorsChangedByField, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { PageErrors } from '@ecp/features/sales/shared/types';
import type { AnswerValue, Field } from '@ecp/types';

import { useStyles } from './MilitaryDeploymentsQuestions.styles';

type MilitaryDeploymentsProps = {
  driverRef: string;
  personRef: string;
  hasMilitaryDeployment: boolean;
  setHasMilitaryDeployment: Dispatch<SetStateAction<boolean>>;
  setIsMilitaryFormOpen?: Dispatch<SetStateAction<boolean>>;
};

type MilitaryDeploymentSnapshot = {
  returnDate: AnswerValue;
  deploymentDate: AnswerValue;
};

export const MilitaryDeploymentsQuestion: React.FC<MilitaryDeploymentsProps> = (props) => {
  const {
    driverRef,
    personRef,
    hasMilitaryDeployment,
    setHasMilitaryDeployment,
    setIsMilitaryFormOpen,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarAlert, setOpenSnackBarAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [removalDialogMessage, setRemovalDialogMessage] = useState('');
  const [showRemovalDialog, setShowRemovalDialog] = useState(false); // This dialog
  const [dialogItem, setDialogItem] = useState('');
  const [editMilitaryRef, setEditMilitaryRef] = useState<string | undefined>();
  const [militaryRefSnapBeforeEdit, setMilitaryRefSnapBeforeEdit] = useState<
    MilitaryDeploymentSnapshot | undefined
  >(undefined);

  const handleClose = useCallback(() => {
    setOpenDialog(false);
    setShowRemovalDialog(false);
  }, []);

  const militaryDeploymentsList = useMilitaryDeployments(driverRef);
  // Use to get a array of strings
  const militaryRefs: string[] | undefined = militaryDeploymentsList
    ?.filter((deployment) => deployment.ref)
    .map((deployment) => deployment.ref);

  const militaryDeploymentsByRef: { [key: string]: (typeof militaryDeploymentsList)[number] } =
    militaryDeploymentsList?.reduce(
      (acc: Record<string, (typeof militaryDeploymentsList)[number]>, deployment) => {
        acc[deployment.ref] = deployment;

        return acc;
      },
      {},
    );

  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const currentMilitaryDeploymentsRef = useAndEnsureCurrentMilitaryDeploymentsRef(driverRef) || '';
  const currentMilitaryDeployment = useMilitaryDeployment(currentMilitaryDeploymentsRef || '');
  const removeMilitaryDeployments = useRemoveUnUsedRefForMilitaryDeployments(driverRef);
  const addMilitaryDeploymentsToDriver = useAttachMilitaryDeploymentsToDriver(driverRef);
  const militaryDeploymentsFields = useGetmilitaryDeploymentsFields(
    editMilitaryRef || currentMilitaryDeploymentsRef || '',
  );
  const addMilitaryDeployments = useAddMilitaryDeployments(driverRef);
  const [pageErrors] = useState<PageErrors[]>([]);

  useEffect(() => {
    if (setIsMilitaryFormOpen) setIsMilitaryFormOpen(!!editMilitaryRef);
  }, [editMilitaryRef, setIsMilitaryFormOpen]);

  useEffect(() => {
    setHasMilitaryDeployment(!!militaryDeploymentsList?.length);
  }, [militaryDeploymentsList?.length, setHasMilitaryDeployment]);
  const activeMilitaryDeployDate =
    militaryDeploymentsFields?.militaryDeployments?.activeMilitaryDeployDate;
  const militaryReturnDate = militaryDeploymentsFields?.militaryDeployments?.militaryReturnDate;

  const { props: { value: name = '' } = {} } = usePersonField('firstName') || {};

  const removeAllSavedMilitaryDeploymentsFromDriver = useCallback(() => {
    militaryDeploymentsList.forEach((militarDeployments) =>
      removeMilitaryDeployments(militarDeployments),
    );
  }, [militaryDeploymentsList, removeMilitaryDeployments]);

  const onValidateAffiliationFields = useCallback(
    (activeMilitaryDeployDate: Field, militaryReturnDate: Field) => {
      let isValid = true;
      if (!activeMilitaryDeployDate.value) {
        dispatch(
          setFormErrorsChangedByField({
            key: activeMilitaryDeployDate.key,
            errors: ['Required field'],
          }),
        );
        isValid = false;
      }
      if (isValid) {
        if (
          dayjs(militaryReturnDate.value as string).isBefore(
            activeMilitaryDeployDate.value as string,
          ) ||
          dayjs(militaryReturnDate.value as string).isSame(activeMilitaryDeployDate.value as string)
        ) {
          dispatch(
            setFormErrorsChangedByField({
              key: militaryReturnDate.key,
              errors: ['Return Date must be after Deploy Date'],
            }),
          );
          isValid = false;
        }
      }

      return isValid;
    },
    [dispatch],
  );

  const handleAddDeployment = useCallback(async () => {
    addMilitaryDeployments();
    const newMilitaryDeployment = addMilitaryDeployments();
    const { militaryDeploymentsRef } = newMilitaryDeployment;
    await newMilitaryDeployment.done;
    addMilitaryDeploymentsToDriver(militaryDeploymentsRef);
    setEditMilitaryRef(militaryDeploymentsRef);
  }, [addMilitaryDeployments, addMilitaryDeploymentsToDriver, setEditMilitaryRef]);

  const handleOnCancel = useCallback(() => {
    const hasActiveDates = activeMilitaryDeployDate?.value || militaryReturnDate?.value;

    if (editMilitaryRef) {
      if (militaryRefSnapBeforeEdit) {
        activeMilitaryDeployDate.validateUpdateAndPatch(militaryRefSnapBeforeEdit.deploymentDate);
        militaryReturnDate.validateUpdateAndPatch(militaryRefSnapBeforeEdit.returnDate);
        setMilitaryRefSnapBeforeEdit(undefined);
        setEditMilitaryRef(undefined);
      } else {
        if (hasActiveDates) {
          setShowRemovalDialog(true);
        } else {
          // Setting has military deployment toggle to false when user selects toggle to yes and clicks cancel without adding military deployment
          setHasMilitaryDeployment(false);
          removeMilitaryDeployments(currentMilitaryDeployment);
          setEditMilitaryRef(undefined);
        }
      }
    } else {
      if (!militaryDeploymentsList?.length) {
        if (hasActiveDates) {
          setShowRemovalDialog(true);
        } else {
          // Setting has military deployment toggle to false when user selects toggle to yes and clicks cancel without adding military deployment
          setHasMilitaryDeployment(false);
        }
      } else if (hasActiveDates) {
        setShowRemovalDialog(true);
      }
    }
  }, [
    activeMilitaryDeployDate,
    militaryReturnDate,
    editMilitaryRef,
    militaryRefSnapBeforeEdit,
    setEditMilitaryRef,
    setHasMilitaryDeployment,
    removeMilitaryDeployments,
    currentMilitaryDeployment,
    militaryDeploymentsList?.length,
  ]);

  const handleRemoveMilitaryRecord = useCallback(async () => {
    removeAllSavedMilitaryDeploymentsFromDriver();
    setOpenDialog(false);
    setOpenSnackBar(true);
    setSnackbarMessage('Deployment removed.');
  }, [removeAllSavedMilitaryDeploymentsFromDriver]);

  const handleActiveMilitaryChange = useCallback(
    async (value: AnswerValue) => {
      if (value) {
        if (!editMilitaryRef) {
          // Check if no form is currently being edited
          setHasMilitaryDeployment(!!value);
          addMilitaryDeployments();
          const newMilitaryDeployment = addMilitaryDeployments();
          const { militaryDeploymentsRef } = newMilitaryDeployment;
          await newMilitaryDeployment.done;
          addMilitaryDeploymentsToDriver(militaryDeploymentsRef);
          setEditMilitaryRef(militaryDeploymentsRef);
        }
      } else {
        if (
          activeMilitaryDeployDate?.value ||
          militaryReturnDate?.value ||
          militaryDeploymentsList?.length > 1
        ) {
          const msg =
            militaryDeploymentsList?.length > 1
              ? 'Remove all military deployments?'
              : 'Remove military deployment?';

          setOpenDialog(true);
          setRemovalDialogMessage(msg);
        } else {
          if (militaryDeploymentsList?.length === 1) {
            removeAllSavedMilitaryDeploymentsFromDriver();
          }
          setHasMilitaryDeployment(false);
          setEditMilitaryRef(undefined);
        }
      }
    },
    [
      editMilitaryRef,
      setHasMilitaryDeployment,
      addMilitaryDeployments,
      addMilitaryDeploymentsToDriver,
      setEditMilitaryRef,
      activeMilitaryDeployDate?.value,
      militaryReturnDate?.value,
      militaryDeploymentsList?.length,
      removeAllSavedMilitaryDeploymentsFromDriver,
    ],
  );

  const handleSaveMilitaryDeployments = useEvent(async () => {
    const militaryDeploymentFieldsValid = onValidateAffiliationFields(
      activeMilitaryDeployDate,
      militaryReturnDate,
    );

    if (militaryDeploymentFieldsValid) {
      setOpenSnackBarAlert(true);
      setShowRemovalDialog(false);
      setSnackbarMessage('Deployment saved.');
      setEditMilitaryRef('');
    }
  });

  const handleRemoveMilitaryDeployments = useCallback(
    (militaryref: string) => () => {
      const militaryDeployment = militaryDeploymentsByRef[militaryref];
      if (militaryDeployment) {
        trackClick({
          action: 'RemovemilitaryDeploymentsLink',
          label: 'RemovemilitaryDeployments',
        });
        removeMilitaryDeployments(militaryDeployment);
      }
      setOpenSnackBar(true);
      setSnackbarMessage('Deployment removed.');
      setShowRemovalDialog(false);
      setHasMilitaryDeployment(!!militaryDeploymentsList?.length);
      removeMilitaryDeployments(currentMilitaryDeployment);
      setMilitaryRefSnapBeforeEdit(undefined);
      setEditMilitaryRef(undefined);
    },
    [
      currentMilitaryDeployment,
      militaryDeploymentsByRef,
      militaryDeploymentsList?.length,
      removeMilitaryDeployments,
      setEditMilitaryRef,
      setHasMilitaryDeployment,
    ],
  );

  const handleClickEdit = useCallback(
    (militaryRef: string) => {
      if (militaryRef) {
        const militaryDeployment = militaryDeploymentsByRef[militaryRef];

        setMilitaryRefSnapBeforeEdit({
          returnDate: militaryDeployment.militaryReturnDate,
          deploymentDate: militaryDeployment.activeMilitaryDeployDate,
        });
        setEditMilitaryRef(militaryRef);
      }
    },
    [militaryDeploymentsByRef, setEditMilitaryRef],
  );

  const handleClickOpen = useCallback((militaryRef: string) => {
    if (militaryRef) {
      setDialogItem(militaryRef);
      setShowRemovalDialog(true);
    }
  }, []);

  const closeSnackBar = useCallback(() => {
    setOpenSnackBar(false);
    setOpenSnackBarAlert(false);
  }, []);

  const renderMilitaryDeployment = useCallback(
    (item: string) => (
      <>
        <Resource.Item
          xs={4}
          title='Deployment Began'
          value={
            militaryDeploymentsByRef[item]?.activeMilitaryDeployDate &&
            dayjs(militaryDeploymentsByRef[item]?.activeMilitaryDeployDate).format('MM/DD/YYYY')
          }
        />
        <Resource.Item
          xs={4}
          title='Deployment Ended'
          value={
            militaryDeploymentsByRef[item]?.militaryReturnDate &&
            dayjs(militaryDeploymentsByRef[item]?.militaryReturnDate).format('MM/DD/YYYY')
          }
        />
      </>
    ),
    [militaryDeploymentsByRef],
  );

  return (
    <>
      <GridItem bottomSpacing='sm' xs={8}>
        <RadioGroupWithOptions
          value={hasMilitaryDeployment}
          label={`Did ${name} return from military deployment within the past 6 months?`}
          id='militaryDeployment'
          variant='yesNoButton'
          data-testid='deploymentYesNo'
          trackingName='MilitaryDeployment'
          actionOnComplete={handleActiveMilitaryChange}
        />
      </GridItem>
      <Grid container xs={8}>
        {militaryDeploymentsList && (
          <Resource.List
            items={militaryRefs}
            pageErrors={pageErrors}
            editItemRef={editMilitaryRef}
            onEdit={handleClickEdit}
            onDelete={handleClickOpen}
            onCancel={handleOnCancel}
            onNext={handleSaveMilitaryDeployments}
            // eslint-disable-next-line react/jsx-no-bind
            form={(formProps) => (
              <Grid xs={12}>
                {activeMilitaryDeployDate.exists && (
                  <GridItem marginLeft={1} topSpacing='sm'>
                    <DatePicker
                      {...activeMilitaryDeployDate.props}
                      fullWidth={false}
                      id='militaryDeployDate'
                      groupLabel={`When did ${name} begin deployment?`}
                      trackingName='militaryDeploymentDate'
                      trackingLabel={activeMilitaryDeployDate.props.value}
                    />
                  </GridItem>
                )}
                {militaryReturnDate.exists && (
                  <GridItem marginLeft={1} topSpacing='md'>
                    <DatePicker
                      {...militaryReturnDate.props}
                      fullWidth={false}
                      id='militaryReturnDate'
                      groupLabel={`When did ${name} return from deployment? (optional)`}
                      trackingName='militaryReturnDate'
                      trackingLabel={militaryReturnDate.props.value}
                    />
                  </GridItem>
                )}

                <GridItem
                  marginLeft={3}
                  topSpacing='md'
                  bottomSpacing='sm'
                  xs={12}
                  className={classes.buttonContainer}
                >
                  <Button
                    id='cancelMilitaryDeployment'
                    type='button'
                    color='primary'
                    variant='iconTextMedium'
                    onClick={handleOnCancel}
                    className={classes.cancelButton}
                  >
                    Cancel
                  </Button>
                  <Button
                    id='saveMilitaryDeployment'
                    type='button'
                    color='primary'
                    variant='primary'
                    onClick={handleSaveMilitaryDeployments}
                    trackingName='save_Military_Deployment_Button'
                    trackingLabel='save_Military_Deployment'
                  >
                    SAVE DEPLOYMENT
                  </Button>
                </GridItem>
              </Grid>
            )}
            renderListItem={renderMilitaryDeployment}
          />
        )}
      </Grid>

      {militaryDeploymentsList?.length > 0 && (
        <Resource.AddButton
          id='saveAffiliation'
          type='button'
          color='primary'
          variant='outlinePrimary'
          onClick={handleAddDeployment}
          trackingName='save_Military_Deployment_Button'
          trackingLabel='save_Military_Deployment'
          disabled={!!editMilitaryRef}
        >
          ADD ANOTHER DEPLOYMENT
        </Resource.AddButton>
      )}
      {openSnackBar && (
        <Snackbar
          classes={{ root: `${classes.snackBarStyle}` }}
          message={snackbarMessage}
          vertical='bottom'
          horizontal='center'
          open={openSnackBar}
          onClose={closeSnackBar}
        />
      )}
      {openSnackBarAlert && (
        <SnackbarAlert
          message={snackbarMessage}
          vertical='bottom'
          horizontal='center'
          open
          hideActionButton
          autoHideDuration={3000}
          onClose={closeSnackBar}
          severity='success'
        />
      )}
      {openDialog && (
        <Dialog
          actionButtonLabel='Remove'
          textButtonLabel='Cancel'
          buttonPlacement='right'
          titleText={removalDialogMessage}
          actionButtonOnClick={handleRemoveMilitaryRecord}
          textButtonOnClick={handleClose}
          open={openDialog}
          onClose={handleClose}
          hideTitleCloseButton
          hideDivider
        >
          {/* TODO: Update to leverage DialogContent is already baked into the ECP Dialog component. */}
          <DialogContent>
            <DialogContentText>Any associated content will be removed.</DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      {showRemovalDialog && (
        <Dialog
          actionButtonLabel='Remove'
          textButtonLabel='Cancel'
          buttonPlacement='right'
          titleText='Remove military deployment?'
          actionButtonOnClick={handleRemoveMilitaryDeployments(dialogItem)} // Add a check to determine if deleting record or records
          textButtonOnClick={handleClose}
          open={showRemovalDialog}
          onClose={handleClose}
          hideTitleCloseButton
          hideDivider
        >
          <DialogContent>
            <DialogContentText>Any associated content will be removed.</DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

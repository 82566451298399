import type { Product } from './tpi.types';
import type { Address } from './tpiSearch.types';

export enum TPISearchType {
  STARTS_WTH = 'Starts with',
  INCLUDES = 'Includes',
}

export interface AddTPIInputFields extends Address {
  companyName: string;
  interestType: string;
}

export type SearchTPIInputFields = Omit<
  AddTPIInputFields,
  'zip5' | 'zip4' | 'address1' | 'address2' | 'loanNumber'
> & {
  searchType: TPISearchType;
  product: Product;
};

export type TPIInputFields = AddTPIInputFields & SearchTPIInputFields;

export type TPISearchSelectionInputFields = {
  tpi: string;
};

import { useCallback } from 'react';

import type { DocumentURLResponse } from '@ecp/features/sales/checkout';
import { retrieveDocumentURL } from '@ecp/features/sales/checkout';
import { DiscountHTMLString } from '@ecp/features/sales/shared/metadata';
import {
  getDalSessionId,
  getIsBundleForOfferProductsSelected,
  getOfferDetailsForProduct,
  getOfferProductsSelected,
  getOfferProductsSelectedByType,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AutoProduct, Product, PropertyProduct } from '@ecp/features/shared/product';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';
import type { AnswerValue, Field } from '@ecp/types';

import { useGetProducerAndQuoteDetails } from '.';
import type { AFIEmailData, ProducerAndQuoteDetail } from '../types';

const useBuildDiscountDetailsForEmail = (
  products: Product[],
): (() => Record<string, string> | undefined) => {
  const offerInfo = products.map(useDiscountForEmail);

  return useCallback(() => {
    return offerInfo?.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});
  }, [offerInfo]);
};

const useGetSendEmail = (): (() => Promise<ProducerAndQuoteDetail>) => {
  const producerAndQuoteDetails = useGetProducerAndQuoteDetails();
  const getBoxURL = useRetrieveBoxURL();
  const offerProductsSelected = useSelector((state: RootStore) => getOfferProductsSelected(state));
  const discountsDetailsForEmail = useBuildDiscountDetailsForEmail(offerProductsSelected);

  return useCallback(async () => {
    const { ...sendEmailRequest } = producerAndQuoteDetails();
    const { ...discountDetails } = discountsDetailsForEmail();
    const { ...boxURL } = await getBoxURL();

    const sendEmailData = {
      ...sendEmailRequest,
      ...discountDetails,
      ...boxURL,
    };

    return sendEmailData;
  }, [discountsDetailsForEmail, getBoxURL, producerAndQuoteDetails]);
};

const useDiscountForEmail = (product: Product): Record<string, string> => {
  const offerPropertyInfo = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, product),
  );
  const productName = getProductDisplayNameFromProduct(product);
  const { initialSection, middleSection, lineBreak } = DiscountHTMLString;

  // Pull the applied discounts from the offer, remove the word 'Discount', map them to the proper type to be used by the component later

  let resultHtml = '';

  offerPropertyInfo?.details?.appliedDiscounts?.forEach((discount, index) => {
    if (index >= 4) return;
    const html =
      index === 0
        ? initialSection.replace('xDiscount', discount)
        : middleSection.replace('xDiscount', discount);
    resultHtml += html + (index !== 3 ? lineBreak : '');
  });

  return { [`${productName}IncludedDiscounts`]: resultHtml };
};

const getProductName = (product: Product, isBundle: boolean): string => {
  const productDisplayName = getProductDisplayNameFromProduct(product);
  const productName = isBundle ? productDisplayName : '';

  return productName;
};

const destructureBoxURLs = (
  urls: DocumentURLResponse['data']['documents'],
  products: {
    auto?: AutoProduct;
    property?: PropertyProduct;
  },
  isBundle: boolean,
): Record<string, string | undefined> => {
  return Object.entries(products).reduce((acc, [reducedProductName, product]) => {
    if (product) {
      const productName = getProductName(product, isBundle);

      acc[`QuoteRefID${productName}`] = urls && urls[product].carrierDocuments[0].url;
    }

    return acc;
  }, {} as Record<string, string | undefined>);
};

const useRetrieveBoxURL = (): (() => Promise<Record<string, string | undefined> | undefined>) => {
  const dispatch = useDispatch();
  const dalSessionId = useSelector(getDalSessionId);
  const products = useSelector(getOfferProductsSelected);
  const offerProducts = useSelector(getOfferProductsSelectedByType);
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);

  return useCallback(async () => {
    if (dalSessionId) {
      const urls = await dispatch(retrieveDocumentURL({ dalSessionId, product: products })).then(
        (result) => result?.response?.documents,
      );

      if (!urls) return undefined;

      return destructureBoxURLs(urls, offerProducts, isBundle);
    }

    return undefined;
  }, [dalSessionId, dispatch, isBundle, offerProducts, products]);
};

export const useBuildSendEmailRequest = (): (() => Promise<AFIEmailData>) => {
  const getSendEmailData = useGetSendEmail();

  return useCallback(async () => {
    const currentEmailData = await getSendEmailData();
    const emailData = Object.entries(currentEmailData).reduce((acc, [key, value]) => {
      acc.push({ key, value });

      return acc;
    }, [] as { key: string; value: string | Field<AnswerValue> | undefined }[]);

    return {
      messageDetails: emailData,
      templateCode: currentEmailData.TemplateName,
      partnerId: 'AFI',
      brandId: 'Quote',
      language: 'EN',
      recipientAddress: currentEmailData.CustomerEmailAddress,
    };
  }, [getSendEmailData]);
};

import { useCallback } from 'react';

import { datadogLog } from '@ecp/utils/logger';

import { THIRD_PARTY_INTEREST_REF } from '@ecp/features/sales/shared/constants';
import {
  deleteInquiryRef,
  getAnswer,
  getInquiryLoaded,
  getVehicles,
  updateAddedRef,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';

export interface ThirdPartyInterest {
  ref: string;
  name?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  serviceTpiId?: string;
  addressRef: string;
  escrow?: boolean;
  cdhId?: string;
  interestType?: string;
  titleHolderType?: string;
  loanLeaseNumber?: string;
  billToTpi?: string;
  renewalDecToTpi?: boolean;
}

interface ThirdPartyInterestWithVeh {
  vehicleRef: string;
  thirdPartyInterest: ThirdPartyInterest;
}

const getDetail = (
  state: RootStore,
  itemRef: string,
  path: string,
  defaultValue: AnswerValue = undefined,
): AnswerValue => {
  const value = getAnswer(state, `delta.${itemRef}.${path}`);

  return value !== undefined ? value : defaultValue;
};

const getAddressDetail = (state: RootStore, itemRef: string, detail: string): AnswerValue => {
  const addressRef = getDetail(state, itemRef, 'address.ref', '') as string;

  return getAnswer(state, `${addressRef}.${detail}`);
};

export const useAllTpiDetails = (items: string[]): ThirdPartyInterest[] => {
  return useSelector((state: RootStore) =>
    items.map((itemRef: string) => ({
      ref: itemRef,
      name: getDetail(state, itemRef, 'name') as string,
      serviceTpiId: getDetail(state, itemRef, 'serviceTpiId') as string,
      cdhId: getDetail(state, itemRef, 'cdhId') as string,
      addressRef: getDetail(state, itemRef, 'address.ref') as string,
      interestType: getDetail(state, itemRef, 'interestType') as string,
      titleHolderType: getDetail(state, itemRef, 'titleHolderType') as string,
      loanLeaseNumber: getDetail(state, itemRef, 'loanLeaseNumber') as string,
      billToTpi: getDetail(state, itemRef, 'billToTpi') as string,
      renewalDecToTpi: getDetail(state, itemRef, 'renewalDecToTpi', undefined) as boolean,
      escrow: getDetail(state, itemRef, 'escrow', undefined) as boolean,
      addressLine1: getAddressDetail(state, itemRef, 'line1') as string,
      addressLine2: getAddressDetail(state, itemRef, 'line2') as string,
      state: getAddressDetail(state, itemRef, 'state')?.toString()?.split('.')[1],
      city: getAddressDetail(state, itemRef, 'city') as string,
      zipCode: getAddressDetail(state, itemRef, 'zipcode') as string,
    })),
  );
};

export const useSingleTpiDetails = (ref: string): ThirdPartyInterest => {
  return useSelector((state: RootStore) => ({
    ref: ref,
    name: getDetail(state, ref, 'name') as string,
    serviceTpiId: getDetail(state, ref, 'serviceTpiId') as string,
    cdhId: getDetail(state, ref, 'cdhId') as string,
    addressRef: getDetail(state, ref, 'address.ref') as string,
    interestType: getDetail(state, ref, 'interestType') as string,
    titleHolderType: getDetail(state, ref, 'titleHolderType') as string,
    loanLeaseNumber: getDetail(state, ref, 'loanLeaseNumber') as string,
    billToTpi: getDetail(state, ref, 'billToTpi') as string,
    renewalDecToTpi: getDetail(state, ref, 'renewalDecToTpi', undefined) as boolean,
    escrow: getDetail(state, ref, 'escrow', undefined) as boolean,
    addressLine1: getAddressDetail(state, ref, 'line1') as string,
    addressLine2: getAddressDetail(state, ref, 'line2') as string,
    state: getAddressDetail(state, ref, 'state')?.toString()?.split('.')[1],
    city: getAddressDetail(state, ref, 'city') as string,
    zipCode: getAddressDetail(state, ref, 'zipcode') as string,
  }));
};

export const useAddTpiToVehicle = (
  vehicleRef?: string | null,
  tpiRef?: string | null,
): (() => string) => {
  const dispatch = useDispatch();
  const inquiryLoaded = useSelector(getInquiryLoaded);

  return useCallback(() => {
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin: 'libs/features/sales/quotes/auto/src/state/modelUtil/tpiModelUtil.ts',
          functionOrigin: 'useAddTPI/useCallback',
        },
      });
      throw new Error('inquiry not loaded');
    }
    if (!tpiRef) throw new Error('No TPI ref on current TPI');
    if (!vehicleRef) throw new Error('No vehicle Ref value provided');
    dispatch(
      updateAddedRef({
        type: `delta.${vehicleRef}.${THIRD_PARTY_INTEREST_REF}`,
        newRef: tpiRef,
      }),
    );

    return tpiRef;
  }, [dispatch, inquiryLoaded, tpiRef, vehicleRef]);
};

export const useRemoveTpiFromVehicle = (): ((
  vehicleRef: string | null,
  tpiRef: string,
  tpiAddressRef: string,
) => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(
    async (vehicleRef: string | null, tpiRef: string, tpiAddressRef: string) => {
      if (!tpiRef) throw new Error('No TPI ref on current TPI');
      if (!vehicleRef) throw new Error('No vehicle Ref value provided');
      if (!tpiAddressRef) throw new Error('No tpiAddressRef value provided');
      await dispatch(deleteInquiryRef(tpiRef));
      await dispatch(deleteInquiryRef(tpiAddressRef));
    },
    [dispatch],
  );
};

export const useAllVehicleTpiDetails = (): ThirdPartyInterestWithVeh[] => {
  const vehicles = useSelector(getVehicles);
  const tpiDetails = useSelector((state) =>
    vehicles.map((vehicle) => {
      const tpiRefs =
        (getAnswer(state, `delta.${vehicle.ref}.thirdPartyInterest.ref`) as Array<string>) || [];

      return tpiRefs.map((ref) => ({
        vehicleRef: vehicle.ref,
        thirdPartyInterest: {
          ref: ref,
          name: getDetail(state, ref, 'name') as string,
          serviceTpiId: getDetail(state, ref, 'serviceTpiId') as string,
          cdhId: getDetail(state, ref, 'cdhId') as string,
          addressRef: getDetail(state, ref, 'address.ref') as string,
          interestType: getDetail(state, ref, 'interestType') as string,
          titleHolderType: getDetail(state, ref, 'titleHolderType') as string,
          loanLeaseNumber: getDetail(state, ref, 'loanLeaseNumber') as string,
          billToTpi: getDetail(state, ref, 'billToTpi') as string,
          renewalDecToTpi: getDetail(state, ref, 'renewalDecToTpi', undefined) as boolean,
          escrow: getDetail(state, ref, 'escrow', undefined) as boolean,
          addressLine1: getAddressDetail(state, ref, 'line1') as string,
          addressLine2: getAddressDetail(state, ref, 'line2') as string,
          state: getAddressDetail(state, ref, 'state')?.toString()?.split('.')[1],
          city: getAddressDetail(state, ref, 'city') as string,
          zipCode: getAddressDetail(state, ref, 'zipcode') as string,
        },
      }));
    }),
  );

  return tpiDetails.flat(); // Flatten the array if each vehicle can have multiple TPIs
};

export const getThirdPartyInterestRefsOnVeh = (state: RootStore) => (vehicleRef: string) =>
  (getAnswer(state, `delta.${vehicleRef}.thirdPartyInterest.ref`) as Array<string>) || [];

import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useRef, useState } from 'react';

import { Divider } from '@mui/material';
import Iframe from 'react-iframe';

import { GridItem } from '@ecp/components';
import {
  useAddFields,
  useGetConditionValues,
  useGetFields,
  useGetInitValues,
  useInitValues,
} from '@ecp/features/sales/form';
import { Dialog, Form, Select, TextField } from '@ecp/features/sales/shared/components';
import { useAFIEmailQuotes } from '@ecp/features/sales/shared/email-quotes';
import { useFieldWithPrefix, useForm, usePniRef } from '@ecp/features/sales/shared/store';

import {
  getOptions,
  useBuildProducerDetails,
  useBuildSendEmailRequest,
  useGetAgentAddressOptions,
  useGetPreviewURL,
  useGetSendEmailFields,
} from '../../util';
import metadata from './metadata';
import { useStyles } from './SendAndPreviewEmailQuotes.style';

interface Props {
  openDialog: boolean;
  isProcessing?: boolean;
  handleDialog: Dispatch<SetStateAction<boolean>>;
  setEmailActionInfoType: Dispatch<SetStateAction<{ status: string; message: string }>>;
}

export const SendAndPreviewEmailQuotes: React.FC<Props> = (props) => {
  const { classes } = useStyles();

  const [openPreview, setOpenPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [, setLoaded] = useState(false);
  const [isSendEmailProcessing, setIsSendEmailProcessing] = useState(false);

  const { openDialog, isProcessing, handleDialog, setEmailActionInfoType } = props;
  const buildProducerDetails = useBuildProducerDetails();

  const { name, addresses, phones, emails } = buildProducerDetails();
  const previewURL = useGetPreviewURL();
  const addressOptions = useGetAgentAddressOptions();
  const sendEmailRequest = useBuildSendEmailRequest();
  const { handleSendEmail } = useAFIEmailQuotes();

  const getInitValues = useGetInitValues();
  const getFields = useGetFields();
  const getConditions = useGetConditionValues();

  const pniRef = usePniRef();
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const pniEmail = usePersonField('email');

  const { emailFrom, emailTo, emailCC, emailMessage, agencyName, agencyAddress, agencyPhone } =
    useGetSendEmailFields();

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: getConditions(),
  });

  useAddFields({
    [emailFrom.key]: emailFrom,
    [emailTo.key]: emailTo,
    [emailCC.key]: emailCC,
    [emailMessage.key]: emailMessage,
    [agencyName.key]: agencyName,
    [agencyAddress.key]: agencyAddress,
    [agencyPhone.key]: agencyPhone,
  });
  useInitValues({
    [emailFrom.key]: emails && emails.length && emails[0]?.emailAddress,
    [emailTo.key]: pniEmail.props.value,
    [agencyName.key]: name,
    [agencyAddress.key]: addresses && addresses.length && addresses[0]?.displayValue,
    [agencyPhone.key]: phones && phones.length && phones[0]?.phoneNumber,
    [emailMessage.key]: metadata.defaultEmailMessage,
  });

  const handlePreviewEmailButtonClick = useCallback(async () => {
    const { isValid } = validateForm();
    if (isValid) {
      await patchFormValues();
      setPreviewUrl(previewURL());
      setOpenPreview(true);
    }
  }, [patchFormValues, previewURL, validateForm]);

  const handleSendEmailButtonClick = useCallback(async () => {
    const { isValid } = validateForm();
    if (isValid) {
      await patchFormValues();
      setIsSendEmailProcessing(true);
      const emailRequest = await sendEmailRequest();
      await handleSendEmail(emailRequest).then((result) => {
        if (result) {
          const { confirmation } = result;
          if (confirmation.emailUniqueId) {
            setEmailActionInfoType({ status: 'SUCCESS', message: 'Quote Sent.' });
          } else {
            setEmailActionInfoType({ status: 'FAILED', message: 'Quote Failed to Send.' });
          }
        }
      });
      setIsSendEmailProcessing(false);
      handleDialog(false);
    }
  }, [
    handleDialog,
    handleSendEmail,
    patchFormValues,
    sendEmailRequest,
    setEmailActionInfoType,
    validateForm,
  ]);

  const handleLoad = useCallback(() => {
    const timeout = setTimeout(() => setLoaded(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  const handlePreviewDialogClose = useCallback(() => {
    setOpenPreview(false);
  }, []);

  const handleDialogClose = useCallback(() => {
    handleDialog(false);
  }, [handleDialog]);

  return (
    <>
      <Form>
        <Dialog
          actionButtonLabel='Send'
          textButtonLabel='Cancel'
          textButtonVariant='iconTextMedium'
          additionalTextButtonLabel='Preview Email'
          buttonPlacement='right'
          titleText='Send Quote'
          actionButtonOnClick={handleSendEmailButtonClick}
          textButtonOnClick={handleDialogClose}
          additionalButtonOnClick={handlePreviewEmailButtonClick}
          open={openDialog}
          showAppBar={false}
          fullScreen={false}
          isProcessing={isProcessing || isSendEmailProcessing}
          onClose={handleDialogClose}
          className={classes.dialogRoot}
          hideTitleCloseButton
          showFullScreenActionButtons
        >
          <>
            <GridItem>
              <Select
                {...emailFrom.props}
                label='From'
                id='AgentFromEmail'
                options={getOptions(emails?.map((e) => e.emailAddress))}
                disabled={isPatchFormInProgress}
              />
            </GridItem>
            <GridItem topSpacing='md'>
              <TextField
                {...emailTo.props}
                label='To'
                id='CustomerToEmail'
                disabled={isPatchFormInProgress}
              />
            </GridItem>
            <GridItem topSpacing='md'>
              <TextField
                {...emailCC.props}
                label='CC Policyholder Contacts'
                disabled={isPatchFormInProgress}
              />
            </GridItem>
            <GridItem topSpacing='lg'>
              <Divider />
            </GridItem>
            <GridItem topSpacing='md'>
              <h4>Message Details</h4>
            </GridItem>
            <GridItem topSpacing='md'>
              <TextField
                {...emailMessage.props}
                label='Message'
                isMultiline
                disabled={isPatchFormInProgress}
              />
            </GridItem>

            <GridItem topSpacing='lg'>
              <Divider />
            </GridItem>

            {/* Agent Section */}
            <GridItem topSpacing='lg'>
              <p>The contact information below will display at the end of the email message.</p>
            </GridItem>
            <GridItem topSpacing='md'>
              <TextField
                {...agencyName.props}
                label='Agency Name'
                disabled={isPatchFormInProgress}
              />
            </GridItem>
            <GridItem topSpacing='md'>
              <Select
                {...agencyAddress.props}
                label='Agency Address'
                id='email'
                options={addresses && addressOptions(addresses.map((e) => e))}
                disabled={isPatchFormInProgress}
              />
            </GridItem>
            <GridItem topSpacing='md'>
              <Select
                {...agencyPhone.props}
                label='Agency Phone Number'
                id='email'
                options={getOptions(phones.map((e) => e.phoneNumber))}
                disabled={isPatchFormInProgress}
              />
            </GridItem>
          </>
        </Dialog>
      </Form>
      {openPreview && (
        <Dialog
          open={openPreview}
          showAppBar={false}
          showFullScreenActionButtons
          actionButtonOnClick={handlePreviewDialogClose}
          aria-labelledby='fullscreen-modal-title'
          aria-describedby='fullscreen-modal-body'
          className={classes.dialogRoot}
          buttonPlacement='right'
          actionButtonLabel='CLOSE'
        >
          <Iframe
            url={previewUrl}
            className={classes.iframe}
            name='output_frame'
            title='Email_Preview'
            onLoad={handleLoad}
          />
        </Dialog>
      )}
    </>
  );
};

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PersonalInjuryCoverage' })((theme) => ({
  root: {
    padding: '20px 6px 20px 0px',
    '&:first-child': {
      paddingTop: 0,
    },
  },
  subCoverageRoot: {
    marginLeft: 74,
  },
}));

import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { useSetupDriverForm } from '@ecp/features/sales/quotes/auto';
import { getAllDriverFormConditions, useIsSniValue } from '@ecp/features/sales/quotes/auto';
import { Form, Resource } from '@ecp/features/sales/shared/components';
import { getPersonRefForDriver, useForm } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { useAutoLineFormStyles } from '@ecp/sales/lob/auto';
import type { AnswerValue, Fields } from '@ecp/types';

import type { DriverFormSnapshot } from '../DriversPageForm/DriversPageForm';
import { DriverFormQuestions } from './DriverFormQuestions';

interface Props {
  itemRef: string;
  setDriverDataBeforeEdit: Dispatch<SetStateAction<DriverFormSnapshot | undefined>>;
  onNext: () => Promise<void> | void;
  onCancel: (vehicleRef: string, driverFields: Fields) => void;
  onRemove: (vehicleRef: string) => void;
}

export const AddEditDriverForm: React.FC<Props> = (props) => {
  const { onNext, itemRef: driverRef, onCancel, onRemove, setDriverDataBeforeEdit } = props;

  const personRef = useSelector(getPersonRefForDriver(driverRef)) as string;

  const { isPni, driverFields, validateFormAndIncidents } = useSetupDriverForm(
    driverRef.split('.')[1],
  );

  const isSniValue = useIsSniValue(personRef);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getFields = useGetFields();
  const { classes } = useAutoLineFormStyles();

  useEffect(() => {
    const driverFormFields = getFields();
    // Storing driver values before user edits.
    setDriverDataBeforeEdit({
      firstName: driverFormFields.firstName?.value as AnswerValue,
      lastName: driverFormFields.lastName?.value as AnswerValue,
      suffix: driverFormFields.suffix?.value as AnswerValue,
      middleName: driverFormFields.middleName?.value as AnswerValue,
      dateOfBirth: driverFormFields.dateOfBirth?.value as AnswerValue,
      maritalStatus: driverFormFields.maritalStatus?.value as AnswerValue,
      gender: driverFormFields.gender?.value as AnswerValue,
      relationshipToApplicant: driverFormFields.relationshipStatus?.value as AnswerValue,
      driverStatus: driverFormFields.driverStatus?.value as AnswerValue,
      licenseNumber: driverFormFields?.dlNumber?.value as AnswerValue,
      licenseState: driverFormFields?.dlState?.value as AnswerValue,
      sr22Filing: driverFormFields.needFinancialForm?.value as AnswerValue,
    });
    // this executes when the form first opens
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitValues = useGetInitValues();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    formKey: driverRef,
    conditions: getAllDriverFormConditions(driverFields, isPni, isSniValue),
  });

  const handleSubmit = useCallback(async () => {
    if (!validateFormAndIncidents(validateForm, false)) return;
    setIsSubmitting(true);
    await patchFormValues();
    onNext();
    setIsSubmitting(false);
  }, [validateFormAndIncidents, validateForm, patchFormValues, onNext]);

  const handleDeleteDriver = useCallback(async (): Promise<void> => {
    onRemove(driverRef);
  }, [onRemove, driverRef]);

  /* const allowAddSNI = useAllowAddingSNI(driverRef);*/ // As of EDSP-11981 this still throws false because of feature flag enable it in EDSP-11979

  const handleCancelDriver = useCallback(async (): Promise<void> => {
    onCancel(driverRef, getFields());
  }, [driverRef, getFields, onCancel]);

  return (
    <Form showBackdrop={isPatchFormInProgress}>
      <DriverFormQuestions driverRef={driverRef} personRef={personRef} isPni={isPni} />
      <Grid container justifyContent='space-between' className={classes.formFooter}>
        <Resource.DeleteButton
          onClick={handleDeleteDriver}
          showButton={!isPni}
          trackingName='remove_driver_link'
          trackingLabel={driverRef}
          analyticsElement='choice.reviewVehicleAndDriverPage.DeleteVehicleButton'
          analyticsEventDetail={driverRef}
        >
          Delete driver
        </Resource.DeleteButton>
        <GridItem>
          <Resource.CancelButton
            onClick={handleCancelDriver}
            trackingName='remove_driver_link'
            trackingLabel={driverRef}
            analyticsElement='choice.reviewVehicleAndDriverPage.CancelDriverButton'
            analyticsEventDetail={driverRef}
          >
            Cancel
          </Resource.CancelButton>
          <Resource.SaveButton
            onClick={handleSubmit}
            isProcessing={isPatchFormInProgress || isSubmitting}
            trackingName={GoogleAnalyticsLabels.CONTINUE}
            trackingLabel='driver_details_page_continue'
            analyticsElement='choice.driverDetailsPage.continueButton'
          >
            Save driver
          </Resource.SaveButton>
        </GridItem>
      </Grid>
    </Form>
  );
};

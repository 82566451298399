import { useCallback, useState } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { useVehiclesForSidebar } from '@ecp/features/sales/quotes/auto';
import { Page } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  getOperatorsInDrivers,
  getPLPCQuoteNumber,
  questionExists,
  submitProofDraft,
  updateAnswers,
  updatePageStatus,
  useRouteToPLPC,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { getProductNameFromUnbundledLob, LineOfBusiness } from '@ecp/features/shared/product';
import { VehiclesPageForm } from '@ecp/sales/lob/auto';
import { PolicyCenterDialog } from '@ecp/sales/lob/property';

import { useShouldRouteToPLPC, useUpdatedAnswers } from '../../utils';

export const VehiclesPage: React.FC = () => {
  // TODO: are they the same or can we get rid of one of the vehicle arrays
  const vehicles = useVehiclesForSidebar();
  const navigateToDriverAssignmentPage = useNavigateToPage(PagePath.DRIVER_ASSIGNMENT);

  const operators = useSelector(getOperatorsInDrivers);
  const dispatch = useDispatch();
  const navigateToPriorCarrier = useNavigateToPage(PagePath.AUTO_PRIOR_INSURANCE);

  // do the driver assignment
  const autoAssign = useCallback(async () => {
    await dispatch(
      updateAnswers({
        answers: {
          [`${vehicles[0].ref}.primary.driver.ref`]: operators[0].ref,
          [`${vehicles[0].ref}.secondary.driver.ref`]: null,
        },
      }),
    );
    dispatch(updatePageStatus(NavStatus.VALID, PagePath.DRIVER_ASSIGNMENT));
    // remove seconday driver if it exists as its no longer needed for 1 driver 1 vehicle scenario.
  }, [dispatch, operators, vehicles]);

  const driverAssignmentQuestionExists = useSelector((state: RootStore) =>
    questionExists('vehicle.<id>.primary.driver.ref')(state),
  );

  // Start - Route to PLPC section
  const [canOpenDialog, setCanOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const policyType = getProductNameFromUnbundledLob(LineOfBusiness.AUTO);
  const plpcQuoteNumber = useSelector((state: RootStore) => getPLPCQuoteNumber(state, policyType));
  // operations to perform routing to PLPC
  const routeToPLPC = useRouteToPLPC();
  // check if quote should be routed to PLPC
  const shouldRouteToPLPC = useShouldRouteToPLPC();
  // flip answers back to defaults before route to PLPC
  const updatedAnswers = useUpdatedAnswers(policyType);

  const handleRouteToPLPCDialogAction = useCallback(async () => {
    if (!plpcQuoteNumber) {
      setCanOpenDialog(false);

      return;
    }
    await dispatch(updateAnswers({ answers: updatedAnswers }));
    await routeToPLPC(setIsSubmitting, LineOfBusiness.AUTO, plpcQuoteNumber);
  }, [dispatch, plpcQuoteNumber, routeToPLPC, updatedAnswers]);

  const handleCancelButtonOnClick = useCallback(async () => {
    setCanOpenDialog(false);

    return;
  }, []);
  // End - Route to PLPC section

  const navigateToNext = useCallback(async () => {
    if (driverAssignmentQuestionExists && operators.length === 1 && vehicles.length === 1) {
      await autoAssign();

      // check if quote should be routed to PLPC once the form is valid
      if (shouldRouteToPLPC(policyType)) {
        setCanOpenDialog(true);

        return;
      }

      await dispatch(
        submitProofDraft({
          policyTypes: [policyType],
        }),
      );

      return await navigateToPriorCarrier();
    }
    await navigateToDriverAssignmentPage();
  }, [
    driverAssignmentQuestionExists,
    operators.length,
    vehicles.length,
    navigateToDriverAssignmentPage,
    autoAssign,
    shouldRouteToPLPC,
    policyType,
    dispatch,
    navigateToPriorCarrier,
  ]);

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.ADD_EDIT_VEHICLES} />,
        }}
        title='Vehicles'
        analyticsElement='choice.vehicleDetailsPage.page'
      >
        <VehiclesPageForm onNext={navigateToNext} />
      </Page>
      {canOpenDialog && (
        <PolicyCenterDialog
          dialogStat={canOpenDialog}
          dialogSubmit={handleRouteToPLPCDialogAction}
          isProcessing={isSubmitting}
          toggleDialog={handleCancelButtonOnClick}
        />
      )}
    </div>
  );
};

import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';
import Iframe from 'react-iframe';

import { parseDollar } from '@ecp/utils/common';

import { GridItem, Snackbar, SnackbarAlert } from '@ecp/components';
import { Button, Dialog, TextField } from '@ecp/features/sales/shared/components';
import { OfferType } from '@ecp/features/sales/shared/constants';
import {
  getAnswer,
  setShouldRecalc,
  submitProofDraft,
  updateOffers,
  updateOfferType,
} from '@ecp/features/sales/shared/store';
import type { RootStore, ThunkAction } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import { getProductNameFromUnbundledLob, LineOfBusiness } from '@ecp/features/shared/product';
import { IconUIEdit } from '@ecp/themes/base';

import { useGetReplacementCostDetails } from '../../utils';
import { usePropertyField } from '../../utils';
import { HomeReplacementCostCalculatorForm } from '../HomeReplacementCostCalculatorForm';
import { useStyles } from './HomeReplacementCostCard.styles';

export const HomeReplacementCostCard: React.FC = () => {
  const { classes } = useStyles();
  const replacementCostValuationId = usePropertyField('replacementCostValuationId');
  const replacementCost = useSelector((state: RootStore) =>
    getAnswer(state, 'property.replacementCost'),
  );
  const [openIFrame, setOpenIframe] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [openDiscardChangeDialog, setOpenDiscardChangeDialog] = useState(false);
  const [iframeActionInfoType, setIframeActionInfoType] = useState<'NONE' | 'SAVE' | 'DISCARD'>(
    'NONE',
  );
  const [iframeActionInfoMessage, setIframeActionInfoMessage] = useState('');
  const policyType = getProductNameFromUnbundledLob(LineOfBusiness.HOME);
  const dispatch = useDispatch();

  const { url } = useGetReplacementCostDetails();

  const handleCreateOrEditReport = useCallback(() => {
    setOpenIframe(true);
  }, []);

  const doSaveAndUpdateOffers = useCallback(
    (): ThunkAction<Promise<void>> => async (dispatch) => {
      await dispatch(submitProofDraft({ policyTypes: [policyType] }));
      await dispatch(updateOfferType({ offerType: OfferType.OFFER_WITHOUT_PREMIUM }));
      await dispatch(updateOffers({ force: true }));
    },
    [policyType],
  );

  const handleRetrieveReport = useCallback(async () => {
    await dispatch(doSaveAndUpdateOffers());
  }, [dispatch, doSaveAndUpdateOffers]);

  // try to keep our loading overlay up as long as possible before closing in favor of iframe
  const handleLoad = useCallback(() => {
    const timeout = setTimeout(() => setLoaded(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  const onCloseIframeAction = useCallback(() => {
    setOpenDiscardChangeDialog(true);
  }, []);

  const onSaveIframeAction = useCallback(async () => {
    await dispatch(doSaveAndUpdateOffers());

    setLoaded(false);
    setOpenIframe(false);
    setIframeActionInfoMessage('Report saved.');
    setIframeActionInfoType('SAVE');
  }, [dispatch, doSaveAndUpdateOffers]);

  const onCancelDialogAction = useCallback(() => {
    setOpenDiscardChangeDialog(false);
  }, []);

  const onDiscardDialogAction = useCallback(() => {
    setLoaded(false);
    setOpenDiscardChangeDialog(false);
    setOpenIframe(false);
    setIframeActionInfoMessage('Changes discarded.');
    setIframeActionInfoType('DISCARD');
  }, []);

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setIframeActionInfoMessage('');
        setIframeActionInfoType('NONE');
      }
    },
    [],
  );

  const discardDialog = (
    <Dialog
      actionButtonLabel='DISCARD'
      titleText='Discard changes?'
      textButtonLabel='CANCEL'
      open={openDiscardChangeDialog}
      onClose={onCancelDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onDiscardDialogAction}
      hideTitleCloseButton
      showFullScreenActionButtons
    >
      Any unsaved changes will be discarded.
    </Dialog>
  );

  const snackbarDefault = iframeActionInfoType === 'DISCARD' && (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={!!iframeActionInfoMessage}
      autoHideDuration={3000}
      message={iframeActionInfoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const snackbarSuccess = iframeActionInfoType === 'SAVE' && (
    <SnackbarAlert
      open
      autoHideDuration={3000}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
      severity='success'
      message={iframeActionInfoMessage}
      hideActionButton
    />
  );

  const handleValuationIdOnChange = useCallback(
    (value: AnswerValue): void => {
      replacementCostValuationId?.props.actionOnChange(value);
      dispatch(setShouldRecalc(true));
    },
    [dispatch, replacementCostValuationId?.props],
  );

  if (!replacementCostValuationId) return null;

  return (
    <>
      <Grid xs={6}>
        <TextField
          {...replacementCostValuationId.props}
          label='Replacement Cost Valuation ID Number'
          trackingLabel='dwelling'
          trackingName='replacement_cost'
          actionOnChange={handleValuationIdOnChange}
        />
      </Grid>
      <Grid container xs={7} className={classes.addSpacing}>
        <GridItem xs={6}>
          <Button onClick={handleRetrieveReport} variant='iconTextMedium'>
            RETRIEVE REPORT
          </Button>
        </GridItem>
        <GridItem xs={6}>
          <Button onClick={handleCreateOrEditReport} variant='iconTextMedium' icon={<IconUIEdit />}>
            CREATE / EDIT REPORT
          </Button>
        </GridItem>
      </Grid>
      <Grid container className={classes.addSpacing} direction='column' gap='4px'>
        <p className={classes.replacementCostText}>Calculated Replacement Cost</p>
        <p className={classes.replacementCost}>
          {replacementCost && parseDollar(replacementCost.toString())}
        </p>
      </Grid>
      {openIFrame && (
        <Dialog
          open={openIFrame}
          fullScreen
          showAppBar={false}
          showFullScreenActionButtons
          aria-labelledby='fullscreen-modal-title'
          aria-describedby='fullscreen-modal-body'
          buttonPlacement='center'
          actionButtonLabel='SAVE'
          textButtonLabel='CANCEL'
          actionButtonOnClick={onSaveIframeAction}
          onClose={onCloseIframeAction}
        >
          <Iframe
            url={url}
            className={classes.iframe}
            name='output_frame'
            title='360'
            onLoad={handleLoad}
          />
          <>{loaded && <HomeReplacementCostCalculatorForm />}</>
        </Dialog>
      )}
      {discardDialog}
      {snackbarDefault}
      {snackbarSuccess}
    </>
  );
};

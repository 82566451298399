import { Grid } from '@mui/material';

import { GridItem, TooltipWithIcon } from '@ecp/components';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { DynamicCoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { CoveragePackageOptions, useTravelPackageManager } from '../../utils';
import { useStyles } from './TravelPeaceOfMindPackageCoverage.styles';

/**
 *
 * Advance offers two coverage packages. They are travel packages. When selected, they will automatically
 *   selected the included coverages that are in the package. The user will get a discounted premium.
 *   Travel assistance package contains 2 coverages. Travel peace of mind contains two additional coverages.
 *   The two packages cannot be stacked. It displays as a radio options.
 *
 * Travel assistance Includes: Emergency Roadside Service and Road Trip Accident Accommodations.
 * Travel peace of mind Includes: Emergency Roadside Service, Road Trip Accident Accommodations, AD&D, and Rental Reimbursement.
 *
 */
export const TravelPeaceOfMindPackageCoverage: React.FC<DynamicCoverageQuestionProps> = (props) => {
  const { classes } = useStyles();
  const { coverageItemField, primaryText = '', setSnackbarMessages, title = '' } = props;
  const { handlePackageChange, selectedPackage } = useTravelPackageManager(
    coverageItemField,
    setSnackbarMessages,
  );

  const noneLabel = <div className={classes.coverageName}>None</div>;
  const travelAssistancePackageLabelWithTooltip = (
    <div className={classes.coverageName}>
      Travel Assistance
      <TooltipWithIcon
        className={classes.toolTip}
        title='Includes: Emergency Roadside Service and Road Trip Accident Accommodations.'
      />
    </div>
  );
  const travelPeaceOfMindPackageLabelWithTooltip = (
    <div className={classes.coverageName}>
      {title}
      <TooltipWithIcon className={classes.toolTip} title={primaryText} />
    </div>
  );

  const availableCoveragePackageOptions = [
    { label: noneLabel, value: CoveragePackageOptions.NONE },
    {
      label: travelAssistancePackageLabelWithTooltip,
      value: CoveragePackageOptions.TRAVELASSISTANCE,
    },
    {
      label: travelPeaceOfMindPackageLabelWithTooltip,
      value: CoveragePackageOptions.TRAVELPEACEOFMIND,
    },
  ];

  return (
    <div>
      <Grid container justifyItems='space-between'>
        <GridItem xs={12}>
          <RadioGroupWithOptions
            label=''
            options={availableCoveragePackageOptions}
            trackingName='travel_packages'
            variant='radioCard'
            actionOnComplete={handlePackageChange}
            value={selectedPackage}
          />
        </GridItem>
      </Grid>
    </div>
  );
};

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { WaterBackupCoverageHome } from './WaterBackupCoverageHome';
import { WaterBackupCoverageRenters } from './WaterBackupCoverageRenters';

export const WaterBackupCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { carrierName, product } = props;

  if (product === 'home')
    return <WaterBackupCoverageHome carrierName={carrierName} product={product} />;
  else return <WaterBackupCoverageRenters carrierName={carrierName} product={product} />;
};

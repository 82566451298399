import { useCallback, useMemo, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, Select } from '@ecp/components';
import { ReadOnlyField } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  getAnswer,
  updateAnswers,
  useField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Person } from '@ecp/features/sales/shared/types';

import { useStyles } from './SecondaryNamedInsuredForm.styles';

interface Props extends QuestionProps {
  sniQuestionLabel: string;
  secondaryText: string;
  sniFieldRef: string;
  nonPNIPersons: Person[];
  showAddNewPerson?: boolean;
  setIsAnotherPerson?: Dispatch<SetStateAction<boolean>>;
}

export const SecondaryNamedInsuredDropdownQuestion: React.FC<Props> = ({
  sniQuestionLabel,
  secondaryText,
  sniFieldRef,
  nonPNIPersons,
  showAddNewPerson,
  setIsAnotherPerson,
}) => {
  const { classes } = useStyles();
  const sniField = useField(sniFieldRef);
  const sniFieldValue = sniField.props.value?.trim();

  const staticSelectedHouseHoldPersonRef = useField(`static.selected.houseHoldPerson.ref`);
  const staticSelectedSniNewlyAddedPersonRef = useField(`static.selected.sniNewlyAddedPerson.ref`);
  const dispatch = useDispatch();

  // Retrieve the first and last name of the selected person
  const usePersonField = useFieldWithPrefix(sniFieldValue, 'person.<id>');
  const personFirstName = usePersonField('firstName');
  const personLastName = usePersonField('lastName');
  const [addAnotherPersonAsDefault, setAddAnotherPersonAsDefault] = useState(false);
  const sniAnswersValue = useSelector((state: RootStore) => getAnswer(state, sniFieldRef));

  // Memoize the person options to avoid unnecessary re-renders
  const personOptions = useMemo(() => {
    const options = nonPNIPersons.map((person) => {
      const formattedName =
        person.firstName && person.lastName ? `${person.firstName} ${person.lastName}` : '';

      return {
        label: formattedName,
        value: person.ref,
      };
    });

    // Add a "Not applicable" option
    options.push({
      label: 'Not applicable',
      value: '',
    });

    // Add option "Add Another Person" for Home and Renters
    if (showAddNewPerson) {
      options.push({
        label: 'Add Another Person',
        value: 'Add_Another_Person',
      });
    }

    return options;
  }, [nonPNIPersons, showAddNewPerson]);

  // Handle the selection of an SNI from the dropdown
  const handleSniSelection = useCallback(
    async (value: string | undefined) => {
      if (value === 'Add_Another_Person') {
        setAddAnotherPersonAsDefault(true);
        setIsAnotherPerson && setIsAnotherPerson(true);
        // Set INSURED TYPE to null when SNI is selected other than house hold
        await dispatch(
          updateAnswers({
            answers: {
              [`${staticSelectedHouseHoldPersonRef}.insuredType`]: null,
            },
          }),
        );
      } else {
        setIsAnotherPerson && setIsAnotherPerson(false);
        setAddAnotherPersonAsDefault(false);
        sniField.props.actionOnComplete(value || '');
        staticSelectedHouseHoldPersonRef.props.actionOnComplete(value);
        staticSelectedSniNewlyAddedPersonRef.props.actionOnComplete(value);
      }
    },
    [
      dispatch,
      setIsAnotherPerson,
      sniField.props,
      staticSelectedHouseHoldPersonRef,
      staticSelectedSniNewlyAddedPersonRef.props,
    ],
  );

  // Render the value of the dropdown
  const renderValue = useCallback(() => {
    if (addAnotherPersonAsDefault && showAddNewPerson) {
      return 'Add Another Person';
    } else if (!sniAnswersValue || !personFirstName.props.value || !personLastName.props.value) {
      return 'Not applicable';
    }

    return `${personFirstName.props.value} ${personLastName.props.value}`;
  }, [
    addAnotherPersonAsDefault,
    showAddNewPerson,
    sniAnswersValue,
    personFirstName.props.value,
    personLastName.props.value,
  ]);

  // SNI question label and secondary text
  const sniQuestion = (
    <GridItem className={classes.sniText}>
      <p className={classes.primaryTextLabel}>{sniQuestionLabel}</p>
      <p className={classes.secondaryText}>{secondaryText}</p>
    </GridItem>
  );

  const readOnlyQuestion = (
    <>
      <ReadOnlyField
        label={sniQuestionLabel}
        value='Not Applicable'
        assistiveText={secondaryText}
        dataTestId={secondaryText}
      />
      <p className={classes.assistiveText}>
        An SNI can only be selected when there are 2 or more drivers.
      </p>
    </>
  );

  return (
    <>
      {nonPNIPersons.length < 1 && !showAddNewPerson ? (
        readOnlyQuestion
      ) : (
        <Select
          key={nonPNIPersons.length}
          value={sniField.props.value || ''}
          id='SNI'
          label={sniQuestion}
          actionOnChange={handleSniSelection}
          options={personOptions}
          displayEmpty
          renderValue={renderValue}
          className={classes.select}
          trackingName='sni_selection'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
      )}
    </>
  );
};

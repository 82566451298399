import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SecondaryNamedInsuredQuestions' })((theme) => {
  return {
    ...theme.mixins.formAll,
    root: {
      ...theme.mixins.form,
      marginTop: 0,
    },
    radioGroupWidth: {
      width: 360,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    questionSubLabel: {
      ...theme.typography.body1,
      marginLeft: 12,
    },
    disabled: {
      color: theme.palette.text.tertiary,
    },
    sniQuestionBox: {
      border: `1px solid ${theme.palette.other.border}`,
      padding: 16,
      borderRadius: 4,
    },
  };
});

import { FormControl, FormControlLabel, FormHelperText, Typography } from '@mui/material';

import { GridItem, NumberFormat, ReadOnlyField, TooltipWithIcon } from '@ecp/components';
import {
  Checkbox,
  RadioGroupWithOptions,
  Select,
  TextField,
} from '@ecp/features/sales/shared/components';
import type { AnswerValue, Field } from '@ecp/types';

import { getDisplayForReadOnlyOptionField } from '../../utils';
import { useStyles } from './CoverageFieldComponents.styles';

export type CoverageFieldComponentProps = {
  field: Field<AnswerValue>;
};

export type CoverageFieldRadioGroupComponentProps = CoverageFieldComponentProps & {
  coverageName: string;
  sortByKey?: boolean;
  handleSubCoverageChange: (value: AnswerValue) => void;
};

export type CoverageFieldCheckboxComponentProps = CoverageFieldComponentProps & {
  coverageName: string;
  isCoverageSelected: boolean;
  handleCoverageChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export type CoverageFieldTextboxComponentProps = CoverageFieldComponentProps & {
  displayAsDollarAmount?: boolean;
};

export type CoverageFieldSelectComponentProps = CoverageFieldComponentProps & {
  disabled?: boolean;
};

export const CoverageFieldSelectComponent: React.FC<CoverageFieldSelectComponentProps> = (
  props: CoverageFieldSelectComponentProps,
) => {
  const { classes } = useStyles();
  const { field, disabled } = props;
  const { title = '', helpText } = field.question;

  return (
    <>
      {field.exists && (
        <GridItem xs={12} className={classes.subCoverage}>
          <Select
            {...field.props}
            groupLabel={
              <div className={classes.label}>
                {title}
                {helpText && <TooltipWithIcon className={classes.toolTip} title={helpText} />}
              </div>
            }
            className={classes.coveragesDropdown}
            labelId={`${field.props.name}-label`}
            id={field.props.name}
            value={field.props.value}
            actionOnChange={field.props.actionOnComplete}
            fullWidth
            trackingName={field.props.name}
            trackingLabel={field.props.value}
            disabled={disabled}
          />
        </GridItem>
      )}
    </>
  );
};

export const CoverageFieldRadioGroupComponent: React.FC<CoverageFieldRadioGroupComponentProps> = (
  props: CoverageFieldRadioGroupComponentProps,
) => {
  const { classes } = useStyles();
  const { field, coverageName, sortByKey, handleSubCoverageChange } = props;
  const { title = '', helpText } = field.question;

  return (
    <>
      {field.exists && (
        <GridItem xs={12} className={classes.subCoverage}>
          <RadioGroupWithOptions
            {...field.props}
            id={coverageName}
            label={
              <div>
                {title}
                {helpText && <TooltipWithIcon className={classes.toolTip} title={helpText} />}
              </div>
            }
            sortByKey={sortByKey}
            trackingName={coverageName}
            variant='button'
            actionOnComplete={handleSubCoverageChange}
          />
        </GridItem>
      )}
    </>
  );
};

export const CoverageFieldReadOnlyComponent: React.FC<CoverageFieldComponentProps> = (
  props: CoverageFieldComponentProps,
) => {
  const { classes } = useStyles();
  const { field } = props;
  const { title = '', helpText } = field.question;

  const fieldSelectedOptionLabel = getDisplayForReadOnlyOptionField(field);

  return (
    <>
      {fieldSelectedOptionLabel && (
        <GridItem xs={12} className={classes.subCoverage}>
          <ReadOnlyField
            label={title}
            helperText={helpText}
            value={fieldSelectedOptionLabel}
            id={field.props.name}
            classes={classes}
          />
        </GridItem>
      )}
    </>
  );
};

export const CoverageFieldMainCoverageCheckboxComponent: React.FC<
  CoverageFieldCheckboxComponentProps
> = (props: CoverageFieldCheckboxComponentProps) => {
  const { classes } = useStyles();
  const { coverageName, field, isCoverageSelected, handleCoverageChange } = props;
  const { title = '', helpText, subtitle } = field.question;

  return (
    <>
      {field.exists && (
        <>
          <GridItem
            xs={12}
            className={isCoverageSelected ? classes.selectedMainCoverage : classes.mainCoverage}
          >
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.formControlCheckBox}
                  id={coverageName}
                  checked={isCoverageSelected}
                  onChange={handleCoverageChange}
                  trackingName={coverageName}
                  trackingLabel={isCoverageSelected.toString()}
                />
              }
              label={
                <>
                  <div className={classes.coverageName}>
                    {title}
                    {helpText && <TooltipWithIcon className={classes.toolTip} title={helpText} />}
                  </div>
                  {subtitle && <div className={classes.subTitle}>{subtitle}</div>}
                </>
              }
              className={classes.formControlLabel}
            />
          </GridItem>
          <FormControl
            className={classes.formControl}
            error={!!field.props.error}
            fullWidth
            variant='outlined'
            role='group'
          >
            {field.props.error && (
              <FormHelperText role='alert' data-testid='hidden-helper'>
                {field.props.error}
              </FormHelperText>
            )}
          </FormControl>
        </>
      )}
    </>
  );
};

export const CoverageFieldTextboxComponent: React.FC<CoverageFieldTextboxComponentProps> = (
  props: CoverageFieldTextboxComponentProps,
) => {
  const { classes } = useStyles();
  const { field, displayAsDollarAmount = false } = props;
  const { title = '', helpText } = field.question;

  const labelWithTooltip = (
    <div className={classes.label}>
      {title}
      {helpText && <TooltipWithIcon className={classes.toolTip} title={helpText} />}
    </div>
  );

  return (
    <>
      {field.exists && (
        <GridItem xs={12} className={classes.subCoverage}>
          {displayAsDollarAmount ? (
            <NumberFormat
              {...field.props}
              groupLabel={<Typography variant='body4'>{labelWithTooltip}</Typography>}
              id={field.props.name}
              prefix='$'
              thousandSeparator
              decimalScale={0}
              trackingName={field.props.name}
              trackingLabel={field.props.value}
            />
          ) : (
            <TextField
              {...field.props}
              label={<Typography variant='body4'>{labelWithTooltip}</Typography>}
              id={field.props.name}
              trackingName={field.props.name}
              trackingLabel={field.props.value}
            />
          )}
        </GridItem>
      )}
    </>
  );
};

import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import { defaultAnnualMileage } from '../../constants';

interface VehicleAnnualMileageQuestionProps extends QuestionProps {
  vehicleRef: string;
}
export const VehicleAnnualMileageQuestion: React.FC<VehicleAnnualMileageQuestionProps> = (
  props,
) => {
  const {
    groupLabel = 'How many miles are driven annually with this vehicle?',
    trackingName = 'AnnualMiles',
    dataTestId = 'annualMiles',
    vehicleRef,
  } = props;

  const annualMiles = useField(`${vehicleRef}.annualMiles`);
  useAddFields({ annualMiles });
  useInitValues({ [annualMiles.key]: defaultAnnualMileage });

  if (!annualMiles.exists) return null;

  return (
    <NumberFormat
      {...annualMiles.props}
      id='AnnualMiles'
      fullWidth={false}
      groupLabel={<Typography variant='body4'>{groupLabel}</Typography>}
      thousandSeparator
      data-testid={dataTestId}
      trackingName={trackingName}
    />
  );
};

import { useCallback, useEffect, useState } from 'react';

import { DialogContent, DialogContentText, Grid } from '@mui/material';

import { isTruthy } from '@ecp/utils/common';
import type { GeoAddress } from '@ecp/utils/geo';

import { GridItem } from '@ecp/components';
import { Dialog } from '@ecp/features/sales/shared/components';
import { STATE_CODE_PREFIX } from '@ecp/features/sales/shared/constants';
import {
  getPrimaryInsuredPersonInfo,
  updateAnswers,
  useGetAddressFieldsFromRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './AddressSuggestionsDialog.styles';

export interface AddressSuggestionsDialogProps {
  geoAddressSuggestions?: GeoAddress[];
  addressRef: string;
  setShowAddressSuggestion?: (value: boolean) => void;
}

type SuggestedAddress = {
  suggestedStreet: string;
  suggestedSecondary: string;
  suggestedCity: string;
  suggestedState: string;
  suggestedZipcode: string;
};

export const AddressSuggestionsDialog: React.FC<AddressSuggestionsDialogProps> = (props) => {
  const { geoAddressSuggestions, addressRef, setShowAddressSuggestion } = props;

  const { classes } = useStyles();
  const dispatch = useDispatch();

  const { firstName } = useSelector(getPrimaryInsuredPersonInfo);

  const foundSuggestedAddress: SuggestedAddress = {
    suggestedStreet: '',
    suggestedSecondary: '',
    suggestedCity: '',
    suggestedState: '',
    suggestedZipcode: '',
  };
  const [suggestedAddress, setSuggestedAddress] = useState<SuggestedAddress>(foundSuggestedAddress);

  const {
    address: { line1, line2, city, state, zipcode },
  } = useGetAddressFieldsFromRef(addressRef);

  const { value: lineOneValue } = line1;
  const { value: lineTwoValue } = line2;
  const { value: cityValue } = city;
  const { value: zipcodeValue } = zipcode;
  const { value: stateValue } = state;
  let streetAddress = lineOneValue ? lineOneValue.toString() : '';
  if (line2 && lineTwoValue) {
    streetAddress += ` ${lineTwoValue.toString()}`;
  }
  const formattedState = stateValue ? stateValue.toString().split('.')[1] : ''; // remove enum prefix
  const enteredZipcode = zipcodeValue ? zipcodeValue.toString() : '';

  useEffect(() => {
    const formattedEnteredAddress = `${[streetAddress, cityValue, formattedState].join(
      ', ',
    )} ${enteredZipcode}`;
    if (
      geoAddressSuggestions &&
      Array.isArray(geoAddressSuggestions) &&
      geoAddressSuggestions.length > 0
    ) {
      for (let i = 0; i < geoAddressSuggestions.length; i += 1) {
        const {
          street_line: suggestedStreet,
          secondary: suggestedSecondary,
          city: suggestedCity,
          state: suggestedState,
          zipcode: suggestedZipcode,
        } = geoAddressSuggestions[i];
        const suggestion = `${[suggestedStreet, suggestedSecondary, suggestedCity, suggestedState]
          .filter((x) => x)
          .join(', ')} ${suggestedZipcode}`;
        if (formattedEnteredAddress !== suggestion) {
          // only display first unique suggestion
          const foundSuggestedAddress = {
            suggestedStreet,
            suggestedSecondary,
            suggestedCity,
            suggestedState,
            suggestedZipcode,
          };
          setSuggestedAddress(foundSuggestedAddress);
          break;
        }
      }
    }
  }, [
    line1,
    line2,
    city,
    state,
    zipcode,
    streetAddress,
    cityValue,
    formattedState,
    enteredZipcode,
    geoAddressSuggestions,
  ]);

  const handleDialogButtonContinue = useCallback(async () => {
    setShowAddressSuggestion?.(false);
    line1.props.actionOnComplete(suggestedAddress.suggestedStreet);
    line2.props.actionOnComplete(suggestedAddress.suggestedSecondary);
    city.props.actionOnComplete(suggestedAddress.suggestedCity);
    state.props.actionOnComplete(`${STATE_CODE_PREFIX}${suggestedAddress.suggestedState}`);
    zipcode.props.actionOnComplete(suggestedAddress.suggestedZipcode);
    const addressToBeUpdated = {
      [`${addressRef}.city`]: suggestedAddress.suggestedCity,
      [`${addressRef}.line1`]: suggestedAddress.suggestedStreet,
      [`${addressRef}.line2`]: suggestedAddress.suggestedSecondary,
      [`${addressRef}.state`]: `${STATE_CODE_PREFIX}${suggestedAddress.suggestedState}`, // add prefix to match SAPI state code
      [`${addressRef}.zipcode`]: suggestedAddress.suggestedZipcode,
    };
    await dispatch(updateAnswers({ answers: { ...addressToBeUpdated } }));
  }, [
    setShowAddressSuggestion,
    line1.props,
    suggestedAddress.suggestedStreet,
    suggestedAddress.suggestedSecondary,
    suggestedAddress.suggestedCity,
    suggestedAddress.suggestedState,
    suggestedAddress.suggestedZipcode,
    line2.props,
    city.props,
    state.props,
    zipcode.props,
    addressRef,
    dispatch,
  ]);

  const handleCancel = useCallback(() => {
    setShowAddressSuggestion?.(false);
  }, [setShowAddressSuggestion]);

  return (
    <Grid container className={classes.root} id='AddressSuggestionsDialog'>
      <Dialog
        actionButtonLabel='Continue'
        actionButtonOnClick={handleDialogButtonContinue}
        textButtonLabel='Cancel'
        buttonPlacement='right'
        titleText={`Verify ${firstName}’s Address`}
        open
        onClose={handleCancel}
        data-testid='AddressSuggestionContinueButton'
      >
        <DialogContent>
          <DialogContentText>
            For the most accurate quote, the United States Postal Service suggests the changes
            highlighted below.
          </DialogContentText>

          <GridItem topSpacing='sm' xs={12}>
            <p className={classes.useAddress}>Suggested address</p>
            <p className={classes.labelAddress}>
              <mark className={classes.labelAddressHighlight}>
                {[suggestedAddress.suggestedStreet, suggestedAddress.suggestedSecondary]
                  .filter(isTruthy)
                  .join(', ')}
                ,
              </mark>
              {` ${[suggestedAddress.suggestedCity, suggestedAddress.suggestedState].join(', ')} ${
                suggestedAddress.suggestedZipcode
              }`}
            </p>
          </GridItem>
          <GridItem topSpacing='sm' xs={12}>
            <strong className={classes.useAddress}>Use original address </strong>
            <p className={classes.labelAddress}>
              {`${[streetAddress, cityValue, formattedState]
                .filter((x) => x)
                .join(', ')} ${enteredZipcode}`}
            </p>
          </GridItem>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AddressQuestions' })((theme) => ({
  addressBox: {
    border: `1px solid ${theme.palette.other.border}`,
    padding: 20,
    marginTop: 15,
    borderRadius: 8,
  },
}));

import { useCallback } from 'react';

import { env } from '@ecp/env';
import {
  getAnswer,
  getPrimaryInsuredAddressInfo,
  getPrimaryInsuredPersonInfo,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { GarageSizeMetadata, GarageTypeMetadata, StructureUseMetadata } from '../metadata';
interface PropertyAddress {
  name: string;
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
}

interface StructureInfo {
  yearBuilt?: string;
  structureUse?: string;
}
interface AttachedStructure {
  garage: GarageDetails;
}

interface GarageDetails {
  numberOfCars?: string;
  style?: string;
}

interface ResidentialDetails {
  owner: PropertyAddress;
  structureGeneralInfo: StructureInfo;
  attachedStructure: AttachedStructure;
}

interface ValuationDetails {
  residential: ResidentialDetails;
}

interface ReplacementCostDetails {
  createXML: () => string;
  url: string;
}

const transformToCCValue = (
  answerValue: string | undefined,
  source: { label: string; value: string }[],
): string | undefined => {
  const hasMatchedValue = source.find((e) => e.label === answerValue);

  return hasMatchedValue ? hasMatchedValue.value : '';
};

const jsonToXml = (json: ValuationDetails, rootElement: string, nameSpaceURI?: string): string => {
  const xmlParts = [`<${rootElement} ${nameSpaceURI ? `xmlns="${nameSpaceURI}"` : ''}>`];

  function buildXML(
    obj: ValuationDetails | PropertyAddress | StructureInfo | AttachedStructure | string,
  ): void {
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          // Handle arrays
          value.forEach((item) => {
            xmlParts.push(`<${key}>`);
            buildXML(item);
            xmlParts.push(`</${key}>`);
          });
        } else {
          // Handle nested objects
          xmlParts.push(`<${key}>`);
          buildXML(value);
          xmlParts.push(`</${key}>`);
        }
      } else {
        // Handle primitive values
        xmlParts.push(`<${key}>${value}</${key}>`);
      }
    }
  }

  buildXML(json);
  xmlParts.push(`</${rootElement}>`);

  return xmlParts.join('');
};

export const useGetReplacementCostDetails = (): ReplacementCostDetails => {
  const { firstName, lastName } = useSelector(getPrimaryInsuredPersonInfo);
  const { line1, city, state, zipcode } = useSelector(getPrimaryInsuredAddressInfo);
  const garageType = useSelector((state: RootStore) => getAnswer(state, 'property.garage.type'));
  const garageSize = useSelector((state: RootStore) => getAnswer(state, 'property.garage.size'));
  const yearBuilt = useSelector((state: RootStore) => getAnswer(state, 'property.yearBuilt'));
  const residentType = useSelector((state: RootStore) =>
    getAnswer(state, 'property.singleOrMultiFamily'),
  );
  const nameSpaceURI = 'http://service.amfam.com/costcalcservice/messages/valuation';

  const createXML = useCallback((): string => {
    const ValuationDetails = {
      residential: {
        owner: {
          name: `${lastName}, ${firstName}`,
          addressLine1: line1,
          city: city,
          state: state,
          zipCode: zipcode,
        },
        structureGeneralInfo: {
          yearBuilt: yearBuilt?.toString(),
          structureUse: transformToCCValue(residentType?.toString(), StructureUseMetadata),
        },
        attachedStructure: {
          garage: {
            numberOfCars: `${transformToCCValue(garageSize?.toString(), GarageSizeMetadata)} Car`,
            style: transformToCCValue(garageType?.toString(), GarageTypeMetadata),
          },
        },
      },
    };

    return jsonToXml(ValuationDetails, 'valuation', nameSpaceURI);
  }, [
    city,
    firstName,
    garageSize,
    garageType,
    lastName,
    line1,
    nameSpaceURI,
    residentType,
    state,
    yearBuilt,
    zipcode,
  ]);

  return {
    createXML,
    url: `${env.homeReplacementCostCalculatorRoot}`,
  };
};

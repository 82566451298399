import { Typography } from '@mui/material';

import type { PaginationOption } from '@ecp/components';

import type { SearchResult } from '../types';

export const chunkTPISearchResultsArray = ({
  results,
  pageSize,
}: {
  results: SearchResult[];
  pageSize: number;
}): { tpiDataPerPage: SearchResult[][]; tpiPaginationOptions: PaginationOption[][] } => {
  const tpiDataPerPage: SearchResult[][] = [];
  const tpiPaginationOptions: PaginationOption[][] = [];

  // Initialize chunks
  let currentDataChunk: SearchResult[] = [];
  let currentDescriptionChunk: PaginationOption[] = [];

  results.forEach((tpi, index) => {
    // Add the current TPI object to the current chunk
    currentDataChunk.push(tpi);

    // Add the description and subdescription for the current TPI
    currentDescriptionChunk.push({
      label: <Typography variant='body1Bold'>{tpi.bank.name1}</Typography>,
      description: (
        <Typography variant='body3'>
          {tpi.address.address1} {tpi.address.city}, {tpi.address.state} {tpi.address.zip5}
        </Typography>
      ),
      value: tpi.tpiId,
    });

    // If the current chunk reaches pageSize, push it to the paginated arrays
    if ((index + 1) % pageSize === 0 || index === results.length - 1) {
      tpiDataPerPage.push(currentDataChunk);
      tpiPaginationOptions.push(currentDescriptionChunk);
      // Reset the current chunk arrays
      currentDataChunk = [];
      currentDescriptionChunk = [];
    }
  });

  // Set both paginated data and descriptions
  return { tpiDataPerPage, tpiPaginationOptions };
};

/* eslint-disable react/jsx-no-bind */
import { useCallback } from 'react';

import { Divider, Grid, Typography } from '@mui/material';

import { Button, GridItem } from '@ecp/components';
import { Dialog } from '@ecp/features/sales/shared/components';
import { getThirdPartyInterestRefsOnVeh, useAllTpiDetails } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUIDelete, IconUIEdit } from '@ecp/themes/base';

import { useThirdPartyInterestPageContext } from '../../../../../common/components';
import { useStyles } from './AutoTPICardDetails.styles';

export interface AutoTPICardDetailsProps {
  vehicleRef: string;
}

export const AutoTPICardDetails: React.FC<AutoTPICardDetailsProps> = ({ vehicleRef }) => {
  const { classes } = useStyles();

  const {
    openSingleTpiDialog,
    onEditVehicleTpi,
    onCloseSingleTpiDialogAction,
    onRemoveSingleTpiDialogAction,
    onRemoveTpi,
    isSubmitting,
  } = useThirdPartyInterestPageContext();

  const tpiRefsOnVehicleSelector = useSelector(getThirdPartyInterestRefsOnVeh);
  const tpiRefsOnVehicle = tpiRefsOnVehicleSelector(vehicleRef);
  const tpiDetails = useAllTpiDetails(tpiRefsOnVehicle);

  const removeDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText='Remove tpi?'
      textButtonLabel='Cancel'
      open={openSingleTpiDialog}
      onClose={onCloseSingleTpiDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onRemoveSingleTpiDialogAction}
      isProcessing={isSubmitting}
    >
      <div>The Third Party Interest will be removed.</div>
    </Dialog>
  );

  const renderTpiItem = useCallback(
    (itemRef: string) => {
      const index = tpiRefsOnVehicle.indexOf(itemRef);
      const tpiCompanyName = tpiDetails[index].name;

      return <Typography className={classes.contentTitle}>{tpiCompanyName}</Typography>;
    },
    [tpiRefsOnVehicle, tpiDetails, classes.contentTitle],
  );

  return (
    <GridItem>
      <Divider className={classes.divider} />
      {tpiRefsOnVehicle.map((item) => (
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          key={`summaryitem-${item}`}
        >
          <Grid item container justifyContent='space-between' xs={8.5}>
            {renderTpiItem(item)}
          </Grid>
          <Grid item container justifyContent='space-between' xs={3.5}>
            <GridItem width='50%'>
              <Button
                variant='iconTextExtraLarge'
                icon={<IconUIEdit />}
                title={`Edit-${item}`}
                className={classes.actionButton}
                onClick={() =>
                  onEditVehicleTpi(tpiDetails[tpiRefsOnVehicle.indexOf(item)], vehicleRef)
                }
              >
                Edit
              </Button>
            </GridItem>

            <GridItem width='50%'>
              <Button
                variant='iconTextExtraLarge'
                icon={<IconUIDelete />}
                title={`Remove-${item}`}
                className={classes.actionButton}
                onClick={() => onRemoveTpi(tpiDetails[tpiRefsOnVehicle.indexOf(item)], vehicleRef)}
              >
                Remove
              </Button>
            </GridItem>
          </Grid>
        </Grid>
      ))}
      {removeDialog}
    </GridItem>
  );
};

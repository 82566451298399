import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import type { GeoAddress } from '@ecp/utils/geo';
import { validateAndCombineAddress } from '@ecp/utils/geo';

import { Alert } from '@ecp/components';
import { GridItem, NumberFormat } from '@ecp/components';
import {
  AddressSuggestionsDialog,
  AutoComplete,
  Select,
  TextField,
} from '@ecp/features/sales/shared/components';
import { STATE_CODE_PREFIX } from '@ecp/features/sales/shared/constants';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import type { AddressOptions } from '@ecp/features/sales/shared/store';
import {
  createAddressForUpdate,
  getPrimaryInsuredPersonInfo,
  setFormErrorsChangedByField,
  updateAnswers,
} from '@ecp/features/sales/shared/store';
import { useAddressFields, useAddressSearch } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';

import { useStyles } from './AddressQuestions.styles';

interface Props extends QuestionProps {
  geoAddressSuggestions?: GeoAddress[];
  addressOptionsLabel?: string;
  addressLinelabel: string;
  zipLabel?: string;
  trackingName: string;
  selectedAddressRef: string;
  addressType: string;
  isValidAddress?: boolean;
  setIsValidAddress?: (value: boolean) => void;
  uniqueAddressOptions?: AddressOptions[];
  isAddressOptionsDisabled?: boolean;
  isAddressFieldsDisabled?: boolean;
  showAddressSuggestion?: boolean;
  setShowAddressSuggestion?: (value: boolean) => void;
  onAddressChange?: (value: string) => void;
  handleStateChange?: (value: boolean) => void;
}

export const AddressQuestions: React.FC<Props> = (props) => {
  const {
    geoAddressSuggestions,
    selectedAddressRef,
    addressOptionsLabel,
    addressLinelabel,
    trackingName,
    addressType,
    isValidAddress,
    setIsValidAddress,
    isAddressOptionsDisabled,
    isAddressFieldsDisabled,
    showAddressSuggestion,
    setShowAddressSuggestion,
    uniqueAddressOptions,
    onAddressChange,
    handleStateChange,
    zipLabel = 'ZIP Code',
  } = props;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { firstName } = useSelector(getPrimaryInsuredPersonInfo);
  const {
    address: { line1, line2, city, state, zipcode },
  } = useAddressFields(selectedAddressRef);

  const {
    autoCompleteAddressSuggestions,
    addressSuggestions,
    handleSuggestionsClearRequested,
    handleAddressSuggestionsFetch,
    handleAddressSelection,
    handleAptSuggestionFetchRequested,
    setAddressSuggestions,
    gaTrackSuggestionClick,
  } = useAddressSearch();

  const handleAddressSuggestionFetch = useCallback(
    (value: string) => {
      handleAddressSuggestionsFetch(value, zipcode, state);
    },
    [zipcode, state, handleAddressSuggestionsFetch],
  );

  const handleAddressSuggestion = useCallback(
    async (value: string) => {
      setIsValidAddress?.(true);
      if (
        addressType === 'PRIMARY' &&
        value.match(/\bP(ost|ostal)?([ .]*O(ffice)?)?([ .]*Box)?\b/i)
      ) {
        dispatch(
          setFormErrorsChangedByField({
            key: line1.key,
            errors: ['Address cannot be a PO Box number.'],
          }),
        );
      }

      const adressToBeValiadted = handleAddressSelection(value);
      if (adressToBeValiadted) {
        if (adressToBeValiadted.entries <= 1) {
          line1.props.actionOnComplete(adressToBeValiadted.street_line);
          const inputAddress = {
            street: adressToBeValiadted.street_line,
            city: adressToBeValiadted.city,
            street2: adressToBeValiadted.secondary !== '' ? adressToBeValiadted.secondary : '',
            state: adressToBeValiadted.state,
            zipcode: adressToBeValiadted.zipcode,
          };

          const validatedAddress = await validateAndCombineAddress(
            inputAddress,
            selectedAddressRef,
          );
          if (validatedAddress) {
            line1.props.actionOnComplete(validatedAddress.line1);
            line2.props.actionOnComplete(validatedAddress.line2);
            city.props.actionOnComplete(validatedAddress.city);
            state.props.actionOnComplete(`${STATE_CODE_PREFIX}${validatedAddress.state}`);
            zipcode.props.actionOnComplete(validatedAddress.zipcode);
            const addressToBeSaved = createAddressForUpdate(validatedAddress);
            await dispatch(updateAnswers({ answers: { ...addressToBeSaved } }));
          }
        } else {
          line1.props.actionOnComplete(adressToBeValiadted.street_line);
          // need to pass selected address as well as the letter A to limit the results to number of valid entries recieved for address.
          // Will  return # of entries whose secondary value starts with A.
          const selectedValue = `${adressToBeValiadted.street_line} ${adressToBeValiadted.secondary} A (${adressToBeValiadted.entries}) ${adressToBeValiadted.city} ${adressToBeValiadted.state} ${adressToBeValiadted.zipcode}`;
          // If selected address is a series of apartments
          const fetchNewAptSuggestions = await handleAptSuggestionFetchRequested(
            adressToBeValiadted.street_line,
            selectedValue,
          );

          setAddressSuggestions(fetchNewAptSuggestions);
        }
      }
    },
    [
      selectedAddressRef,
      addressType,
      city.props,
      dispatch,
      handleAddressSelection,
      handleAptSuggestionFetchRequested,
      line1.key,
      line1.props,
      line2.props,
      setAddressSuggestions,
      setIsValidAddress,
      state.props,
      zipcode.props,
    ],
  );

  const handlePoBoxValidation = useCallback(
    async (value: string) => {
      if (
        addressType !== 'MAILING' &&
        value.match(/\bP(ost|ostal)?([ .]*O(ffice)?)?([ .]*Box)?\b/i)
      ) {
        dispatch(
          setFormErrorsChangedByField({
            key: line2.key,
            errors: ['Address cannot be a PO Box number.'],
          }),
        );
      }
    },
    [addressType, dispatch, line2.key],
  );

  return (
    <>
      {uniqueAddressOptions && (
        <Grid>
          <Grid>
            <GridItem>
              <Select
                label={addressOptionsLabel}
                id={addressType}
                options={uniqueAddressOptions}
                value={selectedAddressRef}
                actionOnChange={onAddressChange}
                disabled={isAddressOptionsDisabled}
              />
            </GridItem>
          </Grid>
        </Grid>
      )}

      {!isAddressOptionsDisabled && (
        <Grid className={classes.addressBox}>
          <Grid container>
            <Grid container columnSpacing={4}>
              {line1.exists && (
                <GridItem xs={12} md={6}>
                  <AutoComplete
                    {...line1.props}
                    label={addressLinelabel}
                    suggestions={autoCompleteAddressSuggestions}
                    geoAddressFormattedSuggestions={addressSuggestions}
                    onSuggestionsClearRequested={handleSuggestionsClearRequested}
                    onSuggestionsFetchRequested={handleAddressSuggestionFetch}
                    onSuggestionSelected={handleAddressSuggestion}
                    id='AddressAutoComplete'
                    trackingName={trackingName + `_line_1`}
                    trackingLabel={GoogleAnalyticsLabels.REDACTED}
                    gaTrackSuggestionClick={gaTrackSuggestionClick}
                    disabled={isAddressFieldsDisabled}
                  />
                </GridItem>
              )}
              {line2.exists && (
                <GridItem xs={12} md={6}>
                  <TextField
                    {...line2.props}
                    id='Apt'
                    label='Apt./Unit # (optional)'
                    trackingName={trackingName + `_line_2`}
                    trackingLabel={GoogleAnalyticsLabels.REDACTED}
                    actionOnComplete={handlePoBoxValidation}
                    disabled={isAddressFieldsDisabled}
                  />
                </GridItem>
              )}
              {city.exists && (
                <GridItem topSpacing='sm' xs={12} md={state.exists ? 4 : 6}>
                  <TextField
                    {...city.props}
                    id='city'
                    label='City'
                    trackingName={trackingName + `_city`}
                    trackingLabel={city.props.value}
                    disabled={isAddressFieldsDisabled}
                  />
                </GridItem>
              )}
              {state.exists && (
                <GridItem topSpacing='sm' xs={12} md={4}>
                  <Select
                    {...(state.props as OptionProps)}
                    id='state'
                    label='State'
                    trackingName={trackingName + `_state`}
                    trackingLabel={state.props.value}
                    disabled={isAddressFieldsDisabled}
                    actionOnComplete={handleStateChange}
                  />
                </GridItem>
              )}
              {zipcode.exists && (
                <GridItem topSpacing='sm' xs={12} md={state.exists ? 4 : 6}>
                  <NumberFormat
                    {...zipcode.props}
                    id='Zip'
                    formatType='zipcode'
                    label={zipLabel}
                    trackingName={trackingName + `_zip_code`}
                    disabled={isAddressFieldsDisabled}
                  />
                </GridItem>
              )}

              {!isValidAddress && (
                <GridItem topSpacing='sm' xs={12}>
                  <Alert withIcon type='error'>
                    We could not find a match in the the United States Postal Service database for{' '}
                    {firstName}’s address. Please verify that the entered address is correct.
                  </Alert>
                </GridItem>
              )}

              {showAddressSuggestion && (
                <GridItem topSpacing='sm' xs={12}>
                  <AddressSuggestionsDialog
                    geoAddressSuggestions={geoAddressSuggestions}
                    addressRef={selectedAddressRef}
                    setShowAddressSuggestion={setShowAddressSuggestion}
                  />
                </GridItem>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

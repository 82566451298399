import { useCallback, useRef } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import { Grid } from '@mui/material';

import { GridItem, RadioGroupWithOptions } from '@ecp/components';
import { Resource, Select } from '@ecp/features/sales/shared/components';
import {
  setFormErrorsChangedByField,
  useFieldWithPrefix,
  useForm,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Garage } from '@ecp/features/sales/shared/types';

import { useGarageOptions, useGarageRefValues, useGarages } from '../garageUtil';
import { useStyles } from './GaragesFormQuestion.styles';
interface GarageFormProps {
  itemRef: string;
  onNext: () => void;
  garageRef: string;
  editItemRef: string;
  onCancel: (garageRef: string) => void;
  setOpenRemoveDialog: Dispatch<SetStateAction<boolean>>;
  setActionInfoMessage: Dispatch<SetStateAction<string>>;
  setAddingStat: Dispatch<SetStateAction<boolean>>;
  setActionType: Dispatch<SetStateAction<'NONE' | 'ADD' | 'REMOVE'>>;
  garagesList: Array<Garage>;
}

export const GaragesFormQuestion: React.FC<GarageFormProps> = (props) => {
  const {
    itemRef: garageRef,
    editItemRef,
    setOpenRemoveDialog,
    setActionType,
    setActionInfoMessage,
    setAddingStat,
    onNext,
    onCancel,
  } = props;

  const { classes } = useStyles();

  const dispatch = useDispatch();

  const { typeMap, sizeMap } = useGarageOptions();

  const garagesField = useFieldWithPrefix(garageRef, 'garage.<id>');

  const garageType = garagesField('type');
  const garageSize = garagesField('size');

  const initValues = useRef({});
  const { validateForm: validateGarageForm, patchFormValues: patchGarageFormValues } = useForm({
    fields: {
      type: garageType,
      size: garageSize,
    },
    initValues,
    conditions: [],
  });

  const garagesRefsValue = useGarageRefValues('property.garage.ref');

  const garageList = useGarages();

  const GaragesByRef: { [key: string]: (typeof garageList)[number] } = garageList?.reduce(
    (acc: Record<string, (typeof garageList)[number]>, garage) => {
      acc[garage.ref] = garage;

      return acc;
    },
    {},
  );
  const transformGarageArray = useCallback(() => {
    const result: { [key: string]: number } = {};
    garageType.props.options &&
      garageType.props.options.forEach((item) => {
        result[item.value] = item.sortingKey || 0;
      });

    return result;
  }, [garageType]);
  const filteredOptions = useCallback(
    (type: string, keymap: string) => {
      const maps = [];
      garagesRefsValue.forEach((item) => {
        if (type === 'type' && GaragesByRef[item].type === keymap) {
          if (!(editItemRef && editItemRef === item)) {
            maps.push(keymap);
          }
        }
      });
      if (maps.length > 0) {
        return false;
      }

      return true;
    },
    [GaragesByRef, editItemRef, garagesRefsValue],
  );
  const getOptions = useCallback((type: string) => {
    const keymap = type === 'type' ? typeMap : sizeMap;
    const transformedTypeArray = transformGarageArray();
    const filteredObjectKeys = Object.keys(keymap).filter((key) => {
      return filteredOptions(type, keymap[key]);
    });

    return filteredObjectKeys.map((key) => ({
      value: key,
      label: keymap[key],
      sortingKey: transformedTypeArray[key],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelAction = useCallback(() => {
    if (!garageSize.props.value && !garageType.props.value) {
      setOpenRemoveDialog(false);
    }
    onCancel(editItemRef || garageRef);
  }, [
    editItemRef,
    garageRef,
    garageSize.props.value,
    garageType.props.value,
    onCancel,
    setOpenRemoveDialog,
  ]);

  const onValidateGarageFields = useCallback(() => {
    let isValid = true;
    if (!garageType?.value) {
      dispatch(setFormErrorsChangedByField({ key: garageType?.key, errors: ['Required field'] }));
      isValid = false;
    }
    if (!garageSize?.value) {
      dispatch(setFormErrorsChangedByField({ key: garageSize?.key, errors: ['Required field'] }));
      isValid = false;
    }

    return isValid;
  }, [dispatch, garageSize?.key, garageSize?.value, garageType?.key, garageType?.value]);

  const handleSaveAction = useCallback(async () => {
    const { isValid } = validateGarageForm();

    const affiliationFieldValid = onValidateGarageFields();
    if (isValid && affiliationFieldValid) {
      await patchGarageFormValues();
      garageType.props.actionOnChange(garageType.value);
      garageSize.props.actionOnChange(garageSize.value);
      onNext();
      setActionType('ADD');
      setActionInfoMessage('Attached Garage or Carport saved');
      setAddingStat(garagesRefsValue.length === Object.keys(typeMap).length);
    }
  }, [
    garageSize,
    garageType,
    garagesRefsValue.length,
    onNext,
    onValidateGarageFields,
    patchGarageFormValues,
    setActionInfoMessage,
    setActionType,
    setAddingStat,
    typeMap,
    validateGarageForm,
  ]);

  return (
    <Grid className={classes.gridRoot}>
      <GridItem topSpacing='sm' xs={12} className={classes.headerText}>
        Tell us about the garage or carport.
      </GridItem>
      <GridItem topSpacing='sm'>
        <RadioGroupWithOptions
          {...garageType.props}
          label={garageType.question.title}
          options={getOptions('type')}
          data-testid='garageType'
          trackingName='garage_type_options'
          id='garageType'
          className={classes.radiobox}
          trackingLabel='garage_type_options'
          sortByKey
        />
      </GridItem>
      <GridItem xs={12} topSpacing='sm'>
        <Select
          {...garageSize.props}
          id='garageSize'
          inputButtonAriaLabel='Garage Size'
          options={getOptions('size')}
          groupLabel={garageSize.question.title}
          fullWidth={false}
          data-testid='garageSize'
          trackingName='garage_size_selection'
          trackingLabel='garage_size_options'
        />
      </GridItem>
      <GridItem xs={12} topSpacing='sm'>
        <div className={classes.actionButtons}>
          <Resource.CancelButton
            onClick={handleCancelAction}
            data-testid='garageItemCancel'
            trackingName='garage_cancel'
            trackingLabel='garage_cancel'
            analyticsElement='choice.garage.cancelButton'
          >
            Cancel
          </Resource.CancelButton>
          <Resource.SaveButton
            onClick={handleSaveAction}
            data-testid='garageItemSave'
            trackingName='garage_save'
            trackingLabel='garage_save'
            analyticsElement='choice.garage.saveButton'
          >
            SAVE
          </Resource.SaveButton>
        </div>
      </GridItem>
    </Grid>
  );
};

import { useCallback, useState } from 'react';

import { GridItem, RadioGroupWithOptions, SnackbarAlert, TooltipWithIcon } from '@ecp/components';
import { Snackbar } from '@ecp/components';
import { Dialog, Resource } from '@ecp/features/sales/shared/components';
import type { Affiliation } from '@ecp/features/sales/shared/store';
import {
  getAnswer,
  useAddAffiliation,
  useAffiliation,
  useAffiliations,
  useFieldWithPrefix,
  useGetAffiliationFields,
  useRemoveAffiliation,
  useRemoveAllAffiliation,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, PageErrors } from '@ecp/features/sales/shared/types';

import { AffiliationQuestion } from '../../../components';
import { useStyles } from './AffiliationFormQuestion.styles';

export interface AffiliationFormQuestionProps {
  namedInsuredRef: string;
}

export const AffiliationFormQuestion: React.FC<AffiliationFormQuestionProps> = (props) => {
  const { namedInsuredRef } = props;

  const { classes } = useStyles();
  const [affiliationRef, setAffiliationRef] = useState('');
  const [addingAffiliation, setAddingAffiliation] = useState(false);
  const [affiliationActionInfoMessage, setAffiliationActionInfoMessage] = useState('');
  const [editAffiliationRef, setEditAffiliationtRef] = useState('');
  const [openAffiliationDialog, setOpenAffiliationDialog] = useState(false);
  const [removeAllAffiliation, setRemoveAllAffiliation] = useState(false);
  const [pageErrors] = useState<PageErrors[]>([]);
  const [removeAllAffiliationAtOnceMsg, setRemoveAllAffiliationAtOnceMsg] = useState(false);
  const [affiliationToBeRemoved, setAffiliationToBeRemoved] = useState<Affiliation>();
  const currentAffiliation = useAffiliation(editAffiliationRef || affiliationRef);
  const affiliationList = useAffiliations(namedInsuredRef || '');
  const affiliationFields = useGetAffiliationFields(editAffiliationRef);
  const removeAffiliation = useRemoveAffiliation(namedInsuredRef || '');
  const removeCurrentAffiliation = useRemoveAffiliation('');
  const removeAllAffiliations = useRemoveAllAffiliation(namedInsuredRef);
  const [affiliationDataBeforeEdit, setAffiliationDataBeforeEdit] = useState<
    Affiliation | undefined
  >();
  const [affiliationActionInfoType, setAffiliationActionInfoType] = useState<
    'NONE' | 'ADD' | 'REMOVE'
  >('NONE');
  const addAffiliation = useAddAffiliation(namedInsuredRef);

  const {
    affiliation: { affiliationType, group },
  } = affiliationFields;

  const affiliationRefsKey = `${namedInsuredRef}.affiliation.ref`;
  const affiliationRefsValue =
    (useSelector((state: RootStore) => getAnswer(state, affiliationRefsKey)) as Array<string>) ||
    [];
  const [hasAffiliation, setHasAffiliation] = useState(affiliationRefsValue?.length > 0);

  const usePersonField = useFieldWithPrefix(namedInsuredRef, 'person.<id>');
  const {
    props: { value: firstName },
  } = usePersonField('firstName');

  const AffiliationsByRef: { [key: string]: (typeof affiliationList)[number] } =
    affiliationList?.reduce(
      (acc: Record<string, (typeof affiliationList)[number]>, affiliation) => {
        acc[affiliation.ref] = affiliation;

        return acc;
      },
      {},
    );

  const handleAddAffiliation = useCallback(() => {
    setAffiliationRef(addAffiliation());
    setAddingAffiliation(true);
  }, [addAffiliation]);

  const onRemoveAffiliation = useCallback(
    (affiliationRef: string) => {
      const affiliation = AffiliationsByRef[affiliationRef];
      setAffiliationToBeRemoved(affiliation);
      setOpenAffiliationDialog(true);
      setRemoveAllAffiliationAtOnceMsg(false);
    },
    [AffiliationsByRef],
  );

  const onEditAffiliation = useCallback(
    (affiliationRef: string) => {
      const affiliation = AffiliationsByRef[affiliationRef];
      setEditAffiliationtRef(affiliationRef);
      setAffiliationDataBeforeEdit({ ...affiliation });
    },
    [AffiliationsByRef],
  );

  const onCloseDialogAction = useCallback(() => {
    setOpenAffiliationDialog(false);
  }, []);

  const onRemoveDialogAction = useCallback(() => {
    if (affiliationToBeRemoved) {
      removeAffiliation(affiliationToBeRemoved);
    }
    if (currentAffiliation) {
      removeCurrentAffiliation(currentAffiliation);
    }
    if (removeAllAffiliation) {
      setHasAffiliation(false);
      removeAllAffiliations();
    }

    group.validateUpdateAndPatch('');
    affiliationType.validateUpdateAndPatch('');
    setAddingAffiliation(false);
    setOpenAffiliationDialog(false);
    setAffiliationActionInfoMessage('Affiliation removed.');
    setAffiliationActionInfoType('REMOVE');
    setAffiliationRef('');
    setEditAffiliationtRef('');
    setAffiliationDataBeforeEdit(undefined);
    setAffiliationToBeRemoved(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    affiliationDataBeforeEdit,
    removeAllAffiliation,
    affiliationType,
    affiliationToBeRemoved,
    currentAffiliation,
    group,
    removeAffiliation,
    removeCurrentAffiliation,
    removeAllAffiliations,
  ]);

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason !== 'clickaway') {
        setAffiliationActionInfoMessage('');
        setAffiliationActionInfoType('NONE');
      }
    },
    [],
  );

  const snackbarDefault = affiliationActionInfoType === 'REMOVE' && (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={!!affiliationActionInfoMessage}
      autoHideDuration={3000}
      message={affiliationActionInfoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const snackbarSuccess = affiliationActionInfoType === 'ADD' && (
    <SnackbarAlert
      open
      autoHideDuration={3000}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
      severity='success'
      message={affiliationActionInfoMessage}
      hideActionButton
    />
  );

  const removeDialog = (
    <Dialog
      actionButtonLabel='REMOVE'
      titleText='Remove affiliation?'
      textButtonLabel='Cancel'
      open={openAffiliationDialog}
      onClose={onCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onRemoveDialogAction}
      hideTitleCloseButton
      hideDivider
    >
      {removeAllAffiliationAtOnceMsg ? (
        <div>{`All of ${firstName}'s affiliations will be removed from all policies.`}</div>
      ) : (
        <div>The affiliation will be removed from all policies.</div>
      )}
    </Dialog>
  );

  const handleCancelAffiliationRef = useCallback(
    async (selectedAffiliationRef: string) => {
      if (selectedAffiliationRef) {
        setOpenAffiliationDialog(true);
        const affiliation = AffiliationsByRef[selectedAffiliationRef];
        setAffiliationToBeRemoved(affiliation);
      }
    },
    [AffiliationsByRef],
  );

  const handleSaveAffiliationRef = useCallback(() => {
    setAddingAffiliation(false);
    setAffiliationRef('');
    setEditAffiliationtRef('');
  }, []);

  const handleHasAffiliationChange = useCallback(
    async (value: AnswerValue) => {
      if (!value && hasAffiliation) {
        // user changed from YES to NO, so show dialog to remove any affiliation
        setOpenAffiliationDialog(true);
        setRemoveAllAffiliation(true);
        affiliationRefsValue.length > 1
          ? setRemoveAllAffiliationAtOnceMsg(true)
          : setRemoveAllAffiliationAtOnceMsg(false);
      } else if (value && affiliationRefsValue.length < 1 && !hasAffiliation) {
        // User clicked from NO to YES, so add an initial affiliation
        setAddingAffiliation(true);
        setAffiliationRef(addAffiliation());
        setHasAffiliation(!!value);
        setRemoveAllAffiliation(false);
      }
    },
    [addAffiliation, affiliationRefsValue.length, hasAffiliation],
  );
  const renderAffiliationItem = useCallback(
    (item: string) => (
      <>
        <Resource.Item
          xs={4}
          title='Affinity Group'
          value={AffiliationsByRef[item]?.group ? AffiliationsByRef[item].group : ''}
        />
        <Resource.Item
          xs={7}
          title='Affinity Type'
          value={
            AffiliationsByRef[item]?.affiliationType ? AffiliationsByRef[item].affiliationType : ''
          }
        />
      </>
    ),
    [AffiliationsByRef],
  );

  return (
    <>
      <GridItem xs={12} bottomSpacing='sm' className={classes.helperText}>
        <RadioGroupWithOptions
          actionOnComplete={handleHasAffiliationChange}
          value={hasAffiliation}
          label={
            <>
              Does {firstName} have any affiliations?
              <TooltipWithIcon title='Policy Level Discounts' />
            </>
          }
          id='hasAffiliations'
          variant='yesNoButton'
          trackingName='hasAffiliation_selection'
        />
      </GridItem>
      {hasAffiliation && (
        <>
          <Resource.List
            items={affiliationRefsValue}
            editItemRef={editAffiliationRef || affiliationRef}
            onEdit={onEditAffiliation}
            onDelete={onRemoveAffiliation}
            onCancel={handleCancelAffiliationRef}
            onNext={handleSaveAffiliationRef}
            pageErrors={pageErrors}
            // eslint-disable-next-line react/jsx-no-bind
            form={(formProps) => (
              <AffiliationQuestion
                {...formProps}
                namedInsuredRef={namedInsuredRef}
                affiliationRef={affiliationRef}
                setAddingAffiliation={setAddingAffiliation}
                setAffiliationActionInfoMessage={setAffiliationActionInfoMessage}
                setAffiliationActionInfoType={setAffiliationActionInfoType}
                editAffiliationRef={editAffiliationRef}
                setOpenAffiliationDialog={setOpenAffiliationDialog}
                affiliationList={affiliationList}
              />
            )}
            renderListItem={renderAffiliationItem}
          />
          {group.props.options && affiliationList.length <= group.props.options?.length - 1 && (
            <Resource.AddButton
              onClick={handleAddAffiliation}
              disabled={addingAffiliation}
              data-testid='AffiliationRefAdd'
            >
              ADD ANOTHER AFFILIATION
            </Resource.AddButton>
          )}
        </>
      )}
      {removeDialog}
      {snackbarDefault}
      {snackbarSuccess}
    </>
  );
};

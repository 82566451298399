import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { parseDollar, upperFirst } from '@ecp/utils/common';

import { Alert, GridItem, Snackbar, SnackbarAlert } from '@ecp/components';
import {
  useAclReportForPerson,
  useAllIncidents,
  useMvrReportForPerson,
} from '@ecp/features/sales/quotes/auto';
import type { ResourceFormProps } from '@ecp/features/sales/shared/components';
import { Dialog, Form, Link, Resource } from '@ecp/features/sales/shared/components';
import { AUTO_POLICY_HOUSEHOLD_LOSSES } from '@ecp/features/sales/shared/constants';
import {
  getDriverInfo,
  getHasLossesInThePastFiveYears,
  getLineOfBusiness,
  usePniRef,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { isLineOfBusinessAuto } from '@ecp/features/shared/product';
import { IconAfiProductAuto } from '@ecp/themes/partners/amfam-adv';

import type { PolicyLoss } from '../../../../common/utils';
import {
  useGetPolicyLossFields,
  usePolicyLoss,
  usePolicyLosses,
  useRemovePolicyLoss,
} from '../../../../common/utils';
import { PolicyLossQuestions } from '../../components';
import { DriverLossAndViolationForm } from '../DriverLossAndViolationForm';
import { useStyles } from './AutoThirdPartyReportsPageForm.styles';

export const AutoThirdPartyReportsPageForm: React.FC = () => {
  const { classes } = useStyles();
  const drivers = useSelector(getDriverInfo);
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const [policyLossRef, setPolicyLossRef] = useState('');
  const [policyLossActionInfoMessage, setPolicyLossActionInfoMessage] = useState('');
  const [editPolicyLossRef, setEditPolicyLossRef] = useState('');
  const [policyLossBeforeEdit, setPolicyLossBeforeEdit] = useState<PolicyLoss | undefined>();
  const [openPolicyLossDialog, setOpenPolicyLossDialog] = useState(false);
  const [policyLossActionInfoType, setPolicyLossActionInfoType] = useState<
    'NONE' | 'ADD' | 'REMOVE'
  >('NONE');
  const [policyLossToBeRemoved, setPolicyLossToBeRemoved] = useState<PolicyLoss>();
  const currentPolicyLoss = usePolicyLoss(editPolicyLossRef || policyLossRef);
  const policyLossesList = usePolicyLosses('auto', 'accidentOrClaim');
  const policyLossFields = useGetPolicyLossFields(editPolicyLossRef || policyLossRef);
  const removePolicyLoss = useRemovePolicyLoss();
  const [noLossesText, setNoLossesText] = useState(policyLossesList.length === 0);
  /**
   * TODO: for now we can check indicator. Later indicator will be updated by reports that are ordered after EDSP-11091
   *   If we need to update this with logic with a count of accidents/claims we could do it then, but
   *   nay not be needed, as the indicator would be updated.
   *   SEE EDSP-11091 and update when that is done.
   */
  const hasIndicatedLosses = useSelector((state: RootStore) =>
    getHasLossesInThePastFiveYears(state, AUTO_POLICY_HOUSEHOLD_LOSSES),
  );
  // ACL & MVR is 1 report url per household, and sapi is sending with PNI ID
  const pniRef = usePniRef();
  const aclReport = useAclReportForPerson(pniRef);
  const mvrReport = useMvrReportForPerson(pniRef);

  const actionBarItems = !!aclReport && (
    <Grid container marginLeft={4} className={classes.actionBarContent}>
      <GridItem sm={6}>
        <Link className={classes.link} href={aclReport} target='_blank' rel='noreferrer'>
          VIEW ACL
        </Link>
      </GridItem>
      {!!mvrReport && (
        <GridItem sm={6}>
          <Link className={classes.link} href={mvrReport} target='_blank' rel='noreferrer'>
            VIEW MVR
          </Link>
        </GridItem>
      )}
    </Grid>
  );

  // Sums up an array made from the violations/incidents on each driver
  const totalDriverIncidents = useAllIncidents(drivers).length;
  // Fetch driver loss and violation form per each driver
  const driversLossesAndViolations = drivers.map((driver) => {
    return <DriverLossAndViolationForm disableAddingIncidents key={driver.ref} driver={driver} />;
  });

  const {
    accidentOrClaim: { lossDate, lossDescription, lossAmountUserEntered, status, source },
  } = policyLossFields;

  const refsSortedByEndDate = policyLossesList
    .sort((a, b) => new Date(b.lossDate).valueOf() - new Date(a.lossDate).valueOf())
    .map((policyLoss) => policyLoss.ref);

  // Check for page, then check if report was ordered, if yes, pull losses from report and put on it's own array, append array to this(below)
  const policyLossByRef: { [key: string]: (typeof policyLossesList)[number] } =
    policyLossesList?.reduce(
      (acc: Record<string, (typeof policyLossesList)[number]>, policyLoss) => {
        acc[policyLoss.ref] = policyLoss;

        return acc;
      },
      {},
    );
  const onEditPolicyLoss = useCallback(
    (policyLossRef: string) => {
      const policyLoss = policyLossByRef[policyLossRef];
      setEditPolicyLossRef(policyLossRef);
      setPolicyLossBeforeEdit({ ...policyLoss });
    },
    [policyLossByRef],
  );
  const hasPolicyLossPrefill = useCallback(
    (ref: string) =>
      policyLossesList.find((policyLoss) => policyLoss.ref === ref)?.source === 'REPORT',
    [policyLossesList],
  );

  const onRemovePolicyLoss = useCallback(
    (policyLossRef: string) => {
      const policyLoss = policyLossByRef[policyLossRef];
      setPolicyLossToBeRemoved(policyLoss);
      setOpenPolicyLossDialog(true);
    },
    [policyLossByRef],
  );
  const renderPolicyLossForm = useCallback(
    (formProps: ResourceFormProps) => (
      <PolicyLossQuestions
        {...formProps}
        policyLossRef={policyLossRef}
        setPolicyLossActionInfoMessage={setPolicyLossActionInfoMessage}
        setPolicyLossActionInfoType={setPolicyLossActionInfoType}
        editPolicyLossRef={editPolicyLossRef}
        setOpenPolicyLossDialog={setOpenPolicyLossDialog}
        policyLossList={policyLossesList}
      />
    ),
    [
      policyLossRef,
      setPolicyLossActionInfoMessage,
      setPolicyLossActionInfoType,
      editPolicyLossRef,
      setOpenPolicyLossDialog,
      policyLossesList,
    ],
  );
  const handleSavePolicyLoss = useCallback(() => {
    setPolicyLossRef('');
    setEditPolicyLossRef('');
  }, []);

  const handleCancelPolicyLoss = useCallback(
    async (selectedPolicyLossRef: string) => {
      // if cancel selected while adding device and if there are any data entered then have to show remove dialog.
      if (selectedPolicyLossRef) {
        const policyLoss = policyLossByRef[selectedPolicyLossRef];
        setPolicyLossToBeRemoved(policyLoss);
      } else {
        // Dont show remove dialog if there are no data loss
        if (editPolicyLossRef && policyLossBeforeEdit) {
          lossDate.validateUpdateAndPatch(policyLossBeforeEdit.lossDate);
          lossAmountUserEntered.validateUpdateAndPatch(policyLossBeforeEdit.lossAmountUserEntered);
          if (policyLossBeforeEdit.lossDescription) {
            const lossDescriptionBeforeEdit = lossDescription.question.options?.find(
              (option) => option.label === policyLossBeforeEdit.lossDescription,
            )?.value;
            if (lossDescriptionBeforeEdit) {
              lossDescription.validateUpdateAndPatch(lossDescriptionBeforeEdit);
            }
          }
          status.validateUpdateAndPatch(policyLossBeforeEdit.status);
          source.validateUpdateAndPatch(policyLossBeforeEdit.source);
        } else {
          removePolicyLoss(currentPolicyLoss);
        }
        setPolicyLossRef('');
        setEditPolicyLossRef('');
        setOpenPolicyLossDialog(false);
        setPolicyLossBeforeEdit(undefined);
        setPolicyLossToBeRemoved(undefined);
      }
    },
    [
      policyLossByRef,
      currentPolicyLoss,
      editPolicyLossRef,
      lossAmountUserEntered,
      lossDate,
      lossDescription,
      removePolicyLoss,
      source,
      status,
      policyLossBeforeEdit,
    ],
  );

  const renderPolicyLossRefItem = useCallback(
    (item: string) => {
      const policyLossItem = policyLossesList.find((policyLoss) => policyLoss.ref === item);

      return (
        <>
          <Resource.Item
            xs={2}
            title='Loss Date'
            value={
              policyLossItem && policyLossItem.lossDate
                ? `${dayjs(policyLossItem.lossDate).format('MM/DD/YYYY')}`
                : ' '
            }
          />
          <Resource.Item xs={2} title='Loss Description' value={policyLossItem?.lossDescription} />
          <Resource.Item
            xs={2}
            title='Amount'
            value={parseDollar(policyLossItem?.lossAmountUserEntered)}
          />
          <Resource.Item xs={2} title='Status' value={upperFirst(policyLossItem?.status) || ''} />
          <Resource.Item
            xs={2}
            title='Source'
            value={policyLossItem?.source === 'REPORT' ? 'Report' : 'Insured'} // Leave as is until EDSP-11091 is implemented, otherwise reads incorrectly
          />
        </>
      );
    },
    [policyLossesList],
  );

  const handleInfoSnackbarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string): void => {
      if (reason === 'clickaway') {
        return;
      }
      setPolicyLossActionInfoMessage('');
      setPolicyLossActionInfoType('NONE');

      return;
    },
    [],
  );

  const snackbarDefault = policyLossActionInfoType === 'REMOVE' && (
    <Snackbar
      classes={{ root: classes.snackBarWidth }}
      open={!!policyLossActionInfoMessage}
      autoHideDuration={3000}
      message={policyLossActionInfoMessage}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
    />
  );

  const snackbarSuccess = policyLossActionInfoType === 'ADD' && (
    <SnackbarAlert
      open
      autoHideDuration={3000}
      vertical='bottom'
      horizontal='center'
      onClose={handleInfoSnackbarClose}
      severity='success'
      message={policyLossActionInfoMessage}
      hideActionButton
    />
  );

  const onCloseDialogAction = useCallback(() => {
    setOpenPolicyLossDialog(false);
  }, []);
  const onRemoveDialogAction = useCallback(() => {
    setOpenPolicyLossDialog(false);
    setPolicyLossActionInfoMessage('Loss removed.');
    setPolicyLossActionInfoType('REMOVE');

    if (policyLossBeforeEdit) {
      // restore old values as user has clicked on discard
      lossDate.validateUpdateAndPatch(policyLossBeforeEdit.lossDate);
      lossAmountUserEntered.validateUpdateAndPatch(policyLossBeforeEdit.lossAmountUserEntered);
      lossDescription.validateUpdateAndPatch(policyLossBeforeEdit.lossDescription);
      status.validateUpdateAndPatch(policyLossBeforeEdit.status);
      source.validateUpdateAndPatch(policyLossBeforeEdit.source);
    } else {
      if (policyLossToBeRemoved) {
        removePolicyLoss(policyLossToBeRemoved);
      }
      lossDate.validateUpdateAndPatch('');
      lossAmountUserEntered.validateUpdateAndPatch('');
      lossDescription.validateUpdateAndPatch('');
      status.validateUpdateAndPatch('');
      source.validateUpdateAndPatch('');
    }
    setEditPolicyLossRef('');
    setPolicyLossBeforeEdit(undefined);
    setPolicyLossToBeRemoved(undefined);
  }, [
    policyLossBeforeEdit,
    lossDate,
    lossAmountUserEntered,
    lossDescription,
    status,
    source,
    policyLossToBeRemoved,
    removePolicyLoss,
  ]);

  const removeDialog = (
    <Dialog
      actionButtonLabel='REMOVE LOSS'
      titleText='Remove loss?'
      textButtonLabel='Cancel'
      open={openPolicyLossDialog}
      onClose={onCloseDialogAction}
      buttonPlacement='right'
      actionButtonOnClick={onRemoveDialogAction}
      hideTitleCloseButton
      hideDivider
    >
      <div>Any associated content will be removed.</div>
    </Dialog>
  );
  /**
   * To display text secondary text under Policy Losses header
   */
  useEffect(() => {
    const hasNoLosses = policyLossesList.length === 0;
    if (noLossesText !== hasNoLosses) {
      setNoLossesText(hasNoLosses);
    }
  }, [policyLossesList, noLossesText]);

  if (!isLineOfBusinessAuto(lineOfBusiness)) {
    return null;
  }

  const totalInsuredAddedIncidents = policyLossesList.length + totalDriverIncidents;
  const totalPrefillAddedIncidents: number = 0; // update when prefill call is working to fetch length of returned prefill violations list;
  const lossesAndViolationsInfoMessage = (
    <p>
      Third-party reports returned {totalPrefillAddedIncidents} new incident
      {totalPrefillAddedIncidents === 1 ? '' : 's'} and {totalInsuredAddedIncidents} incidents that
      {totalInsuredAddedIncidents === 1 ? ' was ' : ' were '}
      previously shared.
    </p>
  );
  const noPolicyLossesMessage = 'No policy losses.';
  const noPolicyLossesFoundInReportsMessage = 'No policy losses found in third-party reports.';

  return (
    <>
      <Form showBackdrop={false}>
        <Grid container>
          <Grid marginBottom={8} container>
            <GridItem sm={1}>
              <IconAfiProductAuto className={classes.productIcon} />
            </GridItem>
            <GridItem sm={7}>
              <h2>Auto Policy Report Results</h2>
            </GridItem>
            <GridItem sm={4}>{actionBarItems}</GridItem>
          </Grid>
          <Alert className={classes.infoBanner} withIcon type='info'>
            {lossesAndViolationsInfoMessage}
          </Alert>
          <Grid xs={12} className={classes.policyLossesContainer}>
            <GridItem>
              <div className={classes.policyHeader}>Policy Losses</div>
            </GridItem>
            {noLossesText ? (
              <p className={classes.secondaryText}>
                {!hasIndicatedLosses ? noPolicyLossesMessage : noPolicyLossesFoundInReportsMessage}
              </p>
            ) : (
              <br />
            )}
          </Grid>
          <Resource.List
            items={refsSortedByEndDate}
            editItemRef={editPolicyLossRef || policyLossRef}
            onEdit={onEditPolicyLoss}
            hideEdit={hasPolicyLossPrefill}
            hideDelete={hasPolicyLossPrefill}
            pageErrors={[]}
            onDelete={onRemovePolicyLoss}
            onCancel={handleCancelPolicyLoss}
            onNext={handleSavePolicyLoss}
            form={renderPolicyLossForm}
            renderListItem={renderPolicyLossRefItem}
          />
          <Grid container>{driversLossesAndViolations}</Grid>
        </Grid>
      </Form>
      {removeDialog}
      {snackbarDefault}
      {snackbarSuccess}
    </>
  );
};

import { useCallback, useEffect } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { HOME_POLICY_HOUSEHOLD_LOSSES, NavStatus } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { getHasLossesInThePastFiveYears, updatePageStatus } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import { HomeOtherStructuresPageForm } from '../../forms';

export const HomeOtherStructuresPage: React.FC = () => {
  const priorLossesAndViolations = useSelector((state: RootStore) =>
    getHasLossesInThePastFiveYears(state, HOME_POLICY_HOUSEHOLD_LOSSES),
  );
  const dispatch = useDispatch();
  const nextPage = priorLossesAndViolations ? PagePath.HOME_LOSSES : PagePath.HOME_DISCOUNTS;
  const navigateToNextPage = useNavigateToPage(nextPage);
  const handleNext = useCallback(async () => {
    if (!priorLossesAndViolations) {
      dispatch(updatePageStatus(NavStatus.VALID, PagePath.HOME_LOSSES));
    }
    await navigateToNextPage();
  }, [dispatch, navigateToNextPage, priorLossesAndViolations]);

  useEffect(() => {
    trackSapiAnalyticsEvent({
      element: 'choice.homeOtherStructuresPage.page',
      event: 'render',
      eventDetail: 'true',
    });
  }, []);

  return (
    <Page
      title='Other Structures'
      sidebarProps={{
        drawer: <NavbarDrawer pagePath={PagePath.HOME_OTHER_STRUCTURES} />,
      }}
    >
      <HomeOtherStructuresPageForm onNext={handleNext} />
    </Page>
  );
};

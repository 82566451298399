import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import { CoverageFieldMainCoverageCheckboxComponent } from '../CoverageFieldComponents';

export const RoofReplacementCostCoverage: React.FC<CoverageQuestionProps> = (props) => {
  // Get the coverage selected fields and all the related subcoverage fields
  const [roofReplacementCostCoverageField] = useGroupedCoverageFields(
    props.carrierName,
    props.product,
    'roofReplacementCost',
    ['selected'],
  );

  // Define the logic around subcoverage fields which are
  // impacted by opt-in/ opt-out feature for main coverage field
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(
    roofReplacementCostCoverageField,
    [],
  );

  const handleroofReplacementCostCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );

  if (!roofReplacementCostCoverageField.exists) return null;

  const isRoofReplacementCoverageSelected = castToBoolean(roofReplacementCostCoverageField.value);

  // TODO Probably with a new story - premium amount
  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='roofReplacementCostCoverage'
          field={roofReplacementCostCoverageField}
          isCoverageSelected={isRoofReplacementCoverageSelected}
          handleCoverageChange={handleroofReplacementCostCoverageChange}
        />
      </Grid>
    </div>
  );
};

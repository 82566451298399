import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { CoverageWithPolicyCenterDialog } from '../common';

export const NamedPerilIncreasedLimitCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { product, carrierName } = props;

  return (
    <CoverageWithPolicyCenterDialog
      coverageKey='namedPerilIncreasedLimit'
      product={product}
      carrierName={carrierName}
    />
  );
};

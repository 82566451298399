import { useCallback } from 'react';

import { env } from '@ecp/env';
import {
  getOfferDetailsForProduct,
  getOfferProductsSelected,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';
import type { OptionMetadata } from '@ecp/types';

import { useGetProducerAndQuoteDetails } from '.';

const useGetDiscountForPreview = (
  Product: Product,
): {
  [key: string]: string;
} => {
  const offerPropertyInfo = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, Product),
  );
  const productDisplayName = getProductDisplayNameFromProduct(Product);

  // Pull the applied discounts from the offer, remove the word 'Discount', map them to the proper type to be used by the component later
  const appliedDiscountsFromOffer = offerPropertyInfo?.details?.appliedDiscounts?.map(
    (discount) => {
      return { title: `${discount.replace('Discount', '')}` };
    },
  ) as OptionMetadata[];

  return appliedDiscountsFromOffer.reduce((acc, item, index) => {
    acc[`${productDisplayName}Discount` + (index + 1)] = item.title;

    return acc;
  }, {} as { [key: string]: string });
};

const useBuildDiscountForPreview = (
  products: Product[],
): (() => { [key: string]: string | undefined } | null | undefined) => {
  const offerInfo = products.map(useGetDiscountForPreview);

  return useCallback(() => {
    return offerInfo?.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});
  }, [offerInfo]);
};

const objectToUrl = (obj: Record<string, string | undefined>): string => {
  const params: string[] = [];

  Object.entries(obj).forEach(([k, v]) => {
    if (v) params.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
  });

  return params.join('&');
};

export const useGetPreviewURL = (): (() => string) => {
  const producerAndQuoteDetails = useGetProducerAndQuoteDetails();
  const offerProductsSelected = useSelector((state: RootStore) => getOfferProductsSelected(state));

  const discountsForPreview = useBuildDiscountForPreview(offerProductsSelected);

  return useCallback(() => {
    const { ...previewDetails } = producerAndQuoteDetails();
    const { ...discountDetails } = discountsForPreview();

    const previewDetailString = objectToUrl({ ...previewDetails, ...discountDetails });

    return `${env.advanceEmailPreviewUrl}` + previewDetailString;
  }, [discountsForPreview, producerAndQuoteDetails]);
};

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { CoverageWithPolicyCenterDialog } from '../common';

export const PetCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { product, carrierName } = props;

  return (
    <CoverageWithPolicyCenterDialog coverageKey='pet' product={product} carrierName={carrierName} />
  );
};

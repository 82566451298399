import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'TPIResultOptions' })((theme) => ({
  tpiResultOption: {
    // marginTop: '1.25 rem',
    '& .MuiFormControlLabel-root': {
      marginTop: '1rem',
      borderRadius: 8,
      '&:has(input[type="radio"]:checked):hover': {
        backgroundColor: 'unset',
      },
    },
  },
}));

import { useCallback, useMemo } from 'react';

import { ensureStringArray } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import {
  createRef,
  deleteInquiryRef,
  getAllValues,
  getInquiryLoaded,
  getOfferProductsSelectedByType,
  getQuestion,
  getValueForPrefix,
  updateAddedRef,
  useField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, FieldsDef } from '@ecp/features/sales/shared/types';
import type { Fields, Option } from '@ecp/types';

import type { PolicyFields } from '../types';

export const useGetFieldByProductName = (policyFieldName: string): PolicyFields => {
  const useGetFieldKey = useFieldWithPrefix('', '');
  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );

  return {
    auto: useGetFieldKey(`${autoOfferProduct}.${policyFieldName}.ref`),
    property: useGetFieldKey(`${propertyOfferProduct}.${policyFieldName}.ref`),
  };
};

export interface PolicyLoss {
  lossDescription: string;
  lossDate: string;
  ref: string;
  lossAmountUserEntered: number;
  status: string;
  source: string;
}

export interface PolicyLossFields extends Fields {
  accidentOrClaim: FieldsDef<PolicyLoss>;
}

interface StringMap {
  [key: string]: string;
}

interface PolicyLossOptions {
  policyLossDescriptionMap: StringMap;
}

const getPolicyLoss = (
  ref: string,
  allValues: Answers,
  policyLossDescriptionMap: StringMap,
): PolicyLoss => {
  const getString = getValueForPrefix<string>(ref, allValues);
  const getNumber = getValueForPrefix<number>(ref, allValues);

  return {
    ref,
    lossDescription: policyLossDescriptionMap[getString('accidentOrClaimDescription')],
    lossDate: getString('accidentOrClaimDate'),
    lossAmountUserEntered: getNumber('lossAmountUserEntered'),
    status: getString('accidentStatus'),
    source: getString('accidentSource'),
  };
};

const arrayToObject = (array: Option[]): StringMap =>
  array.reduce((obj, item) => {
    // TODO StringMap must really be { [key: string]: React.ReactElement | string }
    // Change type and make sure all usage doesn't have unexpected behavior
    // @ts-ignore FIXME ASAP
    obj[item.value] = item.label;

    return obj;
  }, {} as StringMap);

const usePolicyLossOptions = (): PolicyLossOptions => {
  const policyLossDescriptionQuestion = useSelector(
    getQuestion('accidentOrClaim.<id>.accidentOrClaimDescription'),
  );
  const policyLossDescriptionMap = useMemo(() => {
    return arrayToObject(policyLossDescriptionQuestion.options || []);
  }, [policyLossDescriptionQuestion?.options]);

  return {
    policyLossDescriptionMap,
  };
};

export const useGetPolicyLossFields = (policyLossRef: string): PolicyLossFields => {
  const usePolicyField = useFieldWithPrefix(policyLossRef, 'accidentOrClaim.<id>');

  return {
    accidentOrClaim: {
      ref: useField(policyLossRef),
      lossDate: usePolicyField('accidentOrClaimDate'),
      lossDescription: usePolicyField('accidentOrClaimDescription'),
      lossAmountUserEntered: usePolicyField('lossAmountUserEntered'),
      status: usePolicyField('accidentStatus'),
      source: usePolicyField('accidentSource'),
    },
  };
};

export const usePolicyLoss = (policyLossRef: string): PolicyLoss => {
  const allValues = useSelector(getAllValues);
  const { policyLossDescriptionMap } = usePolicyLossOptions();

  return getPolicyLoss(policyLossRef, allValues, policyLossDescriptionMap);
};

export const usePolicyLosses = (productName: string, policyFieldName: string): PolicyLoss[] => {
  const allValues = useSelector(getAllValues);
  const { policyLossDescriptionMap } = usePolicyLossOptions();
  const policyLossRefKey = useGetFieldByProductName(policyFieldName);
  let policyLossRefType = '';
  if (productName === 'auto') {
    policyLossRefType = policyLossRefKey.auto.key;
  }
  const refs: string[] = ensureStringArray(allValues[policyLossRefType]);

  return refs.map((ref) => getPolicyLoss(ref, allValues, policyLossDescriptionMap));
};

export const useAddPolicyLoss = (productName: string, policyFieldName: string): (() => string) => {
  const dispatch = useDispatch();
  const inquiryLoaded = useSelector(getInquiryLoaded);
  const policyLossRefKey = useGetFieldByProductName(policyFieldName);

  return useCallback(() => {
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin: 'apps/sales/edsp-asp/src/common/utils/policyLossModelUtil.ts',
          functionOrigin: 'useAddPolicyLoss/useCallback',
        },
      });
      throw new Error('inquiry not loaded');
    }

    const policyLossRef = dispatch(createRef('accidentOrClaim'));

    let policyLossRefType = '';
    if (productName === 'auto') {
      policyLossRefType = policyLossRefKey.auto.key;
    }
    dispatch(
      updateAddedRef({
        type: policyLossRefType,
        newRef: policyLossRef,
      }),
    );

    return policyLossRef;
  }, [inquiryLoaded, dispatch, productName, policyLossRefKey.auto.key]);
};

export const useRemovePolicyLoss = (): ((policyLoss: PolicyLoss) => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(
    async (policyLoss: PolicyLoss) => {
      await dispatch(deleteInquiryRef(policyLoss.ref));
    },
    [dispatch],
  );
};

import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldReadOnlyComponent,
  CoverageFieldSelectComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './LandlordCoverage.styles';

export const LandlordCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();

  const [landlordCoverageField, limitField, fairRentalValueField] = useGroupedCoverageFields(
    props.carrierName,
    props.product,
    'landlord',
    ['selected', 'limit', 'fairRentalValue'],
  );
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(landlordCoverageField, [
    limitField,
    fairRentalValueField,
  ]);

  const handleLandlordCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );
  if (!landlordCoverageField.exists) return null;

  const isLandlordCoverageSelected = castToBoolean(landlordCoverageField.value);

  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='landlordCoverage'
          field={landlordCoverageField}
          isCoverageSelected={isLandlordCoverageSelected}
          handleCoverageChange={handleLandlordCoverageChange}
        />
        {isLandlordCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            <CoverageFieldSelectComponent field={limitField} />
            <CoverageFieldReadOnlyComponent field={fairRentalValueField} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

import { useMemo } from 'react';

import { Typography } from '@mui/material';

import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getLobForOfferProductsSelected } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';

import { usePropertyField } from '../../utils';

export const SingleOrMultiFamilyQuestion: React.FC<QuestionProps> = (props) => {
  const singleOrMultiFamilyField = usePropertyField('singleOrMultiFamily');

  const lineOfBusiness = useSelector(getLobForOfferProductsSelected);
  const filterOptionsByProduct = useMemo(() => {
    const options = singleOrMultiFamilyField?.props?.options?.filter((item) => {
      if (lineOfBusiness === LineOfBusiness.HOME) {
        return item.policyTypes?.includes('home');
      } else {
        return item.policyTypes?.includes('renters');
      }
    });

    return options;
  }, [lineOfBusiness, singleOrMultiFamilyField]);

  if (!singleOrMultiFamilyField) return null;

  const {
    label = singleOrMultiFamilyField.question?.title,
    trackingName = 'single_or_multi_family_question',
  } = props;

  return (
    <Select
      {...singleOrMultiFamilyField.props}
      id='singleOrMultiFamily'
      options={filterOptionsByProduct}
      groupLabel={<Typography variant='body4'>{label}</Typography>}
      trackingName={trackingName}
      inputButtonAriaLabel='singleOrMultiFamily'
      fullWidth={false}
    />
  );
};

import { useCallback } from 'react';

import { getAnswers, getDriverRefs, getVehicleRefs } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, AnswerValue } from '@ecp/features/sales/shared/types';
import type { ProductName } from '@ecp/features/shared/product';

import metadata from '../metadata';

const determineRouteToPLPC = (
  lob: ProductName,
  driverRefs: string[],
  vehicleRefs: string[],
  deltaFieldRefs: string[],
  allAnswers: Answers,
): boolean => {
  const refsMap = {
    driver: driverRefs,
    vehicle: vehicleRefs,
    delta: deltaFieldRefs,
  };

  return Object.entries(metadata[lob as keyof typeof metadata]).some(([key, value]) => {
    if (refsMap[key]) {
      return refsMap[key].some((ref) => {
        return Object.entries(value).some(([questionKey, question]) => {
          const answerKey = ref === questionKey ? questionKey : `${ref}.${questionKey}`;

          return allAnswers[answerKey] === question.routeToPLPCValue;
        });
      });
    }

    return false;
  });
};

// check if quote should be routed to PLPC
export const useShouldRouteToPLPC = (): ((lob: ProductName) => boolean | undefined) => {
  const driverRefs = useSelector(getDriverRefs);
  const vehicleRefs = useSelector(getVehicleRefs);
  const allAnswers = useSelector(getAnswers);
  const deltaFieldRefs: string[] = Object.keys(metadata.auto.delta);

  return useCallback(
    (lob: ProductName) => {
      return determineRouteToPLPC(lob, driverRefs, vehicleRefs, deltaFieldRefs, allAnswers);
    },
    [allAnswers, deltaFieldRefs, driverRefs, vehicleRefs],
  );
};

// flip answers back to defaults before route to PLPC
export const useUpdatedAnswers = (lob: ProductName): Record<string, AnswerValue> => {
  const driverRefs = useSelector(getDriverRefs);
  const vehicleRefs = useSelector(getVehicleRefs);
  const allAnswers = useSelector(getAnswers);
  const updatedAnswers: Record<string, AnswerValue> = {};
  const deltaFieldRefs: string[] = Object.keys(metadata.auto.delta);
  const refsMap = { driver: driverRefs, vehicle: vehicleRefs, delta: deltaFieldRefs };

  Object.entries(metadata[lob as keyof typeof metadata]).forEach(([key, value]) => {
    const refs = refsMap[key];
    if (!refs) return;

    refs.forEach((ref) => {
      Object.entries(value).forEach(([questionKey, question]) => {
        const answer = allAnswers[`${ref}.${questionKey}`];
        if (answer !== question.defaultValue) {
          const answerKey = ref === questionKey ? questionKey : `${ref}.${questionKey}`;
          updatedAnswers[answerKey] = question.defaultValue;
        }
      });
    });
  });

  return updatedAnswers;
};

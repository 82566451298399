import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ThirdPartyInterestPageDetails' })((theme) => ({
  next: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  content: {
    margin: 0,
    width: '100%',
  },
  contentTitle: {
    ...theme.typography.body4,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  contentSubTitle: { ...theme.typography.body2, color: theme.palette.text.secondary },
  divider: {
    margin: '40px 0px 40px 0px',
    width: '100%',
  },
  snackBarWidth: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
}));

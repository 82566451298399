import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldSelectComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './HiddenWaterCoverage.styles';

export const HiddenWaterCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();

  // Get the coverage selected fields and all the related subcoverage fields
  const [hiddenWaterCoverageField, limitField] = useGroupedCoverageFields(
    props.carrierName,
    props.product,
    'hiddenWater',
    ['selected', 'limit'],
  );

  // Define the logic around subcoverage fields which are
  // impacted by opt-in/ opt-out feature for main coverage field
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(hiddenWaterCoverageField, [
    limitField,
  ]);

  const handleHiddenWaterCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );

  if (!hiddenWaterCoverageField.exists) return null;

  const isHiddenWaterCoverageSelected = castToBoolean(hiddenWaterCoverageField.value);

  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='hiddenWaterCoverage'
          field={hiddenWaterCoverageField}
          isCoverageSelected={isHiddenWaterCoverageSelected}
          handleCoverageChange={handleHiddenWaterCoverageChange}
        />
        {isHiddenWaterCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            <CoverageFieldSelectComponent field={limitField} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

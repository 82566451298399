import { isUndefined, omitBy } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import { env } from '@ecp/env';

import type { Product } from '../types';
import { type SearchResult, TPISearchType } from '../types';
import { getCommonHeadersForTpiRequest } from '../utils';

const MAX_SEARCH = 500;

interface TpiResultsParams {
  toggleValue: TPISearchType;
  searchCompanyName: string;
  searchCityName: string;
  searchStateName: string;
  product: Product;
}

export const fetchTpiResults = async ({
  toggleValue,
  searchCompanyName,
  searchCityName,
  searchStateName,
  product,
}: TpiResultsParams): Promise<SearchResult[]> => {
  const criteria = toggleValue === TPISearchType.STARTS_WTH ? 'startswith' : 'includes';
  const queryString = new URLSearchParams(
    omitBy(
      {
        criteria,
        name: searchCompanyName,
        line: product,
        maxSearch: `${MAX_SEARCH}`,
        city: searchCityName,
        state: searchStateName,
      },
      isUndefined,
    ),
  );
  const url = `${env?.ecpDalTpiRoot}/search?${queryString}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      redirect: 'follow',
      headers: {
        ...getCommonHeadersForTpiRequest(),
      },
    });

    if (!response.ok) {
      datadogLog({
        logType: 'error',
        message: `Failed to fetch data from TPI database. HTTP Status: ${response.status}`,
        context: {
          logOrigin: 'libs/features/sales/tpi/src/features/thirdPartyInterest/api/tpiSearch.tsx',
          contextType: 'Fetch error',
          functionOrigin: 'fetchTpiResults',
        },
      });

      return []; // Return an empty array instead of throwing an error
    }

    const data = await response.json();

    if (!data.tpis || data.tpis.length === 0) {
      datadogLog({
        logType: 'info',
        message: 'No results found in TPI search.',
        context: {
          logOrigin: 'libs/features/sales/tpi/src/features/thirdPartyInterest/api/tpiSearch.tsx',
          contextType: 'No results',
          functionOrigin: 'fetchTpiResults',
        },
      });

      return []; // Return an empty array if no data is found
    }

    return data.tpis;
  } catch (error) {
    datadogLog({
      logType: 'error',
      message: `Unexpected error in fetchTpiResults: ${error}`,
      context: {
        logOrigin: 'libs/features/sales/tpi/src/features/thirdPartyInterest/api/tpiSearch.tsx',
        contextType: 'Unexpected error',
        functionOrigin: 'fetchTpiResults',
      },
    });

    return []; // Return an empty array on network or unexpected errors
  }
};

import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ItemizedPersonalPropertyCoverage' })((theme) => ({
  fullWidth: {
    width: '100%',
  },
  subCoverageRoot: {
    marginLeft: 74,
  },
  marginTop: {
    marginTop: 20,
  },
  snackBar: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
  displayItemValue: {
    ...theme.typography.body4,
  },
  label: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },
  bottomLabel: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  listHeaderText: {
    marginTop: 15,
    marginLeft: -10,
    marginBottom: 20,
    ...theme.typography.body4,
    color: theme.palette.text.secondary,
  },
  totalValueStyle: {
    paddingLeft: 16,
  },
}));

import { useCallback } from 'react';

import { isMasked } from '@ecp/utils/common';

import { useAddConditionValues, useAddFields, useInitValues } from '@ecp/features/sales/form';
import {
  useVehicleFields,
  validateVinFormatRegex as validateVinFormat,
} from '@ecp/features/sales/quotes/auto';
import type { AnswerValue, Field } from '@ecp/types';

import { useVehicleInfo } from '../../../utils/vehicleOptions';

type EntryMode = 'vehicleInfo' | 'vin';

export const useVehicleInfoOrVinQuestion = (
  vehicleRef: string,
): {
  make: Field;
  model: Field;
  series: Field;
  vin: Field;
  year: Field;
  stubVin: Field;
  vehicleDetailId: Field;
  vehicleInfoOrVin: Field;
  msrpPrice: Field;
  handleVehicleInfoOrVinActionOnComplete: (value: EntryMode) => Promise<void>;
} => {
  const { make, model, series, vin, year, stubVin, vehicleDetailId, vehicleInfoOrVin, msrpPrice } =
    useVehicleFields(vehicleRef);
  useInitValues({
    // ECP-12410 Following successful A/B test, this now always defaults to vin
    [vehicleInfoOrVin.key]: 'vin',
  });
  useAddFields({ make, model, series, vin, year });
  useAddConditionValues({
    conditionalFields: [vin],
    isExcluded: () => vehicleInfoOrVin.value === 'vehicleInfo',
  });
  useAddConditionValues({
    conditionalFields: [make, model, series],
    isExcluded: () => vehicleInfoOrVin.value === 'vin',
  });

  const { fetchVehicleInfo } = useVehicleInfo(
    make,
    model,
    series,
    vin,
    stubVin,
    vehicleDetailId,
    msrpPrice,
  );

  const handleVehicleInfoOrVinActionOnComplete = useCallback(
    async (value: EntryMode): Promise<void> => {
      vehicleInfoOrVin.props.actionOnComplete(value);
      // If the user switches tab from Vin to VehicleInfo
      // fetch make/model/series based on the VIN
      // This is specially helpful for partial prefill
      // with incorrect year/make/model/series values
      if (
        value === 'vehicleInfo' &&
        year.value &&
        !validateVinFormat(vin.value as string) &&
        !isMasked(vin.value)
      ) {
        await fetchVehicleInfo(year.value as string, vin.value as string);
      }
    },
    [fetchVehicleInfo, vehicleInfoOrVin.props, vin.value, year.value],
  ) as (value: AnswerValue) => Promise<void>;

  return {
    make,
    model,
    series,
    vin,
    year,
    stubVin,
    vehicleDetailId,
    vehicleInfoOrVin,
    msrpPrice,
    handleVehicleInfoOrVinActionOnComplete,
  };
};

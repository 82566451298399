import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldTextboxComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './DockCollapseCoverage.styles';

export const DockCollapseCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();
  const [dockCollapseCoverageField, permanentDockField, portableDockField] =
    useGroupedCoverageFields(props.carrierName, props.product, 'dockCollapse', [
      'selected',
      'permanentDock',
      'portableDock',
    ]);
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(dockCollapseCoverageField, [
    permanentDockField,
    portableDockField,
  ]);

  const handleDockCollapseCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );
  if (!dockCollapseCoverageField.exists) return null;

  const isDockCollapseCoverageSelected = castToBoolean(dockCollapseCoverageField.value);

  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='dockCollapseCoverage'
          field={dockCollapseCoverageField}
          isCoverageSelected={isDockCollapseCoverageSelected}
          handleCoverageChange={handleDockCollapseCoverageChange}
        />
        {isDockCollapseCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            <CoverageFieldTextboxComponent displayAsDollarAmount field={permanentDockField} />
            <CoverageFieldTextboxComponent displayAsDollarAmount field={portableDockField} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

import { Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';

import { useCoverageSelectedField, useGroupedCoverageFields } from '../../utils';
import {
  CoverageFieldMainCoverageCheckboxComponent,
  CoverageFieldReadOnlyComponent,
} from '../CoverageFieldComponents';
import { useStyles } from './IdentityFraudExpenseCoverage.styles';

export const IdentityFraudExpenseCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();

  // Get the coverage selected fields and all the related subcoverage fields
  const [identityFraudExpenseCoverageField, limitField, deductibleField] = useGroupedCoverageFields(
    props.carrierName,
    props.product,
    'identityFraudExpense',
    ['selected', 'limit', 'deductible'],
  );

  // Define the logic around subcoverage fields which are
  // impacted by opt-in/ opt-out feature for main coverage field
  const selectedCoverageFieldChangeHandler = useCoverageSelectedField(
    identityFraudExpenseCoverageField,
    [limitField, deductibleField],
  );

  const handleIdentityFraudExpenseCoverageChange = useEvent(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      selectedCoverageFieldChangeHandler(newChecked);
    },
  );

  if (!identityFraudExpenseCoverageField.exists) return null;

  const isIdentityFraudExpenseCoverageSelected = castToBoolean(
    identityFraudExpenseCoverageField.value,
  );

  // TODO Probably with a new story - premium amount
  return (
    <div>
      <Grid container justifyItems='space-between'>
        <CoverageFieldMainCoverageCheckboxComponent
          coverageName='identityFraudExpenseCoverage'
          field={identityFraudExpenseCoverageField}
          isCoverageSelected={isIdentityFraudExpenseCoverageSelected}
          handleCoverageChange={handleIdentityFraudExpenseCoverageChange}
        />

        {isIdentityFraudExpenseCoverageSelected && (
          <Grid container item className={classes.subCoverageRoot}>
            <CoverageFieldReadOnlyComponent field={limitField} />
            <CoverageFieldReadOnlyComponent field={deductibleField} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

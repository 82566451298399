import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'QuoteResumeErrorPageForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    padding: '30px 30px 60px',
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 10,
    height: 4,
    borderBottomWidth: 0,
  },
}));

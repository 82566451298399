import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'HomeReplacementCostCard' })((theme) => ({
  addSpacing: {
    ...theme.mixins.addSpacing,
    marginTop: 30,
  },
  iframe: {
    border: 'none',
    width: '100%',
    height: '100%',
  },
  snackBarWidth: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    height: 'fit-content',
  },
  replacementCostText: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
  replacementCost: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
  },
}));

type Metadata = {
  label: string;
  value: string;
}[];

export const StructureUseMetadata: Metadata = [
  {
    label: 'SINGLE_OR_MULTI_FAMILY.SINGLE_FAMILY',
    value: 'Single Family Dwelling',
  },
  {
    label: 'SINGLE_OR_MULTI_FAMILY.DUPLEX',
    value: 'Two Family (Duplex)',
  },
  {
    label: 'SINGLE_OR_MULTI_FAMILY.TOWN',
    value: 'Single Family Attached Interior Unit',
  },
];

export const GarageTypeMetadata: Metadata = [
  {
    label: 'GARAGE.TYPE.ATTACHED_NO_LIVING_SPACE_ABOVE',
    value: 'Attached / Built-In',
  },
  {
    label: 'GARAGE.TYPE.BUILT_IN_LIVING_SPACE_ABOVE',
    value: 'Attached / Built-In',
  },
  {
    label: 'GARAGE.TYPE.BASEMENT',
    value: 'Basement',
  },
  {
    label: 'GARAGE.TYPE.CARPORT',
    value: 'Carport',
  },
];

export const GarageSizeMetadata: Metadata = [
  {
    label: 'GARAGE.SIZE.ONE_CAR',
    value: '1',
  },
  {
    label: 'GARAGE.SIZE.ONE_AND_HALF_CAR',
    value: '1.5',
  },
  {
    label: 'GARAGE.SIZE.TWO_CAR',
    value: '2',
  },
  {
    label: 'GARAGE.SIZE.TWO_AND_HALF_CAR',
    value: '2.5',
  },
  {
    label: 'GARAGE.SIZE.THREE_CAR',
    value: '3',
  },
  {
    label: 'GARAGE.SIZE.THREE_AND_HALF_CAR',
    value: '3.5',
  },
  {
    label: 'GARAGE.SIZE.FOUR_CAR',
    value: '4',
  },
  {
    label: 'GARAGE.SIZE.FOUR_AND_HALF_CAR',
    value: '4.5',
  },
  {
    label: 'GARAGE.SIZE.FIVE_CAR',
    value: '5',
  },
  {
    label: 'GARAGE.SIZE.FIVE_AND_HALF_CAR',
    value: '5.5',
  },
  {
    label: 'GARAGE.SIZE.SIX_CAR',
    value: '6',
  },
  {
    label: 'GARAGE.SIZE.SIX_AND_HALF_CAR',
    value: '6.5',
  },
  {
    label: 'GARAGE.SIZE.SEVEN_CAR',
    value: '7',
  },
  {
    label: 'GARAGE.SIZE.SEVEN_AND_HALF_CAR',
    value: '7.5',
  },
  {
    label: 'GARAGE.SIZE.EIGHT_CAR',
    value: '8',
  },
  {
    label: 'GARAGE.SIZE.EIGHT_AND_HALF_PLUS_CAR',
    value: '8.5 or more',
  },
];

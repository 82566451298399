import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { useAclReportForPerson } from '@ecp/features/sales/quotes/auto';
import { Link, Page } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { updatePageStatus, usePniRef } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { AutoLossesAndViolationsPageForm } from '../../forms/AutoLossesAndViolationsPageForm';
import { useStyles } from './AutoLossesAndViolationsPage.styles';

export const AutoLossesAndViolationsPage: React.FC = () => {
  const { classes } = useStyles();
  const navigateToDiscount = useNavigateToPage(PagePath.AUTO_DISCOUNTS);
  // ACL is 1 report url per household, and sapi is sending with PNI ID
  const pniRef = usePniRef();
  const aclReport = useAclReportForPerson(pniRef);

  const dispatch = useDispatch();

  const navigateToNext = useCallback(async () => {
    dispatch(updatePageStatus(NavStatus.VALID));
    await navigateToDiscount();
  }, [dispatch, navigateToDiscount]);

  const actionBarItems = !!aclReport && (
    <Grid container marginLeft={4} className={classes.actionBarContent}>
      <GridItem sm={12} topSpacing='xs'>
        <Link className={classes.link} href={aclReport} target='_blank' rel='noreferrer'>
          VIEW ACL
        </Link>
      </GridItem>
    </Grid>
  );

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.AUTO_LOSSES_AND_VIOLATIONS} />,
        }}
        title='Review Auto Losses & Violations'
        analyticsElement='choice.lossesAndViolationsDetails.page'
        actionBarItems={actionBarItems}
      >
        <AutoLossesAndViolationsPageForm onNext={navigateToNext} />
      </Page>
    </div>
  );
};

import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

import { Grid } from '@mui/material';

import { useEvent } from '@ecp/utils/react';

import { downloadAndSaveDocuments } from '@ecp/features/sales/checkout';
import { Button } from '@ecp/features/sales/shared/components';
import { SendAndPreviewEmailQuotes } from '@ecp/features/sales/shared/email-quotes';
import {
  fetchProducerDetails,
  getOfferProductsSelected,
  getProducerDetails,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { getProductNameFromProduct } from '@ecp/features/shared/product';
import { IconMaterialDesignFileCloudDownload, IconUISend } from '@ecp/themes/partners/amfam-adv';

import { useStyles } from './DownloadSendQuoteButtons.styles';

interface Props {
  isProcessing?: boolean;
  setEmailActionInfoType: Dispatch<SetStateAction<{ status: string; message: string }>>;
}

export const DownloadSendQuoteButtons: React.FC<Props> = (props) => {
  const { isProcessing, setEmailActionInfoType } = props;
  const { classes } = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [isSendQuoteProcessing, setIsSendQuoteProcessing] = useState(false);

  const dispatch = useDispatch();
  const selectedProducts = useSelector(getOfferProductsSelected);
  const DOWNLOAD_FORM_NAME = 'PrintQuote';
  const producerDetails = useSelector(getProducerDetails);

  const handleDownloadProposalClick = useEvent(async (): Promise<void> => {
    for (const product of selectedProducts) {
      await dispatch(
        downloadAndSaveDocuments({
          product: product,
          documentTypeName: DOWNLOAD_FORM_NAME,
          documentDisplayName: DOWNLOAD_FORM_NAME + getProductNameFromProduct(product),
        }),
      );
    }
  });

  const handleSendEmailClick = useEvent(async () => {
    setIsSendQuoteProcessing(true);
    if (!(producerDetails && producerDetails.name)) await dispatch(fetchProducerDetails());
    setIsSendQuoteProcessing(false);
    setOpenDialog(true);
  });

  return (
    <Grid item xs={12}>
      <Grid container className={classes.premiumBoxButtonContainer}>
        <Grid item xs={12}>
          <Button
            classes={{
              root: classes.premuimActionButton,
            }}
            onClick={handleDownloadProposalClick}
            isProcessing={isProcessing}
            trackingName='download_quote_proposal'
            trackingLabel={DOWNLOAD_FORM_NAME}
            analyticsElement='choice.policyCoveragePage.DownloadProposal'
            data-testid='downloadProposal'
            variant='iconTextMedium'
            icon={<IconMaterialDesignFileCloudDownload className={classes.icon} />}
          >
            DOWNLOAD PROPOSAL
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            classes={{
              root: classes.premuimActionButton,
            }}
            onClick={handleSendEmailClick}
            isProcessing={isProcessing || isSendQuoteProcessing}
            analyticsElement='choice.policyCoveragePage.SendQuoteButton'
            data-testid='sendQuoteButton'
            variant='iconTextMedium'
            icon={<IconUISend className={classes.icon} />}
          >
            SEND QUOTE
          </Button>
        </Grid>
        {openDialog && (
          <SendAndPreviewEmailQuotes
            openDialog={openDialog}
            handleDialog={setOpenDialog}
            isProcessing={isProcessing}
            setEmailActionInfoType={setEmailActionInfoType}
          />
        )}
      </Grid>
    </Grid>
  );
};

import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyDeltaField } from '../../utils/usePropertyField';

export const DeltaSolarPanelsQuestion: React.FC<QuestionProps> = (props) => {
  const solarPanelsField = usePropertyDeltaField('root', 'property.solarPanels');

  if (!solarPanelsField) return null;

  const { trackingName = 'SolarPanels', label = solarPanelsField?.question?.title } = props;

  return (
    <RadioGroupWithOptions
      {...solarPanelsField.props}
      variant='button'
      id='SolarPanels'
      label={label}
      trackingName={trackingName}
      trackingLabel={solarPanelsField.props.value}
    />
  );
};

import { useCallback } from 'react';

import { env } from '@ecp/env';
import {
  getIsBundleForOfferProductsSelected,
  getLineOfBusinessOrder,
  submitDelta,
  submitPolicyType,
  submitProofDraft,
  updateAnswers,
  updateLobOrder,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { LineOfBusinessUnbundled } from '@ecp/features/shared/product';
import { getProductNameFromUnbundledLob } from '@ecp/features/shared/product';

export const useRouteToPLPC = (): ((
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  lineOfBusiness: LineOfBusinessUnbundled,
  plpcQuoteNumber: string,
  shouldApplyDelta?: boolean,
) => Promise<void>) => {
  const dispatch = useDispatch();
  const selectedProducts = useSelector(getLineOfBusinessOrder);
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);

  return useCallback(
    async (
      setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
      lineOfBusiness: LineOfBusinessUnbundled,
      plpcQuoteNumber: string,
      shouldApplyDelta?: boolean,
    ) => {
      setIsSubmitting(true);
      const policyType = getProductNameFromUnbundledLob(lineOfBusiness);
      if (isBundle && lineOfBusiness) {
        // This functionality will exclude lineOfBusiness product that meet the 'route to PLPC' conditions in a bundle scenario and retain the remaining Line Of Business product as updatedLineOfBusiness.
        const updatedLineOfBusiness = selectedProducts.find(
          (product) => !lineOfBusiness.includes(product),
        );
        await dispatch(updateAnswers({ answers: { 'product.lob': `${updatedLineOfBusiness}` } }));
        // Submits the policy type after the modification of product.lob
        await dispatch(submitPolicyType());
        // Updates the line of business (LoB) order in the state, optionally merging with existing orders and stores the result in the answers.
        await dispatch(updateLobOrder(updatedLineOfBusiness, true));
      }
      if (shouldApplyDelta) {
        await dispatch(submitDelta({ policyTypes: [policyType] }));
      } else {
        await dispatch(submitProofDraft({ policyTypes: [policyType] }));
      }

      window.open(`${env.advancePolicyCenterRedirectUrl}?JobNumber=${plpcQuoteNumber}`, '_self');
    },
    [dispatch, isBundle, selectedProducts],
  );
};

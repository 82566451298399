import { merge } from '@ecp/utils/common';

import {
  GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
  GraphicAutoCoverageUnderinsuredUninsuredMotoristImageUrl,
} from '@ecp/themes/base';
import type { OptionsMetadata } from '@ecp/types';

import { PersonalInjuryProtectionMetadata } from '../PersonalInjuryProtection/PersonalInjuryProtection.metadata.js';
import {
  CarrierAutoPolicyMetadata as BaseCarrierAutoPolicyMetadata,
  CarrierVehicleDeductibleMetadata as BaseCarrierVehicleDeductibleMetadata,
} from './AutoCoverageQuestion.metadata.js';

export const CarrierAutoPolicyMetadata: { [productKey: string]: OptionsMetadata } = {
  'amfam-adv.auto': BaseCarrierAutoPolicyMetadata['amfam-adv.auto'],
  'amfam.auto': {
    // New coverage keys with V4 integration
    BodilyInjury: BaseCarrierAutoPolicyMetadata['amfam.auto'].bodilyInjury,
    PropertyDamage: BaseCarrierAutoPolicyMetadata['amfam.auto'].propertyDamage,
    MedicalExpense: BaseCarrierAutoPolicyMetadata['amfam.auto'].medical,
    UninsuredMotorist: BaseCarrierAutoPolicyMetadata['amfam.auto'].uninsuredMotorist,
    uninsuredPropertyDamageLineLimit:
      BaseCarrierAutoPolicyMetadata['amfam.auto'].uninsuredPropertyDamageLineLimit,
    underinsuredPropertyDamageLineLimit:
      BaseCarrierAutoPolicyMetadata['amfam.auto'].underinsuredPropertyDamageLineLimit,
    UnderInsuredMotorist: {
      primaryImage: GraphicAutoCoverageUnderinsuredUninsuredMotoristImageUrl,
      title: 'Underinsured Motorist Bodily Injury',
      primaryText:
        'May cover expenses for bodily injury damages or death caused by a driver who does not have sufficient liability insurance limits to cover the full amount of a covered loss.',
      secondaryText:
        'Applies to damages for you as well as passengers in your insured vehicle. The first amount is the coverage limit per person; the second amount is the coverage limit per accident.',
      subCoverages: {
        underinsuredMotoristStacking: {
          title: 'Stacking',
          primaryText:
            'Stacking allows you to combine the UM/UIM Bodily Injury coverage limits for you and members of your household when two or more vehicles are insured under your policy.',
        },
      },
      stateOptions: {
        PA: {
          primaryText:
            'Pays for legally collectible damages for bodily injury or death caused by a driver who does not have sufficient liability insurance limits to cover the full amount of the loss.',
        },
        MA: {
          title: 'Bodily Injury Caused by an Underinsured Auto',
        },
      },
    },
    uninsuredUnderinsuredMotorist:
      BaseCarrierAutoPolicyMetadata['amfam.auto'].uninsuredUnderinsuredMotorist,
    deathAndDismemberment: BaseCarrierAutoPolicyMetadata['amfam.auto'].deathAndDismemberment,
    personalInjuryProtectionBasicPip: {
      primaryImage: GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
      title: 'Basic Personal Injury Protection (Exists as PIP)',
      primaryText:
        'This coverage pays for medical expenses, wage loss, replacement services, certain survivor’s losses, and funeral expenses up to the specified amount for injuries resulting from a motor accident regardless of fault.',
      secondaryText:
        'Provides benefits for eligible family household members who have not rejected PIP. A deductible amount applies to each accident. Guest Coverage provides benefits for guest passengers in your covered vehicle when all family members have rejected PIP in writing with the State of KY. Added PIP provides additional limits above Basic PIP coverage.',
      subCoverages: {
        personalInjuryProtectionBasicPipDeductible: {
          title: 'Deductible',
          primaryText:
            'Applies to each accident and to the total of any payments made for Personal Injury Protection',
        },
        personalInjuryProtectionAddedPip: {
          title: 'Added Personal Injury Protection',
          primaryText:
            'Provides additional limits above the Basic Personal Injury Protection coverage. If eligible members of the households have previously rejected Basic PIP this coverage can be bought back upon buying back Basic PIP. Subject to exclusions, conditions, limits and other provisions.',
        },
        personalInjuryProtectionBasicPipGuest: {
          title: 'Guest Coverages',
          primaryText:
            'Provides benefits for guest passengers of your covered vehicle when all eligible family household members have rejected Basic Personal Injury Protection in writing with the State of Kentucky.',
        },
        PersonalInjuryProtectionTort: {
          title: 'Tort',
          primaryText:
            'Full Tort provides unrestricted right to seek financial compensation for injuries caused by other drivers. Coverage includes medical, out-of-pocket expenses & compensation for pain & suffering. Limited Tort limits your right of financial compensation except for medical & out-of-pocket expenses.',
        },
      },
      stateOptions: {
        NY: {
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
          subCoverages: {
            personalInjuryProtectionOBELCoverage: {
              title: 'Optional Basic Economic Loss',
              primaryText:
                'Optional coverage adds an additional limit to the Basic Personal Injury Protection Benefits coverage for basic economic loss, loss of earnings from work, psychiatric, physical or occupational therapy, and rehabilitation',
            },
            personalInjuryProtectionAdditionalCoverage: {
              title: 'Additional PIP Coverage',
              primaryText:
                'Provides additional benefits that reimburse for extended economic loss on account of personal injuries sustained by an eligible injured person and caused by an accident arising out of the use or operation of a motor vehicle during the policy period. It also provides out of state guest passenger coverage.',
            },
            personalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'A deductible applies to each accident and to the total of any payments made for benefits that fall under this coverage for a named insured or relative.',
            },
            noFaultWorkLossDeduction: {
              title: 'No Fault Work Loss Reduction',
              primaryText:
                'You may qualify for a discount if you or a member of your household opts to select an employee sponsored Wage Continuation Program for your primary coverage instead of the Work Loss coverage offered under this coverage.',
            },
          },
        },
        KY: {
          title: 'Basic Personal Injury Protection',
        },
      },
    },
    tort: BaseCarrierAutoPolicyMetadata['amfam.auto'].tort,
    uninsuredUnderinsuredPropertyDamageLineLimit:
      BaseCarrierAutoPolicyMetadata['amfam.auto'].uninsuredUnderinsuredPropertyDamageLineLimit,
    firstPartyBenefits: BaseCarrierAutoPolicyMetadata['amfam.auto'].firstPartyBenefits,
    personalInjuryProtection: {
      primaryImage: GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
      title: 'Personal Injury Protection',
      primaryText:
        'Provides no-fault benefits including medical expense, income loss, essential services expenses, and funeral expenses.',
      secondaryText:
        'Provides no-fault benefits when you or passengers in your car get hurt in an accident.',
      subCoverages: {
        PersonalInjuryProtectionWorkLoss: {
          title: 'Work Loss Option',
          primaryText:
            'Work Loss can be waived if neither you or your spouse received any earned income from employment during the 31 days prior to applying and do not anticipate earned income for at least 180 days from inception date. You will be asked to sign a statement verifying that you meet these requirements.',
        },
        personalInjuryProtectionWorkLossDeductible: {
          title: 'Work Loss Deductible',
          primaryText: 'A deductible applies before your insurance will pay for a coverage claim.',
        },
        personalInjuryProtectionStacking: {
          title: 'Stacking',
          primaryText:
            'In the case of a covered loss, Stacking will multiply the Personal Injury Protection benefits you receive by the number of vehicles insured under this policy.',
        },
        personalInjuryProtectionLevel: {
          title: 'Sub Coverage PIP Options',
          primaryText:
            'When you ’Waive’ this insurance there is no coverage for the named insured or members of the insured’s family residing in the household. Selection of ’Full’ covers the named insured and family members residing in the household along with anyone injured while in the named insured’s vehicle.',
        },
        workLossBenefits: {
          title: 'Work Loss Benefits',
          primaryText:
            'Pays for wage loss – up to the coverage limit – incurred by an insured person who sustains bodily injury due to a motor vehicle accident and who, as a result, is disabled and unable to work. Available to named insured(s) age 60 or older.',
        },
        personalInjuryProtectionDeductible: {
          title: 'Deductible',
          primaryText:
            'A deductible applies to each accident and to the total of any payments made for benefits that fall under this coverage for a named insured or relative.',
        },
        personalInjuryProtectionExcessAttendantCare: {
          title: 'Excess Attendant Care',
          primaryText:
            'Excess attendant care is additional coverage purchased for attendant care above the PIP medical coverage limit selected for your policy',
        },
        // example : amfam.auto.coverages.policy.pipExcludeDriver-0ntkc2ya
        'pipExcludeDriver-<driver.id>': {
          title: 'Exclude at least one driver from PIP',
          primaryText:
            'Lower limits have less expensive premiums then options with higher or unlimited PIP coverage. You can select to exclude specific drivers (Option 4) or exclude all drivers (Option 6) if the excluded named insured, spouse or resident relatives have qualified health coverage or are covered under another policy with PIP coverage.',
          isExcludeDriver: true,
        },
      },
      stateOptions: {
        UT: {
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].UT,
        },
        MA: {
          primaryText:
            'Coverage may pay expenses the eligible injured person incurs for medical expenses no matter who causes the accident.',
          secondaryText:
            'It may also include coverage for loss of income, essential services and funeral expenses.',
          subCoverages: {
            personalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'Personal Injury Protection coverage offers selectable per person/per occurrence limits.',
            },
            personalInjuryProtectionDeductibleOption: {
              title: 'Deductible Option',
              primaryText:
                'There are two deductible options for Personal Injury Protection:  You and your spouse or You and your household.',
            },
          },
        },
        KS: {
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].KS,
          title: 'Personal Injury Protection Options',
          primaryText:
            'Personal Injury Protection (referred to as PIP) provides no-fault benefits including medical expense, income loss, essential services expenses, and funeral expenses.',
          secondaryText:
            'Provides no-fault benefits when you or passengers in your car get hurt in an accident.',
        },
        MN: {
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].MN,
        },
        ND: {
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].ND,
        },
        OR: {
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].OR,
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
        },
        WA: {
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].WA,
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
        },
        TX: {
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
        },
        KY: {
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
        },
        MI: {
          title: 'Personal Injury Protection (PIP - Per Person/Per Accident)',
          primaryText:
            'Coverage pays for certain expenses resulting from a motor vehicle accident, regardless of fault. It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
          secondaryText:
            'Lower limits have less expensive premiums then options with higher or unlimited PIP coverage. You can select to exclude specific drivers (Option 4) or exclude all drivers (Option 6) if the excluded driver(s) have qualified health coverage or are covered under another policy with PIP coverage.',
          subCoverages: {
            'pipExcludeDriver-<driver.id>': {
              title: 'Exclude at least one driver from PIP',
              primaryText:
                'When excluding driver(s) you may need to provide proof from your health insurer or employer identifying individuals covered under the insurance; proof that the policy does not exclude or limit auto accident injuries and proof that the individual deductible meets the state criteria. Medicare & Medicaid participants may need proof of enrollment.',
              isExcludeDriver: true,
            },
          },
        },
        DE: {
          title: 'Personal Injury Protection Per Person/Per Accident',
          primaryText:
            'Personal Injury Protection provides no-fault benefits when you or passengers in your car get hurt in an accident.',
          secondaryText:
            'Coverage does not apply to any property and a deductible amount for each accident may apply. Please refer to your policy details for specific coverage information for your state.',
          subCoverages: {
            personalInjuryProtectionDeductibleOption: {
              title: 'Deductible option',
              primaryText:
                'Personal Injury Protection coverage offers selectable per person/per occurrence limits.  There are two deductible options for Personal Injury Protection. 1) you and your spouse or 2) you and your household',
            },
            PersonalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'The deductible selected will apply to losses covered under Personal Injury Protection.',
            },
            PersonalInjuryProtectionDeductibleAppliesTo: {
              title: 'Deductible Applies To',
            },
          },
        },
        FL: {
          title: 'Personal Injury Protection Per Person',
          primaryText:
            'Personal injury protection provides no-fault benefits when you or passengers in your car get hurt in an accident.',
          secondaryText: 'Subject to exclusions, conditions, limits and other provisions.',
          personalInjuryTable: PersonalInjuryProtectionMetadata['amfam.auto'].FL,
          subCoverages: {
            personalInjuryProtectionOption: {
              title: 'PIP Option ',
              primaryText:
                'Coverage provides benefits to you (the named insured), your spouse, any dependent relatives, and persons who are occupants of your insured vehicle or persons struck by your insured vehicle.',
            },
            personalInjuryProtectionDeductibleOption: {
              title: 'Deductible option',
              primaryText:
                'Option A applies to the named insured and spouse residing in the same household. Option B applies to the named insured, spouse, and all dependent resident relatives residing in the same household.',
            },
            personalInjuryProtectionDeductible: {
              title: 'Deductible',
              primaryText:
                'A deductible applies to each accident and to the total of any payments made for this coverage for any eligible family household member.',
            },
            personalInjuryProtectionWorkLoss: {
              title: 'Exclude Work Loss',
              primaryText:
                'Allows you to opt out of coverage for loss of gross income and loss of earning capacity if you are unable to work due to accident-related injuries.',
            },
            personalInjuryProtectionWorkLossOption: {
              title: 'Exclude Work Loss Option',
              primaryText:
                'Option 1 applies to the named insured and spouse residing in the same household. Option 2 applies to the named insured, spouse, and all dependents resident relatives residing in the same household.',
            },
          },
        },
        HI: {
          title: 'Personal Injury Protection Benefits Limit',
          primaryText:
            'Pays for appropriate and reasonable medical treatment and associated expenses that are necessary as a result of accidental harm and which are comparable to the requirements for prepaid health care plans.',
          secondaryText:
            'Limits and exclusions may apply. Please refer to your policy details for specific coverage information for your state.',
          subCoverages: {
            personalInjuryProtectionMedicalDeductible: {
              title: 'Personal Injury Protection Benefits Deductible',
              primaryText:
                'The Personal Injury Protection deductible is a per accident deductible that applies to you and your spouse, relatives residing in your household, and any other person who was injured as a passenger in a motor vehicle accident involving your insured car.',
            },
            personalInjuryProtectionWageLossBenefits: {
              title: 'Wage Loss Benefits',
              primaryText:
                'Pays for wage loss incurred by an insured person who sustains bodily injury due to a motor vehicle accident and who, as a result, is disabled and unable to work.',
            },
            personalInjuryProtectionDeathBenefits: {
              title: 'Death Benefits',
              primaryText:
                'Pays benefits upon the death of an insured person that results from bodily injury due to a motor vehicle accident. We will pay death benefits to the surviving spouse; to dependent children if there is no surviving spouse; or to the estate of the insured person if there is no surviving spouse or dependent children.',
            },
            personalInjuryProtectionFuneralBenefits: {
              title: 'Funeral Expense Benefits',
              primaryText:
                'Pays funeral expense benefits – up to the coverage limit – for funeral expenses upon the death of an insured person resulting from bodily injury due to a motor vehicle accident.',
            },
            personalInjuryProtectionAltHealthCareBenefits: {
              title: 'Alternative Health Care Benefits',
              primaryText:
                'Pays alternative health care benefits – up to the coverage limit – incurred by an insured person who sustains bodily injury due to a motor vehicle accident.',
            },
          },
        },
      },
    },
    personalInjuryProtectionMedRehabExpenses: {
      primaryImage: GraphicAutoCoveragePersonalInjuryProtectionImageUrl,
      title: 'Personal Injury Protection Medical & Rehabilitation Expenses',
      primaryText:
        'Pays for certain expenses resulting from an auto accident, regardless of fault.  It is designed to provide immediate benefits in case of injury and guarantees accident victims fair compensation without legal entanglements.',
      secondaryText:
        'Includes coverage for reasonable charges incurred by a named insured for necessary products, services, accommodations, recovery, or rehabilitation (provided by a licensed medical provider).  Please refer to your policy details for specific coverage information for your state.',
      subCoverages: {
        PersonalInjuryProtectionWorkLossBenefits: {
          title: 'Work Loss Benefits',
          primaryText:
            'Includes reimbursement for loss of income for work an insured person would have performed after the date of the accident had he or she not been injured in said accident.',
        },
        PersonalInjuryProtectionFuneralExpense: {
          title: 'Funeral Benefits',
          primaryText:
            'Includes payment of the actual costs up to the Funeral Benefits limit for each insured person, paid to the survivors to help cover the funeral and other funeral-related costs.',
        },
      },
    },
    personalInjuryProtectionExtraPackageCoverage:
      BaseCarrierAutoPolicyMetadata['amfam.auto'].personalInjuryProtectionExtraPackageCoverage,
    personalInjuryProtectionMedicalMain:
      BaseCarrierAutoPolicyMetadata['amfam.auto'].personalInjuryProtectionMedicalMain,
    supplementalSpousalLiabilityCoverage:
      BaseCarrierAutoPolicyMetadata['amfam.auto'].supplementalSpousalLiabilityCoverage,
    coverageForRentedVehicles:
      BaseCarrierAutoPolicyMetadata['amfam.auto'].coverageForRentedVehicles,
    limitedPropertyDamage: BaseCarrierAutoPolicyMetadata['amfam.auto'].limitedPropertyDamage,
    incomeLossBenefits: BaseCarrierAutoPolicyMetadata['amfam.auto'].incomeLossBenefits,
    totalDisability: BaseCarrierAutoPolicyMetadata['amfam.auto'].totalDisability,
    nonVehicularPropertyDamage:
      BaseCarrierAutoPolicyMetadata['amfam.auto'].nonVehicularPropertyDamage,
    personalInjuryProtectionAccidentalDeathBenefits:
      BaseCarrierAutoPolicyMetadata['amfam.auto'].personalInjuryProtectionAccidentalDeathBenefits,
  },
  'connect.auto': BaseCarrierAutoPolicyMetadata['connect.auto'],
  'connect-boost.auto': BaseCarrierAutoPolicyMetadata['connect-boost.auto'],
};

export const CarrierVehicleDeductibleMetadata: { [productKey: string]: OptionsMetadata } = merge(
  {},
  BaseCarrierVehicleDeductibleMetadata,
  {
    'amfam.auto': {
      // New coverage keys with V4 integration
      Comprehensive: BaseCarrierVehicleDeductibleMetadata['amfam.auto'].comprehensive,
      Collision: BaseCarrierVehicleDeductibleMetadata['amfam.auto'].collision,
    },
  },
);

import { useCallback } from 'react';

import { GridItem } from '@ecp/components';
import { useAddConditionValues, useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import { useField } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';

interface Props {
  vehicleRef: string;
}

export const VehicleUseQuestion: React.FC<Props> = (props) => {
  const { vehicleRef } = props;
  const primaryUse = useField(`${vehicleRef}.primaryUse`);
  useAddFields({ primaryUse });
  useInitValues({ [primaryUse.key]: 'VEHICLE_USE.PRIMARY.COMMUTE' });
  useAddConditionValues({
    conditionalFields: [primaryUse],
    isRequiredOverride: () => true,
  });

  const handleVehicleUseClick = useCallback(
    async (value: string) => {
      await primaryUse.props.actionOnComplete(value);
    },
    [primaryUse],
  );

  if (!primaryUse.exists) return null;

  return (
    <GridItem xs={12}>
      <Select
        {...(primaryUse.props as OptionProps)}
        id='VehiclePrimaryUse'
        groupLabel='What is this vehicle primarily used for?'
        trackingName='primary_use_selection'
        actionOnComplete={handleVehicleUseClick}
        fullWidth={false}
      />
    </GridItem>
  );
};

import { useCallback } from 'react';

import { getAnswers } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers } from '@ecp/features/sales/shared/types';
import type { ProductName } from '@ecp/features/shared/product';
import type { AnswerValue } from '@ecp/types';

import metadata from '../../../metadata';

export const useShouldRouteToPLPC = (): ((lob: ProductName) => boolean) => {
  const allAnswers = useSelector(getAnswers);

  const determineRouteToPLPC = (lob: ProductName, allAnswers: Answers): boolean => {
    return Object.entries(metadata).some(([key, value]) => {
      if (key === lob) {
        return Object.entries(value).some(([questionKey, question]) => {
          const answer = allAnswers[`property.${questionKey}`];
          if (
            questionKey === 'noOfMortgages' &&
            typeof answer === 'number' &&
            typeof question.routeToPCValue === 'number'
          ) {
            return answer > question.routeToPCValue;
          }
          if (
            questionKey === 'material' &&
            allAnswers[`property.roof.${questionKey}`] === question.routeToPCValue
          ) {
            return true;
          }

          return answer === question.routeToPCValue;
        });
      }

      return false;
    });
  };

  return useCallback((lob: ProductName) => determineRouteToPLPC(lob, allAnswers), [allAnswers]);
};

export const useUpdatedAnswers = (lob: string): Record<string, AnswerValue> => {
  const allAnswers = useSelector(getAnswers);
  const updatedAnswers: Record<string, AnswerValue> = {};

  Object.entries(metadata).forEach(([key, value]) => {
    if (key === lob) {
      Object.entries(value).forEach(([questionKey, question]) => {
        const answer = allAnswers[`property.${questionKey}`];
        if (answer !== question.defaultValue) {
          if (
            questionKey === 'noOfMortgages' &&
            typeof answer === 'number' &&
            typeof question.routeToPCValue === 'number' &&
            answer > question.routeToPCValue
          ) {
            updatedAnswers[`property.${questionKey}`] = question.defaultValue;
          } else if (questionKey !== 'noOfMortgages') {
            updatedAnswers[`property.${questionKey}`] = question.defaultValue;
          }
        }
      });
    }
  });

  return updatedAnswers;
};
